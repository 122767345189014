import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

// import TemplateWarnings from './TemplateWarnings'
import TemplateStructure from './TemplateStructure'
import TemplateDragSection from './TemplateDragSection'
import Loading from '../../../../../components/Loading'

const TemplateCreator = ({ data, refetchtemplate }) => {
  if (!data) return <Loading />

  const { id, accountId } = data.ictemplate

  return (
    <>
      {/* <TemplateWarnings ictemplate={ictemplate} /> */}
      <Row gutter={[8]} style={{ paddingTop: '24px' }}>
        <Col span={14}>
          <TemplateStructure data={data} refetchtemplate={refetchtemplate} />
        </Col>
        <Col span={10}>
          <TemplateDragSection ictemplateId={id} accountId={accountId} />
        </Col>
      </Row>
    </>
  )
}

TemplateCreator.propTypes = {
  data: PropTypes.object,
  refetchtemplate: PropTypes.func.isRequired
}

export default TemplateCreator
