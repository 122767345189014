import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Button, Space, Alert } from 'antd'

import { memoryUser } from '../auth/appMemory'

import CenterContent from '../components/CenterContent'
import VerificationHistory from '../models/VerificationHistory'
import AlertError from '../components/AlertError'

import RouteErrorDisplay from '../components/RouteErrorDisplay'

const SEND_EMAIL_VERIFICATION_BY_USER_ID = gql`
  mutation sendEmailVerificationByUserId ($id: ID!, $verificationInput: VerificationInput!) {
    sendEmailVerificationByUserId (id: $id, verificationInput: $verificationInput)
  }
`
const domainName = process.env.REACT_APP_DOMAIN_NAME

const VerifyUser = () => {
  const user = useReactiveVar(memoryUser)

  if (user.emailIsVerified) return useHistory().push('/')

  const [sendEmailVerificationByUserId, { data, loading, error }] = useMutation(SEND_EMAIL_VERIFICATION_BY_USER_ID, {
    variables: {
      id: user.id,
      verificationInput: {
        domainName
      }
    },
    errorPolicy: 'all'
  })

  const [sent, setSent] = useState()

  useEffect(() => {
    if (data?.sendEmailVerificationByUserId) return setSent(true)
  }, [data])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <CenterContent title='Get verified' bordered>
      <Space size='large' direction='vertical' style={{ width: '100%' }}>

        {
          sent &&
            <Alert
              message='Email sent. Click the token we sent to your Email to get verified.'
              type='success'
              action={
                <Space>
                  <Button size='small' onClick={() => setSent(false)}>
                    OK
                  </Button>
                </Space>
              }
            />
        }
        {
          !sent &&
            <>
              <Button type='primary' block='true' loading={loading} onClick={() => sendEmailVerificationByUserId()}>
                Email me a verification link
              </Button>
            </>
        }

        <VerificationHistory sent={sent} />

        <AlertError error={error} />

      </Space>
    </CenterContent>
  )
}

export default VerifyUser
