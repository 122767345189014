import React from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom'

import { memoryUser, reactiveIcquestionnairesPublished } from '../auth/appMemory'
import ItemCardMeta from '../components/ItemCardMeta'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

import { Menu } from 'antd'
const { SubMenu } = Menu

// parents: LeftSideLayout, TopHeader
const LiveQuestionnairesMenu = ({ parent, mode, theme, style, showAdminLinks, showBullets, className }) => {
  if (!memoryUser()) return null

  const { currentAccountUserPermissions, emailIsVerified } = memoryUser()
  const { viewContributor } = currentAccountUserPermissions

  if (!viewContributor) return null

  if (!emailIsVerified) return null

  const history = useHistory()
  const openKeys = getOpenKeys(useLocation(), history)

  // OrderedListOutlined,
  // UnorderedListOutlined

  return (
    <Menu theme={theme} defaultSelectedKeys={openKeys} mode={mode} selectedKeys={openKeys} className={className}>

      {
        reactiveIcquestionnairesPublished() &&
          <SubMenu
            style={{ paddingLeft: 0 }}
            key='/ic/questionnaires'
            className='submenuLight'
            title='My Questionnaires'
            popupOffset={[0, 0]}
            onTitleClick={
              () => memoryUser() && history.push('/ic/questionnaires')
            }
          >
            {
              reactiveIcquestionnairesPublished().map((questionnaireLive) => {
                return (
                  <Menu.Item key={`liveQuestionnaireId_${questionnaireLive.id}`} icon={showBullets ? <FontAwesomeIcon icon={faSquare} style={{ fontSize: '6px' }} color='#000' /> : null}>
                    <Link to={`/ic/questionnaire/${questionnaireLive.id}/form/home`}>

                      <ItemCardMeta item={questionnaireLive} view='small' size='small' />

                    </Link>
                  </Menu.Item>
                )
              })
            }
          </SubMenu>
      }

    </Menu>
  )
}

export default LiveQuestionnairesMenu

const getOpenKeys = (location, history) => {
  const openKeys = [location.pathname]

  // 20211123 GF what is this for?
  // if (icquestionnairesPublished) {
  //   icquestionnairesLive = icquestionnairesPublished?.reduce((acc, current) => {
  //     if (current.live) acc.push(current)
  //     return acc
  //   }, [])
  // }
  //
  // const params = location?.pathname ? location.pathname.split('/') : []
  // if (params) {
  //   openKeys = [params[3], params[5]]
  // }
  //
  // if (location?.pathname.startsWith('/ic/questionnaire')) {
  //   openKeys.push('/ic/questionnaires')
  // }

  return openKeys
}
