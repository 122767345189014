import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { setLocaleStorage } from '../auth/appStorage'
import { memoryUser } from '../auth/appMemory'

import { Result, Progress } from 'antd'

import Logo from '../components/Logo'
import { APP_COLOR, networkOnlyAllErrorsVariables } from '../consts'
import CenterContent from '../components/CenterContent'
import SimpleLink from '../components/SimpleLink'
import RouteErrorDisplay from '../components/RouteErrorDisplay'

const SIGN_OUT = gql`
  mutation {
    signOut
  }
`

const DELAY = 20

const SignOut = () => {
  const [signOutMutation, { data, error }] = useMutation(SIGN_OUT, networkOnlyAllErrorsVariables)

  const [percent, setPercent] = useState(0)
  const [counting, setCounting] = useState(percent < 100)
  const [cancelled, setCancelled] = useState(false)

  useEffect(() => {
    if (cancelled) return
    if (percent >= 100) return setCounting(false)
    setTimeout(() => {
      return setPercent(percent + 1)
    }, DELAY)
  }, [percent])

  useEffect(() => {
    if (error) setLocaleStorage('accessToken', null)
  }, [error])

  useEffect(() => {
    if (data?.signOut) {
      setLocaleStorage('accessToken', null)
      memoryUser(false)
      // window.location.reload(true)
    }
  }, [data])

  useEffect(() => {
    if (!cancelled && !counting) return signOutMutation()
  }, [cancelled, counting])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <CenterContent>
      <Result
        title='Signing out...'
        icon={
          <div className='result-logo'>
            <Logo color='true' title='Sign out now' />
          </div>
        }
        extra={
          <SimpleLink
            onClick={() => setCancelled(true)}
            to='/'
            content={
              <Progress
                strokeColor={APP_COLOR}
                type='circle'
                percent={percent}
                width={50}
                format={() => counting && 'Cancel'}
              />
            }
          />
        }
      />
    </CenterContent>
  )
}

export default SignOut
