import React from 'react'

import Page from '../../../components/Page'
import QuestionnaireLiveList from '../../../components/sections/ic/questionnaire/QuestionnaireLiveList'

import { PageHeader } from 'antd'

const Questionnaires = () => {
  const title = 'Questionnaires'
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: title
    }
  ]
  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PageHeader>
        <QuestionnaireLiveList />
      </PageHeader>
    </Page>
  )
}

export default Questionnaires
