import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Space } from 'antd'

import CenterContent from '../components/CenterContent'
import SimpleLink from '../components/SimpleLink'
import AlertError from '../components/AlertError'
import ResetPassword from './ResetPassword'

const CHECK_PASSWORD_TOKEN = gql`
  query checkPasswordToken ($checkPasswordTokenInput: CheckPasswordTokenInput!) {
    checkPasswordToken (checkPasswordTokenInput: $checkPasswordTokenInput)
  }
`

const CheckPasswordToken = () => {
  const { passwordToken } = useParams()
  const { loading, error } = useQuery(CHECK_PASSWORD_TOKEN, {
    variables: { checkPasswordTokenInput: { passwordToken } },
    errorPolicy: 'all'
  })

  if (error) {
    return (
      <CenterContent>
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <AlertError error={error} />
          <SimpleLink
            to='/forgotpassword'
            content='Send a new Reset Password link'
          />
        </Space>
      </CenterContent>
    )
  }

  return !loading && <ResetPassword passwordToken={passwordToken} />
}

export default CheckPasswordToken
