import React from 'react'
import { withRouter } from 'react-router-dom'

import Page from '../../../../components/Page'
import QuestionList from '../../../../components/sections/admin/ic/template/question/QuestionList'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICQuestions = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    },
    {
      path: '',
      breadcrumbName: 'Questions'
    }
  ]
  return (
    <>
      <Page title='Questions' breadcrumb={breadcrumb}>
        <PageInstructions />
        <QuestionList />
      </Page>
    </>
  )
}

export default withRouter(ICQuestions)
