// react
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

// ants design
import { Alert } from 'antd'

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// instructions
import instructionsJson from './pageInstructions.json'

const PageInstructions = ({ instruction: instructionInit }) => {
  const location = useLocation()
  const [instruction, setInstruction] = useState()

  useEffect(() => {
    if (instruction) return

    instructionsJson.forEach(obj => {
      if (location.pathname.startsWith(obj.path) || obj.path === '*') {
        (obj.message || obj.description) && setInstruction(obj)
      }
    })
  }, [location])

  useEffect(() => {
    instructionInit && setInstruction(instructionInit)
  }, [instructionInit])

  return (
    <>
      {
        (instruction?.message || instruction?.description) &&
          <div className='page-padding-leftRight'>
            <Alert
              message={instruction.message}
              description={instruction.description}
              type='info'
              showIcon
              icon={<FontAwesomeIcon icon={faInfoCircle} color='#1890ff' style={{ color: '#1890ff' }} />}
              style={{ backgroundColor: '#e6f7ff', border: '1px solid #91d5ff', marginBottom: '12px', alignItems: 'center' }}
            />
          </div>
        }
    </>
  )
}

PageInstructions.propTypes = {
  instruction: PropTypes.object
}

export default PageInstructions
