import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Alert } from 'antd'

const Note = ({ message }) => {
  return (
    <>
      {message?.length && <Alert description={<div className='content' dangerouslySetInnerHTML={{ __html: message.replace('\n', '<br/>') }} />} type='info' showIcon style={{ marginBottom: '12px', alignItems: 'center' }} />}
    </>
  )
}

Note.propTypes = {
  message: PropTypes.string.isRequired
}

export default Note
