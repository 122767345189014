// https://cloudinary.com/console/c-aa3a5ba1cb9ba2ae08b32ff687fce3/media_library/folders/be19551a290733e6719f2780e3e25910bc

export const variables = { fetchPolicy: 'no-cache', errorPolicy: 'all' }

export const imageStyle = { width: '100%', maxWidth: '95px' }

const IMAGE_CACHED_SIZE_MAX = 312

const CLOUDINARY_IMAGE_URI = `https://res.cloudinary.com/impactcalculator-io/image/upload/c_thumb,w_${IMAGE_CACHED_SIZE_MAX},g_face/assets/`

export const networkOnlyAllErrorsVariables = {
  errorPolicy: 'all',
  fetchPolicy: 'network-only'
}

export const LOGO_DOT_RING_WHITE = `${CLOUDINARY_IMAGE_URI}/logo_inverted_zm4fof.png`
export const LOGO_DOT_RING_COLOR = `${CLOUDINARY_IMAGE_URI}/spawed-header-logo_z6bxci.png`
export const LOGO_DOT_RING_COLOR_TITLE_BLACK = `${CLOUDINARY_IMAGE_URI}/logo-color-light-bg_letbah.png`
export const LOGO_DOT_RING_COLOR_TITLE_WHITE = `${CLOUDINARY_IMAGE_URI}/logo-color-dark-bg_iawezw.png`

export const NO_IMAGE_USER = `${CLOUDINARY_IMAGE_URI}/no-user-image-icon-0_l8c69y.png`
export const NO_IMAGE = `${CLOUDINARY_IMAGE_URI}/no-photo-available_lozcui.png`

export const APP_COLOR = '#59b343'
export const APP_COLOR_HALF_BLACK = '#acd9a1'

export const USER_FIELDS = `
  id
  emailObfuscated
  fullName
  imageURL
  cloudinaryImage
  emailIsVerified
`

export const CORE_FIELDS = `
  id
  name
  note
  imageURL
  cloudinaryImage
`

export const LOCATION_CORE_FIELDS = `
  ${CORE_FIELDS}
  country {
    name
    emoji
  }
  state {
    name
  }
  city {
    name
  }
`

export const PERMISSIONS_FIELDS = `
  accountId
  iAmOwner
  currentAccountId
  currentAccount {
    ${CORE_FIELDS}
  }
  currentAccountUsers {
    ${USER_FIELDS}
  }
  viewAccountAdministrator
  viewLocationsAdministrator
  viewContributor
  viewAccountAdministratorOnly
  viewLocationsAdministratorOnly
  viewContributorOnly
  locationIdsAdministrator
  locationsAdministrator {
    ${LOCATION_CORE_FIELDS}
  }
  locationIdsContributor
  locationsContributor {
    ${LOCATION_CORE_FIELDS}
  }
`
const CURRENT_ACCOUNT_PERMISSIONS = `
  currentAccountId
  currentAccountUserPermissions {
    ${PERMISSIONS_FIELDS}
  }
`

export const ME_FIELDS = `
  ${CORE_FIELDS}
  ${CURRENT_ACCOUNT_PERMISSIONS}
  emailIsVerified
  emailObfuscated
  hasPassword
  fullName

  permissions {
    SUPER_ADMIN
    ACCOUNTS_ADMIN
    LOCATIONS_ADMIN
    LOCATIONS_GENERAL
    NO_ROLES
    HAS_ROLES

    menuAccess {
      hasMenu
      hasIcMenu
      add
      edit
      list
      icList
      coreMenuNames
      icMenuNames
    }

  }

  usersAdministrativeAccounts {
    selectableAccounts {
      isUserIdAccountOwner
      ${CORE_FIELDS}
      owner {
        ${CORE_FIELDS}
        fullName
        emailIsVerified
      }
    }
    editableAccounts {
      isUserIdAccountOwner
      ${CORE_FIELDS}
      owner {
        ${CORE_FIELDS}
        fullName
        emailIsVerified
      }
    }

  }
`

export const PROFILE_FIELDS = `
  ${CORE_FIELDS}

  ${CURRENT_ACCOUNT_PERMISSIONS}
  emailIsVerified
  emailObfuscated
  firstName
  lastName
  fullName
  email
  howDidYouHearAboutUsNote

  permissions {
    SUPER_ADMIN
    ACCOUNTS_ADMIN
    LOCATIONS_ADMIN
    LOCATIONS_GENERAL
    NO_ROLES
    HAS_ROLES

    menuAccess {
      hasMenu
      hasIcMenu
      add
      edit
      list
      icList
      coreMenuNames
      icMenuNames
    }

    bestRole {
      id
      note
      displayableName
      rank
    }

  }

  userAdminAccounts {
    ${CORE_FIELDS}
  }
  userAdminLocations {
    ${LOCATION_CORE_FIELDS}
  }
  userGeneralLocations {
    ${LOCATION_CORE_FIELDS}
  }
  accountgroups {
    ${CORE_FIELDS}
  }
`
