import React from 'react'
import { gql, useQuery } from '@apollo/client'

import ReportLoading from './Report0002/ReportLoading'
import ReportForm from './Report0002/ReportForm'

const QUERY_REPORTING_ACCOUNT_CLIENT_FORM_DATA = gql`
  query reportingAccountClientFormData ($reportingAccountClientFormDataInput: ReportingAccountClientFormDataInput!) {
    reportingAccountClientFormData (reportingAccountClientFormDataInput: $reportingAccountClientFormDataInput) {
      clientFormData {
        expectedFields {
          field
          fieldtype
        }
        stats {
          users
          countries
          states
          cities
          locations
          groups
          surveys
          surveyed
          questionnaires
          templates
          categories
          questions
          answers
          responses
        }
        minMaxDates {
          minDate
          minDateFromNow
          maxDate
          maxDateFromNow
        }
        sets {
          field,
          data {
            value
            display
            count
          }
        }
        models {
          model
          field
          display
          data {
            id
            name
            imageURL
            cloudinaryImage
            hidden
            published
            country
            state
            city
            countryEmoji
          }
        }
      }
      clientNavigationalReports {
        name
        recordcount
        questionTypes {
          isBarchart
          name
          description
          recordcount
          groupBys {
            model
            display
            models {
              id
              order
              name
              note
              imageURL
              cloudinaryImage
              hidden
              published
              spawnLimit
              completionTimeUnit
              completionTimeLimit
              isPublic
              anonymous
              country
              state
              city
              countryEmoji
              stats {
                min
                max
                count
                percent
                sum
                submittedAt
                submittedAtAgo
                first
                last
                firstAgo
                lastAgo
                average
              }
              barCharts {
                key
                suggestion
                name
                questionName
                config {
                  isGroup
                  hasOwnColor
                  xField
                  yField
                  seriesField
                  data {
                    seriesLabel
                    seriesColor
                    dataLabel
                    dataValue
                    color
                    suggestion
                  }
                  color
                }
              }
            }
          }
        }
      }
      clientChartsPostDemo {
        questionnaireCharts {
            barCharts {
              key
              name
              chartDescription
              chartType
              reportFilename
              questionName
              config {
                isGroup
                hasOwnColor
                xField
                yField
                seriesField
                data {
                  seriesLabel
                  seriesColor
                  dataLabel
                  dataValue
                  color
                  suggestion
                }
                color
              }
            }

        }
      }
    }
  }
`

const ImpactReport0002 = ({ accountId }) => {
  const { data, error } = useQuery(QUERY_REPORTING_ACCOUNT_CLIENT_FORM_DATA, {
    variables: { reportingAccountClientFormDataInput: { accountId } },
    errorPolicy: 'all'
  })

  if (error) return <ReportLoading message='An error occured.' />

  if (!data?.reportingAccountClientFormData) return <ReportLoading message='Preparing chart dependencies...' />

  return <ReportForm accountId={accountId} reportingAccountClientFormData={data.reportingAccountClientFormData} />
}

export default ImpactReport0002
