import React from 'react'
import { Avatar, Typography, Space, Tooltip, Skeleton, Badge } from 'antd'

import { EyeInvisibleTwoTone } from '@ant-design/icons'
import ItemUpload from './ItemUpload'
import Note from '../../components/elements/Note'

import { NO_IMAGE_USER, NO_IMAGE } from '../consts'

const { Paragraph } = Typography

const ItemCardMeta = ({ loading, view = 'default', item, size = 64, shape = 'square', rows = 3, refetch, bordered, expand, width }) => {
  if (loading) return <Skeleton.Image />
  const locationTexts = []

  item?.city?.name && locationTexts.push(item.city.name)
  item?.state?.name && locationTexts.push(item.state.name)

  if (locationTexts.length === 0) {
    item?.country?.name && locationTexts.push(item.country.name)
  }

  const locationName = locationTexts.length !== 0 && `${item.name} (${locationTexts.join(', ')})`

  const title = locationName || item?.fullName || item?.title || item?.name

  const description = item?.emailObfuscated || item?.note

  const src = item?.cloudinaryImage || item?.imageURL
  const icon = item?.country?.emoji

  const fallbackImage = item?.fullName ? NO_IMAGE_USER : NO_IMAGE

  const expandable = Boolean(expand)

  if (view === 'questionnaireLiveImage') {
    const questionnaireLiveSplashSrc = src || fallbackImage
    return (
      <Avatar
        size={width}
        shape={shape}
        src={questionnaireLiveSplashSrc}
        draggable='false'
      />
    )
  }

  if (view === 'questionnaireLiveSplash') {
    return (
      <>
        <Typography.Title>
          {src && <Avatar
            shape={shape}
            src={src}
            draggable='false'
            style={{ marginRight: '8px' }}
                  />}
          {title}
        </Typography.Title>
        <Note message={description} />
      </>
    )
  }

  if (view === 'questionnaireForm') {
    const size = item.level ? 32 : 24

    return (
      <>
        {!!item.level && <>{src && <Avatar size={size} src={src} style={{ marginRight: '6px' }} />}{item.title}</>}
        {!item.level && <div style={{ marginBottom: '6px' }}>{src && <Avatar size={size} src={src} style={{ marginRight: '6px' }} />}{item.title}</div>}
      </>
    )
  }

  if (view === 'templatebuilder') {
    return (
      <Space size='small' align='middle'>
        {
          src &&
            <Avatar
              size={18}
              shape='circle'
              src={src}
              draggable='false'
            />
        }

        <Typography.Text style={{ fontSize: 16 }}>
          <Tooltip title={title}>
            {title}
          </Tooltip>
        </Typography.Text>

      </Space>
    )
  }

  if (view === 'avatar') {
    return (
      <CommonAvatar
        item={item}
        size={size}
        shape={shape}
        src={src}
        icon={icon}
        fallbackImage={fallbackImage}
        title={title}
        tipvisible='true'
      />
    )
  }

  if (view === 'small') {
    const stylesSmall = { }
    if (bordered) {
      stylesSmall.border = '1px solid #eee'
      stylesSmall.paddingRight = 6
      stylesSmall.padding = 6
    }

    return (
      <Space style={stylesSmall}>
        <CommonAvatar
          title={title}
          item={item}
          size={size}
          shape={shape}
          src={src}
          icon={icon}
        />
        {title}
      </Space>
    )
  }

  if (view === 'upload') {
    return (
      <DefaultView
        item={item}
        src={src}
        icon={icon}
        upload='true'
        refetch={refetch}
        fallbackImage={fallbackImage}
        size={size}
        shape={shape}
        title={title}
        rows={rows}
        expandable={expandable}
        description={description}
      />
    )
    // return (
    //   <ItemUpload
    //     refetch={refetch}
    //     item={item}
    //     button={
    //       <DefaultView
    //         item={item}
    //         src={src}
    //         icon={icon}
    //         fallbackImage={fallbackImage}
    //         size={size}
    //         shape={shape}
    //         title={title}
    //         rows={rows}
    //         expandable={expandable}
    //         description={description}
    //       />
    //     }
    //   />
    // )
  }

  return (
    <DefaultView
      src={src}
      icon={icon}
      fallbackImage={fallbackImage}
      size={size}
      shape={shape}
      title={title}
      rows={rows}
      expandable={expandable}
      description={description}
    />
  )
}

export default ItemCardMeta

const DefaultView = ({ src, icon, upload, fallbackImage, size, shape, title, rows, expandable, description, item, refetch }) => {
  return (
    <Space style={{ width: '100%' }}>
      <CommonAvatar
        item={item}
        refetch={refetch}
        size={size}
        shape={shape}
        src={src}
        icon={icon}
        upload={upload}
        fallbackImage={fallbackImage}
      />
      <Space size='small' direction='vertical' wrap>
        <Typography.Text style={{ fontSize: 32 }}>
          {title}
        </Typography.Text>
        <Paragraph
          ellipsis={{
            rows,
            expandable
          }}
        >
          {description}
        </Paragraph>
      </Space>
    </Space>
  )
}

const CommonAvatar = ({ src, icon, upload, fallbackImage, size, shape, title, tipvisible, item, refetch }) => {
  let count

  if (item?.__typename && ['User', 'UserPublic'].includes(item.__typename.toString())) {
    const verified = item.emailIsVerified === true || item.emailIsVerified === 'true' // fixed non boolean for UserPublic
    if (!verified) count = <EyeInvisibleTwoTone twoToneColor='#ffc069' />
  }

  const style = { }

  if (!src && icon) {
    style.padding = '1px'
    style.backgroundColor = 'white'
    style.border = '1px solid #eeeccc'
  }

  if (!src && !icon) {
    src = fallbackImage
  }

  let titleToUse = !item?.hideTip && title

  if (count && titleToUse) titleToUse += ' (unverified)'

  return (
    <>
      {
        upload &&
          <ItemUpload
            refetch={refetch}
            item={item}
            button={

              <Avatar
                key='uploader'
                style={style}
                size={size}
                shape={shape}
                src={src}
                draggable='false'
              />

            }
          />
      }
      {
        !upload &&
          <Tooltip title={titleToUse}>
            <Badge count={count}>
              <Typography.Text style={{ fontSize: 24 }}>
                {src && <Avatar
                  style={style}
                  size={size}
                  shape={shape}
                  src={src}
                  draggable='false'
                        />}
                {icon}
              </Typography.Text>
            </Badge>
          </Tooltip>
      }
    </>
  )
}
