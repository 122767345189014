import React from 'react'

import { Typography, Collapse, Space, Card, Alert, Avatar } from 'antd'
import ReportDisplay from './ReportDisplay'
const { Title } = Typography
const { Panel } = Collapse

const QuestionStats = ({ stats, index }) => {
  return null
}

const ReportsDocumentation = ({ clientNavigationalReports, loading }) => {
  return (
    <>
      {
        clientNavigationalReports.map((report, index) => {
          return (
            <Collapse key={`collpase${index}`} accordion defaultActiveKey='panel0'>
              {
                report.questionTypes.map((questionType, index) => {
                  return (
                    <Panel key={`panel${index}`} header={`${report.name} / ${questionType.name}`}>
                      <Alert size='small' message={`About ${questionType.name}:`} description={`${questionType.description}, recordcount: ${questionType.recordcount}`} />
                      <Space size='large' direction='vertical' style={{ width: '100%' }} key={`questions${index}`}>
                        {
                          questionType.groupBys.map((groupBy, index) => {
                            const { display, models } = groupBy

                            return (
                              <Space size='large' key={`model${index}`} direction='vertical' style={{ width: '100%' }}>
                                {
                                models.map((model, index) => {
                                  const { name, imageURL, countryEmoji, stats, barCharts } = model

                                  let src, icon

                                  if (imageURL) {
                                    src = imageURL
                                  }

                                  if (!src) {
                                    if (countryEmoji) icon = countryEmoji
                                  }

                                  const CardTitle = () => (
                                    <Space direction='vertical'>
                                      <Title level={4}>{display}</Title>
                                      <Space>
                                        <Avatar shape='square' size='small' src={src} icon={icon} />
                                        {name}
                                      </Space>
                                    </Space>
                                  )

                                  return (
                                    <Card
                                      key={`questioncard${index}`}
                                      title={<CardTitle />}
                                    >
                                      <QuestionStats stats={stats} index={index} />
                                      <ReportDisplay barCharts={barCharts} />
                                    </Card>
                                  )
                                })
                              }
                              </Space>
                            )
                          })
                        }
                      </Space>
                    </Panel>
                  )
                })
              }
            </Collapse>
          )
        })
      }
    </>
  )
}

export default ReportsDocumentation
