import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

// ant design
import { Grid, Row, Col, notification } from 'antd'
import { LinkOutlined, BarChartOutlined, CloseOutlined } from '@ant-design/icons'

// components
import QuestionString from '../../../elements/questions/QuestionString'
import QuestionNumber from '../../../elements/questions/QuestionNumber'
import QuestionDate from '../../../elements/questions/QuestionDate'
import QuestionSelectMultiple from '../../../elements/questions/QuestionSelectMultiple'
import QuestionRadio from '../../../elements/questions/QuestionRadio'
import QuestionRate from '../../../elements/questions/QuestionRate'
import SurveyQuestionResult from './widgets/SurveyQuestionResult'

// other libraries
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { useBreakpoint } = Grid

const QuestionnaireQuestion = ({ antForm, icquestionnaire, icnodeId, icnodeChildId, question }) => {
  const screens = useBreakpoint()
  const { icnodeChildId: paramIcnodeChildId } = useParams()
  const questionRef = useRef()
  const icquestionnaireId = icquestionnaire.id
  const landingPageRoute = icquestionnaire.landingPageRoute || 'ic/questionnaire'
  const enableAnchors = landingPageRoute === 'ic/questionnaire' // only show anchors for ic/questionnaire
  const [link] = useState(`${window.location.origin}/${landingPageRoute}/${icquestionnaireId}/form/${icnodeId}/question/${icnodeChildId}`)
  const [widgetVisible, setWidgetVisible] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [hasWidget, setHasWidget] = useState(false)

  const CopyClipboard = () => (
    <>
      {showLink &&
        <CopyToClipboard
          text={link}
          onCopy={() => {
            notification.info({
              message: 'Link to field copied to clipboard.',
              placement: 'topLeft'
            })
          }}
        >
          <LinkOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
        </CopyToClipboard>}
    </>
  )

  const toggleWidget = () => {
    setWidgetVisible(!widgetVisible)
  }

  const renderQuestion = () => (
    <>
      {question.icquestiontype === 'input' && question.inputtype === 'string' && <QuestionString question={question} icnodeChildId={icnodeChildId} />}
      {question.icquestiontype === 'input' && question.inputtype === 'date' && <QuestionDate question={question} icnodeChildId={icnodeChildId} />}
      {question.icquestiontype === 'input' && question.inputtype === 'number' && <QuestionNumber question={question} icnodeChildId={icnodeChildId} />}
      {question.icquestiontype === 'input' && question.inputtype === 'rate' && <QuestionRate question={question} icnodeChildId={icnodeChildId} />}
      {question.icquestiontype === 'select' && question.canselectmanyanswers && <QuestionSelectMultiple question={question} icnodeChildId={icnodeChildId} />}
      {question.icquestiontype === 'select' && !question.canselectmanyanswers && <QuestionRadio question={question} icnodeChildId={icnodeChildId} />}
    </>
  )

  useEffect(() => {
    setHasWidget(false)
  })

  useEffect(() => {
    if (paramIcnodeChildId === icnodeChildId) {
      questionRef.current?.scrollIntoView({ block: 'center', inline: 'start' })
    }
  }, [questionRef])

  return (
    <div style={{ position: 'relative' }}>
      <div
        onMouseEnter={() => setShowLink(false)/* change back to true when have routing perfected */}
        onMouseLeave={() => setShowLink(false)}
        ref={questionRef}
        style={{ position: 'relative', outline: (paramIcnodeChildId === icnodeChildId) ? '#ffeb3b dashed 2px' : undefined, background: (paramIcnodeChildId === icnodeChildId) ? '#ffeb3b36' : undefined }}
      >
        <div style={{ right: '0', position: 'absolute', zIndex: '99' }}>
          <>
            {enableAnchors && <CopyClipboard />}
            {hasWidget && !screens.xl && !widgetVisible && <>&nbsp;<BarChartOutlined onClick={toggleWidget} /></>}
            {hasWidget && !screens.xl && widgetVisible && <>&nbsp;<CloseOutlined onClick={toggleWidget} /></>}
          </>
        </div>
        {hasWidget &&
          <Row gutter={16}>
            <Col span={!screens.xl ? (!widgetVisible ? 24 : 0) : 16}>
              {renderQuestion()}
            </Col>
            <Col span={!screens.xl ? (widgetVisible ? 24 : 0) : 8}>
              <SurveyQuestionResult antForm={antForm} icnodeChildId={icnodeChildId} question={question} />
            </Col>
          </Row>}
        {!hasWidget && renderQuestion()}
      </div>
    </div>
  )
}

QuestionnaireQuestion.propTypes = {
  antForm: PropTypes.object.isRequired,
  icquestionnaire: PropTypes.object.isRequired,
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  icnodeId: PropTypes.string.isRequired
}

export default QuestionnaireQuestion
