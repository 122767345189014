// react
import React, { useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { memoryUser } from '../auth/appMemory'
import ItemCardMeta from '../components/ItemCardMeta'

// react router
import { Link } from 'react-router-dom'

// ant design
import { Menu, Typography, Tooltip, Row, Col } from 'antd'
import {
  ClusterOutlined,
  ApartmentOutlined,
  BarsOutlined,
  CodeOutlined,
  EnvironmentOutlined,
  GoldOutlined,
  LineChartOutlined,
  CopyOutlined,
  SettingOutlined
} from '@ant-design/icons'

const AccountAdminsistrationMenu = ({ mode, style, className }) => {
  const reactiveUser = useReactiveVar(memoryUser)

  const [user, setUser] = useState(reactiveUser)

  useEffect(() => {
    if (reactiveUser !== user) setUser(reactiveUser)
  }, [reactiveUser, user])

  if (!user) return null

  const { emailIsVerified, currentAccountUserPermissions } = user

  if (!emailIsVerified) return null

  const { viewAccountAdministrator, viewLocationsAdministrator, currentAccount, iAmOwner } = currentAccountUserPermissions

  if (!viewAccountAdministrator && !viewLocationsAdministrator) return null

  return (
    <>
      <Typography.Title level={5} className='title'>ADMINISTRATION</Typography.Title>

      <Menu theme='light' mode={mode} style={style} className={className} selectedKeys={[]}>

        {viewAccountAdministrator && iAmOwner && <Menu.Item key='/admin/ic/templates' className='menuItemLight' icon={<ClusterOutlined />}><Link to='/admin/ic/templates'>Templates</Link></Menu.Item>}

        {viewAccountAdministrator && <Menu.Item key='/admin/ic/questionnaires' className='menuItemLight' icon={<BarsOutlined />}><Link to='/admin/ic/questionnaires'>Questionnaires</Link></Menu.Item>}

        {viewAccountAdministrator && iAmOwner && <Menu.Item key='/admin/ic/templates/categories' className='menuItemLight' icon={<ApartmentOutlined />}><Link to='/admin/ic/templates/categories'>Categories</Link></Menu.Item>}

        {viewAccountAdministrator && iAmOwner && <Menu.Item key='/admin/ic/templates/questions' className='menuItemLight' icon={<CodeOutlined />}><Link to='/admin/ic/templates/questions'>Questions</Link></Menu.Item>}

        {viewAccountAdministrator && <Menu.Item key='/admin/ic/spawners' className='menuItemLight' icon={<CopyOutlined />}><Link to='/admin/ic/spawners'>Surveys</Link></Menu.Item>}

        {
          viewAccountAdministrator &&
            <Menu.Item className='menuItemLight' key='/admin/ic/reports' icon={<LineChartOutlined />} style={{ marginTop: 24 }}>
              <Link to='/admin/ic/reports'>Reporting</Link>
            </Menu.Item>
        }

        <Menu.Item className='menuItemLight' key='/admin/locations' icon={<EnvironmentOutlined />}>
          <Link to='/admin/locations'>Locations</Link>
        </Menu.Item>

        {
          viewAccountAdministrator &&
            <Menu.Item className='menuItemLight' key='/admin/accountgroups' icon={<GoldOutlined />}>
              <Link to='/admin/accountgroups'>Groups</Link>
            </Menu.Item>
        }

        {/*
          {
            list.includes('Appservice') &&
              <Menu.Item className='menuItemLight' key='/admin/accountsettings' icon={<SettingOutlined />}>
                <Link to='/notdone'>Appservice</Link>
              </Menu.Item>
          }

          {
            list.includes('Appservice') &&
              <Menu.Item className='menuItemLight' key='/admin/accountsettings' icon={<SettingOutlined />}>
                <Link to='/notdone'>Appservice</Link>
              </Menu.Item>
          }

          {
            list.includes('Servicesubscription') &&
              <Menu.Item className='menuItemLight' key='/admin/accountsettings' icon={<SettingOutlined />}>
                <Link to='/notdone'>Servicesubscription</Link>
              </Menu.Item>
          }

        */}

        {
          viewAccountAdministrator &&
            <Menu.Item className='menuItemLight' theme='dark' key='/admin/accountsettings' style={{ marginTop: 36 }}>
              <Tooltip title={currentAccount.name}>
                <Link to='/admin/accountsettings'>
                  <Row align='middle'>
                    <Col flex='1'>
                      <ItemCardMeta item={{ ...currentAccount, name: 'Account Settings' }} view='small' size='large' />
                    </Col>
                    <Col flex='1'>
                      <span style={{ marginTop: 6, float: 'right' }}><SettingOutlined style={{ fontSize: 20 }} /></span>

                    </Col>

                  </Row>

                </Link>
              </Tooltip>
            </Menu.Item>
        }

      </Menu>
    </>
  )
}

export default AccountAdminsistrationMenu
