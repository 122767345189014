import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Typography } from 'antd'

// components
import ItemCardMeta from '../../core/components/ItemCardMeta'
import Note from './Note'

export const TitleElement = ({ title, note, level, imageURL, cloudinaryImage, avatarSize, style }) => {
  return (
    <>
      {
        !!level &&
          <Typography.Title level={level} style={style}>
            <ItemCardMeta item={{ title, note, imageURL, cloudinaryImage, level }} view='questionnaireForm' />
          </Typography.Title>
      }
      {
        !level &&
          <div style={{ marginBottom: '6px', ...style }}>
            <ItemCardMeta item={{ title, note, imageURL, cloudinaryImage, level }} view='questionnaireForm' />
          </div>
      }
    </>
  )
}

TitleElement.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  imageURL: PropTypes.string,
  avatarSize: PropTypes.string,
  style: PropTypes.object
}

export const NoteElement = ({ note }) => {
  return (
    <>
      {note && <Note message={note} />}
    </>
  )
}

NoteElement.propTypes = {
  note: PropTypes.string
}

const AvatarElement = ({ title, level, note, imageURL, cloudinaryImage, avatarSize, titleStyle }) => {
  return (
    <>
      <TitleElement style={titleStyle} title={title} level={level} imageURL={imageURL} avatarSize={avatarSize} note={note} cloudinaryImage={cloudinaryImage} />
      <NoteElement note={note} />
    </>
  )
}

AvatarElement.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  note: PropTypes.string,
  imageURL: PropTypes.string,
  avatarSize: PropTypes.string,
  titleStyle: PropTypes.object
}

export default AvatarElement
