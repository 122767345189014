import React, { useState } from 'react'
import moment from 'moment'

import { Form, DatePicker } from 'antd'
const { RangePicker } = DatePicker

const ReportsDatePicker = ({ form, dateFormat, minMaxDates }) => {
  if (!minMaxDates) return false

  const { minDate, maxDate } = minMaxDates
  const pickerStart = moment(minDate).subtract(1, 'month').toISOString()
  const pickerEnd = moment(pickerStart).add(1, 'month').toISOString()

  const defaultPickerValue = [moment(pickerStart), moment(pickerEnd)]
  if (!defaultPickerValue) return null
  const [, setDates] = useState([])
  const [hackValue, setHackValue] = useState()
  const [value, setValue] = useState()

  const onOpenChange = open => {
    if (open) {
      setHackValue([])
      setDates([])
    } else {
      setHackValue(undefined)
    }
  }

  const disabledDate = current => {
    const tooEarly = moment(current).isBefore(moment(minDate), 'day')
    const tooLate = moment(current).isAfter(moment(maxDate), 'day')
    return tooEarly || tooLate
  }

  return (
    <Form.Item name='rangePicker' label='DATE RANGE'>
      <RangePicker
        separator
        allowClear
        defaultPickerValue={defaultPickerValue}
        value={hackValue || value}
        style={{ width: '100%' }}
        disabledDate={disabledDate}
        format='YYYY/MM/DD'
        onCalendarChange={val => setDates(val)}
        onChange={val => setValue(val)}
        onOpenChange={onOpenChange}
      />
    </Form.Item>
  )
}

export default ReportsDatePicker
