import React from 'react'

import ContentWrapper from '../layout/ContentWrapper'
import SystemMessageCenter from '../models/SystemMessageCenter'
import ProgramDetails from '../models/ProgramDetails'
import OnboardingView from '../models/OnboardingView'

import Logo from '../components/Logo'

import { Row, Col, Card, Typography, Space, Tooltip } from 'antd'

import { ProfileOutlined, BellTwoTone } from '@ant-design/icons'

const UserAccountDashboard = () => {
  return (
    <ContentWrapper>
      <Row wrap>
        <Col flex='5'>

          <Card
            type='inner'
            style={{ margin: 6 }}
            title={<DashboardTitle title='On-boarding' />}
            extra={<DashboardExtra icon={<ProfileOutlined />} />}
          >
            <OnboardingView />
          </Card>

        </Col>
        <Col flex='3'>

          <Card
            hoverable
            type='inner'
            style={{ margin: 6 }}
            title={<DashboardTitle title='Message Center' />}
            extra={<DashboardExtra icon={<BellTwoTone style={{ color: 'blue' }} />} tooltip='You have 1 message' />}
          >
            <SystemMessageCenter />
          </Card>

          <Card
            type='inner'
            style={{ margin: 6 }}
            title={
              <Space size='small' wrap>
                <DashboardExtra icon={<Logo color style={{ width: 48 }} />} />
                <DashboardTitle title='Program Details' />
              </Space>
            }
          >
            <ProgramDetails />
          </Card>

        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default UserAccountDashboard

const DashboardTitle = ({ title }) => {
  return (
    <Typography.Text style={{ fontSize: 24 }}>
      <Space>
        {title}
      </Space>
    </Typography.Text>
  )
}
const DashboardExtra = ({ icon, tooltip }) => {
  const fontSize = 24
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Typography.Text style={{ fontSize, color: 'grey' }}>
          {icon}
        </Typography.Text>
      </Tooltip>
    )
  }
  return (
    <Typography.Text style={{ fontSize, color: 'grey' }}>
      {icon}
    </Typography.Text>
  )
}
