import React, { useState } from 'react'
import { memoryUser } from '../../../../core/auth/appMemory'

import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import ItemHeading from '../../../../core/components/ItemHeading'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_ACCOUNT_GROUP, QUERY_ACCOUNT_GROUPS } from '../../../../operations/queries/accountGroups'
import { INSERT_ACCOUNT_GROUP, UPDATE_ACCOUNT_GROUP } from '../../../../operations/mutations/accountGroups'

// ant design
import { Form, Input, Button, Switch, Space } from 'antd'

// elements
import FormItemsSuperAdmin from '../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../../utils/form'
import FormContainer from '../../../../components/FormContainer'
import ModalDialog from '../../../../components/ModalDialog'

const FormComponent = ({ data }) => {
  const accountId = data?.accountgroup?.accountId ? data.accountgroup.accountId : memoryUser().currentAccountId
  // const accountId = memoryUser().currentAccountId
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateAccountGroup, { loading: updateLoading }] = useMutation(UPDATE_ACCOUNT_GROUP)
  const [insertAccountGroup, { loading: insertLoading }] = useMutation(INSERT_ACCOUNT_GROUP, {
    update (cache, { data: { insertAccountgroup } }) {
      const data = cache.readQuery({ query: QUERY_ACCOUNT_GROUPS })
      cache.writeQuery({
        query: QUERY_ACCOUNT_GROUPS,
        data: { accountgroups: !data ? [insertAccountgroup] : data.accountgroups.concat([insertAccountgroup]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/accountGroup/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateAccountGroup({ variables: { id: id, accountgroupInput: { ...values, accountId } } }) : await insertAccountGroup({ variables: { ...values, accountId } })
      const modalFunc = values.hidden ? ModalDialog.info : ModalDialog.success

      modalFunc({
        title: 'Success',
        content: `The accountGroup has been ${id ? (values.hidden ? 'deleted' : 'updated') : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/accountgroups'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertAccountgroup?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  return (
    <>
      {data &&
        <Form
          form={form}
          id='accountGroupForm'
          name='accountGroupForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.accountgroup}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >

          <Form.Item
            label='Named'
            name='name'
            hasFeedback
            validateStatus={itemErrors.name ? 'error' : ''}
            help={itemErrors.name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Notes'
            name='note'
            hasFeedback
            validateStatus={itemErrors.note ? 'error' : ''}
            help={itemErrors.note}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Image URL'
            name='imageURL'
            hasFeedback
            validateStatus={itemErrors.imageURL ? 'error' : ''}
            help={itemErrors.imageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Published'
            name='published'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <FormItemsSuperAdmin data={data.accountgroup} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object
}

const AccountGroupForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_ACCOUNT_GROUP, { variables: { id }, skip: !id })
  const initialValues = { accountgroup: { } }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <ItemHeading loading={loading} item={data?.accountgroup} refetch={refetch} />
      <FormContainer loading={loading} size={2} form={<FormComponent data={{ ...initialValues, ...data }} />} />
    </Space>
  )
}

export default AccountGroupForm
