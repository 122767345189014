import React, { useState, useEffect } from 'react'

import { memoryUser, refreshUser } from '../auth/appMemory'

import { gql, useMutation } from '@apollo/client'

import ItemCardMeta from '../components/ItemCardMeta'
import SimpleLink from '../components/SimpleLink'

import { Space, Typography, Skeleton, List, Avatar, Button, Alert, Card, Tooltip, Menu, Dropdown, Popover, Collapse } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
const { Panel } = Collapse

const ADD_USER_ADMIN_ACCOUNT = gql`
  mutation addUserAdminAccount ($id: ID!, $addUserAdminAccountInput: AddUserAdminAccountInput!) {
    addUserAdminAccount (id: $id, addUserAdminAccountInput: $addUserAdminAccountInput) {
      id
    }
  }
`

const REMOVE_USER_ADMIN_ACCOUNT = gql`
  mutation removeUserAdminAccount ($id: ID!, $removeUserAdminAccountInput: RemoveUserAdminAccountInput!) {
    removeUserAdminAccount (id: $id, removeUserAdminAccountInput: $removeUserAdminAccountInput)
  }
`

const ADD_USER_ADMIN_LOCATION = gql`
  mutation addUserAdminLocation ($id: ID!, $addUserAdminLocationInput: AddUserAdminLocationInput!) {
    addUserAdminLocation (id: $id, addUserAdminLocationInput: $addUserAdminLocationInput) {
      id
    }
  }
`

const REMOVE_USER_ADMIN_LOCATION = gql`
  mutation removeUserAdminLocation ($id: ID!, $removeUserAdminLocationInput: RemoveUserAdminLocationInput!) {
    removeUserAdminLocation (id: $id, removeUserAdminLocationInput: $removeUserAdminLocationInput) {
      id
    }
  }
`

const ADD_USER_GENERAL_LOCATION = gql`
  mutation addUserGeneralLocation ($id: ID!, $addUserGeneralLocationInput: AddUserGeneralLocationInput!) {
    addUserGeneralLocation (id: $id, addUserGeneralLocationInput: $addUserGeneralLocationInput) {
      id
    }
  }
`

const REMOVE_USER_GENERAL_LOCATION = gql`
  mutation removeUserGeneralLocation ($id: ID!, $removeUserGeneralLocationInput: RemoveUserGeneralLocationInput!) {
    removeUserGeneralLocation (id: $id, removeUserGeneralLocationInput: $removeUserGeneralLocationInput) {
      id
    }
  }
`

const ADD_USER_ACCOUNT_GROUP = gql`
  mutation addUserAccountgroup ($id: ID!, $addUserAccountgroupInput: AddUserAccountgroupInput!) {
    addUserAccountgroup (id: $id, addUserAccountgroupInput: $addUserAccountgroupInput) {
      id
    }
  }
`

const REMOVE_USER_ACCOUNT_GROUP = gql`
  mutation removeUserAccountgroup ($id: ID!, $removeUserAccountgroupInput: RemoveUserAccountgroupInput!) {
    removeUserAccountgroup (id: $id, removeUserAccountgroupInput: $removeUserAccountgroupInput) {
      id
    }
  }
`

const AccountUserManagement = ({ setActiveKey, accountloading, account, refetch }) => {
  if (!account) {
    return (
      <Space size='large' direction='vertical' style={{ width: '100%' }}>
        <Skeleton active avatar paragraph />
        <Skeleton active avatar paragraph />
        <Skeleton active avatar paragraph />
        <Skeleton active avatar paragraph />
        <Skeleton active avatar paragraph />
      </Space>
    )
  }

  const { pastUsers, userAdminAccountUsers, userAdminLocationUsers, userGeneralLocationUsers, userAccountGroupUsers, roles, locations, accountgroups } = account

  const ACCOUNTS_ADMIN = roles.find(i => i.name === 'ACCOUNTS_ADMIN')
  const LOCATIONS_ADMIN = roles.find(i => i.name === 'LOCATIONS_ADMIN')
  const LOCATIONS_GENERAL = roles.find(i => i.name === 'LOCATIONS_GENERAL')

  return (
    <List>

      <ListPastUsers pastUsers={pastUsers} />

      <ListCurrentUsers />

      <ListItem
        key='ACCOUNTS_ADMIN'
        account={account}
        refetch={refetch}
        setActiveKey={setActiveKey}
        roleTitle='Account Administrators'
        roleName='ACCOUNTS_ADMIN'
        roleDescription={ACCOUNTS_ADMIN.note}
        roleUsers={userAdminAccountUsers}
        pastUsers={pastUsers}
        itemUserKey='userAdminAccountUsers'
        mutationAddUser={ADD_USER_ADMIN_ACCOUNT}
        mutationAddUserInput='addUserAdminAccountInput'
        mutationRemoveUser={REMOVE_USER_ADMIN_ACCOUNT}
        mutationRemoveUserInput='removeUserAdminAccountInput'
      />

      <ListItem
        key='LOCATIONS_ADMIN'
        refetch={refetch}
        setActiveKey={setActiveKey}
        roleTitle='Location Administrators'
        roleName='LOCATIONS_ADMIN'
        roleDescription={LOCATIONS_ADMIN.note}
        roleUsers={userAdminLocationUsers}
        pastUsers={pastUsers}
        items={locations}
        itemUserKey='locationAdminUsers'
        mutationAddUser={ADD_USER_ADMIN_LOCATION}
        mutationAddUserInput='addUserAdminLocationInput'
        mutationRemoveUser={REMOVE_USER_ADMIN_LOCATION}
        mutationRemoveUserInput='removeUserAdminLocationInput'
        model='Location'
        addUrl='/admin/location/add'
      />

      <ListItem
        key='LOCATIONS_GENERAL'
        refetch={refetch}
        setActiveKey={setActiveKey}
        roleTitle='Location Contributors'
        roleName='LOCATIONS_GENERAL'
        roleDescription={LOCATIONS_GENERAL.note}
        roleUsers={userGeneralLocationUsers}
        pastUsers={pastUsers}
        items={locations}
        itemUserKey='locationGeneralUsers'
        mutationAddUser={ADD_USER_GENERAL_LOCATION}
        mutationAddUserInput='addUserGeneralLocationInput'
        mutationRemoveUser={REMOVE_USER_GENERAL_LOCATION}
        mutationRemoveUserInput='removeUserGeneralLocationInput'
        model='Location'
        addUrl='/admin/location/add'
      />

      <ListItem
        key='ACCOUNT_GROUP'
        refetch={refetch}
        setActiveKey={setActiveKey}
        roleTitle='Group Contributors'
        roleName='ACCOUNT_GROUP'
        roleDescription='Contributor groups'
        roleUsers={userAccountGroupUsers}
        pastUsers={pastUsers}
        items={accountgroups}
        itemUserKey='accountgroupUsers'
        mutationAddUser={ADD_USER_ACCOUNT_GROUP}
        mutationAddUserInput='addUserAccountgroupInput'
        mutationRemoveUser={REMOVE_USER_ACCOUNT_GROUP}
        mutationRemoveUserInput='removeUserAccountgroupInput'
        model='Group'
        addUrl='/admin/accountgroup/add'
      />

    </List>
  )
}

export default AccountUserManagement

const ListPastUsers = ({ pastUsers }) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={(
          <Space size='small'>
            <Text>Past Users</Text>
            <AvatarsGrouped items={pastUsers} maxCount={2} />
          </Space>
        )}
        description='History of account users. We use this list so you can re-add user permissions below.'
      />
    </List.Item>
  )
}

const ListCurrentUsers = () => {
  const { currentAccountUserPermissions } = memoryUser()
  const { currentAccountUsers = [] } = currentAccountUserPermissions

  return (
    <List.Item>
      <List.Item.Meta
        title={(
          <Space size='small'>
            <Text>Current Users</Text>
            <AvatarsGrouped items={currentAccountUsers} maxCount={5} />
          </Space>
        )}
        description='Users who have access to published data in this account, including administrators and you.'
      />
    </List.Item>
  )
}

const ListItem = ({ account, refetch, setActiveKey, roleTitle, roleName, roleDescription, roleUsers, pastUsers, items, itemUserKey, mutationAddUser, mutationAddUserInput, mutationRemoveUser, mutationRemoveUserInput, model, addUrl }) => {
  const paddingTop = items?.length > 0 ? 0 : 12

  return (
    <List.Item>
      <Space direction='vertical' style={{ width: '100%', paddingTop: 32 }}>

        <List.Item.Meta
          title={(
            <Space size='small'>
              <Text>{roleTitle}</Text>
              <AvatarsGrouped items={roleUsers} maxCount={2} />

            </Space>
          )}
          description={roleDescription}
        />

        <Collapse bordered style={{ margin: 0 }}>
          <Panel
            header={`Add or remove ${roleTitle}`}
            key={`collapse${roleName}`}
          >

            {
              !items &&
                <ItemCard
                  key={`itemcard${roleName}`}
                  setActiveKey={setActiveKey}
                  refetch={refetch}
                  mutationAddUser={mutationAddUser}
                  mutationAddUserInput={mutationAddUserInput}
                  mutationRemoveUser={mutationRemoveUser}
                  mutationRemoveUserInput={mutationRemoveUserInput}
                  itemUserKey={itemUserKey}
                  pastUsers={pastUsers}
                  item={account}
                />
            }
            {
              items &&

                <Alert
                  style={{ padding: 12, margin: 0, paddingTop }}
                  message={
                    <Space direction='vertical' style={{ width: '100%' }}>
                      {
                        items && items.map(item => (
                          <ItemCard
                            key={`itemcard${roleName}`}
                            setActiveKey={setActiveKey}
                            refetch={refetch}
                            mutationAddUser={mutationAddUser}
                            mutationAddUserInput={mutationAddUserInput}
                            mutationRemoveUser={mutationRemoveUser}
                            mutationRemoveUserInput={mutationRemoveUserInput}
                            itemUserKey={itemUserKey}
                            pastUsers={pastUsers}
                            item={item}
                          />
                        ))
                      }
                      {
                        items && items.length === 0 &&
                          <Space>
                            <Text>No {model}s yet</Text>
                            <SimpleLink
                              to={addUrl}
                              content={`Add ${model}`}
                              type='button'
                            />
                          </Space>
                      }
                    </Space>
                  }
                />
            }

          </Panel>
        </Collapse>

      </Space>

    </List.Item>
  )
}

const ItemCard = ({ setActiveKey, refetch, mutationAddUser, mutationAddUserInput, mutationRemoveUser, mutationRemoveUserInput, itemUserKey, pastUsers, item }) => {
  const { id, owner, note } = item

  const ownerUserId = owner && owner.id

  return (
    <Card
      style={{ marginTop: 0, paddingTop: 0 }}
      bordered={false}
      size='small'
      key={`item${id}`}
      title={
        <Space size='small'>
          <ItemCardMeta item={item} size='large' view='small' />

          {
            note &&
              <Popover
                trigger='click'
                content={note}
              >
                <Button type='link'>Notes</Button>
              </Popover>
          }
        </Space>
      }
      extra={
        <AddUser key={`addUser${id}`} setActiveKey={setActiveKey} mutation={mutationAddUser} input={mutationAddUserInput} id={id} currentUserIds={item[itemUserKey].map(i => i.id)} pastUsers={pastUsers} refetch={refetch} />
      }
    >
      <Space size='small' wrap>
        {
          item[itemUserKey].length === 0 &&
            <span>
              No users in <Text strong>{item.name}</Text>
            </span>
        }
        {
          item[itemUserKey].map(user => {
            return <RemoveUser key={`removeUser${id}`} mutation={mutationRemoveUser} input={mutationRemoveUserInput} id={id} ownerUserId={ownerUserId} user={user} refetch={refetch} />
          })
        }
      </Space>

    </Card>
  )
}

const AddUser = ({ setActiveKey, mutation, input, id, currentUserIds, pastUsers, refetch }) => {
  const [addUser, { data, loading, error }] = useMutation(mutation)
  const [mutationOptions, setMutationOptions] = useState()

  useEffect(() => {
    if (mutationOptions) return addUser(mutationOptions)
  }, [mutationOptions])

  const [errorMessage, setErrorMessage] = useState()

  const noMoreUsers = currentUserIds.length === pastUsers.length

  const options = {
    variables: { id },
    errorPolicy: 'all'
  }

  useEffect(() => {
    if (!data || loading || error) return

    if (mutationOptions.userId === memoryUser().id) refreshUser('AccountUserManagement, addUser')

    return refetch()
  }, [data, loading, refetch, input])

  useEffect(() => {
    if (error?.message) return setErrorMessage(error.message)
  }, [error])

  if (noMoreUsers) {
    return (
      <Space direction='vertical'>
        <Typography.Link onClick={() => setActiveKey('inviteUser')}>Invite user</Typography.Link>
      </Space>
    )
  }

  const menu = (
    <Menu>
      {
        pastUsers.map(user => {
          const { id: userId } = user
          if (currentUserIds.includes(userId)) return null

          return (
            <Menu.Item
              key={`inputUser{input}_${userId}`}
              onClick={() => {
                options.userId = userId
                options.variables[input] = { userId }
                setMutationOptions(options)
              }}
            >
              <ItemCardMeta item={user} view='small' size='small' />
            </Menu.Item>
          )
        })
      }
    </Menu>
  )

  return (
    <Space size='small'>
      {
        errorMessage &&
          <Text type='danger'>{errorMessage}</Text>
      }

      <Dropdown overlay={menu} trigger={['click']} onClick={() => setErrorMessage()}>
        <Button loading={loading} type='link'>
          Add user
        </Button>
      </Dropdown>

    </Space>
  )
}

const RemoveUser = ({ mutation, input, id, ownerUserId, user, refetch }) => {
  const { id: userId } = user

  const isOwner = Boolean(userId === ownerUserId)

  const title = isOwner ? 'Owner can not be removed' : 'Remove'

  const [removeUser, { data, loading, error }] = useMutation(mutation)

  const [errorMessage, setErrorMessage] = useState()

  const [mutationOptions, setMutationOptions] = useState()

  useEffect(() => {
    if (mutationOptions) return removeUser(mutationOptions)
  }, [mutationOptions])

  const options = {
    variables: { id },
    errorPolicy: 'all'
  }

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!data || loading || error) return
    setVisible(true)

    if (mutationOptions.userId === memoryUser().id) refreshUser('AccountUserManagement, RemoveUser')

    return refetch()
  }, [data, loading, error, refetch])

  useEffect(() => {
    if (error?.message) return setErrorMessage(error.message)
  }, [error])

  if (!visible) return null

  return (

    <Card size='small'>
      <Space size='small' align='baseline'>
        <ItemCardMeta item={user} view='avatar' size='small' />
        <Text>{user.fullName}</Text>
        <Tooltip
          title={title}
        >
          <Button
            disabled={isOwner}
            loading={loading}
            type='text'
            size='small'
            shape='circle'
            onClick={() => {
              options.userId = userId
              options.variables[input] = { userId }
              setMutationOptions(options)
            }}
          >
            <Space size='small'>
              {
                errorMessage &&
                  <Text type='danger'>{errorMessage}</Text>
              }
              {
                isOwner &&
                  <Text mark>(owner)</Text>
              }
              {
                !isOwner &&
                  <CloseCircleOutlined style={{ color: '#d9534f', paddingTop: 0 }} />
              }
            </Space>

          </Button>
        </Tooltip>
      </Space>
    </Card>

  )
}

const AvatarsGrouped = ({ items, maxCount = 0 }) => {
  return (
    <Avatar.Group style={{ padding: 6 }} maxCount={maxCount}>
      {items.map(item => <ItemCardMeta key={`AvatarsGroupedItem${item.id}`} item={item} view='avatar' size='medium' shape='circle' />)}
    </Avatar.Group>
  )
}
