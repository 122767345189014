import React, { useState, useEffect } from 'react'

// import { memoryUser } from '../auth/appMemory'
import { variables } from '../consts'

import { gql, useQuery } from '@apollo/client'

import RouteErrorDisplay from '../components/RouteErrorDisplay'
import SimpleLink from '../components/SimpleLink'
import { LinkHandler } from '../models/OnboardingView'

import { List, Skeleton, Avatar, Space, Result } from 'antd'

const QUERY_MY_SYSTEM_MESSAGES = gql`
  query mySystemMessages {
    mySystemMessages {
      avatar
      title
      titleLink
      description
      actions {
        to
        text
      }
    }
  }
`

const SystemMessageCenter = () => {
  const { data, error, loading } = useQuery(QUERY_MY_SYSTEM_MESSAGES, { variables })
  const [messages, setMessages] = useState()

  useEffect(() => {
    if (data?.mySystemMessages) setMessages(data.mySystemMessages)
  }, [data])

  if (error) return <RouteErrorDisplay error={error} />
  if (loading) return <Skeleton />

  return (
    <>
      {
        messages?.length === 0 &&
          <Result
            status='success'
            title='You have no messages'
            extra={[
              <SimpleLink
                key='linkToMyQuestionnaires'
                to='/ic/questionnaires'
                content='View My Questionnaires'
                type='secondary'
              />
            ]}
          />
      }
      {
        messages?.length > 0 &&
          <List
            itemLayout='horizontal'
            dataSource={messages}
            renderItem={item => (
              <List.Item
                extra={<ListActions item={item} />}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={<Avatar src={item.avatar} style={{ fontSize: 32 }} />}
                    title={item.title}
                    description={item.description}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
      }

    </>
  )
}

export default SystemMessageCenter

const ListActions = ({ item }) => {
  if (!item.actions) return null
  return (
    <Space>
      {
          item.actions.map(action => <LinkHandler key={action.to} to={action.to} text={action.text} />)
      }
    </Space>
  )
}
