
// locations
import Locations from '../../pages/admin/location/Locations'
import Location from '../../pages/admin/location/Location'

// IC
import ICTemplates from '../../pages/admin/ic/template/Templates'
import ICTemplate from '../../pages/admin/ic/template/Template'
import ICCategories from '../../pages/admin/ic/category/Categories'
import ICCategory from '../../pages/admin/ic/category/Category'
import ICQuestions from '../../pages/admin/ic/question/Questions'
import ICQuestion from '../../pages/admin/ic/question/Question'
import ICQuestionnaires from '../../pages/admin/ic/questionnaire/Questionnaires'
import ICQuestionnaire from '../../pages/admin/ic/questionnaire/Questionnaire'
import ImpactReportDashboard from '../../pages/admin/ic/reports/ImpactReportDashboard'

// invitations
// import Invitations from '../../pages/admin/invitation/Invitations'
// import Invitation from '../../pages/admin/invitation/Invitation'
// import InvitationLink from '../../pages/public/Invitation'

// import SendEmailVerification from '../../pages/public/SendEmailVerification'
// import VerificationLink from '../../pages/public/EmailVerification'
// import SetPasswordLink from '../../pages/public/SetPassword'
// import MyInformation from '../../pages/protected/MyInformation'

// web hooks
import InvitationLandingPage from '../routes/InvitationLandingPage'
import VerificationLandingPage from '../routes/VerificationLandingPage'
import PasswordResetLandingPage from '../routes/PasswordResetLandingPage'

import AccountGroups from '../../pages/admin/accountGroup/AccountGroups'
import AccountGroup from '../../pages/admin/accountGroup/AccountGroup'

import AccountSettings from '../routes/AccountSettings'
import AccountSelection from '../routes/AccountSelection'
import AccountAdd from '../routes/AccountAdd'

import Profile from '../routes/Profile'
import ChangeMyPassword from '../routes/ChangeMyPassword'
import VerifyUser from '../routes/VerifyUser'
// Questionnaire
import Questionnaires from '../../pages/protected/ic/Questionnaires'

// import IcIndex from '../../pages/protected/ic/index'
import Questionnaire from '../../pages/protected/ic/Questionnaire'

// questionnaire spawner
import QuestionnaireSpawner from '../../pages/public/QuestionnaireSpawner'
import QuestionnaireSpawned from '../../pages/public/QuestionnaireSpawned'
import ICSpawners from '../../pages/admin/ic/spawner/Spawners'
import ICSpawner from '../../pages/admin/ic/spawner/Spawner'

// CORE
import HomeAuthenticated from '../routes/HomeAuthenticated'
import HomeNotAuthenticated from '../routes/HomeNotAuthenticated'
import SignUp from '../routes/SignUp'
import SignIn from '../routes/SignIn'
import SignOut from '../routes/SignOut'
import ForgotPassword from '../routes/ForgotPassword'
import CheckPasswordToken from '../routes/CheckPasswordToken'

const { REACT_APP_QUESTIONNAIRESPAWNER_ROUTE_NAME, REACT_APP_QUESTIONNAIRESPAWNED_ROUTE_NAME } = process.env

export const routesArray = () => {
  const routesArray = [

    // root
    {
      path: '/',
      component: HomeAuthenticated,
      exact: true,
      requiresAuthenticated: true,
      notePrimary: 'KEn',
      label: 'HomeAuthenticated'
    },
    {
      path: '/',
      component: HomeNotAuthenticated,
      exact: true,
      requiresNotAuthenticated: true,
      label: 'HomeNotAuthenticated'
    },

    // web hooks
    {
      path: '/invitation/invitationToken=:token',
      component: InvitationLandingPage,
      label: 'InvitationLandingPage'
    },
    {
      path: '/verify/verificationToken=:token',
      component: VerificationLandingPage,
      label: 'VerificationLandingPage'
    },
    {
      path: '/resetpassword/:passwordToken',
      component: CheckPasswordToken,
      label: 'CheckPasswordToken'
    },
    {
      path: '/security/:passwordResetToken',
      component: PasswordResetLandingPage,
      label: 'PasswordResetLandingPage'
    },

    // user
    {
      path: '/signup',
      component: SignUp,
      exact: true,
      requiresNotAuthenticated: true,
      label: 'SignUp'
    },

    {
      path: '/signin',
      component: SignIn,
      exact: true,
      requiresNotAuthenticated: true,
      label: 'SignIn'
    },
    {
      path: '/signout',
      component: SignOut,
      exact: true,
      requiresAuthenticated: true,
      label: 'SignOut'
    },
    {
      path: '/profile',
      component: Profile,
      exact: true,
      requiresAuthenticated: true,
      label: 'Profile'
    },
    {
      path: '/password',
      component: ChangeMyPassword,
      exact: true,
      requiresAuthenticated: true
    },
    {
      path: '/verifyme',
      component: VerifyUser,
      exact: true,
      requiresAuthenticated: true,
      label: 'VerifyUser'
    },

    {
      path: '/forgotpassword',
      component: ForgotPassword,
      exact: true,
      requiresNotAuthenticated: true,
      label: 'ForgotPassword'
    },

    // account
    {
      path: '/account/add',
      component: AccountAdd,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountAdd'
    },
    {
      path: '/admin/accountsettings',
      component: AccountSettings,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountSettings'
    },
    {
      path: '/admin/accountselection',
      component: AccountSelection,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountSelection'
    },

    // location
    {
      path: '/admin/locations',
      component: Locations,
      exact: true,
      requiresAuthenticated: true,
      label: 'Locations'
    },
    {
      path: '/admin/location/add',
      component: Location,
      exact: true,
      requiresAuthenticated: true,
      label: 'Location Add'
    },
    {
      path: '/admin/location/:id',
      component: Location,
      exact: true,
      requiresAuthenticated: true,
      label: 'Location Edit'
    },

    // accountgroup
    {
      path: '/admin/accountgroups',
      component: AccountGroups,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountGroups'
    },
    {
      path: '/admin/accountgroup/add',
      component: AccountGroup,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountGroup Add'
    },
    {
      path: '/admin/accountgroup/:id',
      component: AccountGroup,
      exact: true,
      requiresAuthenticated: true,
      label: 'AccountGroup Edit'
    },

    // My Questionnaires

    // spawners
    {
      path: '/admin/ic/spawners',
      component: ICSpawners,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICSpawners'
    },
    {
      path: '/admin/ic/spawner/add',
      component: ICSpawner,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICSpawner Add'
    },
    {
      path: '/admin/ic/spawner/:id',
      component: ICSpawner,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICSpawner Edit'
    },
    // spawner landing page where auth in ignored on client side
    {
      path: `/${REACT_APP_QUESTIONNAIRESPAWNER_ROUTE_NAME}/:rootRouteName/:id`,
      component: QuestionnaireSpawner,
      exact: true,
      useHeadlessLayout: true,
      label: 'QuestionnaireSpawner'
    },
    {
      path: `/${REACT_APP_QUESTIONNAIRESPAWNED_ROUTE_NAME}/:id`,
      component: QuestionnaireSpawned,
      exact: true,
      useHeadlessLayout: true,
      label: 'QuestionnaireSpawned'
    },
    {
      path: `/${REACT_APP_QUESTIONNAIRESPAWNED_ROUTE_NAME}/:id/form/:icnodeId`,
      component: QuestionnaireSpawned,
      exact: true,
      useHeadlessLayout: true,
      label: 'QuestionnaireSpawned Form'
    },

    // questionnaires
    {
      path: '/admin/ic/questionnaires',
      component: ICQuestionnaires,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestionnaires'
    },
    {
      path: '/admin/ic/questionnaire/add',
      component: ICQuestionnaire,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestionnaire Add'
    },
    {
      path: '/admin/ic/questionnaire/:id',
      component: ICQuestionnaire,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestionnaire Edit'
    },

    // general locartions and up >  questionnaire live forms
    {
      path: '/ic/questionnaires',
      component: Questionnaires,
      exact: true,
      requiresAuthenticated: true,
      label: 'Questionnaires'
    },
    {
      path: '/ic/questionnaire/:id',
      component: Questionnaire,
      exact: true,
      requiresAuthenticated: true,
      label: 'Questionnaire Live'
    },
    {
      path: '/ic/questionnaire/:id/form/:icnodeId',
      component: Questionnaire,
      exact: true,
      requiresAuthenticated: true,
      label: 'Questionnaire Live Form'
    },
    {
      path: '/ic/questionnaire/:id/form/:icnodeId/question/:icnodeChildId',
      component: Questionnaire,
      exact: true,
      requiresAuthenticated: true,
      label: 'Questionnaire Live Question'
    },

    // templates
    {
      path: '/admin/ic/templates',
      component: ICTemplates,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICTemplates'
    },
    {
      path: '/admin/ic/template/add',
      component: ICTemplate,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICTemplate Add'
    },
    {
      path: '/admin/ic/template/:id',
      component: ICTemplate,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICTemplate Edit'
    },

    // categories
    {
      path: '/admin/ic/templates/categories',
      component: ICCategories,
      requiresAuthenticated: true,
      label: 'ICCategories'
    },
    {
      path: '/admin/ic/templates/add',
      component: ICCategory,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICCategory Add'
    },
    {
      path: '/admin/ic/templates/category',
      component: ICCategory,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICCategory Add'
    },
    {
      path: '/admin/ic/templates/category/:id',
      component: ICCategory,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICCategory Edit'
    },
    {
      path: '/admin/ic/templates/category/:id/template/:templateId',
      component: ICCategory,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICCategory Template'
    },

    // questions
    {
      path: '/admin/ic/templates/questions',
      component: ICQuestions,
      requiresAuthenticated: true,
      label: 'ICQuestions'
    },
    {
      path: '/admin/ic/templates/question',
      component: ICQuestion,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestions Add'
    },
    {
      path: '/admin/ic/templates/question/:id',
      component: ICQuestion,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestion Edit'
    },
    {
      path: '/admin/ic/templates/question/:id/template/:templateId',
      component: ICQuestion,
      exact: true,
      requiresAuthenticated: true,
      label: 'ICQuestion Template'
    },

    {
      path: '/admin/ic/reports',
      component: ImpactReportDashboard,
      requiresAuthenticated: true,
      label: 'ImpactReportDashboard'
    }
  ]

  // sort this so less rendering if order changes
  routesArray.forEach((route, index) => {
    const name = route.component.name
    route.name = name
    route.key = `routeKey${returnRouteKeyId(route)}`
    return route
  })
  return routesArray
}

const returnRouteKeyId = route => {
  const { path } = route

  let hash = 0
  let i
  let chr

  if (path.length === 0) return hash

  for (i = 0; i < path.length; i++) {
    chr = path.charCodeAt(i)

    hash = ((hash << 5) - hash) + chr

    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
/*

  imports
  // acounts
  // import Accounts from '../../pages/protected/account/Accounts' see note below

  // import UsersSuperAdmin from '../../pages/admin/user/UsersSuperAdmin'
  // import Users from '../../pages/admin/user/Users'
  // import User from '../../pages/admin/user/User'

  // signup
  // import Signup from '../../pages/public/Signup'
  // import Login from '../../pages/public/Login'
  // import ChangePassword from '../../pages/protected/ChangePassword'
  // import ForgotPassword from '../../pages/public/ForgotPassword'

  20211125 GF REVIEW
  {
    path: '/ic',
    component: IcIndex,
    requiresAuthenticated: true
  },

  20211125 GF this is for CRUD

  {
    path: '/superadmin/users',
    component: UsersSuperAdmin,
    requiresAuthenticated: true
  },
  {
    path: '/admin/users',
    component: Users,
    requiresAuthenticated: true
  },
  {
    path: '/admin/user/add',
    component: User,
    exact: true,
    requiresAuthenticated: true
  },
  {
    path: '/admin/user/:id',
    component: User,
    exact: true,
    requiresAuthenticated: truee
  },

  20211126 GF backup of original
  {
    path: '/superadmin/users',
    component: UsersSuperAdmin,
    requiresAuthenticated: true
  },
  {
    path: '/admin/users',
    component: Users,
    requiresAuthenticated: true
  },
  {
    path: '/admin/user/add',
    component: User,
    exact: true,
    requiresAuthenticated: true
  },
  {
    path: '/admin/user/:id',
    component: User,
    exact: true,
    requiresAuthenticated: truee
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
    requiresNotAuthenticated: true
  },

  TEST
  {
    path: '/signin/redirect/:pathname',
    component: Login,
    exact: true,
    requiresNotAuthenticated: true
  },
  REVIEW
  {
    path: '/changepassword',
    component: ChangePassword,
    exact: true,
    requiresAuthenticated: true
  },
*/
