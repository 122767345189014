// react
import React from 'react'

import { useHistory } from 'react-router-dom'

import ItemCardMeta from '../components/ItemCardMeta'

// ant design
import { Menu, Typography } from 'antd'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const { SubMenu } = Menu

const RoutedLiveQuestionnaireMenu = ({ mode, style, className, questionnaireLive }) => {
  if (!questionnaireLive) return null

  const { icquestionnaire } = questionnaireLive
  const { id: icquestionnaireId } = icquestionnaire

  const openKeys = []
  const history = useHistory()

  const onSelect = (item, key) => {
    history.push(`/ic/questionnaire/${icquestionnaireId}/form/${item.item.props.value}`)
  }

  return (
    <div>
      <Typography.Title level={5} className='title'>
        <ItemCardMeta item={icquestionnaire} size='small' view='small' />
      </Typography.Title>
      <Menu
        theme='light'
        mode='inline'
        defaultOpenKeys={openKeys}
        defaultSelectedKeys={openKeys}
        selectedKeys={openKeys}
        style={style}
        className={className}
        onSelect={onSelect}
      >
        {questionnaireLive.navData.map((sub, index) => {
          if (sub.children) {
            return (
              <SubMenu
                key={`subValue${sub.value}`}
                title={sub.title}
                onTitleClick={() => history.push(`/ic/questionnaire/${icquestionnaireId}/form/${sub.value}`)}
                icon={<FontAwesomeIcon icon={faSquare} style={{ fontSize: '6px' }} color='#fff' />}
              >
                {sub.children.map((item) => (
                  <Menu.Item
                    key={`itemValue${item.value}`}
                    value={item.value}
                    disabled={sub.disabled}
                    questionnaireId={icquestionnaireId}
                  >
                    <ItemCardMeta item={item} size='small' view='small' />
                  </Menu.Item>
                ))}
              </SubMenu>
            )
          } else if (sub.value !== 'home') {
            return (
              <Menu.Item
                key={sub.value}
                questionnaireid={icquestionnaireId}
                value={sub.value}
                disabled={sub.disabled}
                icon={<FontAwesomeIcon icon={faSquare} style={{ fontSize: '6px' }} color='#fff' />}
              >
                <ItemCardMeta item={sub} size='small' view='small' />
              </Menu.Item>
            )
          }
          return null
        })}
      </Menu>
    </div>
  )
}

export default RoutedLiveQuestionnaireMenu
