import { gql } from '@apollo/client'
import { QUESTIONNAIRE_FIELDS } from '../../queries/ic/questionnaires'

export const INSERT_QUESTIONNAIRE = gql`
mutation insertIcquestionnaire(
  $name: String!
  $note: String,
  $imageURL: String,
  $accountId: ID!
  $hidden: Boolean
  $published: Boolean
  $accountgroupIds: [ID]
  $endDate: String
  $ictemplateId: ID
  $locationIds: [ID]
  $locked: Boolean
  $reportingDate: String
  $startDate: String
) {
  insertIcquestionnaire(icquestionnaireInput: {
    name: $name
    note: $note,
    imageURL: $imageURL,
    accountId: $accountId
    hidden: $hidden
    published: $published
    accountgroupIds: $accountgroupIds
    endDate: $endDate
    ictemplateId: $ictemplateId
    locationIds: $locationIds
    locked: $locked
    reportingDate: $reportingDate
    startDate: $startDate
  }) {
    ${QUESTIONNAIRE_FIELDS}
  }
}
`

export const UPDATE_QUESTIONNAIRE = gql`
mutation updateIcquestionnaire($id: ID!, $icquestionnaireInput: IcquestionnaireInput!) {
  updateIcquestionnaire(id: $id, icquestionnaireInput: $icquestionnaireInput) {
    ${QUESTIONNAIRE_FIELDS}
  }
}
`
