import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'antd'

const QuestionnaireFeedback = ({ live, reasons }) => {
  return (
    <>
      {live === false &&
        <>
          {reasons.map((reason, key) => (
            <Alert key={key} message={reason} type='warning' showIcon style={{ marginBottom: '12px' }} />
          ))}
        </>}
    </>
  )
}

QuestionnaireFeedback.propTypes = {
  live: PropTypes.bool,
  reasons: PropTypes.array
}

export default QuestionnaireFeedback
