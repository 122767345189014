import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Card, Switch, Menu, Dropdown, Space, Button, Tabs } from 'antd'

import { DownOutlined } from '@ant-design/icons'

import TemplateDragCategoriesQuestions from './TemplateDragCategoriesQuestions'

import { colorCategory, colorQuestion } from '../../../../../components/elements/icons'

const menus = [
  {
    name: 'Category',
    type: 'iccategorys',
    linkNew: '/admin/ic/templates/category',
    items: [
      {
        name: 'All',
        filter: { }
      },
      {
        name: 'All Published',
        filter: { published: true }
      }
    ]
  },
  {
    name: 'Question',
    type: 'icquestions',
    linkNew: '/admin/ic/templates/question',
    items: [
      {
        name: 'All',
        filter: { }
      },
      {
        name: 'All Inputs',
        filter: { icquestiontype: 'input' }
      },
      {
        name: 'All Selects',
        filter: { icquestiontype: 'select' }
      },
      {
        name: 'All Published',
        filter: { published: true }
      },
      {
        name: 'Published Inputs',
        filter: { published: true, icquestiontype: 'input' }
      },
      {
        name: 'Published Selects',
        filter: { ipublished: true, cquestiontype: 'select' }
      }
    ]
  }
]

const TemplateDragSection = ({ ictemplateId, accountId }) => {
  const [type, setType] = useState('iccategorys')
  const [name, setName] = useState('')
  const [linkNew, setLinkNew] = useState('')

  const currentMenu = () => menus.find(i => i.type === type)
  const defaultMenuTitle = currentMenu().items[0].name
  const [menuTitle, setMenuTitle] = useState(defaultMenuTitle)
  const [menuLastCategory, setMenuLastCategory] = useState(defaultMenuTitle)
  const [menuLastQuestion, setMenuLastQuestion] = useState(defaultMenuTitle)

  const [filter, setFilter] = useState({})

  const [color, setColor] = useState(colorCategory)

  const { TabPane } = Tabs

  useEffect(() => {
    const menu = menus.find(i => i.type === type)
    const { name, linkNew } = menu
    setName(name)
    setLinkNew(linkNew)
    if (type === 'iccategorys') {
      setColor(colorCategory)
      setMenuTitle(menuLastCategory)
    }
    if (type === 'icquestions') {
      setColor(colorQuestion)
      setMenuTitle(menuLastQuestion)
    }
  }, [type])

  useEffect(() => {
    const { filter } = currentMenu().items.find(i => i.name === menuTitle)
    if (type === 'iccategorys') setMenuLastCategory(menuTitle)
    if (type === 'icquestions') setMenuLastQuestion(menuTitle)
    setFilter(filter)
  }, [menuTitle])

  const menu = () => (
    <Menu>
      {
        currentMenu().items.map(item => (
          <Menu.Item
            onClick={() => setMenuTitle(item.name)}
            key={`Menu${type}${item.name}}`}
          >
            {item.name}
          </Menu.Item>
        ))
      }
    </Menu>
  )

  const menuFilter = () => (
    <Space size='large'>
      <Link to={linkNew}>
        <Button type='link'>Add new {name}</Button>
      </Link>
      <Dropdown overlay={menu}>
        <a onClick={e => e.preventDefault()}>
          {menuTitle} <DownOutlined />
        </a>
      </Dropdown>
    </Space>
  )

  const categoryQuestionSwitch = () => (
    <Tabs defaultActiveKey="iccategorys" type="card" size='small'
    onChange={key => setType(key)}>
      <TabPane tab="Categories" key="iccategorys" />
      <TabPane tab="Questions" key="icquestions" />
    </Tabs>
  )

  return (
    <Card
      style={{ height: '100%' }}
      size='small'
      title={categoryQuestionSwitch()}
      extra={menuFilter()}
    >
      <TemplateDragCategoriesQuestions ictemplateId={ictemplateId} accountId={accountId} type={type} filter={filter} />
    </Card>
  )
}

export default TemplateDragSection

TemplateDragSection.propTypes = {
  ictemplateId: PropTypes.string,
  accountId: PropTypes.string
}
