import { gql } from '@apollo/client'
import { SPAWNER_FIELDS } from '../../queries/ic/spawners'

export const INSERT_SPAWNER = gql`
mutation insertIcquestionnairespawner(
    $accountgroupIds: [ID]
    $accountId: ID
    $anonymous: Boolean
    $completionTimeLimit: Int
    $completionTimeUnit: String
    $icquestionnaireImageURL: String
    $icquestionnaireName: String
    $icquestionnaireNote: String
    $ictemplateId: ID
    $imageURL: String
    $isPublic: Boolean
    $locationIds: [ID]
    $locked: Boolean
    $name: String
    $note: String
    $published: Boolean
    $rootRouteName: String
    $spawnLimit: Int
    $startDate: String
    $endDate: String
    $hidden: Boolean
) {
  insertIcquestionnairespawner(icquestionnairespawnerInput: {
    accountgroupIds: $accountgroupIds
    accountId: $accountId
    anonymous: $anonymous
    completionTimeLimit: $completionTimeLimit
    completionTimeUnit: $completionTimeUnit
    icquestionnaireImageURL: $icquestionnaireImageURL
    icquestionnaireName: $icquestionnaireName
    icquestionnaireNote: $icquestionnaireNote
    ictemplateId: $ictemplateId
    imageURL: $imageURL
    isPublic: $isPublic
    locationIds: $locationIds
    locked: $locked
    name: $name
    note: $note
    published: $published
    rootRouteName: $rootRouteName
    spawnLimit: $spawnLimit
    startDate: $startDate
    endDate: $endDate
    hidden: $hidden
  }) {
    ${SPAWNER_FIELDS}
  }
}
`

export const UPDATE_SPAWNER = gql`
mutation updateIcquestionnairespawner($id: ID!, $icquestionnairespawnerInput: IcquestionnairespawnerInput!) {
  updateIcquestionnairespawner(id: $id, icquestionnairespawnerInput: $icquestionnairespawnerInput) {
    ${SPAWNER_FIELDS}
  }
}
`
