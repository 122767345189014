import { gql } from '@apollo/client'

export const ANSWERS_FIELDS = `
  id
  key
  accountId
  icquestionId
  name
  hidden
  order
  published
  note
  imageURL
  cloudinaryImage
  privateNote
`
export const QUERY_ANSWERS = gql`
query
 {
  icanswers
  {
    ${ANSWERS_FIELDS}
  }
}
`

export const ANSWER_FIELDS = `
  id
  accountId
  icquestionId
  name
  hidden
  order
  published
  note
  imageURL
  cloudinaryImage
  privateNote
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "mmm dd yyyy HH:MM:ss")
  updatedAt (format: "mmm dd yyyy HH:MM:ss")
`

export const QUERY_ANSWER = gql`
query Answer($id: ID!) {
  icanswer (id: $id) {
    ${ANSWER_FIELDS}
  }
}
`
