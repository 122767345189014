import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Space, Row, Col, Card, Button, Skeleton, Progress, Modal, Typography } from 'antd'
import { SaveOutlined, WarningTwoTone } from '@ant-design/icons'

const StatusIcon = ({ status, error }) => {
  if (status !== 'ERROR') return null
  return (
    <Space size='small'>
      <WarningTwoTone twoToneColor='#f5222d' />
      Result didnt save
    </Space>
  )

  // if (status === 'SAVED') {
  //   return (<><CheckOutlined style={{ color: '#52c41a' }} /> Saved</>)
  // } else if (status === 'SAVING') {
  //   return (<><SyncOutlined style={{ color: '#1890ff' }} spin /> Saving...</>)
  // } else if (status === 'RETRYING') {
  //   return (<><SyncOutlined style={{ color: '#1890ff' }} spin /> Retrying...</>)
  // } else if (status === 'NOT_SAVED') {
  //   return (<><CloudSyncOutlined style={{ color: '#faad14' }} /> Not saved</>)
  // } else if (status === 'ERROR') {
  //   return (<><WarningTwoTone twoToneColor='#f5222d' /> Saving error</>)
  // } else {
  //   return (<></>)
  // }
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired
}

const QuestionnaireHeader = ({ icquestionnaire, status, loading, percent, retry, hasPages, error }) => {
  const [modal, contextHolder] = Modal.useModal()
  const [previousPercent, setPreviousPercent] = useState(parseFloat(percent))
  const [visible, setVisible] = useState(false)
  if (!loading && !icquestionnaire) return (<></>)

  const isSurvey = Boolean(icquestionnaire?.icquestionnairespawnerId)

  useEffect(() => {
    if (isSurvey && percent && parseFloat(percent) >= 100) {
      setVisible(true)
    }
    if (!isSurvey && percent && previousPercent && parseFloat(percent) >= 100 && previousPercent < 100) {
      modal.info({
        title: 'Completed',
        content: <>Thanks for completing {icquestionnaire?.name}.<br />{hasPages && <>You can revise your responses by using the navigation at the bottom of the screen.</>}</>
      })
    }
    setPreviousPercent(parseFloat(percent))
  }, [percent])

  const onClose = () => {
    window.opener = null
    window.open('', '_self')
    window.close()
  }

  return (
    <div style={{ position: 'sticky', top: '0', zIndex: '999' }}>
      <Modal
        title='Completed'
        visible={visible}
        onOk={() => onClose()}
        okText='Close survey'
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Space direction='vertical'>
          <Typography.Text style={{ fontSize: 16 }}>Thanks for completing {icquestionnaire?.name}.</Typography.Text>
        </Space>
      </Modal>

      <Card
        style={{ marginTop: 20, marginBottom: 28, boxShadow: '#c5c5c5 0px 1px 0px 0px' }}
        bodyStyle={{ padding: '18px 24px' }}
      >
        <Row>
          <Col xs={19} sm={22} xl={23}>
            {!loading && <Progress percent={percent} strokeColor='#59b343' showInfo size='large' className='questionnaireProgress' format={(percent) => percent + '% completed'} style={{ paddingTop: 12 }} />}
            {loading && <Skeleton title={{ width: 150 }} paragraph={false} />}
          </Col>
        </Row>
        {
          status === 'ERROR' &&
            <Row>
              <Col xs={9} sm={6} style={{ textAlign: 'right', lineHeight: '28px' }}>
                <StatusIcon status={status} error={error} />
                <Button onClick={retry} type='danger' size='small'><SaveOutlined />Save</Button>
              </Col>
            </Row>
        }
      </Card>
      {contextHolder}
    </div>
  )
}

QuestionnaireHeader.propTypes = {
  icquestionnaire: PropTypes.object,
  status: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  percent: PropTypes.number,
  retry: PropTypes.func,
  hasPages: PropTypes.bool
}

export default QuestionnaireHeader
