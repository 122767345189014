import React from 'react'
import Page from '../../components/Page'
import Container from '../../components/Container'
import SpawnerLandingLink from '../../components/sections/ic/questionnaire/SpawnerLandingLink'

const QuestionnaireSpawner = () => {
  return (
    <Page>
      <Container>
        <SpawnerLandingLink />
      </Container>
    </Page>
  )
}

export default QuestionnaireSpawner
