// components
import { ErrorWithNotification } from './errorWithNotification'

const formatErrors = (error) => {
  let formError
  let itemErrors

  if (error?.graphQLErrors?.[0]) {
    const graphQLErrors = error.graphQLErrors[0]

    if (graphQLErrors?.extensions?.exception?.details) {
      itemErrors = {}
      graphQLErrors?.extensions?.exception?.details.forEach((_err) => {
        itemErrors[_err.context.key] = _err.message
      })
    }

    if (graphQLErrors?.message) {
      formError = graphQLErrors?.message
    }
  } else if (!error?.networkError?.result && error.message) {
    ErrorWithNotification('Form error', { title: 'Form error', message: error.message + ' - E004', error })
  }

  return { itemErrors, formError }
}

export default formatErrors

export { formatErrors }
