import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
// components

import { Layout, Typography, Row, Tooltip } from 'antd'
import Container from '../../Container'

const { Text } = Typography

const { REACT_APP_TITLE, REACT_APP_PARENT_URL, REACT_APP_VERSION, REACT_APP_ENV } = process.env

export const QUERY_ABOUT = gql`
  query {
    about {
      name
      version
      env,
      origin

    }
  }
`

const currentYear = moment().format('YYYY')

const Footer = () => {
  const { data, error } = useQuery(QUERY_ABOUT, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  })

  const displayAppCredentials = ['local', 'staging', 'production'].includes(REACT_APP_ENV)

  const [about, setAbout] = useState()

  useEffect(() => {
    if (data?.about) setAbout(data.about)
  }, [data])

  return (
    <Layout.Footer className='footer'>
      <Container>
        <div className='container container-footer'>
          <div className='footer-copyright'>
            <Row justify='center' wrap>
              <Text>
                Copyright © {currentYear} <Link to={REACT_APP_PARENT_URL} target='_blank'>{REACT_APP_TITLE}</Link> All Rights Reserved..
              </Text>
            </Row>

            {
              displayAppCredentials &&
                <Tooltip title='Displayed versioning in footer is used for debugging. Will not be visible on BETA. Therefore footer will will also more condensed.' placement='bottom' color='purple'>

                  <Text style={{ fontSize: 8 }} code>
                    CLIENT: {REACT_APP_VERSION} | API: {error?.message}{about?.version}
                  </Text>

                </Tooltip>
            }

          </div>

        </div>
      </Container>
    </Layout.Footer>

  )
}

export default Footer

/*
  {
    <Row justify='space-between'>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Title level={3}>{list1Title}</Title>
        <ul>
          {list1Items.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.text}</Link></li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Title level={3}>{list2Title}</Title>
        <ul>
          {list2Items.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.text}</Link></li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Title level={3}>{list3Title}</Title>
        <ul>
          {list3Items.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.text}</Link></li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Title level={3}>{list4Title}</Title>
        <ul>
          {list4Items.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.text}</Link></li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Title level={3}>{list5Title}</Title>
        <ul>
          {list5Items.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.text}</Link></li>
          ))}
        </ul>
      </Col>
    </Row>}
*/

// const list1Title = 'Menu'
// const list1Items = [
//   { text: 'Home', link: '/' },
//   { text: 'Services', link: '/' }
// ]

// const list2Title = 'Account'
// const list2Items = [
//   { text: 'Register', link: '/' },
//   { text: 'Sign in', link: '/' }
// ]

// const list3Title = 'Services'
// const list3Items = [
//   { text: 'Social impact', link: '/' },
//   { text: 'Service 2', link: '/' },
//   { text: 'Pricing', link: '/' }
// ]

// const list4Title = 'About'
// const list4Items = [
//   { text: 'Terms of use', link: '/' },
//   { text: 'Privacy policy', link: '/' },
//   { text: 'Support', link: '/' }
// ]

// const list5Title = 'Support'
// const list5Items = [
//   { text: 'FAQ', link: '/' },
//   { text: 'Get support', link: '/' }
// ]
