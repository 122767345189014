// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { memoryUser } from '../../../../../../core/auth/appMemory'
import ItemHeading from '../../../../../../core/components/ItemHeading'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_QUESTION, QUERY_QUESTIONS } from '../../../../../../operations/queries/ic/questions'
import { INSERT_QUESTION, UPDATE_QUESTION } from '../../../../../../operations/mutations/ic/questions'

// ant design
import { Form, Card, Input, Button, Radio, Switch, Select, Alert, InputNumber } from 'antd'

// components
import AnswersList from './AnswersList'

// elements
import FormItemsSuperAdmin from '../../../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../../../../utils/form'
import FormContainer from '../../../../../../components/FormContainer'
import ModalDialog from '../../../../../../components/ModalDialog'

const FormComponent = ({ data, refetch }) => {
  const accountId = data?.iccategory?.id ? data.icquestion.id : memoryUser().currentAccountId
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [answers, setAnswers] = useState([])

  // states used for form reactivity only
  const [icquestiontype, setIcquestiontype] = useState(data.icquestion.icquestiontype)
  const [inputType, setInputType] = useState(data.icquestion.inputtype)
  const [numberType, setNumberType] = useState(data.icquestion.numbertype)
  // const [isMultiAnswers, setIsMultiAnswers] = useState(data.icquestion.canselectmanyanswers)

  // do not add fields with useState()

  const [updateQuestion, { loading: updateLoading }] = useMutation(UPDATE_QUESTION)
  const [insertQuestion, { loading: insertLoading }] = useMutation(INSERT_QUESTION, {
    update (cache, { data: { insertIcquestion } }) {
      const data = cache.readQuery({ query: QUERY_QUESTIONS })
      cache.writeQuery({
        query: QUERY_QUESTIONS,
        data: { icquestions: !data ? [insertIcquestion] : data.icquestions.concat([insertIcquestion]) }
      })
    }
  })

  const onChangeQuestionType = (e) => setIcquestiontype(e.target.value)
  const onChangeInputType = (e) => setInputType(e.target.value)
  const onChangeNumberType = (e) => setNumberType(e.target.value)
  // const onChangeMultiAnswers = (value) => setIsMultiAnswers(value)

  const continueEditing = (_id) => _id && history.push(`/admin/ic/templates/question/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateQuestion({ variables: { id: id, icquestionInput: { accountId, ...values } } }) : await insertQuestion({ variables: { accountId, ...values } })
      ModalDialog.success({
        title: 'Success',
        content: `The question has been ${id ? 'updated' : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/ic/templates/questions'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertIcquestion?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  useEffect(() => {
    if (data.icquestion.icanswers) {
      setAnswers(data.icquestion.icanswers)
    }
  }, [])

  return (
    <>
      {data &&
        <>
          <Form
            form={form}
            id='questionForm'
            name='questionForm'
            onFinish={onFinish}
            preserve={false}
            initialValues={data.icquestion}
            labelCol={{ sm: 7, md: 10, lg: 7, xl: 4, xxl: 4 }}
            wrapperCol={{ lg: 12, xl: 10, xxl: 8 }}
          >
            <Form.Item
              label='Type'
              name='icquestiontype'
              hasFeedback
              validateStatus={itemErrors.icquestiontype ? 'error' : ''}
              help={itemErrors.icquestiontype}
              extra={
                <div style={{ marginTop: '12px' }}>
                  <Alert banner='true' message='Select is a user checking off answers.' type='info' showIcon='true' style={{ marginBottom: '1px' }} />
                  <Alert banner='true' message='Input is a user submitting strings, numbers or dates.' type='info' showIcon='true' />
                </div>
}
            >
              <Radio.Group onChange={onChangeQuestionType}>
                <Radio.Button value='select'>SELECT</Radio.Button>
                <Radio.Button value='input'>INPUT</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label='Name'
              name='name'
              hasFeedback
              validateStatus={itemErrors.name ? 'error' : ''}
              help={itemErrors.name}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label='Notes'
              name='note'
              hasFeedback
              validateStatus={itemErrors.note ? 'error' : ''}
              help={itemErrors.note}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>

            <Form.Item
              label='Private Notes'
              name='privateNote'
              hasFeedback
              validateStatus={itemErrors.privateNote ? 'error' : ''}
              help={itemErrors.privateNote}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>

            <Form.Item
              label='Image URL'
              name='imageURL'
              hasFeedback
              validateStatus={itemErrors.imageURL ? 'error' : ''}
              help={itemErrors.imageURL}
            >
              <Input placeholder='https://' />
            </Form.Item>

            <Form.Item
              label='Required?'
              name='required'
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>

            {icquestiontype === 'select' && (
              <>
                <Form.Item
                  label='Randomize answers?'
                  name='randomizeselectableanswers'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  label='Can select multiple answers?'
                  name='canselectmanyanswers'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  label='Click on images to answer'
                  name='answerIsClickTouch'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>

                {/*
                  <Form.Item
                    label='Correct answer'
                    name='correcticanswerId'
                    hasFeedback
                    validateStatus={itemErrors.correcticanswerId ? 'error' : ''}
                    help={itemErrors.correcticanswerId}
                  >
                    <Select
                      allowClear
                    >
                      {
                        answers.map(answer => <Select.Option key={answer.id} value={answer.id}>{answer.name}</Select.Option>)
                      }
                    </Select>
                  </Form.Item>
                */}
              </>
            )}

            {icquestiontype === 'input' && (
              <Form.Item
                label='Input type'
                name='inputtype'
                hasFeedback
                validateStatus={itemErrors.inputtype ? 'error' : ''}
                help={itemErrors.inputtype}
              >

                <Radio.Group onChange={onChangeInputType}>
                  <Radio.Button value='number'>Number</Radio.Button>
                  <Radio.Button value='string'>String</Radio.Button>
                  <Radio.Button value='date'>Date</Radio.Button>
                  {/* <Radio.Button value='file'>File</Radio.Button> */}
                </Radio.Group>

              </Form.Item>
            )}

            {icquestiontype === 'input' && inputType === 'number' && (

              <>
                <Form.Item
                  label='Number type'
                  name='numbertype'
                  hasFeedback
                  validateStatus={itemErrors.numbertype ? 'error' : ''}
                  help={itemErrors.numbertype}
                >
                  <Radio.Group onChange={onChangeNumberType}>
                    <Radio.Button value='real'>Real</Radio.Button>
                    <Radio.Button value='integer'>Integer</Radio.Button>
                    <Radio.Button value='natural'>Natural</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {numberType === 'real' && (
                  <Form.Item
                    label='Decimal places'
                    name='decimalplaces'
                    hasFeedback
                    validateStatus={itemErrors.decimalplaces ? 'error' : ''}
                    help={itemErrors.decimalplaces}
                  >
                    <Select
                      allowClear
                      style={{ width: '60px' }}
                    >
                      {
                        Array.from(Array(10).keys()).map(i => <Select.Option key={`DecimalPlaces${i}`} value={i}>{i}</Select.Option>)
                      }
                    </Select>
                  </Form.Item>
                )}

              </>

            )}

            <Form.Item
              label='Minimum value'
              name='minValue'
              hasFeedback
              validateStatus={itemErrors.minValue ? 'error' : ''}
              help={itemErrors.minValue}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label='VarA'
              name='varA'
              hasFeedback
              validateStatus={itemErrors.varA ? 'error' : ''}
              help={itemErrors.varA}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label='VarB'
              name='varB'
              hasFeedback
              validateStatus={itemErrors.varB ? 'error' : ''}
              help={itemErrors.varB}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label='VarC'
              name='varC'
              hasFeedback
              validateStatus={itemErrors.varC ? 'error' : ''}
              help={itemErrors.varC}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label='Dominant group?'
              name='isDominantGroup'
              valuePropName='checked'
              wrapperCol={{ sm: 20 }}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label='Suggestion: Trending Well'
              name='suggestionTrendingWell'
              hasFeedback
              validateStatus={itemErrors.suggestionTrendingWell ? 'error' : ''}
              help={itemErrors.suggestionTrendingWell}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label='Suggestion: Needs Improvement'
              name='suggestionNeedsImprovement'
              hasFeedback
              validateStatus={itemErrors.suggestionNeedsImprovement ? 'error' : ''}
              help={itemErrors.suggestionNeedsImprovement}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label='Suggestion: Below Standard'
              name='suggestionBelowStandard'
              hasFeedback
              validateStatus={itemErrors.suggestionBelowStandard ? 'error' : ''}
              help={itemErrors.suggestionBelowStandard}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label='Published'
              name='published'
              valuePropName='checked'
              wrapperCol={{ sm: 20 }}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label='Internal Notes'
              name='internalNote'
              hasFeedback
              validateStatus={itemErrors.internalNote ? 'error' : ''}
              help={itemErrors.internalNote}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>

            <FormItemsSuperAdmin data={data.icquestion} hasHidden />

            <FormItemError error={formError} />

            <Form.Item>
              <Button type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
                {id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </Form>
          {icquestiontype === 'select' && !!id &&
            <Card title='Answers'>
              <AnswersList
                accountId={accountId}
                refetch={refetch}
                questionId={data.icquestion.id}
                answers={answers}
                onChange={dataSource => setAnswers(dataSource)}
              />
            </Card>}

          {icquestiontype === 'select' && !id &&
            <Alert message='Create answers to this question after you click Add.' type='info' />}

        </>}

    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object
}

const QuestionForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_QUESTION, { variables: { id }, skip: !id })
  const initialValues = { icquestion: { name: '', required: true } }

  return (
    <>
      <ItemHeading loading={loading} item={data?.icquestion} refetch={refetch} />
      <Card title={data?.icquestion.name} style={{ marginBottom: '24px' }}>
        <FormContainer loading={loading} size={2} form={<FormComponent refetch={refetch} data={{ ...initialValues, ...data }} />} />
      </Card>
    </>
  )
}

export default QuestionForm
