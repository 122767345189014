import React from 'react'
import { Result } from 'antd'
import CenterContent from './CenterContent'
import SimpleLink from './SimpleLink'

const returnErrorMessage = error => {
  if (!error) return null
  const { graphQLErrors } = error
  if (!graphQLErrors) return null
  if (!graphQLErrors[0]) return null

  return graphQLErrors[0].message
}

const messageMappping = [
  {
    name: 'Email is not verified',
    to: '/verifyme',
    buttonText: 'Get verified'
  },
  {
    name: 'Token has expired',
    to: '/forgotpassword',
    buttonText: 'Forgot password?'
  }
]

const RouteErrorDisplay = ({ error, message, notVerified }) => {
  let errorMessage = message || returnErrorMessage(error)

  // do not change following, its eqaul to api response for same thing
  if (notVerified) errorMessage = 'Email is not verified'

  if (!errorMessage) return null

  const map = messageMappping.find(i => i.name === errorMessage)

  const to = map?.to || '/'
  const buttonText = map?.buttonText || 'Okay'

  return (
    <CenterContent>
      <Result
        status='warning'
        title={errorMessage}
        extra={

          <SimpleLink
            to={to}
            type='secondary'
            content={buttonText}
          />
        }
      />

    </CenterContent>
  )
}

export default RouteErrorDisplay
