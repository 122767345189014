import React from 'react'

import { Space } from 'antd'

import Page from '../../components/Page'

const ContentWrapper = ({ children, title, breadcrumb }) => {
  if (!breadcrumb) {
    return (
      <div className='page-padding-leftRight page-padding-topBottom'>
        <Space direction='vertical' style={{ width: '100%' }}>
          {children}
        </Space>
      </div>
    )
  }

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <div className='page-padding-leftRight page-padding-topBottom'>
        <Space direction='vertical' style={{ width: '100%' }}>
          {children}
        </Space>
      </div>
    </Page>
  )
}

export default ContentWrapper
