import React from 'react'
import { withRouter } from 'react-router-dom'

import Page from '../../../../components/Page'
import CategoryList from '../../../../components/sections/admin/ic/template/category/CategoryList'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICCategories = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    },
    {
      path: '',
      breadcrumbName: 'Categories'
    }
  ]
  return (
    <>
      <Page title='Categories' breadcrumb={breadcrumb}>
        <PageInstructions />
        <CategoryList />
      </Page>
    </>
  )
}

export default withRouter(ICCategories)
