import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

// import { memoryUser } from '../auth/appMemory'
import { variables } from '../consts'

import { gql, useQuery } from '@apollo/client'

import RouteErrorDisplay from '../components/RouteErrorDisplay'
import SimpleLink from '../components/SimpleLink'

import { MailTwoTone, SettingTwoTone, EnvironmentTwoTone, GoldTwoTone, RightCircleTwoTone, SmileTwoTone, TabletTwoTone, MobileTwoTone } from '@ant-design/icons'

import { Steps, Result, Skeleton } from 'antd'
const { Step } = Steps

const QUERY_MY_CURRENT_ACCOUNT_ONBOARDING = gql`
  query currentAccountUserOnboarding {
    currentAccountUserOnboarding {
      stepsComplete
      steps {
        id
        status
        title
        icon
        description
        link {
          to
          text
        }
      }
    }
  }
`

const OnboardingView = () => {
  const { data, error, loading } = useQuery(QUERY_MY_CURRENT_ACCOUNT_ONBOARDING, { variables })
  const [steps, setSteps] = useState([])
  const [stepsComplete, setStepsComplete] = useState()

  useEffect(() => {
    if (!data?.currentAccountUserOnboarding) return
    const { currentAccountUserOnboarding } = data
    setStepsComplete(currentAccountUserOnboarding.stepsComplete)
    const stepsToSet = []

    currentAccountUserOnboarding.steps.forEach(step => {
      const icon = returnIcon(step.icon)
      stepsToSet.push({ ...step, icon })
    })
    setSteps(stepsToSet)
  }, [data])

  if (error) return <RouteErrorDisplay error={error} />
  if (loading) return <Skeleton />

  return (
    <>
      {
        stepsComplete &&
          <Result
            status='success'
            title='On-boarding Complete'
            extra={[
              <SimpleLink
                key='linkToMyQuestionnaires'
                to='/ic/questionnaires'
                content='View My Questionnaires'
                type='secondary'
              />
            ]}
          />
      }
      {
        !stepsComplete &&
          <Steps
            direction='vertical'
          >
            {
              steps.map((item, index) => {
                return (
                  <Step
                    key={`stepKey${item.id}`}
                    title={item.title}
                    status={item.status}
                    subTitle={item.status === 'finish' && 'Complete'}
                    icon={item.status !== 'finish' && item.icon}
                    description={<Description item={item} />}
                  />
                )
              })
            }
          </Steps>
      }
    </>
  )
}

export default OnboardingView

const returnIcon = name => {
  // is there a better way?
  if (name === 'MailTwoTone') return <MailTwoTone />
  if (name === 'SettingTwoTone') return <SettingTwoTone />
  if (name === 'EnvironmentTwoTone') return <EnvironmentTwoTone />
  if (name === 'GoldTwoTone') return <GoldTwoTone />
  if (name === 'RightCircleTwoTone') return <RightCircleTwoTone />
  if (name === 'SmileTwoTone') return <SmileTwoTone />
  if (name === 'TabletTwoTone') return <TabletTwoTone />
  if (name === 'MobileTwoTone') return <MobileTwoTone />
}

export const LinkHandler = ({ to, text }) => {
  if (!to || !text) return null
  return <Link to={to}>{text}</Link>
}

const Description = ({ item }) => {
  const to = item?.link?.to
  const text = item?.link?.text

  const descriptionArray = item.description.split('{link}')

  return (
    <>
      {
        descriptionArray.length === 1 &&
          <>
            <>{descriptionArray[0]}</>
            <LinkHandler to={to} text={text} />
          </>
      }

      {
        descriptionArray.length === 2 &&
          <>
            <>{descriptionArray[0]}</>
            <LinkHandler to={to} text={text} />
            <>{descriptionArray[1]}</>
          </>
      }

    </>
  )
}
