import { EncryptStorage } from 'encrypt-storage'

import { memoryUser } from './appMemory'

const { REACT_APP_ENCRYPT_STORAGE_KEY } = process.env

const encryptStorage = new EncryptStorage(REACT_APP_ENCRYPT_STORAGE_KEY)

export const returnLocaleStorage = key => {
  try {
    return encryptStorage.getItem(key)
  } catch (err) {

  }
}

export const setLocaleStorage = (key, value) => {
  if (value) encryptStorage.setItem(key, value)
  if (!value) {
    encryptStorage.removeItem(key)
    memoryUser(null)
  }
}
