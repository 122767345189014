// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../core/components/ItemCardMeta'

import VerifyUserEmailAddress from '../../../../core/models/VerifyUserEmailAddress'

// ant design
import { Button, Tooltip } from 'antd'

// operations
import { QUERY_LOCATIONS } from '../../../../operations/queries/locations'

// components
import Listing from '../../../Listing'

import { DeiReportable } from './LocationDEIValuesForm'

const LocationDeis = ({ isDeiReportable, deis }) => {
  const ages = []
  const ethnicityAndRaces = []
  const sexes = []

  deis.forEach(dei => {
    const { age, ethnicityAndRace, sex, percent, percentEmployeed, percentWages } = dei

    const percentages = []

    if (percent) { percentages.push(`${percent}% total`) }
    if (percentEmployeed) { percentages.push(`${percentEmployeed}% employeed`) }
    if (percentWages) { percentages.push(`${percentWages}% wages`) }

    const percentagesDisplay = ` (${percentages.join(', ')})`

    if (age) ages.push(`${age.name}${percentagesDisplay}`)
    if (ethnicityAndRace) ethnicityAndRaces.push(`${ethnicityAndRace.name}${percentagesDisplay}`)
    if (sex) sexes.push(`${sex.name}${percentagesDisplay}`)
  })

  const locationDeis = [...ages, ...ethnicityAndRaces, ...sexes]

  return (
    <>
      {isDeiReportable && <DeiReportable />}
      {locationDeis.map(i => <div key={Math.random()}>{i}</div>)}
    </>
  )
}

const LocationList = () => {
  if (!memoryUser()) return null

  const { emailIsVerified, currentAccountUserPermissions } = memoryUser()

  const { viewAccountAdministrator, viewLocationsAdministrator, currentAccount } = currentAccountUserPermissions

  const extra = viewAccountAdministrator ? <Tooltip title='Add new location'><Link to='/admin/location/add'><Button type='primary'>Add Location</Button></Link></Tooltip> : <ItemCardMeta item={currentAccount} />

  if (!emailIsVerified) {
    return <VerifyUserEmailAddress user={memoryUser()} title='Security update required' subTitle='You will have permission to edit locations after you verify your email address.' />
  }

  if (!viewAccountAdministrator && !viewLocationsAdministrator) {
    useHistory().push('/')
    return null
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        return (
          <Link to={`/admin/location/${record.id}`}>
            <ItemCardMeta key={record.id} size='large' item={record} view='small' />
          </Link>
        )
      }
    },
    {
      title: 'DEI Settings',
      dataIndex: 'deis',
      render: (deis, { isDeiReportable }) => <LocationDeis deis={deis} isDeiReportable={isDeiReportable} />
    }
    // {
    //   title: 'Admin User(s)',
    //   dataIndex: 'locationAdminUsers',
    //   render: value => (
    //     <Space direction='vertical'>
    //       {
    //         value.map(i => <Space key={Math.random()}>{i.email}</Space>)
    //       }
    //     </Space>
    //   )
    // },
    // {
    //   title: 'General Access User(s)',
    //   dataIndex: 'locationGeneralUsers',
    //   render: value => (
    //     <Space direction='vertical'>
    //       {
    //         value.map(i => <Space key={Math.random()}>{i.email}</Space>)
    //       }
    //     </Space>
    //   )
    // },
    // {
    //   title: 'Questionnaire(s)',
    //   dataIndex: 'icquestionnaires',
    //   render: value => (
    //     <Space direction='vertical'>
    //       {
    //         value.map(i => <Space key={Math.random()}>{i.name}</Space>)
    //       }
    //     </Space>
    //   )
    // }
  ]

  return (
    <Listing
      query={QUERY_LOCATIONS}
      columns={columns}
      dataKey='locations'
      criterias={{ accountId: memoryUser()?.currentAccountId }}
      defaultSort={{
        sortField: 'name',
        sortDirection: 1
      }}
      extra={extra}
    />
  )
}

export default LocationList
