import React from 'react'
import { useParams, withRouter } from 'react-router-dom'

import Page from '../../../../components/Page'
import TemplateForm from '../../../../components/sections/admin/ic/template/TemplateForm'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICTemplate = () => {
  const { id } = useParams()
  const title = id ? 'Edit template' : 'Add template'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    },
    {
      path: '/admin/ic/templates',
      breadcrumbName: 'Templates'
    },
    {
      path: '',
      breadcrumbName: title
    }
  ]
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-padding-leftRight page-padding-topBottom'>
          <TemplateForm />
        </div>
      </Page>
    </>
  )
}

export default withRouter(ICTemplate)
