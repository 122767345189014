import React from 'react'
import Page from '../../components/Page'
import Container from '../../components/Container'

import SpawnedLandingSurvey from '../../components/sections/ic/questionnaire/SpawnedLandingSurvey'

const QuestionnaireSpawned = () => {
  return (
    <Page>
      <Container>
        <SpawnedLandingSurvey />
      </Container>
    </Page>
  )
}

export default QuestionnaireSpawned
