// react
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../../core/components/ItemCardMeta'

// ant design
import { Button, Tooltip } from 'antd'

// operations
import { QUERY_TEMPLATES } from '../../../../../operations/queries/ic/templates'

// components
import TemplateCreateModal from './TemplateCreateModal'
import Listing from '../../../../Listing'
import TemplateFeedback from './TemplateFeedback'

const TemplateList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Ictemplate')
  const edit = permissions?.menuAccess?.edit.includes('Ictemplate')
  const list = permissions?.menuAccess?.list.includes('Ictemplate')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const [createVisible, setCreateVisible] = useState(false)

  const extra = (
    <>
      {add && <Tooltip title='Add new template'><Button type='primary' onClick={() => setCreateVisible(true)}>Add Template</Button></Tooltip>}
    </>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) return <ItemCardMeta key={record.id} size='large' item={record} view='small' />
        return (
          <Link to={`/admin/ic/template/${record.id}`}>
            <ItemCardMeta key={record.id} size='large' item={record} view='small' />
          </Link>
        )
      }
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      render: (value) => {
        if (!value) return null
        return <TemplateFeedback feedback={value} />
      }
    },
    {
      title: 'Published',
      dataIndex: 'published',
      sorter: (a, b) => a > b,
      render: (value) => value ? 'TRUE' : 'FALSE'
    }
  ]

  return (
    <>
      <Listing
        query={QUERY_TEMPLATES}
        columns={columns}
        dataKey='ictemplates'
        criterias={{ accountId: memoryUser()?.currentAccountId }}
        defaultSort={{
          sortField: 'name',
          sortDirection: 1
        }}
        extra={extra}
      />
      <TemplateCreateModal
        visible={createVisible}
        onCancel={() => {
          setCreateVisible(false)
        }}
      />
    </>
  )
}

export default TemplateList
