// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// ant design
import { Modal, Form, Input, Switch, Space } from 'antd'
import ItemHeading from '../../../../../../core/components/ItemHeading'

// apollo & operations
import { useMutation } from '@apollo/client'
import { INSERT_ANSWER, UPDATE_ANSWER } from '../../../../../../operations/mutations/ic/answers'

// other libraries
import formatErrors from '../../../../../../utils/form'
import FormItemError from '../../../../../elements/formItems/FormItemError'

const AnswerModalForm = ({ answer, visible, setVisible, onChange, refetch }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateAnswer] = useMutation(UPDATE_ANSWER)
  const [insertAnswer] = useMutation(INSERT_ANSWER)

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  return (
    <Modal
      visible={visible}
      title={answer?.id ? 'Edit an answer' : 'Add an answer'}
      okText={answer?.id ? 'Update' : 'Add'}
      cancelText='Cancel'
      onCancel={() => setVisible(false)}
      confirmLoading={loading}
      destroyOnClose
      onOk={async () => {
        setLoading(true)
        try {
          const values = await form.validateFields()
          values.order = values.order ? parseInt(values.order) : 0
          values.icanswerscore = values.icanswerscore ? parseInt(values.icanswerscore) : 0
          values.icquestionId = answer.icquestionId
          values.accountId = answer.accountId
          const mutated = answer.id.startsWith('new_') === false ? await updateAnswer({ variables: { id: answer.id, icanswerInput: { ...values } } }) : await insertAnswer({ variables: { ...values } })
          for (const field in values) {
            answer[field] = values[field]
          }
          if (mutated.data?.insertIcanswer) {
            answer.id = mutated.data?.insertIcanswer.id
          }
          if (onChange) onChange(answer)
          setLoading(false)
          setVisible(false)
        } catch (e) {
          setLoading(false)
          setErrors(formatErrors(e))
        }
      }}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <ItemHeading loading={loading} item={answer} refetch={refetch} />
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          initialValues={answer}
          preserve={false}
        >
          <Form.Item
            label='Name (this becomes a reportable value when selected)'
            name='name'
            hasFeedback
            validateStatus={itemErrors.name ? 'error' : ''}
            help={itemErrors.name}
          >
            <Input />
          </Form.Item>

          {/*
            <Row gutter={6}>
              <Col span={12}>
                <Form.Item
                  label='Score'
                  name='icanswerscore'
                  hasFeedback
                  validateStatus={itemErrors.icanswerscore ? 'error' : ''}
                  help={itemErrors.icanswerscore}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label='Order'
                  name='order'
                  hasFeedback
                  validateStatus={itemErrors.order ? 'error' : ''}
                  help={itemErrors.order}
                  style={{ display: 'none' }}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          */}

          <Form.Item
            label='Notes'
            name='note'
            hasFeedback
            validateStatus={itemErrors.note ? 'error' : ''}
            help={itemErrors.note}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label='Private Notes'
            name='privateNote'
            hasFeedback
            validateStatus={itemErrors.privateNote ? 'error' : ''}
            help={itemErrors.privateNote}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label='ImageURL'
            name='imageURL'
            hasFeedback
            validateStatus={itemErrors.imageURL ? 'error' : ''}
            help={itemErrors.imageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <Form.Item
            label='Published'
            name='published'
            valuePropName='checked'
            hasFeedback
            validateStatus={itemErrors.published ? 'error' : ''}
            help={itemErrors.published}
          >
            <Switch />
          </Form.Item>

          <FormItemError error={formError} />
        </Form>
      </Space>
    </Modal>
  )
}

AnswerModalForm.propTypes = {
  setVisible: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
  answer: PropTypes.object.isRequired
}

export default AnswerModalForm
