import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { networkOnlyAllErrorsVariables, USER_FIELDS } from '../consts'
import { refreshUser } from '../auth/appMemory'

import ItemCardMeta from '../components/ItemCardMeta'

import { Select, Space, Modal, Alert, Typography } from 'antd'

const { Option } = Select

const QUERY_ACCOUNT_ADMINS = gql`
  query transferToUsers ($accountId: ID!) {
    transferToUsers (accountId: $accountId) {

      ${USER_FIELDS}
    }
  }
`

const TRANSFER_ACCOUNT = gql`
  mutation transferAccount ($transferAccountInput: TransferAccountInput!) {
    transferAccount (transferAccountInput: $transferAccountInput) {
      id
    }
  }
`

const AccountOwnerTransfer = ({ account, refetch }) => {
  if (!account?.isUserIdAccountOwner) return null

  const { id: accountId } = account

  const [queryAdminAccounts, { data, error, loading }] = useLazyQuery(QUERY_ACCOUNT_ADMINS)

  const [transferAccount, { data: transferred, error: transfererror }] = useMutation(TRANSFER_ACCOUNT)

  const [transferToUsers, setTransferToUsers] = useState([])
  const [transferToUserId, setTransferToUserId] = useState(null)

  useEffect(() => {
    queryAdminAccounts({
      variables: { accountId: account.id },
      ...networkOnlyAllErrorsVariables
    })
  }, [account])

  useEffect(() => {
    if (transferred) {
      setTransferToUserId(null)
      refreshUser('AccountOwnerTransfer')
      refetch()
    }
  }, [transferred])

  useEffect(() => {
    if (data?.transferToUsers) setTransferToUsers(data.transferToUsers)
  }, [data])

  useEffect(() => {
    if (error || transfererror) setTransferToUserId(null)
  }, [error, transfererror])

  if (error?.message) return <>{error?.message}</>
  if (transfererror?.message) return <>{transfererror?.message}</>

  return (
    <Space size='small'>
      <Modal
        header={null}
        title={<Typography.Text style={{ fontSize: 24 }}>Confirm Account Transfer</Typography.Text>}
        visible={transferToUserId}
        onCancel={() => setTransferToUserId(null)}
        onOk={(values) => transferAccount({
          variables: { transferAccountInput: { accountId, transferToUserId } },
          errorPolicy: 'all'
        })}
      >
        <Alert
          type='error'
          title='NOTE'
          description='If you transfer this account ownership to a different user, you can only get it back if this user transfers it back.'
        />
      </Modal>

      Transfer ownership:

      <Select
        value={transferToUserId}
        loading={loading}
        showSearch
        placeholder='Select administrator:'
        onChange={(userId) => setTransferToUserId(userId)}
        allowClear='true'
      >
        {
          transferToUsers.map(user => (
            <Option key={`transferUserId${user.id}`} value={user.id}>
              <ItemCardMeta item={user} view='small' size='small' />
            </Option>
          ))
        }
      </Select>

    </Space>
  )
}

export default AccountOwnerTransfer
