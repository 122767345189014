import React, { useEffect, useState } from 'react'

import { Form, Skeleton, Space } from 'antd'

import { gql, useMutation } from '@apollo/client'

import FormWrapper from '../../layout/FormWrapper'
import InvitationFormItems from './InvitationFormItems'
import TipImpactCalculatorLocationAdmnVsGeneral from '../tips/TipImpactCalculatorLocationAdmnVsGeneral'

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const INSERT_INVITE = gql`
  mutation insertInvite ($inviteInput: InviteInput!) {
    insertInvite (inviteInput: $inviteInput) {
      id
    }
  }
`

const InvitationAdd = ({ setActiveKey, refetch, accountloading, account }) => {
  if (accountloading || !account) return <Skeleton active avatar paragraph />
  const [form] = Form.useForm()

  const [insertInvite, { data, loading, error }] = useMutation(INSERT_INVITE)
  const [customerror, setCustomerror] = useState()

  useEffect(() => {
    if (!data?.insertInvite?.id) return
    form.resetFields()
    refetch()
    setActiveKey('invitationHistory')
  }, [data])

  useEffect(() => {
    if (!error) return
    if (error?.message === 'Invite already exists') return setCustomerror('This email address has accepted an invitation sent from your account previously. User management allows you to add and remove users in your account.')
  }, [error])

  const { id: accountId } = account

  const emailNowWithFreshToken = true

  return (
    <Space direction='vertical'>
      <TipImpactCalculatorLocationAdmnVsGeneral />
      <FormWrapper
        size='medium'
        form={form}
        preserve={false}
        onFinish={(values) => insertInvite({
          variables: {
            inviteInput: {
              ...values,
              accountId,
              domainName,
              emailNowWithFreshToken
            }
          },
          errorPolicy: 'all'
        })}
        loading={loading}
        error={error}
        customerror={customerror}
        fluid='true'
      >

        <InvitationFormItems account={account} />

      </FormWrapper>
    </Space>
  )
}

export default InvitationAdd
