// https://charts.ant.design/demos/organizational-graph/?type=api
import React from 'react'
import { useQuery } from '@apollo/client'
import { OrganizationGraph } from '@ant-design/charts'
import Loading from '../../../../../components/Loading'

import { QUERY_NODE_TREE_TEMPLATE_GRAPH } from '../../../../../operations/queries/ic/templates'

const TemplateOrganizationGraph = ({ ictemplateId }) => {
  const variables = { nodeTreeTemplateBuilderInput: { ictemplateId } }
  const { data } = useQuery(QUERY_NODE_TREE_TEMPLATE_GRAPH, { variables })

  if (!data) return <Loading />

  const { organizationGraph } = data.nodeTreeTemplateGraph

  const organizationGraphConfig = {
    behaviors: ['drag-canvas', 'zoom-canvas'],
    data: organizationGraph,
    onReady: (graph) => {
      graph.on('node:click', (evt) => {
        const { id } = evt.item._cfg
        const idObject = JSON.parse(id)

        // possibilities { templateId, icnodeChildId, iccategoryId, icquestionId, icanswerId } = idObject
        if (['local'].includes(process.env.REACT_APP_ENV)) console.info('TemplateOrganizationGraph', JSON.stringify(idObject, null, 4))
      })
    }
  }

  return <OrganizationGraph {...organizationGraphConfig} />
}

export default TemplateOrganizationGraph
