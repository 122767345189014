// mutations hould be moved to new file
import { gql } from '@apollo/client'
import { queryTemplateFields } from './templates'

import { PERMISSIONS_FIELDS } from '../../../core/consts'

const IC_MAX_LEVELS = parseInt(process.env.REACT_APP_IC_MAX_LEVELS)

const userRoleFields = `
  userRoles {
    locationGeneralUserIds
    locationsAdminUserIds
    contributorUserIds
    isIcquestionnaireAccountAdmin
    isIcquestionnaireLocationAdmin
    canLockIcquestionnaire
  }
`

const finalizationfields = `
  canRequestFinalization
  canCancelFinalization
  canAcceptOrRejectFinalization
  finalizationIsRequested
  finalizationIsCancelled
  finalizationIsAccepted
  finalizationRejected
  lastFinalizationRejectedNote
  lastFinalizationDateAgo
  lastFinalizationStatus
`

const icquestionnaireLiveFields = `
  id
  ictemplateId
  name
  note
  imageURL
  cloudinaryImage
  reportingDate (format: "yyyy-mm-dd")
  locked
  reasons
  live
  ictemplate {
    id
    name
    imageURL
    cloudinaryImage
  }
  feedback {
    issues
  }
  ${userRoleFields}
  ${finalizationfields}
  currentUserAccountPermissions{
    ${PERMISSIONS_FIELDS}
  }
`

export const QUERY_ICQUESTIONNAIRES_PUBLISHED = gql`
  query(
    $filter: Filter
    $current: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: Int
  ) {
    icquestionnairesPublished (
      filter: $filter
      current: $current
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      ${icquestionnaireLiveFields}
    }
  }
`

export const MUTATE_QUESTIONNAIRE_TOGGLE_LOCKED = gql`
  mutation toggleLocked ( $icquestionnaireId: ID! ) {
    toggleLocked (icquestionnaireId: $icquestionnaireId) {
      ${icquestionnaireLiveFields}
    }
  }
`

export const MUTATE_QUESTIONNAIRE_FINALIZATION_REQUEST = gql`
  mutation finalizationRequest ( $icquestionnaireId: ID! ) {
    finalizationRequest (icquestionnaireId: $icquestionnaireId) {
      ${icquestionnaireLiveFields}
    }
  }
`

export const MUTATE_QUESTIONNAIRE_FINALIZATION_CANCEL_REQUEST = gql`
  mutation finalizationCancelRequest ( $icquestionnaireId: ID! ) {
    finalizationCancelRequest (icquestionnaireId: $icquestionnaireId) {
      ${icquestionnaireLiveFields}
    }
  }
`

export const MUTATE_QUESTIONNAIRE_FINALIZATION_ACCEPT_REQUEST = gql`
  mutation finalizationAcceptRequest ( $icquestionnaireId: ID! ) {
    finalizationAcceptRequest (icquestionnaireId: $icquestionnaireId) {
      ${icquestionnaireLiveFields}
    }
  }
`

export const MUTATE_QUESTIONNAIRE_FINALIZATION_REJECT_REQUEST = gql`
  mutation finalizationRejectRequest ( $icquestionnaireId: ID!, $finalizationRejectedNote: String ) {
    finalizationRejectRequest (icquestionnaireId: $icquestionnaireId, finalizationRejectedNote: $finalizationRejectedNote) {
      ${icquestionnaireLiveFields}
    }
  }
`

const nodeTreeQuestionnaireLiveNavDataChildrenFields = `
  selectable
  disabled
  title
  cloudinaryImage
  value
`
export let nodeTreeQuestionnaireLiveNavDataChildren = ''
let nodeTreeQuestionnaireLiveNavDataChildrenCounter = IC_MAX_LEVELS

while (nodeTreeQuestionnaireLiveNavDataChildrenCounter > 0) {
  if (nodeTreeQuestionnaireLiveNavDataChildrenCounter === IC_MAX_LEVELS) {
    nodeTreeQuestionnaireLiveNavDataChildren = `
      children {
        ${nodeTreeQuestionnaireLiveNavDataChildrenFields}
      }
    `
  } else {
    nodeTreeQuestionnaireLiveNavDataChildren = `
      children {
        ${nodeTreeQuestionnaireLiveNavDataChildrenFields}
        ${nodeTreeQuestionnaireLiveNavDataChildren}
      }
    `
  }
  nodeTreeQuestionnaireLiveNavDataChildrenCounter -= 1
}

const nodeTreeQuestionnaireLiveFormDataChildrenFields = `
  indentIndex
  icnodeChildId
  question {
    icquestionId
    name
    note
    imageURL
    cloudinaryImage
    icquestiontype
    canselectmanyanswers
    inputtype
    numbertype
    decimalplaces
    required
    answers {
      icanswerId
      name
      note
      imageURL
      cloudinaryImage
    }
    response
    answerIsClickTouch
  }
`

let nodeTreeQuestionnaireLiveFormDataChildren = ''
let nodeTreeQuestionnaireLiveFormDataChildrenCounter = IC_MAX_LEVELS

while (nodeTreeQuestionnaireLiveFormDataChildrenCounter > 0) {
  if (nodeTreeQuestionnaireLiveFormDataChildrenCounter === IC_MAX_LEVELS) {
    nodeTreeQuestionnaireLiveFormDataChildren = `
      children {
        ${nodeTreeQuestionnaireLiveFormDataChildrenFields}
      }
    `
  } else {
    nodeTreeQuestionnaireLiveFormDataChildren = `
      children {
        ${nodeTreeQuestionnaireLiveFormDataChildrenFields}
        form {
          key
          title
          note
          imageURL
          cloudinaryImage
          ${nodeTreeQuestionnaireLiveFormDataChildren}
        }
      }
    `
  }
  nodeTreeQuestionnaireLiveFormDataChildrenCounter -= 1
}

export const QUESTIONNAIRES_LIVE_FIELDS = `
  icquestionnaire {
    id
    icquestionnairespawnerId
    name
    note
    imageURL
    cloudinaryImage
    reportingDate (format: "mmmm dS, yyyy")
    startDate (format: "mmmm dS, yyyy")
    startDateAgo
    endDate (format: "mmmm dS, yyyy")
    endDateAgo
    locked

    ictemplate {
      id
      name
    }
    account {
      id
      name
      note
    }
    landingPageRoute
  }
  navData {
    selectable
    cloudinaryImage
    disabled
    title
    value
    ${nodeTreeQuestionnaireLiveNavDataChildren}
  }
  formData {
    icnodeId
    rootForm {
      key
      title
      note
      imageURL
      cloudinaryImage
      ${nodeTreeQuestionnaireLiveFormDataChildren}
    }
  }
  feedback {
    live
    reasons
  }
  stats {
    allQuestionsPercentComplete
  }
  icquestionnairespawnerImageURL
`

export const QUERY_NODE_TREE_QUESTIONNAIRE_LIVE = gql`
  query nodeTreeQuestionnaireLive ( $nodeTreeQuestionnaireLiveInput: NodeTreeQuestionnaireLiveInput! ) {
    nodeTreeQuestionnaireLive (nodeTreeQuestionnaireLiveInput: $nodeTreeQuestionnaireLiveInput) {
      ${QUESTIONNAIRES_LIVE_FIELDS}
    }
  }
`

export const QUESTIONNAIRES_FIELDS = `
  id
  key
  name
  imageURL
  cloudinaryImage
  note
  privateNote
  endDate (format: "mmm dd yyyy HH:MM:ss")
  reportingDate (format: "yyyy-mm-dd")
  startDate (format: "mmm dd yyyy HH:MM:ss")
  published
  locked
  live
  feedback {
    issuesCount
  }

  ${userRoleFields}

  lastFinalizationDateAgo
  lastFinalizationStatus

  ictemplate {
    id
    name
    imageURL
    cloudinaryImage
    note
    privateNote
    feedback {
      issuesCount
    }
  }
`
export const QUERY_QUESTIONNAIRES = gql`
query(
  $filter: Filter
  $current: Int
  $pageSize: Int
  $sortField: String
  $sortDirection: Int
) {
  icquestionnaires(
    filter: $filter
    current: $current
    pageSize: $pageSize
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    ${QUESTIONNAIRES_FIELDS}
  }
}
`

export const QUESTIONNAIRE_FIELDS = `
  id
  accountId
  name
  note
  privateNote
  imageURL
  cloudinaryImage
  published
  hidden
  accountgroupIds
  accountId
  endDate (format: "ddd mmm dd yyyy HH:MM:ss")
  endDateAgo
  ictemplateId
  locationIds
  locked
  reportingDate
  startDate (format: "ddd mmm dd yyyy HH:MM:ss")
  startDateAgo
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "ddd mmm dd yyyy HH:MM:ss")
  updatedAt (format: "ddd mmm dd yyyy HH:MM:ss")

  live
  feedback {
    feedbackDataTime
    instructions
    warnings
    issues
    hasFeedback
    instructionsCount
    warningsCount
    issuesCount
  }

  ${userRoleFields}

  lastFinalizationDateAgo
  lastFinalizationStatus

  ictemplate {
    id
    name
    note
    privateNote
    imageURL
    cloudinaryImage
    accountId
    hidden
    published

    feedback {
      feedbackDataTime
      instructions
      warnings
      issues
      hasFeedback
      issuesCount
    }

    createdBy
    createdAtAgo
    updatedAtAgo
    createdAt (format: "ddd mmm dd yyyy h:MM:ss TT Z")
    updatedAt (format: "ddd mmm dd yyyy h:MM:ss TT Z")
  }
`

export const QUERY_QUESTIONNAIRE = gql`
query Questionnaire($id: ID!) {
  icquestionnaire (id: $id) {
    ${QUESTIONNAIRE_FIELDS}
  }
}
`

export const QUERY_QUESTIONNAIRE_PUBLISHED = gql`
query QuestionnairePublished($id: ID!) {
  icquestionnairePublished(id: $id) {
    id
    name
    imageURL
    cloudinaryImage
    published
    hidden
    endDate (format: "ddd mmm dd yyyy HH:MM:ss")
    endDateAgo
    locked
    reportingDate (format: "yyyy-mm-dd")
    startDate (format: "ddd mmm dd yyyy HH:MM:ss")
    startDateAgo
    ictemplate {
      ${queryTemplateFields}
    }
    createdBy
    createdAtAgo
    updatedAtAgo
    createdAt (format: "ddd mmm dd yyyy HH:MM:ss")
    updatedAt (format: "ddd mmm dd yyyy HH:MM:ss")
  }
}
`

export const QUERY_SPAWNER_SURVEY_LANDING = gql`
  query icquestionnairespawnedSurveyLanding( $id: ID!, $icquestionnairespawnedSurveyLandingInput: IcquestionnairespawnedSurveyLandingInput! ) {
    icquestionnairespawnedSurveyLanding(id: $id, icquestionnairespawnedSurveyLandingInput: $icquestionnairespawnedSurveyLandingInput) {
      ${QUESTIONNAIRES_LIVE_FIELDS}

      createdAt
      createdAtAgo
      expiresAt
      expiresAtAgo
    }
  }
`
