import { gql } from '@apollo/client'

export const INSERT_CHILD_NODE = gql`
mutation insertIcnodechild(
  $icnodeId: ID,
  $name: String,
  $iccategoryId: ID,
  $icquestionId: ID,
  $order: Int
) {
  insertIcnodechild(icnodechildInputInsert: {
    icnodeId: $icnodeId,
    name: $name,
    order: $order,
    iccategoryId: $iccategoryId,
    icquestionId: $icquestionId
  })
}
`

export const UPDATE_CHILD_NODE = gql`
mutation updateIcnodechild($id: ID!, $icnodechildInputUpdate: IcnodechildInputUpdate) {
  updateIcnodechild(id: $id, icnodechildInputUpdate: $icnodechildInputUpdate)
}
`
