import { gql } from '@apollo/client'
import { ACCOUNT_GROUP_FIELDS } from '../queries/accountGroups'

export const INSERT_ACCOUNT_GROUP = gql`
mutation insertAccountgroup(
  $accountId: ID
  $hidden: Boolean
  $name: String
  $note: String
  $imageURL: String
  $published: Boolean
) {
  insertAccountgroup(accountgroupInput: {
    accountId: $accountId
    hidden: $hidden
    name: $name
    note: $note
    imageURL: $imageURL
    published: $published
  }) {
    ${ACCOUNT_GROUP_FIELDS}
  }
}
`

export const UPDATE_ACCOUNT_GROUP = gql`
mutation updateAccountgroup($id: ID!, $accountgroupInput: AccountgroupInput!) {
  updateAccountgroup(id: $id, accountgroupInput: $accountgroupInput) {
    ${ACCOUNT_GROUP_FIELDS}
  }
}
`
