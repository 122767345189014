import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, Radio, Grid, Tooltip } from 'antd'

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faGrin, faMeh, faFrown, faFrownOpen } from '@fortawesome/free-solid-svg-icons'

// components
import { TitleElement, NoteElement } from '../AvatarElement'

const { useBreakpoint } = Grid

const QuestionRate = ({ icnodeChildId, question }) => {
  const screens = useBreakpoint()
  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={question.response}
        rules={[{ required: question.required, message: 'This field is required' }]}
      >
        <Radio.Group className='radioRate'>
          <Radio.Button value='1'><Tooltip title='I strongly agree'><FontAwesomeIcon icon={faGrin} className='pointer' size={!screens.md ? '1x' : '2x'} color='#34ac42' /><span style={{ display: !screens.md ? 'none' : 'block', fontSize: '12px' }}>I strongly agree</span></Tooltip></Radio.Button>
          <Radio.Button value='2'><Tooltip title='I agree'><FontAwesomeIcon icon={faSmile} className='pointer' size={!screens.md ? '1x' : '2x'} color='#87c256' /><span style={{ display: !screens.md ? 'none' : 'block', fontSize: '12px' }}>I agree</span></Tooltip></Radio.Button>
          <Radio.Button value='3'><Tooltip title="I'm not sure"><FontAwesomeIcon icon={faMeh} className='pointer' size={!screens.md ? '1x' : '2x'} color='#faa639' /><span style={{ display: !screens.md ? 'none' : 'block', fontSize: '12px' }}>I'm not sure</span></Tooltip></Radio.Button>
          <Radio.Button value='4'><Tooltip title='I disagree'><FontAwesomeIcon icon={faFrown} className='pointer' size={!screens.md ? '1x' : '2x'} color='#ef5125' /><span style={{ display: !screens.md ? 'none' : 'block', fontSize: '12px' }}>I disagree</span></Tooltip></Radio.Button>
          <Radio.Button value='5'><Tooltip title='I strongly disagree'><FontAwesomeIcon icon={faFrownOpen} className='pointer' size={!screens.md ? '1x' : '2x'} color='#dc1e24' /><span style={{ display: !screens.md ? 'none' : 'block', fontSize: '12px' }}>I strongly disagree</span></Tooltip></Radio.Button>
        </Radio.Group>
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionRate.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default QuestionRate
