import React from 'react'

import { Tag } from 'antd'

const TokenExpiryView = ({ expired, acceptedFromNow, expiresFromNow }) => {
  if (acceptedFromNow) {
    return (
      <Tag color='success'>
        Accepted {acceptedFromNow}
      </Tag>
    )
  }
  if (!acceptedFromNow && !expired) {
    return (
      <Tag color='default'>
        Waiting: Expires {expiresFromNow}
      </Tag>
    )
  }
  return (
    <Tag color='error'>
      Expired
    </Tag>
  )
}

export default TokenExpiryView
