// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useParams } from 'react-router-dom'

import { memoryUser } from '../../../../../core/auth/appMemory'
// import ItemCardMeta from '../../../../../core/components/ItemCardMeta'
import ItemHeading from '../../../../../core/components/ItemHeading'

import Loading from '../../../../../components/Loading'
import FormContainer from '../../../../../components/FormContainer'
import FormItemsSuperAdmin from '../../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../../elements/formItems/FormItemError'
import formatErrors from '../../../../../utils/form'

import TemplateCreator from './TemplateCreator'
import TemplateFeedback from './TemplateFeedback'

// components
import ModalDialog from '../../../../../components/ModalDialog'

import { Form, Input, Button, Switch, Alert, Space, Card } from 'antd'
// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_TEMPLATE, QUERY_TEMPLATES } from '../../../../../operations/queries/ic/templates'
import { INSERT_TEMPLATE, UPDATE_TEMPLATE } from '../../../../../operations/mutations/ic/templates'

const ICTemplate = () => {
  const { id } = useParams()

  const { data, loading, refetch: refetchtemplate } = useQuery(QUERY_TEMPLATE, { fetchPolicy: 'no-cache', variables: { id }, skip: !id })
  const initialValues = { ictemplate: { name: '' } }

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={
        <FormComponent
          data={{ ...initialValues, ...data }}
          refetchtemplate={refetchtemplate}
        />
}
    />
  )
}

export default ICTemplate

const FormComponent = ({ data, refetchtemplate }) => {
  const { permissions } = memoryUser()

  const edit = permissions?.menuAccess?.edit.includes('Ictemplate')

  if (!edit) {
    useHistory().push('/')
    return null
  }

  const accountId = data?.ictemplate?.accountId || memoryUser().currentAccountId
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [feedback, setFeedback] = useState(data.ictemplate?.feedback)
  const [icquestionnaires, setIcquestionnaires] = useState(data.ictemplate?.icquestionnaires || [])
  const [itemErrors, setItemErrors] = useState({})
  const [updateTemplate, { loading: updateLoading }] = useMutation(UPDATE_TEMPLATE)
  const [insertTemplate, { loading: insertLoading }] = useMutation(INSERT_TEMPLATE, {
    update (cache, { data: { insertIctemplate } }) {
      const data = cache.readQuery({ query: QUERY_TEMPLATES })
      cache.writeQuery({
        query: QUERY_TEMPLATES,
        data: { ictemplates: !data ? [insertIctemplate] : data.ictemplates.concat([insertIctemplate]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/ic/template/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateTemplate({ variables: { id: id, ictemplateInput: { ...values, accountId } } }) : await insertTemplate({ variables: { ...values, accountId } })

      if (id) {
        setFeedback(mutated.data.updateIctemplate.feedback)
        setIcquestionnaires(mutated.data.updateIctemplate.icquestionnaires)
      }

      const modalFunc = values.hidden ? ModalDialog.info : ModalDialog.success
      modalFunc({
        title: 'Success',
        content: `The template has been ${id ? (values.hidden ? 'deleted' : 'updated') : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/ic/templates'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertTemplate?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  if (!data) return <Loading />
  const { ictemplate } = data

  return (

    <Space direction='vertical'>

      <ItemHeading loading={updateLoading} item={ictemplate} refetch={refetchtemplate} />

      <Alert banner='true' message='How to build a Template' description='Switch between Categories and Questions on the right pane. Click to drag and drop on the left pane. Mouse-over all icons to read what you can do.' type='success' showIcon='true' />

      <Alert banner='true' message='Qualifying a Template' description='Template issues identify a negative user experience and pauses any questionnaire(s) in LIVE MODE. Warnings are categories, or questions, or answers that will not be displayed to the user in LIVE MODE.' type='info' showIcon='true' />

      <Form
        form={form}
        id='templateForm'
        name='templateForm'
        onFinish={onFinish}
        preserve={false}
        initialValues={ictemplate}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 17 }}
      >
        <Form.Item
          label='Name'
          name='name'
          hasFeedback
          validateStatus={itemErrors.name ? 'error' : ''}
          help={itemErrors.name}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label='Notes'
          name='note'
          hasFeedback
          validateStatus={itemErrors.note ? 'error' : ''}
          help={itemErrors.note}
        >
          <Input.TextArea
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label='Private Notes'
          name='privateNote'
          hasFeedback
          validateStatus={itemErrors.privateNote ? 'error' : ''}
          help={itemErrors.privateNote}
        >
          <Input.TextArea
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label='Image URL'
          name='imageURL'
          hasFeedback
          validateStatus={itemErrors.imageURL ? 'error' : ''}
          help={itemErrors.imageURL}
        >
          <Input placeholder='https://' />
        </Form.Item>

        <Form.Item
          className='form-item-text'
          label='Published'
          name='published'
          valuePropName='checked'
          wrapperCol={{ sm: 20 }}
        >
          <Switch />
        </Form.Item>

        <FormItemsSuperAdmin data={ictemplate} hasHidden />

        <FormItemError error={formError} />

        <Form.Item>
          <Button style={{ marginTop: '32px' }} type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
            {id ? 'Update' : 'Add'}
          </Button>
        </Form.Item>
      </Form>

      {
        icquestionnaires.length > 0 &&
          <Space align='baseline'>

            {
              icquestionnaires.map((item, index) => {
                return (
                  <Card
                    title={(
                      <Link to={`/admin/ic/questionnaire/${item.id}`}>
                        <>{item.name}</>
                        {/*
                          <ItemCardMeta item={item} rows={5} />
                        */}
                      </Link>
                    )}
                    key={`relatedquestionnaire${item.id}`}
                  >
                    <Space direction='vertical' style={{ width: '100%' }}>

                      {
                        item.live
                          ? <Alert banner='true' message='Questionnaire is LIVE' type='success' showIcon='false' />
                          : <Alert banner='true' message='Questionnaire is PAUSED' type='warning' showIcon='false' />
                      }

                      <TemplateFeedback feedback={item.feedback} />

                    </Space>
                  </Card>
                )
              })
            }
          </Space>
      }

      <TemplateFeedback feedback={feedback} />

      {
        data.ictemplate && <TemplateCreator data={data} refetchtemplate={refetchtemplate} />
      }

    </Space>

  )
}

FormComponent.propTypes = {
  data: PropTypes.object,
  refetchtemplate: PropTypes.func.isRequired
}
