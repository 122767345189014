import React from 'react'
import { Skeleton } from 'antd'

const Loading = () => {
  return (
    <Skeleton active />
  )
}

export default Loading
