import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'

// constants
const SAVE_INTERVAL_MS = 1000
const MAX_HISTORY = 5

const useReactiveForm = (initialValue) => {
  const [changed, updateChanged] = useState(initialValue)
  const [status, setStatus] = useState('') // global status

  let current = changed

  const get = () => current

  const add = (updated) => {
    if (!current[updated.name]) current[updated.name] = {}
    if (!current[updated.name].history) current[updated.name].history = []
    current[updated.name].history.unshift(updated)
    current[updated.name].history = current[updated.name].history.slice(0, MAX_HISTORY)
    updateChanged(current)
  }

  const set = (newValue) => {
    current = newValue
    updateChanged(newValue)
    return current
  }

  const save = (field) => {
    if (!current[field.name].history) return
    const saveEntry = current[field.name].history.find(
      (_field) => _field.unique === field.unique
    )
    saveEntry.saved = true // saved status for the field
    current = { ...changed, ...current }
    updateChanged(current)
  }

  return {
    get,
    set,
    add,
    save,
    status,
    setStatus
  }
}

const ReactiveForm = ({ form, onFormErrors, onFieldChanged, onStatusChanged, children, retries, ...props }) => {
  const reactiveForm = useReactiveForm({})

  const onFieldsChange = async (newChangedFields, allFields) => {
    const fieldErrors = allFields.reduce((errors, field) => {
      if (field.errors.length) {
        errors.push({
          name: field.name[0],
          errors: field.errors
        })
      }
      return errors
    }, [])
    onFormErrors(fieldErrors)
    form.addChangedFields(newChangedFields)
  }

  form.addChangedFields = (newChangedFields) => {
    newChangedFields.forEach((field) => {
      if (field.errors.length === 0) {
        reactiveForm.add({
          timestamp: new Date().getTime(),
          unique: new Date().getTime() + Math.random(),
          saved: false,
          name: typeof field.name === 'string' ? field.name : field.name[0],
          value: field.value
        })

        if (reactiveForm.status === 'ERROR') {
          reactiveForm.setStatus('RETRYING')
          onStatusChanged('RETRYING')
        } else if (reactiveForm.status !== 'SAVING') onStatusChanged('NOT_SAVED')
      }
    })
  }

  useEffect(() => {
    if (reactiveForm.status) onStatusChanged(reactiveForm.status)
  }, [reactiveForm.status])

  useEffect(() => {
    const getFieldsToSave = () => {
      return Object.values(reactiveForm.get()).reduce(
        (fields, field) => {
          if (field.history?.length && field.history[0].saved === false) {
            fields.push(field.history[0])
          }
          return fields
        },
        []
      )
    }

    const saveFields = async (fieldsToSave) => {
      try {
        await Promise.all(fieldsToSave.map(async field => onFieldChanged(field)))
        fieldsToSave.map(field => reactiveForm.save(field))
        const _fieldsToSave = getFieldsToSave()
        if (_fieldsToSave.length) {
          await saveFields(_fieldsToSave)
        } else {
          reactiveForm.setStatus('SAVED')
        }
      } catch (e) {
        reactiveForm.setStatus('ERROR')
        onStatusChanged('ERROR')
      }
    }
    const interval = setInterval(async () => {
      // this interval has to finish before we can save again, or it will cause duplicates saving fields
      if (reactiveForm.status === 'SAVING' || reactiveForm.status === 'ERROR') return
      const fieldsToSave = getFieldsToSave()
      if (fieldsToSave.length) {
        reactiveForm.setStatus('SAVING')
        saveFields(fieldsToSave)
      } else {
        reactiveForm.setStatus('SAVED')
      }
    }, SAVE_INTERVAL_MS)
    return () => clearInterval(interval)
  }, [reactiveForm])

  useEffect(() => {
    if (retries) reactiveForm.setStatus('RETRYING')
  }, [retries])

  return (
    <Form form={form} {...props} onFieldsChange={onFieldsChange}>
      {children}
    </Form>
  )
}

ReactiveForm.propTypes = {
  form: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  onFormErrors: PropTypes.func.isRequired,
  onStatusChanged: PropTypes.func.isRequired,
  retries: PropTypes.number.isRequired
}

export default ReactiveForm
