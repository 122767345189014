import React from 'react'
import { useParams } from 'react-router-dom'

import Page from '../../../components/Page'
import AccountGroupForm from '../../../components/sections/admin/accountGroup/AccountGroupForm'
import PageInstructions from '../../../documentation/PageInstructions'

const AccountGroup = () => {
  const { id } = useParams()
  const title = id ? 'Edit account group' : 'Add account group'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/admin/accountgroups',
      breadcrumbName: 'Account groups'
    },
    {
      path: '',
      breadcrumbName: title
    }
  ]
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-container'>
          <div className='page-padding-leftRight page-padding-topBottom'>
            <AccountGroupForm />
          </div>
        </div>
      </Page>
    </>
  )
}

export default AccountGroup
