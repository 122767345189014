import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { Result } from 'antd'
import { SmileOutlined, LoadingOutlined } from '@ant-design/icons'

import RouteErrorDisplay from '../components/RouteErrorDisplay'
import SimpleLink from '../components/SimpleLink'

import { memoryUser } from '../auth/appMemory'

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const MUTATION_USE_INVITATION_TOKEN = gql`
  mutation useInvitationToken ($useInvitationTokenInput: UseInvitationTokenInput!) {
    useInvitationToken (useInvitationTokenInput: $useInvitationTokenInput) {
      userId
      emailIsVerified
      hasPassword
    }
  }
`

const InvitationLandingPage = () => {
  const [useInvitationToken, { data, loading, error }] = useMutation(MUTATION_USE_INVITATION_TOKEN)

  const [hasPassword, setHasPassword] = useState()
  const { token } = useParams()

  useEffect(() => {
    if (!token) return
    useInvitationToken({
      variables: {
        useInvitationTokenInput: {
          domainName,
          invitationToken: token
        }
      },
      errorPolicy: 'all'
    })
  }, [token])

  useEffect(() => {
    if (data?.useInvitationToken?.userId) {
      setHasPassword(data.useInvitationToken.hasPassword)
    }
  }, [data])

  useEffect(() => {
    if (data?.useInvitationToken?.userId) {
      setHasPassword(data.useInvitationToken.hasPassword)
    }
  }, [data])

  if (error) return <RouteErrorDisplay error={error} />

  const title = loading ? 'Checking token' : 'Invitation accepted'
  const icon = loading ? <LoadingOutlined spin={loading} /> : <SmileOutlined />
  const subTitle = !hasPassword ? 'Please check your email for a link to set your password.' : 'Sign in to get started'

  return (
    <Result
      status='success'
      title={title}
      subTitle={!loading && subTitle}
      icon={icon}
      extra={
        <>
          {/*
            (!loading && !hasPassword) &&
              <SimpleLink
                to='/forgotpassword'
                content={
                  <Space>
                    <MailOutlined />
                    <>Send me a new password link</>
                  </Space>
                }
                size='large'
                type='default'
              />
          */}
          {
            (!memoryUser() && hasPassword) &&
              <SimpleLink
                to='/signin'
                content='Sign in'
                size='large'
                type='default'
              />
          }

        </>
      }
    />
  )
}

export default InvitationLandingPage

// {
//   !hasPassword &&
//     <>
//       Please check your email for a link so you can set your password.
//     </>
// }
//
// TIP: If you ever want to update your password, you can always use the Forgot Password tool.
