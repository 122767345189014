import React from 'react'
import PropTypes from 'prop-types'
import QuestionnaireQuestion from './QuestionnaireQuestion'
import ReactiveForm from '../../../ReactiveForm'
import { notificationWarn } from '../../../../utils/notification'

// apollo & operations
import { useMutation } from '@apollo/client'
import { INSERT_RESPONSE } from '../../../../operations/mutations/ic/responses'

// ant design
import { Form, Space, Typography, Skeleton } from 'antd'

// components
import AvatarElement from '../../../elements/AvatarElement'

const { Paragraph } = Typography

const QuestionnaireFormContent = ({ icnodeId, icquestionnaire, antForm, form }) => {
  if (!form) return null

  return (
    <>
      <AvatarElement
        title={form.title}
        level={form.children.length > 0 && form.children[0].indentIndex + 2}
        note={form.note}
        imageURL={form.imageURL}
        cloudinaryImage={form.cloudinaryImage}
        avatarSize='large'
      />
      <Space direction='vertical' style={{ width: '100%' }}>
        {form?.children.length > 0 && form.children.map((child) => (
          <div key={child.icnodeChildId} className='questionnaire-formContent' style={{ padding: `12px ${12 * parseInt(child.indentIndex + 1)}px` }}>
            {!!child.question && <QuestionnaireQuestion antForm={antForm} question={child.question} icquestionnaire={icquestionnaire} icnodeId={icnodeId} icnodeChildId={child.icnodeChildId} />}
            {!!child.form && <QuestionnaireFormContent antForm={antForm} form={child.form} icquestionnaire={icquestionnaire} />}
          </div>
        ))}
      </Space>
    </>
  )
}

QuestionnaireFormContent.propTypes = {
  icnodeId: PropTypes.string.isRequired,
  icquestionnaire: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  antForm: PropTypes.object.isRequired
}

const QuestionnaireForm = ({ icquestionnaire, icnode, setFormErrors, setFormStatus, loading, retries, onChanged }) => {
  const [antForm] = Form.useForm()
  const form = icnode.rootForm || icnode.form
  const icquestionnaireId = icquestionnaire.id
  const icnodeId = icnode.icnodeId || icnode.icnodeChildId

  const [insertResponse] = useMutation(INSERT_RESPONSE)

  const onFieldChanged = async (changedField) => {
    try {
      const payload = JSON.stringify(changedField.value)

      const response = await insertResponse({
        variables: {
          icnodechildId: changedField.name,
          icquestionnaireId,
          payload
        }
      })
      onChanged(response.data.insertIcresponse)
    } catch (e) {
      console.error('insertResponse', e)
      notificationWarn('Saving error', e.message)
      throw new Error(e.message)
    }
  }

  const onFormErrors = (formErrors) => {
    setFormErrors(icnodeId, formErrors)
  }

  return (
    <Skeleton loading={loading} paragraph={{ rows: 20 }}>
      {!loading &&
        <ReactiveForm
          form={antForm}
          onFieldChanged={onFieldChanged}
          onFormErrors={onFormErrors}
          onStatusChanged={(status) => setFormStatus(icnodeId, status)}
          retries={retries}
          name={form.key}
          preserve
          initialValues={{}}
          wrapperCol={{ sm: 20, md: 14, lg: 16, xl: 12, xxl: 8 }}
          layout='vertical'
        >
          <QuestionnaireFormContent antForm={antForm} form={form} icnodeId={icnodeId} icquestionnaire={icquestionnaire} />
          <Paragraph style={{ marginTop: '12px' }}><sup style={{ color: 'red' }}>*</sup> required field.</Paragraph>
        </ReactiveForm>}
    </Skeleton>
  )
}

QuestionnaireForm.propTypes = {
  icquestionnaire: PropTypes.object.isRequired,
  icnode: PropTypes.object.isRequired,
  setFormStatus: PropTypes.func.isRequired,
  setFormErrors: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  refetchQuery: PropTypes.func,
  retries: PropTypes.number,
  onChanged: PropTypes.func
}

export default QuestionnaireForm
