import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { Result, Input, Row, Col, Form } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import FormWrapper from '../layout/FormWrapper'

import RouteErrorDisplay from '../components/RouteErrorDisplay'
import SimpleLink from '../components/SimpleLink'
import { networkOnlyAllErrorsVariables } from '../consts'

const MUTATION_CHECK_PASSWORD_TOKEN = gql`
  mutation checkPasswordResetToken ($passwordResetToken: String!) {
    checkPasswordResetToken (passwordResetToken: $passwordResetToken) {
      userId
      emailIsVerified
      hasPassword
      requiresOldPassword
    }
  }
`

const PasswordResetLandingPage = () => {
  const [checkPasswordResetToken, { data, loading, error }] = useMutation(MUTATION_CHECK_PASSWORD_TOKEN)

  const [requiresOldPassword, setRequiresOldPassword] = useState()

  const { passwordResetToken } = useParams()

  useEffect(() => {
    if (data?.checkPasswordResetToken?.userId) {
      setRequiresOldPassword(data.checkPasswordResetToken.requiresOldPassword)
    }
  }, [data])

  useEffect(() => {
    if (!passwordResetToken) return
    checkPasswordResetToken({
      variables: { passwordResetToken },
      ...networkOnlyAllErrorsVariables
    })
  }, [passwordResetToken])

  if (error) return <RouteErrorDisplay error={error} />

  if (!data || loading) return <>Checking token...</>

  return <SubmitNewPasswordWithPasswordResetToken passwordResetToken={passwordResetToken} requiresOldPassword={requiresOldPassword} />
}

export default PasswordResetLandingPage

const MUATION_SUBMIT_NEW_PASSWORD_WITH_PASSWORD_TOKEN = gql`
  mutation submitNewPasswordWithPasswordResetToken ($passwordResetToken: String!, $submitNewPasswordWithPasswordResetTokenInput: SubmitNewPasswordWithPasswordResetTokenInput!) {
    submitNewPasswordWithPasswordResetToken (passwordResetToken: $passwordResetToken, submitNewPasswordWithPasswordResetTokenInput: $submitNewPasswordWithPasswordResetTokenInput)
  }
`

const extra = (
  <Row justify='end'>
    <Col>
      <SimpleLink
        to='/signin'
        content='Sign In'
        block='true'
        type='secondary'
      />
    </Col>
  </Row>
)

const SubmitNewPasswordWithPasswordResetToken = ({ passwordResetToken, requiresOldPassword }) => {
  const [form] = Form.useForm()

  const [sent, setSent] = useState()

  const [submitNewPasswordWithPasswordResetToken, { data, loading, error }] = useMutation(MUATION_SUBMIT_NEW_PASSWORD_WITH_PASSWORD_TOKEN)

  useEffect(() => {
    if (!data?.submitNewPasswordWithPasswordResetToken) return
    setSent(true)
  }, [data])

  const subTitle = 'Please sign in with your new password'
  return (
    <>
      {
        sent &&
          <Result
            icon={<LockOutlined />}
            title='Password changed'
            subTitle={subTitle}
            extra={
              <SimpleLink
                to='/signin'
                content='Sign In'
                size='large'
                type='default'
              />
            }
          />
      }
      {
        !sent &&
          <FormWrapper
            title='Set a new password'
            size='large'
            form={form}
            onFinish={(values) => submitNewPasswordWithPasswordResetToken({
              variables: { passwordResetToken, submitNewPasswordWithPasswordResetTokenInput: { ...values } },
              ...networkOnlyAllErrorsVariables
            })}
            loading={loading}
            error={error}
            extra={extra}
          >

            {
              requiresOldPassword &&
                <Form.Item
                  name='oldPassword'
                  label='Old password'
                >
                  <Input.Password autoComplete='off' />
                </Form.Item>
            }

            <Form.Item
              name='password'
              label='Password'
            >
              <Input.Password autoComplete='off' />
            </Form.Item>

            <Form.Item
              name='passwordConfirm'
              label='Password Confirm'
            >
              <Input.Password autoComplete='off' />
            </Form.Item>

          </FormWrapper>
      }
    </>
  )
}
