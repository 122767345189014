import React from 'react'

import { ConfigProvider } from 'antd'

// antd locale
import enUS from 'antd/lib/locale-provider/en_US'

import i18next from 'i18next'

i18next.init({
  lng: (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage,
  debug: false,
  resources: {
    en: {
      translation: {
        intlNumber: '{{val, number}}'
      }
    }
  }
})

// MomentJs: define its locales (moment is used by ant design)
// import moment from 'moment'
// add more moment locales here:
// import "moment/locale/fr"

const AppTranslation = ({ children }) => {
  return (
    <ConfigProvider locale={enUS}>
      {children}
    </ConfigProvider>
  )
}

export default AppTranslation
