import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

const SimpleLink = ({ to, size, type = 'link', block, content, icon }) => {
  return (
    <Link to={to}>
      <Button size={size} type={type} block={block} style={{ paddingTop: 4, paddingBottom: 0 }} icon={icon} content={content}>
        {content}
      </Button>
    </Link>
  )
}

export default SimpleLink
