import React, { useState } from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, Radio, Space, Grid } from 'antd'

// components
import AvatarElement, { TitleElement, NoteElement } from '../AvatarElement'

const { useBreakpoint } = Grid

const QuestionRadio = ({ icnodeChildId, question }) => {
  const screens = useBreakpoint()
  const { response } = question

  const [value, setValue] = useState(response ? JSON.parse(response) : '')

  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} note={question.note} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={value}
        rules={[{ required: question.required, message: 'This field is required' }]}
        className={question.answerIsClickTouch ? 'radioImage' : ''}
      >
        <Radio.Group value={value}>
          <Space direction={question.answerIsClickTouch && screens.md ? 'horizontal' : 'vertical'}>
            {question?.answers && question.answers.map(answer => (
              <Radio key={answer.icanswerId} value={answer.icanswerId} onChange={e => setValue(e.target.value)}>
                <AvatarElement
                  title={answer.name}
                  note={!question.answerIsClickTouch ? answer.note : ''}
                  imageURL={answer.imageURL}
                  cloudinaryImage={answer.cloudinaryImage}
                  avatarSize='small'
                  titleStyle={question.answerIsClickTouch && screens.md ? { display: 'block' } : {}}
                />
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionRadio.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default QuestionRadio
