import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Skeleton, Alert, List, Typography, Space, Row, Col, Button, Tooltip, Input } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { INSERT_UPDATE_LOCATION_DEI } from '../../../../operations/mutations/locations'

const { Text, Title } = Typography
const { Search } = Input

const greenToUse = '#59B343'

const DeiProperty = ({ property, pkFieldName, dei }) => {
  const { id: locationId } = useParams()
  if (!locationId) return null

  const { display, values, reportable } = dei

  const field = display[property]
  const initialValue = values[property] ? values[property].toString() : '-'

  const [showLabel, setShowLabel] = useState(true)
  const [value, setValue] = useState(initialValue)

  const getFieldReportable = () => reportable[property] && !isNaN(value)
  const [fieldReportable, setFieldReportable] = useState(getFieldReportable())

  const [insertUpdateLocationDei, { data, loading, error }] = useMutation(INSERT_UPDATE_LOCATION_DEI)

  useEffect(() => {
    setShowLabel(true)
    if (!data?.insertUpdateLocationDei) return false
    setValue(data.insertUpdateLocationDei[property].toString())
    setFieldReportable(getFieldReportable())
  }, [data])

  const onSearch = input => {
    if (isNaN(input) || input === value) return setShowLabel(true)

    const insertUpdateLocationDeiInput = {
      locationId
    }
    insertUpdateLocationDeiInput[property] = Number(input)
    insertUpdateLocationDeiInput[pkFieldName] = dei[pkFieldName]

    insertUpdateLocationDei({
      variables: { insertUpdateLocationDeiInput },
      errorPolicy: 'all'
    })
  }

  const buttonStyle = { width: '100%', textAlign: 'left', paddingLeft: '6px', backgroundColor: '#fcfcfc', border: '1px solid white' }

  if (fieldReportable) buttonStyle.borderColor = greenToUse
  return (
    <Space direction='vertical' size='small' style={{ width: '100%', padding: 2 }}>

      <Title level={5} style={{ paddingBottom: 0 }}>{field}</Title>

      {
        showLabel &&
          <Tooltip
            title='Click to edit'
          >
            <Button
              type='text'
              size='large'
              style={buttonStyle}
              onClick={() => setShowLabel(!showLabel)}
            >
              {value} %
            </Button>
          </Tooltip>
      }

      {
        !showLabel &&
          <Search
            loading={loading}
            disabled={loading}
            enterButton={`${loading ? 'Saving' : 'OK'}`}
            size='large'
            onSearch={onSearch}
            defaultValue={!isNaN(value) ? value : ''}
          />
      }

      {
        error && <Text type='danger'>{error.message}</Text>
      }
    </Space>
  )
}

export const DeiReportable = ({ reportablesCSV }) => {
  let text = 'This is DEI reportable.'
  if (reportablesCSV) text = `Reportable: ${reportablesCSV}`

  return (
    <Space>
      <CheckCircleOutlined style={{ color: greenToUse }} />
      <Text strong>{text}</Text>
    </Space>
  )
}

const DeiListItem = ({ dei, pkFieldName }) => {
  const { name, reportable } = dei
  const reportableKeys = []

  const keys = Object.keys(reportable)

  keys.forEach(key => {
    if (['__typename', 'dei'].includes(key)) return
    if (reportable[key]) reportableKeys.push(key)
  })

  const { percent, percentEmployeed, percentWages } = reportable
  const reportables = []
  if (percent) reportables.push('Percent')
  if (percentEmployeed) reportables.push('Percent employeed')
  if (percentWages) reportables.push('Percent wages')

  const sectionSizes = { xs: 24, sm: 24, md: 12, lg: 12 }
  const propertySizes = { xs: 24, sm: 8, md: 8, lg: 8 }

  const sectionStyle = { padding: 0 }
  const propertyStyle = { padding: '0 12 0 12' }

  return (
    <List.Item>
      <Row style={{ width: '100%' }}>

        <Col {...sectionSizes} style={sectionStyle}>

          <Space direction='vertical' style={{ width: '100%' }} size='small'>

            <Text style={{ fontSize: 18 }}>{name}</Text>
            {
              reportable.dei && <DeiReportable reportablesCSV={reportables.join(', ')} />
            }
          </Space>

        </Col>

        <Col {...sectionSizes} style={sectionStyle}>

          <Row style={{ width: '100%' }}>
            <Col {...propertySizes} style={propertyStyle}>
              <DeiProperty property='percent' pkFieldName={pkFieldName} dei={dei} />
            </Col>
            <Col {...propertySizes} style={propertyStyle}>
              <DeiProperty property='percentEmployeed' pkFieldName={pkFieldName} dei={dei} />
            </Col>
            <Col {...propertySizes} style={propertyStyle}>
              <DeiProperty property='percentWages' pkFieldName={pkFieldName} dei={dei} />
            </Col>
          </Row>

        </Col>

      </Row>

    </List.Item>
  )
}

const DeiMapping = ({ data, map }) => {
  if (!data) return null
  const { locationFormDeis } = data
  const { key, arrayName, display } = map

  return (
    <>
      <Title level={3}>{display}</Title>
      <List
        style={{ width: '100%' }}
        dataSource={locationFormDeis[arrayName]}
        renderItem={dei => <DeiListItem dei={dei} pkFieldName={key} />}
      />
    </>
  )
}

const LocationDEIValuesForm = ({ deiData }) => {
  const { data, loading, error } = deiData

  const [deiMappings, setDeiMappings] = useState([])
  const [errorToDisplay, setErrorToDisplay] = useState()

  useEffect(() => {
    if (!data?.locationFormDeis) return
    const { deiMappings: values } = data.locationFormDeis
    setDeiMappings(values)
  }, [data])

  useEffect(() => {
    let errorToSet

    if (error) {
      if (error[0]) errorToSet = error[0]
      if (error.graphQLErrors) errorToSet = error.graphQLErrors[0].message
    }
    setErrorToDisplay(errorToSet)
  }, [error])

  if (error) return <Alert message={errorToDisplay} type='error'>{}</Alert>
  if (loading) <Skeleton />

  return (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>
      <Alert message='Click on any percent (%) to change their values.' type='info' />
      {
        deiMappings.map(map => <DeiMapping key={map.key} data={data} map={map} />)
      }
    </Space>
  )
}

export default LocationDEIValuesForm
