import { gql } from '@apollo/client'
import { CATEGORY_FIELDS } from '../../queries/ic/categories'

export const INSERT_CATEGORY = gql`
mutation insertIccategory(
  $name: String!,
  $note: String,
  $imageURL: String,
  $accountId: ID!,
  $hidden: Boolean,
  $published: Boolean
) {
  insertIccategory(iccategoryInput: {
    name: $name,
    note: $note,
    imageURL: $imageURL,
    accountId: $accountId,
    hidden: $hidden,
    published: $published
  }) {
    ${CATEGORY_FIELDS}
  }
}
`

export const UPDATE_CATEGORY = gql`
mutation updateIccategory($id: ID!, $iccategoryInput: IccategoryInput!) {
  updateIccategory(id: $id, iccategoryInput: $iccategoryInput) {
    ${CATEGORY_FIELDS}
  }
}
`
