import React, { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { networkOnlyAllErrorsVariables } from '../consts'

import { memoryUser } from '../auth/appMemory' //, refreshUser, memoryAccessToken, memoryUser memoryAccessToken, memoryUser

import RouteErrorDisplay from './RouteErrorDisplay'

import { Table, Row, Typography, Space } from 'antd'

const CommonTable = ({ render, refetchQuery, rowKey, dataKey, query, columns, criterias: filter = { }, defaultSort, defaultSortOrder, variables: filterAndSort = { }, expandable: imcomingExpandable, extra }) => {
  const { currentAccountId } = useReactiveVar(memoryUser)

  if (filter.accountId) {
    filter.accountId = currentAccountId
  }

  const { data, loading, error } = useQuery(query, {
    variables: { filter },
    ...networkOnlyAllErrorsVariables
  })

  const [tableData, setTableData] = useState()

  useEffect(() => {
    if (loading) return

    if (!data || !data[dataKey]) return

    const dataSource = data[dataKey]

    const title = () => (
      <Row justify='end'>
        <Space>
          <Typography.Text type='secondary'>{dataSource.length} record{dataSource.length !== 1 ? 's' : ''}</Typography.Text>
          {extra}
        </Space>
      </Row>
    )

    setTableData({ expandable: imcomingExpandable, columns, dataSource, title })
  }, [data, loading])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <Table
      bordered
      size='small'
      scroll={{ x: 700 }}
      loading={loading}
      {...tableData}
      pagination={{
        showSizeChanger: true,
        position: ['topCenter', 'bottomCenter']
      }}
    />

  )
}

export default CommonTable
