
import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { memoryUser } from '../../../../../core/auth/appMemory'

import ItemCardMeta from '../../../../../core/components/ItemCardMeta'

// libraries
import SortableTree from 'react-sortable-tree'
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer'

// Contexts
import Loading from '../../../../../components/Loading'

// operations
import { QUERY_CATEGORIES } from '../../../../../operations/queries/ic/categories'
import { QUERY_QUESTIONS } from '../../../../../operations/queries/ic/questions'

import { PublishedIcon, EditNodechildIcon, InputOrSelectIcon } from '../../../../../components/elements/icons'

const TemplateDragCategoriesQuestions = ({ ictemplateId, accountId, type, filter }) => {
  const variables = { filter: { ...filter, accountId: memoryUser().currentAccountId }, pageSize: parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) + 100 }

  const [treeData, setTreeData] = useState([])

  let query
  if (type === 'iccategorys') query = QUERY_CATEGORIES
  if (type === 'icquestions') query = QUERY_QUESTIONS

  const { loading, error, data } = useQuery(query, { variables })
  if (error) console.error(error)

  useEffect(() => {
    if (data) createTreeData(data)
  }, [data])

  const createTreeData = async data => {
    const { iccategorys, icquestions } = data
    const array = iccategorys || icquestions
    const tree = []
    array.forEach(item => {
      const title = item.name
      const iccategory = iccategorys && item
      const icquestion = icquestions && item
      tree.push({
        title,
        iccategory,
        icquestion,
        draggedIn: true,
        canNodeHaveChildren: false,
        published: item.published
      })
    })
    setTreeData(tree)
  }

  if (loading) return <Loading />
  if (treeData.length === 0) return <div>No data</div>

  const generateNodeProps = rowInfo => {
    const { node } = rowInfo

    const icons = []
    const buttons = []

    icons.push(
      <InputOrSelectIcon
        key={`InputOrSelectIcon${node.id}`}
        icquestion={node.icquestion}
        size='12px'
      />
    )
    icons.push(
      <PublishedIcon
        key={`PublishedIcon${node.id}`}
        published={node.published}
      />
    )

    buttons.push(
      <EditNodechildIcon
        key={`EditNodechildIcon${node.id}`}
        ictemplateId={ictemplateId}
        iccategory={node.iccategory}
        icquestion={node.icquestion}
      />
    )

    const item = node.iccategory || node.icquestion

    const title = <ItemCardMeta item={item} view='templatebuilder' />

    return { icons, buttons, node: { ...node, title } }
  }

  return (
    <div style={{ height: 600, marginLeft: '-20px' }}>
      <SortableTree
        dndType='yourNodeType'
        theme={FileExplorerTheme}
        onChange={() => createTreeData(data)}
        treeData={treeData}
        canDrop={() => false}
        generateNodeProps={rowInfo => generateNodeProps(rowInfo)}
      />
    </div>
  )
}

TemplateDragCategoriesQuestions.propTypes = {
  ictemplateId: PropTypes.string,
  accountId: PropTypes.string,
  type: PropTypes.string,
  filter: PropTypes.object
}

export default TemplateDragCategoriesQuestions
