// react
import React from 'react'
import { ApolloProvider } from '@apollo/client'

import { returnClient } from './core/auth/apolloClient'

import RollbarProvider from './core/layout/RollbarProvider'

import AppTranslation from './core/auth/AppTranslation'
import AppRouter from './core/auth/AppRouter'

import TokenChecker from './core/auth/TokenChecker'
import TriggerWrapper from './core/auth/TriggerWrapper'

// styles
import './assets/styles/index.less'
import 'react-sortable-tree/style.css'

window.process = {}

function App () {
  return (
    <RollbarProvider>
      <ApolloProvider client={returnClient()}>
        <AppTranslation>
          <TriggerWrapper>
            <TokenChecker>
              <AppRouter />
            </TokenChecker>
          </TriggerWrapper>
        </AppTranslation>
      </ApolloProvider>
    </RollbarProvider>
  )
}

export default App
