import { notificationError } from './notification'
import { ApplicationError } from './applicationError'

const FLOOD_MS = 1000
let lastErrorTs
let lastMessage

function ErrorWithNotification (message, metadata) {
  if (!lastErrorTs || (Date.now() - lastErrorTs > FLOOD_MS) || lastMessage !== message) {
    lastErrorTs = Date.now()
    lastMessage = message
    const error = new ApplicationError(message, metadata)
    notificationError(metadata.title || 'Error', metadata.message || message)
    return error
  }
}

ErrorWithNotification.prototype = Object.create(Error.prototype)

export { ErrorWithNotification }
