import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, Input } from 'antd'

// components
import { TitleElement, NoteElement } from '../AvatarElement'

const QuestionString = ({ icnodeChildId, question }) => {
  const { response } = question
  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={response && JSON.parse(response)}
        rules={[{ required: question.required, message: 'This field is required' }]}
      >
        <Input />
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionString.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default QuestionString
