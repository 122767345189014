import React from 'react'

import Page from '../../../components/Page'
import AccountGroupList from '../../../components/sections/admin/accountGroup/AccountGroupList'
import PageInstructions from '../../../documentation/PageInstructions'

const AccountGroups = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'Account groups'
    }
  ]
  return (
    <>
      <Page title='Account groups' breadcrumb={breadcrumb}>
        <PageInstructions />
        <AccountGroupList />
      </Page>
    </>
  )
}

export default AccountGroups
