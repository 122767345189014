import React from 'react'

import { Link } from 'react-router-dom'

import { refreshUser } from '../auth/appMemory'

import {
  LOGO_DOT_RING_COLOR_TITLE_BLACK,
  LOGO_DOT_RING_WHITE,
  imageStyle
} from '../consts'

const { REACT_APP_TITLE } = process.env

const Logo = ({ color, style = { } }) => {
  const src = color ? LOGO_DOT_RING_COLOR_TITLE_BLACK : LOGO_DOT_RING_WHITE

  return (
    <Link to='/' onClick={() => refreshUser('Logo clicked')}>
      <img src={src} alt={REACT_APP_TITLE} style={{ ...imageStyle, ...style }} />
    </Link>
  )
}

export default Logo
