import React from 'react'
// const breadcrumb = [
//   {
//     path: '/',
//     breadcrumbName: 'Home'
//   },
//   {
//     path: '',
//     breadcrumbName: 'Account Seelction'
//   }
// ]

const AccountSelection = () => <>AccountSelection</>

export default AccountSelection
