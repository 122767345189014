
import { notification as antNotification } from 'antd'

const FLOOD_MS = 2000
let lastWarnTs
let lastWarnMessage

const notificationSuccess = (message, description, placement) => {
  antNotification.success({ message, description, placement: placement || 'bottomRight' })
}

const notificationError = (message, description, placement) => {
  antNotification.error({ message, description, placement: placement || 'bottomRight' })
}

const notificationInfo = (message, description, placement) => {
  antNotification.info({ message, description, placement: placement || 'bottomRight' })
}

const notificationWarning = (message, description, placement) => {
  antNotification.warning({ message, description, placement: placement || 'bottomRight' })
}

const notificationWarn = (message, description, placement) => {
  if (!lastWarnTs || (Date.now() - lastWarnTs > FLOOD_MS) || lastWarnMessage !== message) {
    lastWarnTs = Date.now()
    lastWarnMessage = message
    antNotification.warn({ message, description, placement: placement || 'bottomRight' })
  }
}

export default notificationSuccess

export { notificationSuccess, notificationError, notificationInfo, notificationWarning, notificationWarn }
