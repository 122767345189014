import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation

} from 'react-router-dom'

import { useReactiveVar } from '@apollo/client'

import { returnLocaleStorage } from './appStorage'
import { sendGoogleAnalyticsPageview } from './googleAnalytics'

import { memoryUser } from './appMemory' //, refreshUser, memoryAccessToken

import { routesArray } from './routesData'

import AppLayout from '../layout/AppLayout'
import NotFound from '../routes/NotFound'

const ACCOUNTS_ADMIN_HOME = '/' //
const LOCATIONS_ADMIN_HOME = '/' // '/admin/ic/locations'
const LOCATIONS_GENERAL_HOME = '/' // ic/questionnaires
const NO_ROLES_HOME = '/'

const debug = false

const AppRouter = () => {
  return (
    <Router>
      <AppLayout>
        <DynamicRouting />
      </AppLayout>
    </Router>
  )
}

export default AppRouter

const DynamicRouting = () => {
  const user = useReactiveVar(memoryUser)

  const location = useLocation()
  const { pathname } = location

  const userRoutableData = returnUserRoutableData(user, pathname)
  const { userRoutes, notUserRoutes } = userRoutableData

  const routes = user ? userRoutes : notUserRoutes

  debug && console.log('user', !!user)
  debug && console.log('routes', routes)

  return (
    <Switch>
      {
        routes.map((route, index) => {
          sendGoogleAnalyticsPageview({ location, route, user })
          return (
            <Route
              key={route.key}
              path={route.path}
              exact={!!route.exact}
              component={route.component}
              label={route.label}
            />
          )
        })
      }

      <CatchAll user={user} />
    </Switch>
  )
  // const validRoutes = []

  // routes.forEach((route, index) => validRoutes.push(<Route key={`routeKey${returnRouteKeyId(route)}`} path={route.path} component={route.component} label={route.label} />))

  // validRoutes.push(<CatchAll key='catchall' user={user} />)

  // debug && console.log('validRoutes', validRoutes)
  // return validRoutes
}

const CatchAll = ({ user, children, ...rest }) => {
  const localAccessToken = returnLocaleStorage('accessToken')
  return (
    <Route
      path='*'
      {...rest}
      render={(args) => {
        debug && console.log('args', args)

        const { location } = args
        const { pathname } = location

        debug && console.log('location', location)
        debug && console.log('localAccessToken', Boolean(localAccessToken))
        debug && console.log('user', Boolean(user))

        if (localAccessToken && !user) {
          debug && console.log('user is authenticating')
          return null
        }

        const authenticating = ['/signin', '/signin'].includes(pathname) && user && localAccessToken

        if (authenticating) {
          debug && console.log('user signed in')
          const { permissions } = user
          const { ACCOUNTS_ADMIN, LOCATIONS_ADMIN, LOCATIONS_GENERAL, NO_ROLES } = permissions

          if (ACCOUNTS_ADMIN) return <Redirect key='redirect-ACCOUNTS_ADMIN_HOME' to={ACCOUNTS_ADMIN_HOME} />
          if (LOCATIONS_ADMIN) return <Redirect key='redirect-LOCATIONS_ADMIN_HOME' to={LOCATIONS_ADMIN_HOME} />
          if (LOCATIONS_GENERAL) return <Redirect key='redirect-LOCATIONS_GENERAL_HOME' to={LOCATIONS_GENERAL_HOME} />
          if (NO_ROLES) return <Redirect key='redirect-NO_ROLES_HOME' to={NO_ROLES_HOME} />
        }

        if (pathname === '/signout' && !user && !localAccessToken) {
          debug && console.log('user signed out')
          return <Redirect key='redirect-ROOT' to='/' />
        }

        if (localAccessToken && user) {
          debug && console.log('user is authenticed')
          // return null
        }

        const notauthenticated = !user && !localAccessToken
        if (notauthenticated) {
          debug && console.log('user signed out, no match')
          // return <Redirect key='redirect-ROOT' to='/' />
        }

        return <NotFound />
      }}
    />
  )

  // if (user) {
  //   const { permissions } = user
  //   const { ACCOUNTS_ADMIN, LOCATIONS_ADMIN, LOCATIONS_GENERAL, NO_ROLES } = permissions
  //
  //   if (ACCOUNTS_ADMIN) return <Redirect key='redirect-ACCOUNTS_ADMIN_HOME' to={ACCOUNTS_ADMIN_HOME} />
  //   if (LOCATIONS_ADMIN) return <Redirect key='redirect-LOCATIONS_ADMIN_HOME' to={LOCATIONS_ADMIN_HOME} />
  //   if (LOCATIONS_GENERAL) return <Redirect key='redirect-LOCATIONS_GENERAL_HOME' to={LOCATIONS_GENERAL_HOME} />
  //   if (NO_ROLES) return <Redirect key='redirect-NO_ROLES_HOME' to={NO_ROLES_HOME} />
  // } else {
  //
  // }
}

export const returnUserRoutableData = user => {
  const appRoutes = routesArray()

  const anonymousRoutes = appRoutes.filter(i => !i.requiresAuthenticated && !i.requiresNotAuthenticated)

  const authenticatedRoutes = appRoutes.filter(i => user && i.requiresAuthenticated)

  const notAuthenticatedRoutes = appRoutes.filter(i => !user && i.requiresNotAuthenticated)

  // const userRoutes = [...anonymousRoutes, ...authenticatedRoutes]
  // const notUserRoutes = [...anonymousRoutes, ...notAuthenticatedRoutes]
  const userRoutes = [...anonymousRoutes, ...authenticatedRoutes]
  const notUserRoutes = [...anonymousRoutes, ...notAuthenticatedRoutes]

  // left overs (known routes, not 404)
  // THIS HAS TO GO, it does not match routes with :id params
  // const unauthorizedRoutes = appRoutes.filter(i => !userRoutes.includes(i))

  // console.log('unauthorizedRoutes', unauthorizedRoutes)

  const userRoutableData = { userRoutes, notUserRoutes }

  return userRoutableData
}
