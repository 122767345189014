import { makeVar } from '@apollo/client'

// source of truth. if it doesnt exist on refresh, check secure local storage and set if exists.

// if exists, user is authenticated
export const memoryUser = makeVar()

export const refreshUser = makeVar()

export const memoryAccessToken = makeVar()

export const reactiveIcquestionnairesPublished = makeVar()

export const memoryActiveTab = makeVar()
