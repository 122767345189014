// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// ant design
import { Table, ExportTableButton } from 'ant-table-extensions'

// icons
import { FileExcelOutlined } from '@ant-design/icons'

const ReportDataListing = ({ columns, fields, data, btnProps, pagination, hideFooter, fileName }) => {
  const [tableFields, setTableFields] = useState()
  const [tableColumns, setTableColumns] = useState()

  const ExportCsvButton = () => {
    return (
      <ExportTableButton
        dataSource={data}
        columns={tableColumns}
        fields={tableFields}
        btnProps={btnProps || { type: 'primary', icon: <FileExcelOutlined /> }}
        showColumnPicker
        exportableProps={{ fileName }}
      >
        Export Data to CSV
      </ExportTableButton>
    )
  }

  useEffect(() => {
    if (data) {
      const defaultColumns = [
        {
          title: 'Data label',
          dataIndex: 'dataLabel'
        },
        {
          title: 'Series label',
          dataIndex: 'seriesLabel'
        },
        {
          title: 'Data value',
          dataIndex: 'dataValue'
        }
      ]
      if (data && data[0]?.suggestion) {
        defaultColumns.push({ title: 'Suggestion', dataIndex: 'suggestion' })
      }
      setTableColumns(columns || defaultColumns)

      const defaultFields = {
        dataLabel: {
          header: 'Data label',
          formatter: fieldValue => fieldValue
        },
        seriesLabel: {
          header: 'Series label',
          formatter: fieldValue => fieldValue
        },
        dataValue: {
          header: 'Data value',
          formatter: fieldValue => fieldValue
        }
      }
      if (data && data[0]?.suggestion) {
        defaultFields.suggestion = { header: 'Suggestion', formatter: fieldValue => fieldValue }
      }
      setTableFields(fields || defaultFields)
    }
  }, [data, columns, fields])

  return (
    <>
      {data && tableFields && tableColumns &&
        <>
          <Table
            bordered
            pagination={pagination || false}
            columns={tableColumns}
            dataSource={data}
            loading={!data}
            footer={hideFooter ? null : () => <div style={{ textAlign: 'right' }}><ExportCsvButton fileName={fileName} /></div>}
          />

        </>}
    </>
  )
}

ReportDataListing.propTypes = {
  columns: PropTypes.array,
  fields: PropTypes.array,
  btnProps: PropTypes.object,
  pagination: PropTypes.object,
  data: PropTypes.array.isRequired,
  hideFooter: PropTypes.bool
}

export default ReportDataListing
