import { gql } from '@apollo/client'
import { LOCATION_FIELDS } from '../queries/locations'

export const INSERT_UPDATE_LOCATION_DEI = gql`
  mutation insertUpdateLocationDei
    ( $insertUpdateLocationDeiInput: InsertUpdateLocationDeiInput! )
    {
      insertUpdateLocationDei ( insertUpdateLocationDeiInput: $insertUpdateLocationDeiInput )
      {
        id
        percent
        percentEmployeed
        percentWages
      }
    }
`

export const INSERT_LOCATION = gql`
  mutation insertLocation(
    $accountId: ID
    $address1: String
    $address2: String

    $cscCityId: Int
    $cscStateId: Int
    $cscCountryId: Int

    $hidden: Boolean
    $name: String
    $postalzip: String
    $note: String
    $imageURL: String
    $published: Boolean
  ) {
    insertLocation(locationInput: {
      accountId: $accountId
      address1: $address1
      address2: $address2
      cscCityId: $cscCityId
      cscStateId: $cscStateId
      cscCountryId: $cscCountryId
      hidden: $hidden
      name: $name
      postalzip: $postalzip
      note: $note
      imageURL: $imageURL
      published: $published
    }) {
      ${LOCATION_FIELDS}
    }
  }
`

export const UPDATE_LOCATION = gql`
mutation updateLocation($id: ID!, $locationInput: LocationInput!) {
  updateLocation(id: $id, locationInput: $locationInput) {
    ${LOCATION_FIELDS}
  }
}
`
