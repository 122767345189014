import React from 'react'

import { Form, Input, Row, Col, Radio, Select, Alert } from 'antd'

import { Link } from 'react-router-dom'
import ItemCardMeta from '../../components/ItemCardMeta'

const { Option } = Select

const InvitationFormItems = ({ account }) => {
  const { locations, accountgroups } = account

  return (
    <>
      <Row wrap justify='space-between'>
        <Col flex={1} style={{ padding: 10 }}>
          <Form.Item
            name='email'
            label='Email'
          >
            <Input autoComplete='off' />
          </Form.Item>

          <Form.Item
            name='firstName'
            label='First name'
          >
            <Input autoComplete='off' />
          </Form.Item>

          <Form.Item
            name='lastName'
            label='Last name'
          >
            <Input autoComplete='off' />
          </Form.Item>

        </Col>
        <Col flex={1} style={{ padding: 10 }}>

          <Form.Item
            name='locationIds'
            label='Locations'
            extra={locations.length === 0 && <Link to='/admin/location/add'>Add your first location</Link>}
          >
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              placeholder='Select at least one location'
              optionLabelProp='label'
            >
              {
                locations.map(i => {
                  return (
                    <Option key={`insertinvitelocation_${i.id}`} value={i.id} label={<ItemCardMeta view='small' size='small' item={i} />}>
                      <ItemCardMeta view='small' size='small' item={i} />
                    </Option>
                  )
                })
              }
            </Select>
          </Form.Item>

          <Form.Item
            name='accountgroupIds'
            label='Groups'
            extra={accountgroups.length === 0 && <Link to='/admin/accountgroup/add'>Add your first group</Link>}
          >
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              placeholder='Select at least one group'
              optionLabelProp='label'
            >
              {
                accountgroups.map(i => {
                  return (
                    <Option key={`insertinviteaccountgroup_${i.id}`} value={i.id} label={<ItemCardMeta view='small' size='small' item={i} />}>
                      <ItemCardMeta view='small' size='small' item={i} />
                    </Option>
                  )
                })
              }
            </Select>
          </Form.Item>

        </Col>
      </Row>

      <Form.Item
        label='User level'
        name='userLevel'
        initialValue='contributor'
      >
        <Radio.Group>
          <Radio value='contributor'>Contributor</Radio>
          <Radio value='accountAdmin'>Account Administrator</Radio>
          <Radio value='locationAdmin'>Location Administrator</Radio>
        </Radio.Group>
      </Form.Item>

      <Alert style={{ textAlign: 'center' }} type='info' message='NOTE: After submitting your edits, your invitee will receive an emailed invitation link.' />
    </>
  )
}

export default InvitationFormItems
