import React from 'react'

import Page from '../../../components/Page'
import LocationList from '../../../components/sections/admin/location/LocationList'
import PageInstructions from '../../../documentation/PageInstructions'

const Locations = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'Locations'
    }
  ]
  return (
    <>
      <Page title='Locations' breadcrumb={breadcrumb}>
        <PageInstructions />
        <LocationList />
      </Page>
    </>
  )
}

export default Locations
