import React from 'react'

import { Skeleton, List } from 'antd'

import ItemCardMeta from '../components/ItemCardMeta'

const ItemHeading = ({ loading, item, refetch }) => {
  if (loading) return <Skeleton active avatar paragraph />
  if (!item) return null
  return (
    <List itemLayout='horizontal'>
      <List.Item>
        <ItemCardMeta item={item} size={128} view='upload' refetch={refetch} />
      </List.Item>
    </List>
  )
}

export default ItemHeading
