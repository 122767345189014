import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader } from 'antd'

const Page = (props) => {
  const { children } = props

  const itemRender = (route, params, routes, paths, extra) => {
    const last = routes.indexOf(route) === routes.length - 1
    return last
      ? (
        <span>{route.breadcrumbName}</span>
        )
      : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
        )
  }

  return (
    <>
      {(props.title !== undefined || props.breadcrumb !== undefined) &&
        <PageHeader
          style={{ marginBottom: 24, paddingBottom: 0 }}
          className='page-header'
          title={props.title}
          subTitle={props.subtitle}
          ghost={false}
          breadcrumb={{ itemRender, routes: props.breadcrumb }}
          extra={props.extra}
        />}
      {children}
    </>
  )
}

export default Page
