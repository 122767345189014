import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'

import moment from 'moment'
import friendlyUrl from 'friendly-url'

import { Card, Form, Button, Space, Row, message } from 'antd'

import ReportsDatePicker from './ReportsDatePicker'
import ReportDisplay from './ReportDisplay'
import ReportStats from './ReportStats'
import ReportSets from './ReportSets'
import ReportModels from './ReportModels'
import LandscapePortrait from './LandscapePortrait'

import { toCanvas } from 'html-to-image'
import JsPDF from 'jspdf'

import logo from '../../../../../assets/images/logo.png'

const QUERY_REPORTING_ACCOUNT_CLIENT_REPORT_DATA = gql`
  query reportingAccountClientReportData ($reportingAccountClientReportDataInput: ReportingAccountClientReportDataInput!) {
    reportingAccountClientReportData (reportingAccountClientReportDataInput: $reportingAccountClientReportDataInput) {
    querystring
    clientNavigationalReports {
      name
      recordcount
      questionTypes {
        isBarchart
        name
        description
        recordcount
        groupBys {
          model
          display
          models {
            id
            order
            name
            note
            imageURL
            hidden
            published
            spawnLimit
            completionTimeUnit
            completionTimeLimit
            isPublic
            anonymous
            country
            state
            city
            countryEmoji
            stats {
              min
              max
              count
              percent
              sum
              submittedAt
              submittedAtAgo
              first
              last
              firstAgo
              lastAgo
              average
            }
            barCharts {
              suggestion
              name
              chartType
              reportFilename
              questionName
              config {
                isGroup
                hasOwnColor
                xField
                yField
                seriesField
                data {
                  seriesLabel
                  seriesColor
                  dataLabel
                  dataValue
                  color
                  suggestion
                }
              }
            }
          }
        }
      }
    }
    clientChartsPostDemo {
      questionnaireCharts {
          barCharts {
            name
            chartType
            reportFilename
            chartDescription
            questionName
            config {
              isGroup
              hasOwnColor
              xField
              yField
              seriesField
              data {
                seriesLabel
                seriesColor
                dataLabel
                dataValue
                color
                suggestion
              }
            }
          }

      }
    }
  }
}
`

// const QUERY_CHECK_SAVED_REPORT = gql`
//   query reportingAccountClientReportData ($reportingAccountClientReportDataInput: ReportingAccountClientReportDataInput!) {
//     reportingAccountClientReportData (reportingAccountClientReportDataInput: $reportingAccountClientReportDataInput) {
//       querystring
//     }
//   }
// `

const ReportForm = ({ accountId, reportingAccountClientFormData }) => {
  if (!reportingAccountClientFormData) return null

  const reportsRef = useRef(null)

  const {
    clientFormData,
    clientNavigationalReports: clientNavigationalReportsInit,
    clientChartsPostDemo: clientChartsPostDemoInit
  } = reportingAccountClientFormData
  if (!clientFormData) return null

  const history = useHistory()
  const dateFormat = 'YYYY/MM/DD'
  const [form] = Form.useForm()
  const [returnReportData, { data, loading, error }] = useLazyQuery(QUERY_REPORTING_ACCOUNT_CLIENT_REPORT_DATA)
  // const [checkSavedReport] = useLazyQuery(QUERY_CHECK_SAVED_REPORT)
  const [reportData, setReportData] = useState()
  const [querystring, setQuerystring] = useState()
  const [clientNavigationalReports, setClientNavigationalReports] = useState(clientNavigationalReportsInit)
  const [clientChartsPostDemo, setClientChartsPostDemo] = useState(clientChartsPostDemoInit)
  const [tableOfContent, setTableOfContent] = useState([])

  const key = 'downloadingAll'
  const options = {
    key
  }

  useEffect(() => {
    if (!clientChartsPostDemo) return
    const { questionnaireCharts } = clientChartsPostDemo
    const { barCharts } = questionnaireCharts

    const contents = []

    barCharts?.forEach(barChart => {
      const { name, questionName, chartType, chartDescription } = barChart

      contents.push({
        Type: chartType,
        Name: name,
        Question: questionName,
        Description: chartDescription
      })
    })

    return setTableOfContent(contents)
  }, [clientChartsPostDemo])

  useEffect(() => {
    if (!data) return
    setReportData(data.reportingAccountClientReportData)
  }, [data])

  useEffect(() => {
    let uri = `${window.location.pathname}`

    if (querystring && querystring.length > 0) {
      uri += `?${querystring}`
    }

    history.replace(uri)
  }, [querystring])

  useEffect(() => {
    if (!reportData) return false
    const { querystring, clientNavigationalReports, clientChartsPostDemo } = reportData

    setQuerystring(querystring)
    setClientNavigationalReports(clientNavigationalReports)
    setClientChartsPostDemo(clientChartsPostDemo)
  }, [reportData])

  const [orientation, setOrientation] = useState()

  const requestFilteredData = async () => {
    const formValues = await form.getFieldsValue()

    const { rangePicker } = clientFormData
    const minDate = rangePicker && moment(rangePicker[0]).format(dateFormat)
    const maxDate = rangePicker && moment(rangePicker[1]).format(dateFormat)

    const {
      savename,
      country = [],
      state = [],
      city = [],
      surveyed = [],
      spawnLimit = [],
      isPublic = [],
      anonymous = [],
      required = [],
      icquestiontype = [],
      inputtype = [],
      numbertype = [],
      decimalplaces = [],
      randomizeselectableanswers = [],
      canselectmanyanswers = [],
      answerIsClickTouch = [],
      isDominantGroup = [],
      isComparativeDataQualified = [],
      icquestionnaireId = [],
      icquestionnairespawnerId = [],
      locationId = [],
      accountgroupId = [],
      ictemplateId = [],
      iccategoryId = [],
      icquestionId = [],
      icanswerId = [],
      userId = []
    } = formValues

    const query = {
      savename,
      minDate,
      maxDate,
      country,
      state,
      city,
      surveyed,
      spawnLimit,
      isPublic,
      anonymous,
      required,
      icquestiontype,
      inputtype,
      numbertype,
      decimalplaces,
      randomizeselectableanswers,
      canselectmanyanswers,
      answerIsClickTouch,
      isDominantGroup,
      isComparativeDataQualified,

      icquestionnaireId,
      icquestionnairespawnerId,
      locationId,
      accountgroupId,
      ictemplateId,
      iccategoryId,
      icquestionId,
      icanswerId,
      userId
    }

    const variables = { reportingAccountClientReportDataInput: { ...query, accountId } }

    returnReportData({
      variables,
      errorPolicy: 'all'
    })
  }

  const { minMaxDates, sets, models, stats } = clientFormData

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  }

  const buttonItemLayout = {
    wrapperCol: { span: 18, offset: 6 }
  }

  const format = 'PNG'
  const compression = 'NONE'
  const fillColor = '#fff'
  const alias = 'pdfExportEverything'
  const padding = 3

  const tableOfContentHeader = [
    { name: 'Type', width: 50 },
    { name: 'Name', width: 100 },
    { name: 'Question', width: 100 },
    { name: 'Description', width: 100 }
  ]

  const exportAllToPdf = async orientation => {
    const now = moment().format('LLL')
    const reportFilename = friendlyUrl(`charts and data export ${now}`)
    message.loading({ content: 'Preparing export...', ...options, duration: 3 })

    const pdf = new JsPDF({ orientation: orientation.key })

    const { pageSize } = pdf.internal
    const pageSizeWidth = pageSize.getWidth()
    const pageSizeHeight = pageSize.getHeight()
    const canvas = await toCanvas(reportsRef.current, { cacheBust: true })
    const imgData = canvas.toDataURL('image/png')

    const imgWidth = pageSizeWidth - padding
    const pageHeight = pageSizeHeight
    const imgHeight = canvas.height * imgWidth / canvas.width

    let pageCount = 1
    let position = 0
    let heightLeft = imgHeight

    // start putting pdf here

    const logoProperties = pdf.getImageProperties(logo)
    const logoWidth = 80
    const logoHeight = logoWidth / (logoProperties.width / logoProperties.height)
    const logoX = (pdf.internal.pageSize.getWidth() - logoWidth) / 2
    const logoY = (pdf.internal.pageSize.getHeight() - logoHeight) / 3
    const textX = pdf.internal.pageSize.getWidth() / 2
    const textY = pdf.internal.pageSize.getHeight() - (pdf.internal.pageSize.getHeight() / 3)
    pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)
    pdf.setFontSize(22)
    pdf.text('Impact report', textX, textY, null, null, 'center')

    pdf.setFontSize(12)
    pdf.text(now, textX, textY + 10, null, null, 'center')

    pdf.addPage()

    pdf.setFontSize(12)
    pdf.table(10, 10, tableOfContent, tableOfContentHeader, { autoSize: false, padding: 5, headerBackgroundColor: '#313131', headerTextColor: '#ffffff' })

    pdf.text(`Page ${pageCount}`, pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.getHeight() - 11, null, null, 'right')

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight
      pdf.addPage()
      pdf.setFillColor(fillColor)
      pdf.addImage(imgData, format, padding, position, imgWidth, imgHeight, alias, compression)
      heightLeft -= pageHeight
      pageCount += 1
      pdf.text(`Page ${pageCount}`, pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.getHeight() - 11, null, null, 'right')
    }
    pdf.save(reportFilename)

    message.success({ content: 'Download started...', ...options, duration: 1 })
  }

  return (
    <Space size='large' direction='vertical' style={{ width: '100%' }}>
      <Card
        title={<ReportStats stats={stats} minMaxDates={minMaxDates} />}
      >
        <Form
          {...formItemLayout}
          form={form}
          layout='horizontal'
        >

          <ReportsDatePicker form={form} dateFormat={dateFormat} minMaxDates={minMaxDates} />

          <ReportSets form={form} sets={sets} />

          <ReportModels form={form} sets={models} />

          <Form.Item {...buttonItemLayout} />

          {
            error &&
              <>Sorry, something went wrong. Try refresh</>
          }

        </Form>

        <Button size='large' type='primary' loading={loading} onClick={() => requestFilteredData()}>
          {loading ? 'Fetching...' : 'Update'}
        </Button>

      </Card>

      <Row justify='end' wrap>
        <LandscapePortrait orientation={orientation} setOrientation={setOrientation} loading={loading} title='Download Everything' exportAllToPdf={exportAllToPdf} />
      </Row>

      <div id='report' ref={reportsRef}>
        <ReportDisplay clientNavigationalReports={clientNavigationalReports} clientChartsPostDemo={clientChartsPostDemo} loading={loading} orientation={orientation} />
      </div>

    </Space>
  )
}

export default ReportForm
