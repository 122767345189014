import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { memoryUser, refreshUser, memoryActiveTab } from '../auth/appMemory'

import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'

import ContentWrapper from '../layout/ContentWrapper'

import AccountUserManagement from '../models/AccountUserManagement'
import AccountSubscriptions from '../models/AccountSubscriptions'
// import AccountActivity from '../models/AccountActivity'

import InvitationHistory from '../models/Invitations/InvitationHistory'
import InvitationAdd from '../models/Invitations/InvitationAdd'
import AccountEdit from '../models/AccountEdit'
import RouteErrorDisplay from '../components/RouteErrorDisplay'
import ItemHeading from '../components/ItemHeading'
import ItemCardMeta from '../components/ItemCardMeta'
import AccountOwnerTransfer from '../models/AccountOwnerTransfer'

import { USER_FIELDS, networkOnlyAllErrorsVariables } from '../consts'

import { Tabs, Card } from 'antd'

const { TabPane } = Tabs

const QUERY_ACCOUNT = gql`
  query queryAccount ($id: ID!) {
    account (id: $id) {
      pastUsers {
        ${USER_FIELDS}
      }
      roles {
        id
        displayableName
        name
        note
        imageURL
        cloudinaryImage
      }
      invites {
        id
      }
      isUserIdAccountOwner
      userId
      id
      name
      note
      imageURL
      cloudinaryImage
      userId
      owner {
        ${USER_FIELDS}
      }
      userAdminAccountUsers {
        ${USER_FIELDS}
      }
      userAdminLocationUsers {
        ${USER_FIELDS}
      }
      userGeneralLocationUsers {
        ${USER_FIELDS}
      }
      userAccountGroupUsers {
        ${USER_FIELDS}
      }

      locations {
        id
        name
        note
        imageURL
        cloudinaryImage
        country {
          name
          emoji
        }
        state {
          name
        }
        city {
          name
        }
        locationAdminUsers {
          ${USER_FIELDS}
        }
        locationGeneralUsers {
          ${USER_FIELDS}
        }
      }
      accountgroups {
        id
        name
        note
        imageURL
        cloudinaryImage
        accountgroupUsers {
          ${USER_FIELDS}
        }
      }
    }
  }
`

const breadcrumb = [
  {
    path: '/',
    breadcrumbName: 'Home'
  },
  {
    path: '',
    breadcrumbName: 'Account Settings'
  }
]

const AccountSettings = () => {
  const refresh = useReactiveVar(refreshUser)

  const { emailIsVerified, currentAccountUserPermissions } = memoryUser()

  if (!emailIsVerified) return <RouteErrorDisplay notVerified='true' />

  const { viewAccountAdministrator } = currentAccountUserPermissions

  const history = useHistory()
  if (!viewAccountAdministrator) {
    history.push('/')
    return null
  }

  const [queryAccount, { data, error, loading: accountloading, refetch }] = useLazyQuery(QUERY_ACCOUNT)

  const [account, setAccount] = useState()

  useEffect(() => {
    if (!refresh) {
      const variables = {
        id: memoryUser().currentAccountId,
        ...networkOnlyAllErrorsVariables
      }
      return queryAccount({ variables })
    }
  }, [refresh])

  useEffect(() => {
    if (data?.account?.id) setAccount(data.account)
  }, [data, accountloading])

  // useEffect(() => {
  //   if (account) refreshUser('AccountSettings, account changed')
  // }, [account])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <ContentWrapper title='Account Settings' breadcrumb={breadcrumb}>
      <ItemHeading loading={accountloading} item={account} refetch={refetch} />
      <Card
        loading={accountloading}
        hoverable
        style={{ margin: 6 }}
        size='small'
        title='Owner'
        extra={<AccountOwnerTransfer account={account} refetch={refetch} />}
      >
        <ItemCardMeta loading={accountloading} item={account?.owner} />
      </Card>
      <Card loading={accountloading}>
        <AccountTabs accountloading={accountloading} account={account} refetch={refetch} />
      </Card>

    </ContentWrapper>
  )
}

export default AccountSettings

const AccountTabs = ({ accountloading, account, refetch }) => {
  const [activeKey, setActiveKey] = useState(memoryActiveTab())

  useEffect(() => {
    if (activeKey && activeKey !== memoryActiveTab()) {
      memoryActiveTab(activeKey)
    }
  }, [activeKey, memoryActiveTab()])

  return (
    <Tabs
      type='card'
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      onChange={(activeKey) => setActiveKey(activeKey)}
    >

      <TabPane tab='Basic details' key='basicDetails'>
        <AccountEdit accountloading={accountloading} account={account} refetch={refetch} />
      </TabPane>

      <TabPane tab='User management' key='userManagement'>
        <AccountUserManagement setActiveKey={setActiveKey} accountloading={accountloading} account={account} refetch={refetch} />
      </TabPane>

      <TabPane tab='Invitation history' key='invitationHistory'>
        <InvitationHistory setActiveKey={setActiveKey} accountloading={accountloading} account={account} />
      </TabPane>

      <TabPane tab='Invite user' key='inviteUser'>
        <InvitationAdd setActiveKey={setActiveKey} refetch={refetch} accountloading={accountloading} account={account} />
      </TabPane>

      {
        account?.isUserIdAccountOwner &&
          <TabPane tab='Subscriptions' key='subscriptions'>
            <AccountSubscriptions />
          </TabPane>
      }

      {/*
        <TabPane tab='Activity' key='5'>
          <AccountActivity />
        </TabPane>
      */}

    </Tabs>
  )
}
