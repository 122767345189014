
import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'

import CenterContent from '../components/CenterContent'

import { Modal, Alert, Typography, message, Form, Input } from 'antd'

const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback ($submitFeedbackInput: SubmitFeedbackInput!) {
    submitFeedback (submitFeedbackInput: $submitFeedbackInput)
  }
`

const SubmitFeedback = ({ visible, setVisible }) => {
  const [form] = Form.useForm()

  const [submitFeedback, { loading, data, error }] = useMutation(SUBMIT_FEEDBACK)

  useEffect(() => {
    if (loading) return
    if (data?.submitFeedback) {
      message.success({ content: 'Thank you for your feedback !', duration: 5 })
      form.resetFields()
      return setVisible(false)
    }
  }, [data, loading])

  return (
    <Modal
      confirmLoading={loading}
      header={null}
      title={<Typography.Text style={{ fontSize: 24 }}>Submit feedback</Typography.Text>}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={(values) =>
        submitFeedback({
          variables: { submitFeedbackInput: { ...form.getFieldsValue() } },
          errorPolicy: 'all'
        })}
    >
      <CenterContent fluid='true'>

        <Form
          form={form}
          preserve={false}
          layout='vertical'
          style={{ width: '100%' }}
        >

          <Form.Item
            name='feedback'
            label='Enter any feedback you might like to share with us.'
          >
            <Input.TextArea
              placeholder='(optional)'
              rows={4}
            />
          </Form.Item>

        </Form>

        {
          error &&
            <Alert type='error' message={error?.message} />
        }

      </CenterContent>
    </Modal>
  )
}

export default SubmitFeedback
