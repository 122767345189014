import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { Form, Input, Row, Col, message } from 'antd'

import FormWrapper from '../layout/FormWrapper'
import SimpleLink from '../components/SimpleLink'

const SIGN_IN = gql`
  mutation changeMyPassword ($changeMyPasswordInput: ChangeMyPasswordInput!) {
    changeMyPassword (changeMyPasswordInput: $changeMyPasswordInput) {
      id
    }
  }
`

const extra = (
  <Row justify='end'>
    <Col>
      <SimpleLink
        to='/profile'
        content='Cancel'
        block='true'
        type='secondary'
      />
    </Col>
  </Row>
)

const ChangeMyPassword = () => {
  const history = useHistory()

  const [form] = Form.useForm()
  const [changeMyPassword, { data, loading, error }] = useMutation(SIGN_IN)

  useEffect(() => {
    if (data?.changeMyPassword?.id) {
      message.success('Password changed')
      form.resetFields()

      history.push('/profile')
    }
  }, [data])

  return (
    <FormWrapper
      title='Change my password'
      form={form}
      onFinish={(values) => changeMyPassword({
        variables: { changeMyPasswordInput: values },
        errorPolicy: 'all'
      })}
      loading={loading}
      error={error}
      extra={extra}
    >

      <Form.Item
        name='oldPassword'
        label='Old password'
      >
        <Input.Password autoComplete='on' />
      </Form.Item>

      <Form.Item
        name='password'
        label='New password'
      >
        <Input.Password autoComplete='on' />
      </Form.Item>

      <Form.Item
        name='passwordConfirm'
        label='Confirm new password'
      >
        <Input.Password autoComplete='on' />
      </Form.Item>

    </FormWrapper>
  )
}

export default ChangeMyPassword
