import React, { useEffect } from 'react'

import { Form, Input, message, PageHeader } from 'antd'

import { ME_FIELDS } from '../consts'
import { gql, useMutation } from '@apollo/client'

import FormWrapper from '../layout/FormWrapper'
import RouteErrorDisplay from '../components/RouteErrorDisplay'

const UPDATE_ACCOUNT = gql`
  mutation updateAccount ($id: ID!, $accountInput: AccountInput!) {
    updateAccount (id: $id, accountInput: $accountInput) {
      ${ME_FIELDS}
    }
  }
`

const AccountEdit = ({ accountloading, account, refetch }) => {
  const [form] = Form.useForm()
  const [updateAccount, { data, loading, error }] = useMutation(UPDATE_ACCOUNT)

  useEffect(() => {
    if (accountloading) return
    if (account) {
      return form.setFieldsValue(account)
    }
  }, [account, accountloading])

  useEffect(() => {
    if (!data?.updateAccount?.id) return
    message.success('Account updated')
    return refetch()
  }, [data, refetch])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <PageHeader
      style={{ padding: 0 }}
      subTitle='Please keep your account up to date.'
    >

      <FormWrapper
        size='medium'
        form={form}
        preserve={false}
        initialValues={account && account}
        onFinish={(values) => updateAccount({
          variables: {
            id: account?.id,
            userId: account?.userId,
            accountInput: { ...values, userId: account?.userId }
          },
          errorPolicy: 'all'
        })}
        loading={loading}
        error={error}
        fluid='true'
      >

        <Form.Item
          name='name'
          label='Name'
        >
          <Input autoComplete='on' />
        </Form.Item>

        <Form.Item
          name='imageURL'
          label='imageURL'
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='note'
          label='Notes'
        >
          <Input.TextArea
            placeholder='(optional)'
            rows={4}
          />
        </Form.Item>

      </FormWrapper>

    </PageHeader>
  )
}

export default AccountEdit
