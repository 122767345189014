import React from 'react'
import { Space, Result, List, Steps } from 'antd'
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'

const { Step } = Steps

const AccountSubscriptions = () => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Steps>
        <Step status='finish' title='Create Questionnaires / Surveys' icon={<UserOutlined />} />
        <Step status='finish' title='Subscribe FREE' icon={<SolutionOutlined />} />
        <Step status='process' title='Deploy Questionnaires / Surveys' icon={<LoadingOutlined />} />
        <Step status='wait' title='View Reporting' icon={<SmileOutlined />} />
      </Steps>

      <Result
        icon={<SmileOutlined />}
        title='How it works'
        status='404'
        extra={(

          <List>

            <List.Item>
              You can start now to begin creating <Link to='/admin/ic/questionnaire/add'>Questionnaires</Link> and/or <Link to='/admin/ic/spawner/add'>Surveys</Link>.
            </List.Item>

            <List.Item>
              For a limitted time, <Link to='/profile'>your</Link> Accounts can enjoy a FREE subscription.
            </List.Item>

            <List.Item>
              Once you've subscribed, take your <Link to='/admin/ic/questionnaires'>Questionnaires</Link> and/or <Link to='/admin/ic/spawners'>Surveys</Link> LIVE to begin collecting data.
            </List.Item>

            <List.Item>
              Review our ever expanding array of <Link to='/admin/ic/reports'>Reporting</Link> options to review your DEI comparative data!
            </List.Item>

          </List>

        )}
      />

    </Space>
  )
}

export default AccountSubscriptions

// <>
//   <li>list account subscriptions</li>
//   <li>user subscribes to an impactcalculator.io plan</li>
//   <li>a impactcalculator.io plan is the account's amount ($) / month or amount ($) / year for a impactcalculator.io service</li>
//   <li>a impactcalculator.io service is for now, the Impact Calulator</li>
// </>
