import React from 'react'
import { useParams, withRouter } from 'react-router-dom'

import Page from '../../../../components/Page'
import SpawnerForm from '../../../../components/sections/admin/ic/spawner/SpawnerForm'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICSpawner = () => {
  const { id, templateId } = useParams()
  const title = id ? 'Edit survey' : 'Add survey'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    }
  ]

  templateId && breadcrumb.push(
    {
      path: `/admin/ic/${templateId}`,
      breadcrumbName: 'Template'
    }
  )

  breadcrumb.push(
    {
      path: '/admin/ic/spawners',
      breadcrumbName: 'Surveys'
    }
  )
  breadcrumb.push(
    {
      path: '',
      breadcrumbName: title
    }
  )

  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-padding-leftRight page-padding-topBottom'>
          <SpawnerForm />
        </div>
      </Page>
    </>
  )
}

export default withRouter(ICSpawner)
