import React from 'react'
import { Row, Col, Card, Typography } from 'antd'

const { Title } = Typography

const Center = ({ children, fluid }) => {
  if (fluid) return <>{children}</>

  return (
    <Row justify='center' style={{ paddingTop: '36px' }}>
      <Col
        xs={22}
        sm={22}
        md={16}
        lg={16}
        xl={14}
        xxl={10}
      >
        {children}
      </Col>
    </Row>
  )
}

const CenterContent = ({ title: optionTitle, label: optionalLabel, children, bordered, fluid }) => {
  let title = optionalLabel && <Title level={2}>{optionalLabel}</Title>
  title = optionTitle && <Title level={2}>{optionTitle}</Title>

  if (bordered) {
    return (
      <Center fluid={fluid}>
        <Card
          title={title}
        >
          {children}
        </Card>
      </Center>
    )
  }
  return (
    <Center fluid={fluid}>
      {children}
    </Center>
  )
}

export default CenterContent
