import React from 'react'
import PropTypes from 'prop-types'
import ItemCardMeta from '../../../../core/components/ItemCardMeta'

const QuestionnaireHome = ({ icquestionnaire, loading }) => {
  return <ItemCardMeta loading={loading} item={icquestionnaire} view='questionnaireLiveSplash' expand='true' />
}

QuestionnaireHome.propTypes = {
  icquestionnaire: PropTypes.object,
  loading: PropTypes.bool
}

export default QuestionnaireHome
