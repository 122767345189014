import { gql } from '@apollo/client'

export const INSERT_RESPONSE = gql`
mutation insertIcresponse(
  $icnodechildId: ID
  $icquestionnaireId: ID
  $payload: String
) {
  insertIcresponse(insertIcresponseInsert: {
    icnodechildId: $icnodechildId
    icquestionnaireId: $icquestionnaireId
    payload: $payload
  }) {
    percent
  }
}
`
