import React from 'react'

import { Form, Checkbox, Row, Col, Space, Typography } from 'antd'

const { Text } = Typography

const ReportSetField = ({ form, field, data }) => {
  const checkboxGroupChanged = async list => {
    const clone = await form.getFieldsValue()
    clone[field] = list
    await form.setFieldsValue(clone)
  }

  const DisplayCount = ({ count }) => {
    if (!count) return null
    return (
      <Text disabled style={{ fontSize: 10 }}>{`${count} record${count !== 1 ? 's' : ''}`}</Text>
    )
  }

  return (
    <Checkbox.Group
      key={`checkboxGroup${field}`}
      style={{ width: '100%' }}
      onChange={checkboxGroupChanged}
    >
      <Row key={`checkboxGroupRow${field}`}>
        {
          data.map((record, index) => {
            const { value, display, count } = record
            return (
              <Col
                key={`column{field}${value}`}
                span={8}
              >
                <Checkbox
                  key={`checkbox${field}${value}${index}`}
                  value={value}
                  style={{ lineHeight: '32px' }}
                >
                  <Space size='small' align='bottom'>
                    <Text strong>{display}</Text>
                    <DisplayCount count={count} />
                  </Space>
                </Checkbox>
              </Col>
            )
          })
        }
      </Row>
    </Checkbox.Group>
  )
}

const ReportSets = ({ form, sets }) => {
  return sets.map((set, index) => {
    const { field, data } = set
    return (
      <Form.Item
        key={`formSets{field}${index}`}
        label={field.toUpperCase()}
        name={field}
      >
        <ReportSetField form={form} field={field} data={data} />
      </Form.Item>
    )
  })
}

export default ReportSets
