import { gql } from '@apollo/client'

export const QUERY_CHECK_SPAWNER_ROOTROUTE = gql`
  query(
    $icquestionnairespawnerId: ID
    $rootRouteName: String!
  ) {
    icquestionnairespawnerRouteRouteNameIsValid (
      icquestionnairespawnerId: $icquestionnairespawnerId
      rootRouteName: $rootRouteName
    )
  }
`

export const SPAWNERS_FIELDS = `
  id
  key
  rootRouteName
  name
  note
  privateNote
  imageURL
  cloudinaryImage
  published
  spawnLimit
  completionTimeLimit
  completionTimeUnit
  locked
  startDate
  startDateAgo
  endDate
  endDateAgo

  isPublic
  anonymous

  icquestionnaireName
  icquestionnaireNote
  icquestionnaireImageURL
  ictemplateId
  locationIds
  accountgroupIds
  spawnLength
  ictemplate {
    id
    name
  }
  landingPagePath
  live
  reasons

`

export const QUERY_SPAWNERS = gql`
query(
  $filter: Filter
  $sortField: String
  $sortDirection: Int
) {
  icquestionnairespawners(
    filter: $filter
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    ${SPAWNERS_FIELDS}
  }
}
`

export const SPAWNER_FIELDS = `
  accountgroupIds
  accountId
  anonymous
  completionTimeLimit
  completionTimeUnit
  icquestionnaireImageURL
  icquestionnaireName
  icquestionnaireNote
  ictemplateId
  id
  imageURL
  cloudinaryImage
  isPublic
  locationIds
  locked
  name
  note
  privateNote
  published
  rootRouteName
  routeName
  sharing
  sharingAccountIds
  spawnLimit
  startDate
  endDate
  hidden
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "mmm dd yyyy HH:MM:ss")
  updatedAt (format: "mmm dd yyyy HH:MM:ss")
  spawnLength
  landingPagePath
  live
  feedback {
    instructions
    warnings
    issues
  }
`

export const QUERY_SPAWNER = gql`
query icquestionnairespawner($id: ID!) {
  icquestionnairespawner (id: $id) {
    ${SPAWNER_FIELDS}
  }
}
`

export const QUERY_SPAWNER_LANDING = gql`
query icquestionnairespawnerLanding($id: ID!, $icquestionnairespawnerLandingInput: IcquestionnairespawnerLandingInput!) {
  icquestionnairespawnerLanding (id: $id, icquestionnairespawnerLandingInput: $icquestionnairespawnerLandingInput) {
    redirectURL
  }
}
`
