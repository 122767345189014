import { gql } from '@apollo/client'
import { ANSWER_FIELDS } from '../../queries/ic/answers'

export const INSERT_ANSWER = gql`
mutation insertIcanswer(
  $icquestionId: ID!
  $accountId: ID!
  $name: String!
  $hidden: Boolean
  $order: Int
  $icanswerscore: Int
  $published: Boolean
  $note: String
  $imageURL: String
) {
  insertIcanswer(icanswerInput: {
    name: $name
    accountId: $accountId
    icquestionId: $icquestionId
    hidden: $hidden
    order: $order
    icanswerscore: $icanswerscore
    published: $published
    note: $note
    imageURL: $imageURL
  }) {
    ${ANSWER_FIELDS}
  }
}
`

export const UPDATE_ANSWER = gql`
mutation updateIcanswer($id: ID!, $icanswerInput: IcanswerInput!) {
  updateIcanswer(id: $id, icanswerInput: $icanswerInput) {
    ${ANSWER_FIELDS}
  }
}
`
