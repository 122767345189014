import React from 'react'
import { Form } from 'antd'
import AlertError from './AlertError'

const ValidationError = ({ error, customerror }) => {
  if (!error && !customerror) return null

  return (
    <Form.Item>
      <AlertError error={error} customerror={customerror} />
    </Form.Item>
  )
}

export default ValidationError
