import React, { useEffect } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
// import { useParams, Prompt } from 'react-router-dom'
import { message } from 'antd'
import Page from '../../../components/Page'
import Container from '../../../components/Container'
import QuestionnaireLive from '../../../components/sections/ic/questionnaire/QuestionnaireLive'
import { QUERY_NODE_TREE_QUESTIONNAIRE_LIVE } from '../../../operations/queries/ic/questionnaires'

const Questionnaire = () => {
  const { id: icquestionnaireId } = useParams()
  const { loading, error, data } = useQuery(QUERY_NODE_TREE_QUESTIONNAIRE_LIVE, { variables: { nodeTreeQuestionnaireLiveInput: { icquestionnaireId } } })

  // console.log('data', JSON.stringify(data)?.length)
  // console.log('formData', data?.nodeTreeQuestionnaireLive?.formData[0].rootForm.children[0].form.children)
  // console.log('formData', JSON.stringify(data?.nodeTreeQuestionnaireLive?.formData[0].rootForm.children[0].form.children).length)
  useEffect(() => {
    if (loading) {
      message.loading({ content: 'Loading questionnaire...', key: 'loadingQuestionnaire', duration: 0 })
    } else {
      message.destroy('loadingQuestionnaire')
    }
  }, [loading])
  return (
    <Page>
      <div className=' page-padding-topBottom'>
        <Container margin>
          <QuestionnaireLive icquestionnaireId={icquestionnaireId} loading={loading} error={error} data={data} />
        </Container>
      </div>
    </Page>
  )
}

export default withRouter(Questionnaire)
