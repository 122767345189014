// react
import React, { useState, useEffect } from 'react'
import { memoryUser } from '../../../../../core/auth/appMemory'
import ItemHeading from '../../../../../core/components/ItemHeading'

import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

// apollo & operations
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { QUERY_SPAWNER, QUERY_SPAWNERS, QUERY_CHECK_SPAWNER_ROOTROUTE } from '../../../../../operations/queries/ic/spawners'
import { INSERT_SPAWNER, UPDATE_SPAWNER } from '../../../../../operations/mutations/ic/spawners'

// ant design
import { Card, Form, Input, Button, Switch, InputNumber, Divider, DatePicker, Alert, Tooltip, Space, Select } from 'antd'
import { CopyOutlined, LinkOutlined } from '@ant-design/icons'

// elements
import FormItemsSuperAdmin from '../../../../elements/formItems/FormItemsSuperAdmin'
import FormItemSelectAccountGroup from '../../../../elements/formItems/FormItemSelectAccountGroup'
import FormItemSelectTemplate from '../../../../elements/formItems/FormItemSelectTemplate'
import FormItemSelectLocation from '../../../../elements/formItems/FormItemSelectLocation'

import FormItemError from '../../../../elements/formItems/FormItemError'
import TemplateFeedback from '../template/TemplateFeedback'

// other libraries
import moment from 'moment'
import formatErrors from '../../../../../utils/form'
import FormContainer from '../../../../../components/FormContainer'
import ModalDialog from '../../../../../components/ModalDialog'

const { Option } = Select

export const getFullLandingPage = icquestionnairespawner => `${window.location.origin}/${icquestionnairespawner.landingPagePath}`

export const SpawnerLandingPage = ({ icquestionnairespawner }) => {
  const fullLandingPage = getFullLandingPage(icquestionnairespawner)

  const [landingPagePathText, setLandingPagePathText] = useState(fullLandingPage)

  const clickLandingPagePath = () => {
    navigator.clipboard.writeText(fullLandingPage)
    setLandingPagePathText('Copied to clipboard')
    setTimeout(() => setLandingPagePathText(fullLandingPage), 2000)
  }
  return (
    <Space>
      <Tooltip title='Click to copy'>
        <Button
          style={{ paddingLeft: '0px' }}
          type='link'
          onClick={clickLandingPagePath}
        >
          {landingPagePathText} <CopyOutlined />
        </Button>
      </Tooltip>

      <a href={`${landingPagePathText}`} target='_blank' rel='noreferrer'>
        <Button type='link'>
          Complete now in new tab <LinkOutlined />
        </Button>
      </a>
    </Space>
  )
}

SpawnerLandingPage.propTypes = {
  icquestionnairespawner: PropTypes.object
}

const FormComponent = ({ data }) => {
  const { icquestionnairespawner } = data
  if (!icquestionnairespawner) return null
  const accountId = data?.icquestionnairespawner?.accountId ? data.icquestionnairespawner.accountId : memoryUser().currentAccountId
  // const accountId = memoryUser().currentAccountId

  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [startDate, setStartDate] = useState(data.icquestionnairespawner.startDate && moment(data.icquestionnairespawner.startDate))
  const [endDate, setEndDate] = useState(data.icquestionnairespawner.endDate && moment(data.icquestionnairespawner.endDate))
  const [updateSpawner, { loading: updateLoading }] = useMutation(UPDATE_SPAWNER)
  const [insertSpawner, { loading: insertLoading }] = useMutation(INSERT_SPAWNER, {
    update (cache, { data: { insertIcspawner } }) {
      const data = cache.readQuery({ query: QUERY_SPAWNERS })
      cache.writeQuery({
        query: QUERY_SPAWNERS,
        data: { icspawners: !data ? [insertIcspawner] : data.icspawners.concat([insertIcspawner]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/ic/spawner/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateSpawner({ variables: { id: id, icquestionnairespawnerInput: { ...values, accountId } } }) : await insertSpawner({ variables: { ...values, accountId } })
      const modalFunc = values.hidden ? ModalDialog.info : ModalDialog.success
      modalFunc({
        title: 'Success',
        content: `The survey has been ${id ? (values.hidden ? 'deleted' : 'updated') : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/ic/spawners'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertIcquestionnairespawner?.id)
      })
      setDefault()
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  const onChangeStartDate = date => {
    form.setFieldsValue({ ...form.getFieldsValue(), startDate: date })
    setStartDate(date)
  }

  const onChangeEndDate = date => {
    form.setFieldsValue({ ...form.getFieldsValue(), endDate: date })
    setEndDate(date)
  }

  const [checkAvailabilityText, setCheckAvailabilityText] = useState()
  const [checkAvailabilityValidateStatus, setCheckAvailabilityValidateStatus] = useState()
  const [checkRouteRouteName, { loading: checkRootRouteLoading, data: checkRootRouteData }] = useLazyQuery(QUERY_CHECK_SPAWNER_ROOTROUTE)

  useEffect(() => {
    if (checkRootRouteLoading) setCheckAvailabilityValidateStatus('validating')
  }, [checkRootRouteLoading])

  const setDefault = () => {
    setCheckAvailabilityText('')
    setCheckAvailabilityValidateStatus('')
  }

  const setSuccess = () => {
    setCheckAvailabilityText('Available')
    setCheckAvailabilityValidateStatus('success')
  }

  const setWarning = () => {
    setCheckAvailabilityText('Route is currently in use')
    setCheckAvailabilityValidateStatus('warning')
  }

  useEffect(() => {
    if (!checkRootRouteData) return

    const { icquestionnairespawnerRouteRouteNameIsValid } = checkRootRouteData

    if (icquestionnairespawnerRouteRouteNameIsValid) setSuccess()
    if (!icquestionnairespawnerRouteRouteNameIsValid) setWarning()

    const { rootRouteName } = form.getFieldsValue()
    if (rootRouteName === '') setDefault()
  }, [checkRootRouteData])

  const checkAvailability = () => {
    const { id: icquestionnairespawnerId } = data.icquestionnairespawner
    const { rootRouteName } = form.getFieldsValue()

    if (rootRouteName === '') {
      setDefault()
      return
    }

    const variables = {
      icquestionnairespawnerId,
      rootRouteName
    }

    checkRouteRouteName({ variables })
  }

  return (
    <>
      {data &&
        <Form
          form={form}
          id='spawnerForm'
          name='spawnerForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.icquestionnairespawner}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >

          <Space direction='vertical' style={{ width: '100%' }}>

            <Alert
              banner='true'
              message='Landing page'
              description={
                <Space direction='vertical'>
                  <SpawnerLandingPage icquestionnairespawner={data.icquestionnairespawner} />
                  <div>Spawned questionaires: {data.icquestionnairespawner.spawnLength}</div>
                </Space>

              }
              type='success'
              showIcon='false'
            />

            {
              data.icquestionnairespawner.live
                ? <Alert banner='true' message='Status' description='Survey is LIVE' type='success' showIcon='false' />
                : <Alert banner='true' message='Status' description='Survey is not spawning' type='warning' showIcon='false' />
            }

            {
              data.icquestionnairespawner?.feedback && <TemplateFeedback feedback={data.icquestionnairespawner.feedback} />
            }

          </Space>

          <Divider orientation='left'>Survey</Divider>

          <Form.Item
            label='Secret URL route'
            name='rootRouteName'
            hasFeedback
            validateStatus={itemErrors.rootRouteName ? 'error' : checkAvailabilityValidateStatus}
            help={checkAvailabilityText || itemErrors.rootRouteName}
            onChange={() => checkAvailability()}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Name'
            name='name'
            hasFeedback
            validateStatus={itemErrors.name ? 'error' : ''}
            help={itemErrors.name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Notes'
            name='note'
            hasFeedback
            validateStatus={itemErrors.note ? 'error' : ''}
            help={itemErrors.note}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Private Notes'
            name='privateNote'
            hasFeedback
            validateStatus={itemErrors.privateNote ? 'error' : ''}
            help={itemErrors.privateNote}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Image URL'
            name='imageURL'
            hasFeedback
            validateStatus={itemErrors.imageURL ? 'error' : ''}
            help={itemErrors.imageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <Form.Item label='Completion time limit' style={{ marginBottom: '0' }}>
            <Form.Item
              name='completionTimeLimit'
              hasFeedback
              validateStatus={itemErrors.completionTimeLimit ? 'error' : ''}
              help={itemErrors.completionTimeLimit}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name='completionTimeUnit'
              hasFeedback
              validateStatus={itemErrors.completionTimeUnit ? 'error' : ''}
              help={itemErrors.completionTimeUnit}
              style={{ display: 'inline-block', width: 'calc(50%)' }}
            >
              <Select>
                <Option value='second'>Seconds</Option>
                <Option value='minute'>Minutes</Option>
                <Option value='hour'>Hours</Option>
                <Option value='day'>Days</Option>
                <Option value='week'>Weeks</Option>
                <Option value='month'>Months</Option>
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item name='startDate' hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label='Start date'
          >
            <DatePicker
              style={{ width: '100%' }}
              picker='date'
              format='dddd, MMMM Do, yyyy'
              onChange={onChangeStartDate}
              value={startDate}
            />
          </Form.Item>

          <Form.Item name='endDate' hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label='End date'
          >
            <DatePicker
              style={{ width: '100%' }}
              picker='date'
              format='dddd, MMMM Do, yyyy'
              onChange={onChangeEndDate}
              value={endDate}
            />
          </Form.Item>

          <Form.Item
            label='Spawn limit'
            name='spawnLimit'
            hasFeedback
            validateStatus={itemErrors.spawnLimit ? 'error' : ''}
            help={itemErrors.spawnLimit}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Anonymous'
            name='anonymous'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Public'
            name='isPublic'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Locked'
            name='locked'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Published'
            name='published'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Divider orientation='left'>Questionnaire</Divider>

          <FormItemSelectTemplate
            label='Template'
            name='ictemplateId'
            hasFeedback
            validateStatus={itemErrors.ictemplateId ? 'error' : ''}
            help={itemErrors.ictemplateId}
          />

          <Form.Item
            label='Name'
            name='icquestionnaireName'
            hasFeedback
            validateStatus={itemErrors.icquestionnaireName ? 'error' : ''}
            help={itemErrors.icquestionnaireName}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Notes'
            name='icquestionnaireNote'
            hasFeedback
            validateStatus={itemErrors.icquestionnaireNote ? 'error' : ''}
            help={itemErrors.icquestionnaireNote}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Image URL'
            name='icquestionnaireImageURL'
            hasFeedback
            validateStatus={itemErrors.icquestionnaireImageURL ? 'error' : ''}
            help={itemErrors.icquestionnaireImageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <FormItemSelectAccountGroup
            label='Account groups'
            name='accountgroupIds'
            hasFeedback
            validateStatus={itemErrors.accountgroupIds ? 'error' : ''}
            help={itemErrors.accountgroupIds}
            mode='multiple'
          />

          <FormItemSelectLocation
            label='Locations'
            name='locationIds'
            hasFeedback
            validateStatus={itemErrors.locationIds ? 'error' : ''}
            help={itemErrors.locationIds}
            mode='multiple'
          />

          <FormItemsSuperAdmin data={data.icquestionnairespawner} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object
}

const SpawnerForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_SPAWNER, { variables: { id }, skip: !id })
  const initialValues = {
    icquestionnairespawner: {
      name: '',
      startDate: data?.icquestionnairespawner?.startDate,
      endDate: data?.icquestionnairespawner?.endDate
    }
  }

  return (
    <>
      <Card title={data?.icquestionnairespawner?.name} style={{ marginBottom: '24px' }}>
        <ItemHeading loading={loading} item={data?.icquestionnairespawner} refetch={refetch} />
        <FormContainer loading={loading} size={4} form={<FormComponent data={{ ...initialValues, ...data }} />} />
      </Card>
    </>
  )
}

export default SpawnerForm
