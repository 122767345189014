import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// styles
import './SurveyQuestionResult.less'
// ant design
import { Typography, Checkbox } from 'antd'

const SurveyQuestionResult = ({ antForm, icnodeChildId, question }) => {
  const [isChecked, setIsChecked] = useState()
  const [surveyValue, setSurveyValue] = useState()

  const onChangeChecked = (e) => {
    setIsChecked(e.target.checked)
    const value = surveyValue
    if (value === antForm.getFieldValue(icnodeChildId)) return true

    if (e.target.checked) {
      antForm.setFields([{
        name: icnodeChildId,
        value
      }])

      // add change manually to reactiveForm because ant form triggers only events on users actions.
      antForm.addChangedFields([{
        name: icnodeChildId,
        value,
        errors: []
      }])
    }
  }

  useEffect(() => {
    setIsChecked(antForm.getFieldValue(icnodeChildId) === surveyValue)
    setSurveyValue('123456789')
  }, [])

  return (
    <div className='SurveyQuestionResult'>
      <table style={{ width: '100' }}>
        <tr>
          <th><Typography.Title level={5}>Results from the Anonymous<br />Self-Identification Questionnaire</Typography.Title>
            <Checkbox onChange={onChangeChecked} checked={isChecked}>Copy below value to calculator field</Checkbox>
          </th>
        </tr>
        <tr>
          <td>{surveyValue}</td>
        </tr>
      </table>
    </div>
  )
}

SurveyQuestionResult.propTypes = {
  antForm: PropTypes.object.isRequired,
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default SurveyQuestionResult
