import { gql } from '@apollo/client'
import { QUESTION_FIELDS } from '../../queries/ic/questions'

export const INSERT_QUESTION = gql`
mutation insertIcquestion(
  $name: String!
  $note: String
  $imageURL: String
  $accountId: ID!
  $hidden: Boolean,
  $canselectmanyanswers: Boolean
  $correcticanswerId: ID
  $icquestionscore: Int
  $icquestiontype: String
  $randomizeselectableanswers: Boolean
  $published: Boolean
  $inputtype: String
  $numbertype: String
  $decimalplaces: Int

  $minValue: Float
  $varA: Float
  $varB: Float
  $varC: Float
  $isDominantGroup: Boolean
  $answerIsClickTouch: Boolean
  $suggestionTrendingWell: String
  $suggestionNeedsImprovement: String
  $suggestionBelowStandard: String
  $internalNote: String
) {
  insertIcquestion(icquestionInput: {
    name: $name
    note: $note
    imageURL: $imageURL
    accountId: $accountId
    hidden: $hidden
    canselectmanyanswers: $canselectmanyanswers
    correcticanswerId: $correcticanswerId
    icquestionscore: $icquestionscore
    icquestiontype: $icquestiontype
    randomizeselectableanswers: $randomizeselectableanswers
    published: $published
    inputtype: $inputtype
    numbertype: $numbertype
    decimalplaces: $decimalplaces

    minValue: $minValue
    varA: $varA
    varB: $varB
    varC: $varC
    isDominantGroup: $isDominantGroup
    answerIsClickTouch: $answerIsClickTouch
    suggestionTrendingWell: $suggestionTrendingWell
    suggestionNeedsImprovement: $suggestionNeedsImprovement
    suggestionBelowStandard: $suggestionBelowStandard
    internalNote: $internalNote

  }) {
    ${QUESTION_FIELDS}
  }
}
`

export const UPDATE_QUESTION = gql`
mutation updateIcquestion($id: ID!, $icquestionInput: IcquestionInput!) {
  updateIcquestion(id: $id, icquestionInput: $icquestionInput) {
    ${QUESTION_FIELDS}
  }
}
`
