import React from 'react'
import { useParams } from 'react-router-dom'

import Page from '../../../../components/Page'
import QuestionnaireForm from '../../../../components/sections/admin/ic/questionnaire/QuestionnaireForm'
import PageInstructions from '../../../../documentation/PageInstructions'

const Questionnaire = () => {
  const params = useParams()

  const title = params?.id ? 'Edit questionnaire' : 'Add questionnaire'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    },
    {
      path: '/admin/ic/questionnaires',
      breadcrumbName: 'Questionnaires'
    },
    {
      path: '',
      breadcrumbName: title
    }
  ]
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-padding-leftRight page-padding-topBottom'>
          <QuestionnaireForm />
        </div>
      </Page>
    </>
  )
}

export default Questionnaire
