import React from 'react'
import { useHistory } from 'react-router-dom'

import { returnLocaleStorage } from '../auth/appStorage'

import { Result } from 'antd'
import SimpleLink from '../components/SimpleLink'

const NotFound = () => {
  const history = useHistory()
  if (!returnLocaleStorage('accessToken')) {
    history.push('/signin')
  }
  return (
    <Result
      status='404'
      title='404'
      extra={[
        <SimpleLink
          key='linktohome404'
          to='/'
          content='Home'
          type='secondary'
        />
      ]}
    />
  )
}

export default NotFound
