import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

import { memoryUser, refreshUser } from '../auth/appMemory'

import { Result } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import SimpleLink from '../components/SimpleLink'

const USE_EMAIL_VERIFICATION_TOKEN = gql`
  mutation useEmailVerificationToken ($verificationToken: String!) {
    useEmailVerificationToken (verificationToken: $verificationToken) {
      emailIsVerified
    }
  }
`

const VerificationLandingPage = () => {
  const [useEmailVerificationToken, { data, error }] = useMutation(USE_EMAIL_VERIFICATION_TOKEN)

  const [emailIsVerified, setEmailIsVerified] = useState()

  const [title, setTitle] = useState()
  const [subTitle, setSubTitle] = useState('Checking token...')

  const { token } = useParams()

  useEffect(() => {
    if (data?.useEmailVerificationToken?.emailIsVerified) {
      setEmailIsVerified(true)
      return refreshUser('VerificationLandingPage, email is verfied')
    }
  }, [data, memoryUser])

  useEffect(() => {
    if (!error?.message) return

    setTitle('Thank you !')
    setSubTitle(error.message)

    return refreshUser('VerificationLandingPage, error using token')
  }, [error])

  useEffect(() => {
    if (emailIsVerified) {
      setTitle('Thank you !')
      setSubTitle('Your email is verified.')

      return refreshUser('VerificationLandingPage, thank you for verfiying')
    }
  }, [emailIsVerified, refreshUser])

  useEffect(() => {
    if (!token || data) return
    useEmailVerificationToken({
      variables: {
        verificationToken: token
      },
      errorPolicy: 'all'
    })
  }, [token, data])

  return (
    <Result
      icon={<LockOutlined />}
      title={title}
      subTitle={subTitle}
      extra={
        title &&
          <SimpleLink
            to='/'
            content='Get started'
            size='large'
            type='default'
          />
      }
    />
  )
}

export default VerificationLandingPage
