import React from 'react'

import Page from '../../../../components/Page'
import QuestionnaireList from '../../../../components/sections/admin/ic/questionnaire/QuestionnaireList'
import PageInstructions from '../../../../documentation/PageInstructions'

const Questionnaires = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    },
    {
      path: '',
      breadcrumbName: 'Questionnaires'
    }
  ]
  return (
    <>
      <Page title='Questionnaires' breadcrumb={breadcrumb}>
        <PageInstructions />
        <QuestionnaireList />
      </Page>
    </>
  )
}

export default Questionnaires
