import React from 'react'

import { Typography, Space } from 'antd'

import ReportCard from './ReportCard'
import ReportsDocumentation from './ReportsDocumentation'

const { Text } = Typography

const ReportDisplay = ({ clientNavigationalReports, clientChartsPostDemo, loading, orientation }) => {
  if (!clientNavigationalReports) return null

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {
        loading &&
          <Text>Loading your filtered data....</Text>
      }
      <ReportsUI clientChartsPostDemo={clientChartsPostDemo} orientation={orientation} />

      <ReportsDocumentation clientNavigationalReports={clientNavigationalReports} loading={loading} />
    </Space>
  )
}

export default ReportDisplay

const ReportsUI = ({ clientChartsPostDemo, orientation }) => {
  if (!clientChartsPostDemo) return null
  const { questionnaireCharts } = clientChartsPostDemo
  if (!questionnaireCharts) return null
  const { barCharts } = questionnaireCharts
  if (!barCharts) return null

  return (
    <Space direction='vertical'>
      {
        barCharts.map((barChart, index) => <ReportCard key={barChart.key} barChart={barChart} orientation={orientation} />)
      }
    </Space>
  )
}
