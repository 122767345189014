import React, { useEffect, useState } from 'react'

import { APP_COLOR, ME_FIELDS } from '../consts'
import { memoryUser, refreshUser } from '../auth/appMemory'
import ItemCardMeta from '../components/ItemCardMeta'
import SubmitFeedback from '../models/SubmitFeedback'

import { gql, useMutation } from '@apollo/client'

import { Space, Menu, Badge, Tooltip, Row, Col, message } from 'antd'
import {
  SettingOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  WechatOutlined,
  SubnodeOutlined,
  HomeOutlined,
  MailOutlined
} from '@ant-design/icons'

const MUTATION_CHANGE_CURRENT_ACCOUNT = gql`
  mutation changeCurrentAccount ($changeCurrentAccountInput: ChangeCurrentAccountInput!) {
    changeCurrentAccount (changeCurrentAccountInput: $changeCurrentAccountInput) {
      ${ME_FIELDS}
    }
  }
`

const AuthenticatedDropdown = ({ history, user }) => {
  const [visible, setVisible] = useState(false)

  const [updateCurrentAccount, { data, error }] = useMutation(MUTATION_CHANGE_CURRENT_ACCOUNT)

  const key = 'changeAccount'

  const clickChangeAccount = accountId => {
    if (accountId === memoryUser().currentAccountId) return message.success({ content: 'Already using account you selected', key, duration: 2 })

    const changingToAccount = user.usersAdministrativeAccounts.selectableAccounts.find(i => i.id.toString() === accountId.toString())

    message.loading({ content: `${changingToAccount.name} is loading...`, key })

    const update = {
      variables: { changeCurrentAccountInput: { accountId } },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    }
    return updateCurrentAccount(update)
  }

  useEffect(() => {
    if (data?.changeCurrentAccount?.id) refreshUser('AuthenticatedDropdown, clickChangeAccount')
  }, [data])

  useEffect(() => {
    if (error) message.error('Could not change account')
  }, [error])

  const displaySwitchAccounts = user.emailIsVerified && user.usersAdministrativeAccounts.selectableAccounts.length > 1

  return (
    <>
      <SubmitFeedback visible={visible} setVisible={setVisible} />
      <Menu theme='dark' style={{ minWidth: '200px' }}>
        <Menu.Item key='myinformation' icon={<SettingOutlined />} onClick={() => history.push('/profile')}>Profile</Menu.Item>
        {
          displaySwitchAccounts &&
            <Menu.SubMenu key='switch_accounts' title='Switch account' icon={<HomeOutlined />} popupClassName='ant-menu-dark'>
              {
                user.usersAdministrativeAccounts.selectableAccounts.map((account, index) => {
                  const { id: accountId, owner } = account

                  const showMyCurrentAccountBadge = user.currentAccountId === accountId

                  const { fullName } = owner

                  const sharingTooltip = user.id !== owner.id ? `${fullName} owns this account` : ''

                  return (
                    <Menu.Item
                      key={`menuItemName_${accountId}`}
                      onClick={() => clickChangeAccount(accountId)}
                    >
                      <Row justify='space-between'>
                        <Col flex='1'>
                          <Space>
                            <ItemCardMeta item={account} size='small' view='small' />

                            {
                              showMyCurrentAccountBadge &&
                                <Tooltip key={`badge_tooltip${accountId}`} title='Your currently selected account' color='blue'>
                                  <Badge color='green' />
                                </Tooltip>
                            }
                          </Space>

                        </Col>
                        <Col flex='1' align='right' style={{ marginLeft: '12px' }}>
                          {
                            sharingTooltip &&
                              <Tooltip key={`accountName_tooltip${accountId}`} title={sharingTooltip} color={APP_COLOR}>
                                <UsergroupAddOutlined style={{ color: APP_COLOR }} />
                              </Tooltip>
                          }
                        </Col>
                      </Row>

                    </Menu.Item>
                  )
                })
              }
            </Menu.SubMenu>
        }

        {!user.emailIsVerified &&
          <Menu.Item key='get_verified' icon={<MailOutlined />} onClick={() => history.push('/verifyme')}>
            Get verified
          </Menu.Item>}

        {false && user.emailIsVerified &&
          <Menu.Item key='add_account' icon={<SubnodeOutlined />} onClick={() => history.push('/account/add')}>
            Add Account
          </Menu.Item>}

        <Menu.Divider />

        <Menu.Item key='report' icon={<WechatOutlined />} onClick={() => setVisible(!visible)}>
          Feedback
        </Menu.Item>

        <Menu.Item key='out' icon={<LogoutOutlined />} onClick={() => history.push('/signout')}>
          Sign out
        </Menu.Item>
      </Menu>
    </>
  )
}

export default AuthenticatedDropdown
