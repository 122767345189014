// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../../../core/components/ItemCardMeta'

// ant design
import { Button, Tooltip, Space } from 'antd'

// operations
import { QUERY_QUESTIONS } from '../../../../../../operations/queries/ic/questions'
import { InputOrSelectIcon } from '../../../../../../components/elements/icons'

// components
import Listing from '../../../../../Listing'

const QuestionList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Icquestion')
  const edit = permissions?.menuAccess?.edit.includes('Icquestion')
  const list = permissions?.menuAccess?.list.includes('Icquestion')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const extra = (
    <>
      {add && <Tooltip title='Add new question'><Link to='./question'><Button type='primary'>Add Question</Button></Link></Tooltip>}
    </>
  )

  const InputOrSelectIconWrapper = ({ children, record }) => (
    <Space>
      <InputOrSelectIcon
        key={`InputOrSelectIcon${record.id}`}
        icquestion={record}
        size='12px'
      />
      {children}
    </Space>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) return <InputOrSelectIconWrapper><ItemCardMeta key={record.id} size='large' item={record} view='small' /></InputOrSelectIconWrapper>
        return (
          <InputOrSelectIconWrapper record={record}>
            <Link to={`/admin/ic/templates/question/${record.id}`}>
              <ItemCardMeta key={record.id} size='large' item={record} view='small' />
            </Link>
          </InputOrSelectIconWrapper>
        )
      }
    },
    {
      title: 'Answers',
      dataIndex: 'icanswers',
      sorter: (a, b) => a?.length > b?.length,
      render: (value) => value.length > 0 ? value.length : ''
    },
    {
      title: 'minValue',
      dataIndex: 'minValue'
    },
    {
      title: 'varA',
      dataIndex: 'varA'
    },
    {
      title: 'varB',
      dataIndex: 'varB'
    },
    {
      title: 'varC',
      dataIndex: 'varC'
    },
    {
      title: 'isDominantGroup',
      dataIndex: 'isDominantGroup',
      sorter: (a, b) => a > b,
      render: (value) => value ? 'TRUE' : 'FALSE'
    },
    {
      title: 'Published',
      dataIndex: 'published',
      sorter: (a, b) => a > b,
      render: (value) => value ? 'TRUE' : 'FALSE'
    }
  ]

  return (
    <Listing
      query={QUERY_QUESTIONS}
      columns={columns}
      dataKey='icquestions'
      criterias={{ accountId: memoryUser()?.currentAccountId }}
      defaultSort={{
        sortField: 'name',
        sortDirection: 1
      }}
      extra={extra}
    />
  )
}

export default QuestionList
