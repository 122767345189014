import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Button, Row, Col, Skeleton } from 'antd'

const QuestionnaireFooter = ({ icquestionnaire, prevIcnode, nextIcnode, loading, status }) => {
  const history = useHistory()

  const { id, landingPageRoute } = icquestionnaire

  const route = landingPageRoute || 'ic/questionnaire'

  const onClickPrevPage = () => {
    history.push(`/${route}/${id}/form/${prevIcnode.icnodeId}`)
  }

  const onClickNextPage = () => {
    history.push(`/${route}/${id}/form/${nextIcnode.icnodeId}`)
  }

  return (
    <div style={{ marginTop: '16px' }}>
      <Row>
        <Col span={8}>
          {loading && <Skeleton.Button size='large' />}
          {!loading && prevIcnode &&
            <Button type='primary' htmlType='submit' onClick={onClickPrevPage} disabled={!prevIcnode} size='large'>
              {'< Previous'}
            </Button>}
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'right' }}>
          {loading && <Skeleton.Button size='large' />}
          {!loading && nextIcnode &&
            <Button type='primary' htmlType='submit' onClick={onClickNextPage} disabled={!nextIcnode} size='large'>
              {'Next >'}
            </Button>}
        </Col>
      </Row>
    </div>
  )
}

QuestionnaireFooter.propTypes = {
  icquestionnaire: PropTypes.object.isRequired,
  prevIcnode: PropTypes.object,
  nextIcnode: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string
}

export default QuestionnaireFooter
