import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from
} from '@apollo/client'

import { onError } from '@apollo/client/link/error'

import { returnLocaleStorage, setLocaleStorage } from './appStorage'
import { handleGraphQLErrors, handleNetworkError } from './handleErrors'

import { createUploadLink } from 'apollo-upload-client'

const { REACT_APP_API_URL } = process.env

const returnXTid = () => {
  const tid = returnLocaleStorage('tid')

  if (tid) return tid

  const returnNewTid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))

  const newTid = returnNewTid()
  setLocaleStorage('tid', newTid)

  return newTid
}

const returnXLocale = navigator => (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

const errorLink = onError(args => {
  handleGraphQLErrors(args)
  handleNetworkError(args)
  return args.forward(args.operation)
})

const setHeaders = new ApolloLink((operation, forward) => {
  // 20211126 GF: do not use memory. local token is there when user refreshes. this token is verified immediately after refresh and set again. always use local storage for the app auth stradegy

  const bearerToken = () => returnLocaleStorage('accessToken')

  const clientRequest = {
    'Content-Type': 'application/json',
    'x-locale': returnXLocale(navigator),
    'x-tid': returnXTid(),
    Authorization: `Bearer ${bearerToken() || 'null'}`
  }

  operation.setContext(({ headers }) => ({
    headers: {
      ...clientRequest,
      ...headers
    }
  }))

  return forward(operation)
})

const httpLink = createUploadLink({
  uri: REACT_APP_API_URL
})

export const returnClient = () => new ApolloClient({
  link: from([setHeaders, errorLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include'
})
