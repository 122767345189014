import React from 'react'
import { memoryUser } from '../auth/appMemory'

import VerifyUserEmailAddress from '../models/VerifyUserEmailAddress'
import UserAccountDashboard from '../layout/UserAccountDashboard'
import HomeNoRoles from './HomeNoRoles'

const HomeAuthenticated = () => {
  if (!memoryUser()) return null

  const { currentAccountUserPermissions, emailIsVerified } = memoryUser()

  if (!emailIsVerified) return <VerifyUserEmailAddress user={memoryUser()} title='Security update' subTitle='Verify your email address for secure access to the Impact Calulcator.' />

  const { viewContributor } = currentAccountUserPermissions

  return (
    <>
      {
        !viewContributor &&
          <HomeNoRoles />
      }
      {
        viewContributor &&
          <UserAccountDashboard />
      }
    </>
  )
}

export default HomeAuthenticated
