// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../../core/components/ItemCardMeta'

// ant design
import { Button, Tooltip, Space } from 'antd'

// operations
import { QUERY_SPAWNERS } from '../../../../../operations/queries/ic/spawners'

// components
import { SpawnerLandingPage, getFullLandingPage } from './SpawnerForm'

import QuestionnaireFeedback from '../../../../../components/sections/ic/questionnaire/QuestionnaireFeedback'
import Listing from '../../../../Listing'

const SpawnerList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Icquestionnairespawner')
  const edit = permissions?.menuAccess?.edit.includes('Icquestionnairespawner')
  const list = permissions?.menuAccess?.list.includes('Icquestionnairespawner')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const extra = (
    <>
      {add && <Tooltip title='Add new survey'><Link to='./spawner/add'><Button type='primary'>Add Survey</Button></Link></Tooltip>}
    </>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) {
          return (
            <Space direction='vertical' style={{ width: '100%' }}>
              <ItemCardMeta key={record.id} size='large' item={record} view='small' />
              <SpawnerLandingPage icquestionnairespawner={record} />
            </Space>
          )
        }
        return (
          <Space direction='vertical' style={{ width: '100%' }}>
            <Link to={`/admin/ic/spawner/${record.id}`}>
              <ItemCardMeta key={record.id} size='large' item={record} view='small' />
            </Link>
            <SpawnerLandingPage icquestionnairespawner={record} />
          </Space>
        )
      }
    },
    {
      title: 'Template',
      dataIndex: 'name',
      sorter: (a, b) => a.ictemplate.name.localeCompare(b.ictemplate.name),
      render: (value, record) => <ItemCardMeta key={record.id} size='large' item={record.ictemplate} view='small' />
    },
    {
      title: 'Spawned',
      dataIndex: 'spawnLength',
      sorter: (a, b) => a > b
    },
    {
      title: 'Status',
      dataIndex: 'live',
      sorter: (a, b) => a > b,
      render: (value, record) => {
        const { live, reasons } = record
        const fullLandingPage = getFullLandingPage(record)
        return (
          <>
            <Button type='link' href={fullLandingPage}>

              {live && <div>LIVE</div>}
              {!live && <div>NOT LIVE</div>}

            </Button>
            <QuestionnaireFeedback live={live} reasons={reasons} />
          </>
        )
      }
    }
  ]

  return (
    <Listing
      query={QUERY_SPAWNERS}
      columns={columns}
      dataKey='icquestionnairespawners'
      criterias={{ accountId: memoryUser()?.currentAccountId }}
      defaultSort={{
        sortField: 'name',
        sortDirection: 1
      }}
      extra={extra}
    />
  )
}

export default SpawnerList
