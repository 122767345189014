import React, { useState, useEffect } from 'react'

import { gql, useMutation } from '@apollo/client'

import { reactiveIcquestionnairesPublished } from '../auth/appMemory'

import { Tooltip, Upload, Button, Typography, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'

const { Text } = Typography

const ITEM_UPLOAD = gql`
  mutation itemUpload ($itemUploadInput: ItemUploadInput!) {
    itemUpload (itemUploadInput: $itemUploadInput) {
      filename
      mimetype
      encoding
      url
    }
  }
`

const ItemUpload = ({ item, button, refetch }) => {
  const defaultButtonText = 'Upload'

  const [imageURL, setImageURL] = useState()
  const [buttonText, setButtonText] = useState('Upload')

  const [itemUpload, { data, loading, error }] = useMutation(ITEM_UPLOAD)

  useEffect(() => {
    if (!data?.itemUpload?.url) return
    setImageURL()
    setButtonText(defaultButtonText)
    if (refetch) refetch()
    reactiveIcquestionnairesPublished(false)
    return setImageURL(data.itemUpload.url)
  }, [data, refetch])

  useEffect(() => {
    if (!error?.message) return
    setButtonText(error.message + ' - Try again')
    return setImageURL()
  }, [error])

  const key = 'uploading'

  useEffect(() => {
    if (loading) message.loading({ content: 'Uploading...', key })
  }, [loading, data])

  return (
    <ImgCrop
      quality={1}
      rotate
      grid
      modalTitle='Zoom or rotate before uploading'
    >
      <Upload
        name='file'
        showUploadList={false}
        multiple={false}
        beforeUpload={file => itemUpload({
          variables: {
            itemUploadInput: {
              file,
              item: {
                id: item.id,
                typename: item.__typename
              }
            },
            errorPolicy: 'all'
          }
        })}
      >
        <ButtonToUse loading={loading} buttonText={buttonText} imageURL={imageURL} button={button} />
      </Upload>

    </ImgCrop>
  )
}

export default ItemUpload

const ButtonToUse = ({ loading, buttonText, imageURL, button }) => {
  if (button) {
    return (
      <Tooltip title='Click, or drag image to upload'>
        <Text style={{ cursor: 'pointer' }}>{button}</Text>
      </Tooltip>
    )
  }

  return (
    imageURL
      ? <img src={imageURL} alt='avatar' style={{ width: '100%' }} />
      : <Button loading={loading} icon={<UploadOutlined />}>{buttonText}</Button>

  )
}

// <input type='file' required onChange={onChange} />
// const onChange = ({
//   target: {
//     validity,
//     files: [file]
//   }
// }) => validity.valid && mutate({ variables: { file } })
