// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../core/components/ItemCardMeta'

// ant design
import { Card, Button, Tooltip } from 'antd'

// operations
import { QUERY_ACCOUNT_GROUPS } from '../../../../operations/queries/accountGroups'

// components
import Listing from '../../../Listing'

const AccountGroupList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Accountgroup')
  const edit = permissions?.menuAccess?.edit.includes('Accountgroup')
  const list = permissions?.menuAccess?.list.includes('Accountgroup')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const extra = (
    <>
      {add && <Tooltip title='Add new accountGroup'><Link to='/admin/accountgroup/add'><Button type='primary'>Add Account group</Button></Link></Tooltip>}
    </>
  )

  const columns = [
    {
      title: 'Account Group',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) return <ItemCardMeta key={record.id} size='large' item={record} view='small' />
        return (
          <Link to={`/admin/accountgroup/${record.id}`}>
            <ItemCardMeta key={record.id} size='large' item={record} view='small' />
          </Link>
        )
      }
    }
    // {
    //   title: 'Account',
    //   dataIndex: 'account',
    //   render: (value, record) => <div>{value.name}</div>
    // }
    // {
    //   title: 'User(s)',
    //   dataIndex: 'accountgroupUsers',
    //   render: value => (
    //     <Space direction='vertical'>
    //       {
    //         value.map(i => <Space key={Math.random()}>{i.email}</Space>)
    //       }
    //     </Space>
    //   )
    // },
    // {
    //   title: 'Questionnaire(s)',
    //   dataIndex: 'icquestionnaires',
    //   render: value => (
    //     <Space direction='vertical'>
    //       {
    //         value.map(i => <Space key={Math.random()}>{i.name}</Space>)
    //       }
    //     </Space>
    //   )
    // }
  ]

  return (
    <Listing
      query={QUERY_ACCOUNT_GROUPS}
      columns={columns}
      dataKey='accountgroups'
      criterias={{ accountId: memoryUser()?.currentAccountId }}
      defaultSort={{
        sortField: 'name',
        sortDirection: 1
      }}
      extra={extra}
      render={(table) => {
        return (
          <>
            <div className='page-container'>
              <Card title='Groups' bordered={false} extra={extra}>
                {table}
              </Card>
            </div>
          </>
        )
      }}
    />
  )
}

export default AccountGroupList
