// import React from 'react'
import PropTypes from 'prop-types'

// ant design
// import { Form } from 'antd'

const FormItemsSuperAdmin = ({ data, hasHidden, itemsCreating, itemsUpdating }) => {
  // this is great! but it needs to be adjusted to use
  return null

  // return (
  //   <>
  //     <>
  //       {data.id && (
  //         <>
  //           <Form.Item
  //             className='form-item-text'
  //             label='Created by'
  //             wrapperCol={{ sm: 20 }}
  //           >
  //             <span className='ant-form-text'>{data.createdBy}</span>
  //           </Form.Item>
  //           <Form.Item
  //             className='form-item-text'
  //             label='Created at'
  //             wrapperCol={{ sm: 20 }}
  //           >
  //             <span className='ant-form-text'>{data.createdAt} ({data.createdAtAgo})</span>
  //           </Form.Item>
  //           <Form.Item
  //             className='form-item-text'
  //             label='Updated at'
  //             wrapperCol={{ sm: 20 }}
  //           >
  //             <span className='ant-form-text'>{data.updatedAt}  ({data.updatedAtAgo})</span>
  //           </Form.Item>
  //           {itemsCreating && itemsCreating}
  //         </>
  //       )}
  //       {data.id && itemsUpdating}
  //       {/*
  //         hasHidden &&
  //         <Form.Item
  //           className='form-item-text'
  //           label='Deleted'
  //           name='hidden'
  //           valuePropName='checked'
  //           wrapperCol={{ sm: 20 }}
  //         >
  //           <Switch />
  //         </Form.Item>
  //       */}
  //     </>
  //   </>
  // )
}

FormItemsSuperAdmin.propTypes = {
  data: PropTypes.object,
  hasHidden: PropTypes.bool,
  itemsCreating: PropTypes.element,
  itemsUpdating: PropTypes.element
}

export default FormItemsSuperAdmin
