import React from 'react'
import SimpleLink from '../components/SimpleLink'

import { Result, Space } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const VerifyUserEmailAddress = ({ title = 'Welcome!', subTitle }) => (
  <Result
    status='success'
    title={title}
    subTitle={subTitle}
    extra={
      <SimpleLink
        to='/verifyme'
        content={
          <Space>
            <MailOutlined />
            <>Get verified</>
          </Space>
        }
        size='large'
        type='default'
      />
    }
  />
)

export default VerifyUserEmailAddress
