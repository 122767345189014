import { gql } from '@apollo/client'

export const ACCOUNT_GROUPS_FIELDS = `
  id
  key
  accountId
  hidden
  name
  published
  account {
    id
    name
  }
  accountgroupUsers {
    id
    email
  }
  icquestionnaires {
    id
    name
  }
  note
  imageURL
  cloudinaryImage
  privateNote
`

export const QUERY_ACCOUNT_GROUPS = gql`
query(
  $filter: Filter
  $sortField: String
  $sortDirection: Int
) {
   accountgroups(
     filter: $filter
     sortField: $sortField
     sortDirection: $sortDirection
   )
  {
    ${ACCOUNT_GROUPS_FIELDS}
  }
}
`

export const ACCOUNT_GROUP_FIELDS = `
  id
  accountId
  hidden
  name
  published
  account {
    id
    name
  }
  accountgroupUsers {
    id
    email
  }
  icquestionnaires {
    id
    name
  }
  note
  imageURL
  cloudinaryImage
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "mmm dd yyyy HH:MM:ss")
  updatedAt (format: "mmm dd yyyy HH:MM:ss")
`

export const QUERY_ACCOUNT_GROUP = gql`
query AccountGroup($id: ID!) {
  accountgroup (id: $id) {
    ${ACCOUNT_GROUP_FIELDS}
  }
}
`
