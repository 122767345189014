import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { memoryUser, refreshUser } from '../auth/appMemory'

import { useHistory } from 'react-router-dom'

import AuthenticatedDropdown from './AuthenticatedDropdown'
import ItemCardMeta from '../components/ItemCardMeta'

// ant design
import { Button, Space, Menu, Dropdown, Spin } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

const NotAuthenticatedDropdown = ({ history }) => {
  return (
    <Menu theme='dark'>
      <Menu.Item key='in' onClick={() => history.push('/signin')}>
        Sign in
      </Menu.Item>
      <Menu.Item key='up' onClick={() => history.push('/signup')}>
        NEW? Sign up
      </Menu.Item>
    </Menu>
  )
}

const DropDownOverlay = ({ user }) => {
  const history = useHistory()

  return (
    <Space size='middle' style={{ height: '100%' }}>
      {
        !user &&
          <NotAuthenticatedDropdown history={history} />
      }
      {
        user &&
          <AuthenticatedDropdown history={history} user={user} />
      }
    </Space>
  )
}

const UserOptionsDropdownMenu = () => {
  const user = useReactiveVar(memoryUser)

  const refresh = useReactiveVar(refreshUser)

  if (!user) return null

  return (
    <Dropdown
      overlayClassName='headerDropdown ant-dropdown-dark'
      overlay={<DropDownOverlay user={user} />}
    >
      <Button className='ant-btn-dark' type='link' style={{ padding: 0 }}>
        <Space>
          {
            refresh &&
              <Spin size='small' style={{ paddingTop: 6 }} />
          }
          <ItemCardMeta item={{ ...user, hideTip: true }} size='small' view='small' />

          <CaretDownOutlined style={{ verticalAlign: 'middle' }} />

        </Space>
      </Button>

    </Dropdown>
  )
}

export default UserOptionsDropdownMenu
