import { gql } from '@apollo/client'

export const CATEGORIES_FIELDS = `
  id
  key
  accountId
  name
  note
  imageURL
  cloudinaryImage
  privateNote
  published
  hidden
`

export const QUERY_CATEGORIES = gql`
query(
  $filter: Filter
  $sortField: String
  $sortDirection: Int
  $pageSize: Int
) {
  iccategorys(
    filter: $filter
    sortField: $sortField
    sortDirection: $sortDirection
    pageSize: $pageSize
  ) {
    ${CATEGORIES_FIELDS}
  }
}
`

export const CATEGORY_FIELDS = `
  id
  accountId
  name
  note
  imageURL
  cloudinaryImage
  privateNote
  hidden
  published
  ictemplates {
    id
    name
    hidden
  }
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "mmm dd yyyy HH:MM:ss")
  updatedAt (format: "mmm dd yyyy HH:MM:ss")
`

export const QUERY_CATEGORY = gql`
query User($id: ID!) {
  iccategory (id: $id) {
    ${CATEGORY_FIELDS}
  }
}
`
