import { useReactiveVar } from '@apollo/client'
import { memoryAccessToken, refreshUser, memoryUser } from './appMemory'
import { returnLocaleStorage, setLocaleStorage } from './appStorage'

const TokenChecker = ({ children }) => {
  const sessionAccessToken = useReactiveVar(memoryAccessToken)
  const storageAccessToken = returnLocaleStorage('accessToken')
  const isRefreshing = refreshUser()

  if (sessionAccessToken && !storageAccessToken) {
    // console.log('sessionAccessToken', sessionAccessToken)
    // console.log('TokenChecker', 'setLocaleStorage to', sessionAccessToken)
    !isRefreshing && setLocaleStorage('accessToken', sessionAccessToken)
  }

  if (storageAccessToken && !sessionAccessToken) {
    // console.log('storageAccessToken', storageAccessToken)
    // console.log('TokenChecker', 'set memoryAccessToken to', storageAccessToken)
    !isRefreshing && memoryAccessToken(storageAccessToken)
  }

  if (memoryAccessToken() && !memoryUser()) {
    !isRefreshing && refreshUser('Verifying local token...')
  }

  return children
}

export default TokenChecker
