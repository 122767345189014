import React from 'react'

import { Result } from 'antd'

import Logo from '../components/Logo'
import CenterContent from '../components/CenterContent'
import SimpleLink from '../components/SimpleLink'

const { REACT_APP_TITLE } = process.env

const HomeNotAuthenticated = () => {
  return (
    <CenterContent>
      <Result
        style={{ paddingTop: 0 }}
        icon={
          <div className='result-logo'>
            <Logo color='true' />
          </div>
        }
        title={`Welcome to ${REACT_APP_TITLE}`}
        subTitle='We currently have no content to display here.'
        extra={
          <SimpleLink
            size='large'
            type='primary'
            to='/signin'
            content='Sign In'
          />
        }
      />
    </CenterContent>
  )
}

export default HomeNotAuthenticated
