import React from 'react'
import { useLocation } from 'react-router-dom'

import Logo from '../components/Logo'

// ant design
import { Layout, Row, Col } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'

import LiveQuestionnairesMenu from './LiveQuestionnairesMenu'
import UserOptionsDropdownMenu from './UserOptionsDropdownMenu'

const TopHeader = ({ collapsed, setCollapsed }) => {
  const location = useLocation()
  const locationRoutes = location.pathname.split('/')
  const ignoreRoutes = ['link', 'survey'] // dont show left menu for survey landing page or survey
  if (locationRoutes[1] && ignoreRoutes.includes(locationRoutes[1])) return null

  return (
    <Layout.Header className='header'>
      <Row align='middle'>
        <Col flex='auto'>

          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>

            {
              React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: () => setCollapsed(true),
                  style: { color: '#fff' }
                }
              )
            }

            <div className='header-logo'>
              <Logo />
            </div>

            <div style={{ flexGrow: '2' }}>
              <LiveQuestionnairesMenu parent='TopHeader' mode='horizontal' theme='dark' className='headerMenu' />
            </div>

          </div>

        </Col>

        <Col flex='1' align='right'>
          <UserOptionsDropdownMenu />
        </Col>

      </Row>

      {/*

        <div style={{ flexGrow: '2' }}>
          <HeaderMenu mode='horizontal' theme='dark' className='headerMenu' />
        </div>

      */}

    </Layout.Header>

  )
}

export default TopHeader

/*
GF 20211104: to be implemented anoth day per ken
<Menu>
  <Menu.Item key='topin' onClick={() => history.push('/signin')}>
    Sign in
  </Menu.Item>
  <Menu.Divider />
  <Menu.Item key='topup' onClick={() => history.push('/signup')}>
    Sign up
  </Menu.Item>
</Menu>

const languagesMenu = (
  <Menu>
    <Menu.Item key='English'><Button type='primary' size='small' onClick={e => e.preventDefault()}>EN</Button> English</Menu.Item>
    <Menu.Item key='French'><Button type='primary' size='small' onClick={e => e.preventDefault()}>FR</Button> French</Menu.Item>
  </Menu>
)
*/
