// react
import React, { useState, useEffect } from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { memoryUser, reactiveIcquestionnairesPublished } from '../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../core/components/ItemCardMeta'

import VerifyUserEmailAddress from '../../../../core/models/VerifyUserEmailAddress'

// ant design
import { Card, List, Space, Button, Typography, Modal, Input, notification, Tooltip, Skeleton, Row, Col, Popover } from 'antd'

// operations
import {
  MUTATE_QUESTIONNAIRE_TOGGLE_LOCKED,
  MUTATE_QUESTIONNAIRE_FINALIZATION_REQUEST,
  MUTATE_QUESTIONNAIRE_FINALIZATION_CANCEL_REQUEST,
  MUTATE_QUESTIONNAIRE_FINALIZATION_ACCEPT_REQUEST,
  MUTATE_QUESTIONNAIRE_FINALIZATION_REJECT_REQUEST
} from '../../../../operations/queries/ic/questionnaires'

const { Meta } = Card
const { Text } = Typography
const { TextArea } = Input

const width = 330

const QuestionnaireLiveList = ({ title }) => {
  if (!memoryUser().emailIsVerified) {
    return <VerifyUserEmailAddress user={memoryUser()} title='Security update required' subTitle='You will have permission to contribute after you verify your email address.' />
  }

  const icquestionnairesPublished = useReactiveVar(reactiveIcquestionnairesPublished)

  if (!icquestionnairesPublished) return <Skeleton />

  return (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>

      <Row
        wrap
        justify='left'
      >

        {
          icquestionnairesPublished.length === 0 &&
            <>There are no live questionnaires right now.</>
        }

        {
          icquestionnairesPublished.map(icquestionnaire => (
            <LiveQuestionnaiteCard
              key={`icquestionnairePublished${icquestionnaire.id}`}
              icquestionnaire={icquestionnaire}
              icquestionnairesPublished={icquestionnairesPublished}
            />
          ))
        }
      </Row>

    </Space>
  )
}

export default QuestionnaireLiveList

const LiveQuestionnaiteCard = ({ icquestionnaire, icquestionnairesPublished }) => {
  const { id, name, note, currentUserAccountPermissions, live } = icquestionnaire
  const { viewLocationsAdministrator, viewAccountAdministrator } = currentUserAccountPermissions

  return (
    <Col
      style={{ padding: 6 }}
    >

      <Card
        style={{ width }}
        hoverable
        cover={
          <Link to={`/ic/questionnaire/${id}`}>
            <ItemCardMeta width={width} item={icquestionnaire} view='questionnaireLiveImage' />
          </Link>
        }
        actions={viewLocationsAdministrator && [
          <Popover
            key='locationAdminSettings'
            content={
              <IcQuestionnaireStatus
                icquestionnaire={icquestionnaire}
                refetchListing={() => reactiveIcquestionnairesPublished(null)}
              />
            }
            trigger='hover'
          >
            <Button type='link' danger={!live}>
              {
                viewAccountAdministrator
                  ? 'Account '
                  : 'Location '
              }
              Administrator Panel
            </Button>
          </Popover>
        ]}
      >
        <Link to={`/ic/questionnaire/${id}`}>
          <Meta
            title={name}
            description={(
              <Typography.Paragraph
                style={{ fontSize: 12 }}
                ellipsis={{
                  rows: 2
                }}
              >
                {note}
              </Typography.Paragraph>
            )}
          />
        </Link>
      </Card>

    </Col>
  )
}

const IcQuestionnaireStatus = ({ icquestionnaire: icquestionnaireInit, refetchListing }) => {
  const [icquestionnaire, setIcquestionnaire] = useState(icquestionnaireInit)

  const refreshStatus = value => setIcquestionnaire(value)

  const { id, live, feedback, currentUserAccountPermissions } = icquestionnaire
  const { viewAccountAdministrator } = currentUserAccountPermissions

  return (
    <List itemLayout='horizontal'>
      {
        live &&
          <List.Item>
            <Row justify='center' style={{ width: '100%' }}>
              <Text style={{ fontSize: 24 }}>Questionniare is Live </Text>
            </Row>

          </List.Item>
      }

      {
        !live &&
          <List.Item key={`issues${id}`}>
            <Space direction='vertical' size='small'>
              <Text type='danger' strong>This questionnaire is not live</Text>
              <>
                {
                  feedback?.issues &&
                    feedback.issues.map((issue, index) => <Text key={`issue${id}${index}`} type='danger'>{issue}</Text>)
                }
              </>
            </Space>
          </List.Item>
      }

      <List.Item>
        <IcquestionnaireUtilities icquestionnaire={icquestionnaireInit} refreshStatus={refreshStatus} refetchListing={refetchListing} />
      </List.Item>

      {
        viewAccountAdministrator &&
          <List.Item>
            <IcquestionnaireLinks icquestionnaire={icquestionnaire} />
          </List.Item>
      }

    </List>
  )
}

const IcquestionnaireUtilities = ({ icquestionnaire: icquestionnaireInit, refreshStatus, refetchListing }) => {
  const [icquestionnaire, setIcquestionnaire] = useState(icquestionnaireInit)
  const [finalizationRejectModalVisible, setFinalizationRejectModalVisible] = useState(false)
  const [finalizationRejectedNote, setFinalizationRejectedNote] = useState('')
  const [finalizationRejectedErrorVisible, setFinalizationRejectedErrorVisible] = useState(false)
  const [finalizationRejectButtonDisabled, setFinalizationRejectButtonDisabled] = useState(false)

  const [mutationToggleLocked, { loading: lockedLoading }] = useMutation(MUTATE_QUESTIONNAIRE_TOGGLE_LOCKED)
  const [mutationfFinalizationRequest, { loading: finalizationRequestLoading }] = useMutation(MUTATE_QUESTIONNAIRE_FINALIZATION_REQUEST)
  const [mutationfFinalizationCancel, { loading: finalizationCancelLoading }] = useMutation(MUTATE_QUESTIONNAIRE_FINALIZATION_CANCEL_REQUEST)
  const [mutationfFinalizationAccept, { loading: finalizationAcceptLoading }] = useMutation(MUTATE_QUESTIONNAIRE_FINALIZATION_ACCEPT_REQUEST)
  const [mutationfFinalizationReject, { loading: finalizationRejectLoading }] = useMutation(MUTATE_QUESTIONNAIRE_FINALIZATION_REJECT_REQUEST)

  const { currentUserAccountPermissions } = icquestionnaireInit
  const { viewAccountAdministrator } = currentUserAccountPermissions

  useEffect(() => {
    refreshStatus(icquestionnaire)
  }, [icquestionnaire])

  const toggleLocked = async () => {
    const { data } = await mutationToggleLocked({ variables: { icquestionnaireId: icquestionnaire.id } })
    data && setIcquestionnaire(data.toggleLocked)
  }

  const finalizationRequest = async () => {
    try {
      const { data } = await mutationfFinalizationRequest({ variables: { icquestionnaireId: icquestionnaire.id } })
      data && setIcquestionnaire(data.finalizationRequest)
    } catch (err) {
      notification.warning(
        {
          message: 'Incomplete data',
          description: err.message
        }
      )
    }
  }

  const finalizationCancel = async () => {
    const { data } = await mutationfFinalizationCancel({ variables: { icquestionnaireId: icquestionnaire.id } })
    data && setIcquestionnaire(data.finalizationCancelRequest)
  }

  const finalizationAccept = async () => {
    // refetchListing()
    const { data } = await mutationfFinalizationAccept({ variables: { icquestionnaireId: icquestionnaire.id } })
    if (data) {
      setIcquestionnaire(data.finalizationAcceptRequest) // not necessary to get/set new data since this will remove from list via refetchListing()
      refetchListing()
    }
  }

  const finalizationReject = async () => {
    setFinalizationRejectButtonDisabled(true)
    setFinalizationRejectedErrorVisible(false)
    if (finalizationRejectedNote.length === 0) {
      setFinalizationRejectedErrorVisible(true)
    } else {
      const { data } = await mutationfFinalizationReject({ variables: { icquestionnaireId: icquestionnaire.id, finalizationRejectedNote } })
      data && setIcquestionnaire(data.finalizationRejectRequest)
    }
    setFinalizationRejectButtonDisabled(false)
    setFinalizationRejectModalVisible(false)
  }

  return (
    <>
      {
        icquestionnaire.lastFinalizationRejectedNote &&
          <Row wrap>
            <Text type='warning'>
              Rejection reason:
              <Text italic> {icquestionnaire.lastFinalizationRejectedNote}</Text>
            </Text>
          </Row>
      }

      <Space size='small'>
        <Button
          loading={lockedLoading}
          onClick={toggleLocked}
          type='primary'
          size='small'
          danger={icquestionnaire.locked}
        >
          {
            icquestionnaire.locked ? 'Unlock questionnaire' : 'Lock questionnaire'
          }
        </Button>

        {
          icquestionnaire.canRequestFinalization &&
            <Button
              loading={finalizationRequestLoading}
              onClick={finalizationRequest}
              type='default'
              size='small'
            >
              Request finalization
            </Button>
        }

        {
          icquestionnaire.canCancelFinalization &&
            <Button
              loading={finalizationCancelLoading}
              onClick={finalizationCancel}
              type='default'
              size='small'
              ghost={!icquestionnaire.locked}
            >
              Cancel request for finalization
            </Button>
        }

        {
          (viewAccountAdministrator && icquestionnaire.canAcceptOrRejectFinalization) &&
            <Space size='small' wrap>
              <Button
                loading={finalizationAcceptLoading}
                type='link'
                onClick={finalizationAccept}
                size='small'
                ghost={!icquestionnaire.locked}
              >
                Finalize
              </Button>
              <Button
                loading={finalizationRejectLoading}
                type='link'
                onClick={() => setFinalizationRejectModalVisible(true)}
                size='small'
                ghost={!icquestionnaire.locked}
              >
                Reject
              </Button>

              <Modal
                title='A rejection reason is required'
                visible={finalizationRejectModalVisible}
                onOk={() => finalizationReject()}
                okButtonProps={finalizationRejectButtonDisabled}
                onCancel={() => setFinalizationRejectModalVisible(false)}
              >
                <TextArea onChange={event => setFinalizationRejectedNote(event.target.value)} />

                {
                  finalizationRejectedErrorVisible &&
                    <Text type='warning'>The administrator will find any information you provide useful.</Text>
                }

              </Modal>
            </Space>
        }

      </Space>
    </>

  )
}

const IcquestionnaireLinks = ({ icquestionnaire }) => {
  const { ictemplateId, ictemplate } = icquestionnaire

  return (
    <Space size='small'>

      <Button
        type='link'
        size='small'
      >
        <Tooltip title='Edit questionnaire'>
          <Link to={`/admin/ic/questionnaire/${icquestionnaire.id}`}>
            <ItemCardMeta key='linkeditquestionnaite' item={icquestionnaire} view='small' size='small' bordered='true' />
          </Link>
        </Tooltip>
      </Button>

      <Button
        type='link'
        size='small'
      >
        <Tooltip title='Edit template'>
          <Link to={`/admin/ic/template/${ictemplateId}`}>
            <ItemCardMeta key='linkedittemplate' item={ictemplate} view='small' size='small' bordered='true' />
          </Link>
        </Tooltip>
      </Button>
    </Space>
  )
}
