import React, { useState, useEffect } from 'react'

// import { memoryUser } from '../auth/appMemory'
import { variables } from '../consts'

import { gql, useQuery } from '@apollo/client'
import { PlayCircleTwoTone } from '@ant-design/icons'

import RouteErrorDisplay from '../components/RouteErrorDisplay'
import { LinkHandler } from '../models/OnboardingView'

import { List, Typography, Skeleton, Space } from 'antd'

const QUERY_POST_BETA_MESSAGES = gql`
  query allPostBetaMessages {
    postBetaMessages {
      title
      bullets
    }
  }
`

const ProgramDetails = () => {
  const { data, error, loading } = useQuery(QUERY_POST_BETA_MESSAGES, { variables })
  const [messages, setMessages] = useState()

  useEffect(() => {
    if (data?.postBetaMessages) setMessages(data.postBetaMessages)
  }, [data])

  if (error) return <RouteErrorDisplay error={error} />
  if (loading) return <Skeleton />

  return (
    <>
      {
        messages?.length > 0 &&
          <List
            itemLayout='horizontal'
            dataSource={messages}
            renderItem={item => (
              <List.Item
                extra={<ListActions item={item} />}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={<PlayCircleTwoTone style={{ fontSize: 18 }} />}
                    title={<Typography.Text>{item.title}</Typography.Text>}
                    description={(
                      <Space direction='vertical' size='small'>
                        {
                          item.bullets.map(i => <Typography.Text key={i}>• {i}</Typography.Text>)
                        }
                      </Space>
                    )}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
      }

    </>
  )
}

export default ProgramDetails

const ListActions = ({ item }) => {
  if (!item.actions) return null
  return (
    <Space>
      {
          item.actions.map(action => <LinkHandler key={action.to} to={action.to} text={action.text} />)
      }
    </Space>
  )
}
