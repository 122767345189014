import React from 'react'
import { useParams } from 'react-router-dom'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_SPAWNER_SURVEY_LANDING } from '../../../../operations/queries/ic/questionnaires'
import { SpawnerLoader, SpawnerError } from './SpawnerLandingLink'

import QuestionnaireLive from './QuestionnaireLive'

// logo
import Logo from '../../../../assets/images/logo.png'

const { REACT_APP_QUESTIONNAIRESPAWNED_ROUTE_NAME } = process.env

const SpawnedLandingSurvey = () => {
  const { id } = useParams()
  const landingPageRoute = REACT_APP_QUESTIONNAIRESPAWNED_ROUTE_NAME

  const variables = {
    id,
    icquestionnairespawnedSurveyLandingInput: { landingPageRoute }
  }

  const { data, loading, error } = useQuery(QUERY_SPAWNER_SURVEY_LANDING, { variables })

  return (
    <div>

      <div style={{ textAlign: 'center', margin: '12px 0' }}><img src={data ? data.icquestionnairespawnedSurveyLanding.icquestionnairespawnerImageURL : Logo} width='150px' height='auto' /></div>

      <div style={{ marginTop: '12px' }}>
        <div className='page-padding-leftRight page-padding-topBottom'>
          <div className='page-container'>
            {loading && <SpawnerLoader message='Loading...' />}
            {error && <SpawnerError error={error} />}
          </div>
          {data && <QuestionnaireLive icquestionnaireId={id} data={data} />}
        </div>
      </div>
    </div>
  )
}

export default SpawnedLandingSurvey
