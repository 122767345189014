import React, { useEffect, useState } from 'react'

import { Form, message } from 'antd'

import { gql, useMutation } from '@apollo/client'

import FormWrapper from '../../layout/FormWrapper'
import InvitationFormItems from './InvitationFormItems'

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const UPDATE_INVITE = gql`
  mutation updateInvite ($id: ID!, $inviteInput: InviteInput!) {
    updateInvite (id: $id, inviteInput: $inviteInput) {
      id
    }
  }
`

const InvitationEdit = ({ record, account, refetch, setVisisble }) => {
  const emailNowWithFreshToken = true

  const [form] = Form.useForm()

  const [updateInvite, { data, loading, error }] = useMutation(UPDATE_INVITE)
  const [customerror, setCustomerror] = useState()

  useEffect(() => {
    if (!data?.updateInvite?.id) return
    if (emailNowWithFreshToken) message.success('Updated invitation resent to invitee.')
    setVisisble(false)
    return refetch()
  }, [data, refetch])

  useEffect(() => {
    if (!error) return
    if (error?.message === 'Invite was accepted') {
      setCustomerror('This email address has accepted an invitation sent from your account previously. User management allows you to add and remove users in your account.')
      refetch()
    }
    if (error?.message === 'Invite already used') return setCustomerror('This email address has accepted an invitation sent from your account previously. User management allows you to add and remove users in your account.')
  }, [error])

  const { id: accountId } = account
  const { id } = record

  return (
    <FormWrapper
      size='medium'
      form={form}
      preserve={false}
      initialValues={record}
      onFinish={(values) => updateInvite({
        variables: {
          id,
          inviteInput: {
            ...values,
            accountId,
            domainName,
            emailNowWithFreshToken
          }
        },
        errorPolicy: 'all'
      })}
      loading={loading}
      error={error}
      customerror={customerror}
      fluid='true'
    >

      <InvitationFormItems account={account} />

    </FormWrapper>
  )
}

export default InvitationEdit
