// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../core/auth/appMemory'

// ant design
import { Modal, Form, Input } from 'antd'

// apollo & operations
import { useMutation } from '@apollo/client'
import { QUERY_TEMPLATES } from '../../../../../operations/queries/ic/templates'
import { INSERT_TEMPLATE } from '../../../../../operations/mutations/ic/templates'

// other libraries
import formatErrors from '../../../../../utils/form'
import FormItemError from '../../../../elements/formItems/FormItemError'

const TemplateCreateModal = ({ visible, onCreate, onCancel }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [insertTemplate] = useMutation(INSERT_TEMPLATE, {
    update (cache, { data: { insertIctemplate } }) {
      const data = cache.readQuery({ query: QUERY_TEMPLATES })
      cache.writeQuery({
        query: QUERY_TEMPLATES,
        data: { ictemplates: !data ? [insertIctemplate] : data.ictemplates.concat([insertIctemplate]) }
      })
    }
  })

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  return (
    <Modal
      visible={visible}
      title='Add a new template'
      okText='Add'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={async () => {
        try {
          const values = await form.validateFields()
          const response = await insertTemplate({ variables: { accountId: memoryUser().currentAccountId, ...values } })
          form.resetFields()
          history.push('/admin/ic/template/' + response.data.insertIctemplate.id)
        } catch (e) {
          setErrors(formatErrors(e))
        }
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          name: ''
        }}
      >
        <Form.Item
          label='Name'
          name='name'
          hasFeedback
          validateStatus={itemErrors.name ? 'error' : ''}
          help={itemErrors.name}
        >
          <Input />
        </Form.Item>
        <FormItemError error={formError} />
      </Form>
    </Modal>
  )
}
TemplateCreateModal.propTypes = {
  onCreate: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool

}

export default TemplateCreateModal
