import React from 'react'

import { Space, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const ReportLoading = ({ message }) => (
  <Space className='loading' direction='vertical'>
    <Typography.Title level={5}>{message}</Typography.Title>
    <LoadingOutlined style={{ fontSize: '32px' }} />
  </Space>
)

export default ReportLoading
