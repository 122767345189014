// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../core/auth/appMemory'
import CheckCurrentAccount from '../../../../../core/auth/CheckCurrentAccount'
import ItemCardMeta from '../../../../../core/components/ItemCardMeta'

// ant design
import { Button, Tooltip, Space } from 'antd'

// operations
import { QUERY_QUESTIONNAIRES } from '../../../../../operations/queries/ic/questionnaires'

// components
import Listing from '../../../../Listing'
// import { notificationWarn } from '../../../../../utils/notification'

const QuestionnaireList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Icquestionnaire')
  const edit = permissions?.menuAccess?.edit.includes('Icquestionnaire')
  const list = permissions?.menuAccess?.list.includes('Icquestionnaire')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const extra = (
    <>
      {add && <Tooltip title='Add new questionnaire'><Link to='/admin/ic/questionnaire/add'><Button type='primary'>Add Questionnaire</Button></Link></Tooltip>}
    </>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) return <ItemCardMeta key={record.id} size='large' item={record} view='small' />
        return (
          <Link to={`/admin/ic/questionnaire/${record.id}`}>
            <ItemCardMeta key={record.id} size='large' item={record} view='small' />
          </Link>
        )
      }
    },
    // {
    //   title: 'Start date',
    //   dataIndex: 'startDate',
    //   sorter: (a, b) => a.startDate.localeCompare(b.startDate)
    // },
    // {
    //   title: 'End date',
    //   dataIndex: 'endDate',
    //   sorter: (a, b) => a.startDate.localeCompare(b.endDate)
    // },
    {
      title: 'Reporting date',
      dataIndex: 'reportingDate',
      sorter: (a, b) => a.reportingDate.localeCompare(b.reportingDate)
    },
    {
      title: 'Template',
      dataIndex: 'ictemplate',
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      render: (value, record) => {
        if (!value) return ''
        const { issuesCount } = value?.feedback || 0
        return (
          <Space direction='vertical'>
            <Link key={value.id} to={`/admin/ic/template/${value.id}`}>
              <ItemCardMeta key={value.id} size='large' item={value} view='small' />
            </Link>
            {
              issuesCount > 0 &&
                <div>
                  {`${issuesCount} issue${issuesCount > 1 ? 's' : ''} to be fixed`}
                </div>

            }
          </Space>
        )
      }
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'feedback',
    //   render: (value, record) => {
    //     if (record.live) return <Alert banner='true' message='LIVE' type='success' showIcon='false' />
    //     if (!record.live) {
    //       const issuesCount = record.feedback.issuesCount || '?'
    //       return <Alert banner='true' message='PAUSED' description={`${issuesCount} issue${issuesCount === 1 ? '' : 's'} to be fixed`} type='info' showIcon='false' />
    //     }
    //   }
    // }
    {
      title: 'Status',
      dataIndex: 'lastFinalizationStatus',
      render: (value, record) => {
        const { lastFinalizationStatus, lastFinalizationDateAgo, live } = record
        return (
          <>
            <Link to={`/ic/questionnaire/${record.id}`}>

              {live && <div>LIVE</div>}
              {!live && <div>NOT LIVE</div>}

            </Link>
            <div>{lastFinalizationStatus}</div>
            <div>{lastFinalizationDateAgo}</div>
          </>
        )
      }
    }

    // ,
    // {
    //   title: 'Users',
    //   dataIndex: 'userRoles',
    //   render: (value, record) => {
    //     const { locationGeneralUserIds, locationsAdminUserIds, isIcquestionnaireAdmin } = value
    //     return (
    //       <>
    //         <div>{`${locationGeneralUserIds.length} general user${locationGeneralUserIds.length === 1 ? '' : 's'}`}</div>
    //         <div>{`${locationsAdminUserIds.length} administrator${locationsAdminUserIds.length === 1 ? '' : 's'}`}</div>
    //         {
    //           isIcquestionnaireAdmin &&
    //             <Tag>
    //               You are administrator
    //             </Tag>
    //         }
    //       </>
    //     )
    //   }
    // }
  ]

  return (
    <>
      <CheckCurrentAccount />
      <Listing
        query={QUERY_QUESTIONNAIRES}
        columns={columns}
        dataKey='icquestionnaires'
        criterias={{ accountId: memoryUser()?.currentAccountId }}
        defaultSort={{
          sortField: 'reportingDate',
          sortDirection: 1
        }}
        extra={extra}
      />
    </>
  )
}

export default QuestionnaireList
