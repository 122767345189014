import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { gql, useMutation } from '@apollo/client'

import { ME_FIELDS } from '../consts'
import { refreshUser } from '../auth/appMemory'

import { Form, Input, Typography } from 'antd'

import FormWrapper from '../layout/FormWrapper'

const CREATE_ACCOUNT_FOR_ME = gql`
  mutation createAccountForMe ($createAccountForMeInput: CreateAccountForMeInput!) {
    createAccountForMe (createAccountForMeInput: $createAccountForMeInput) {
      ${ME_FIELDS}
    }
  }
`

const heading = (
  <Typography.Paragraph>Add an account to get create your own Impact Calulcator.</Typography.Paragraph>
)

const AccountAdd = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [createAccountForMe, { data, loading, error }] = useMutation(CREATE_ACCOUNT_FOR_ME)

  useEffect(() => {
    if (data?.createAccountForMe?.id) {
      refreshUser('AccountAdd, account was just added')
      history.push('/admin/accountsettings')
    }
  }, [data])

  return (
    <FormWrapper
      heading={heading}
      title='Add account'
      size='large'
      form={form}
      onFinish={(values) => createAccountForMe({
        variables: { createAccountForMeInput: values },
        errorPolicy: 'all'
      })}
      loading={loading}
      error={error}
    >

      <Form.Item
        name='name'
        label='Name'
      >
        <Input />
      </Form.Item>

    </FormWrapper>
  )
}

export default AccountAdd
