import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, Prompt } from 'react-router-dom'

import Loading from '../../../../components/Loading'

// ant design
import { Alert, Card, Space } from 'antd'

// components
import QuestionnaireHome from './QuestionnaireHome'
import QuestionnaireHeader from './QuestionnaireHeader'
import QuestionnaireFooter from './QuestionnaireFooter'
import QuestionnaireForm from './QuestionnaireForm'
import QuestionnaireFeedback from './QuestionnaireFeedback'

const QuestionnaireLive = ({ icquestionnaireId, loading, error, data }) => {
  const { icnodeId: urlParamIcnodeId } = useParams()

  const [questionnaireData, setQuestionnaireData] = useState()
  const [currentIcnode, setCurrentIcnode] = useState()
  const [prevIcnode, setPrevIcnode] = useState()
  const [nextIcnode, setNextIcnode] = useState()
  const [status, setStatus] = useState('')
  const [retries, setRetries] = useState(0)
  const [percent, setPercent] = useState(0)
  const [formsErrors, setFormsErrors] = useState({})
  const formsStatus = {}
  const formRef = useRef()

  useEffect(() => {
    if (!data) return
    const { nodeTreeQuestionnaireLive, icquestionnairespawnedSurveyLanding } = data
    if (nodeTreeQuestionnaireLive) setQuestionnaireData(nodeTreeQuestionnaireLive)
    if (icquestionnairespawnedSurveyLanding) setQuestionnaireData(icquestionnairespawnedSurveyLanding)
  }, [data])

  const setFormStatus = (icnodeId, status) => {
    formsStatus[icnodeId] = status
    setStatus(Object.values(formsStatus).reduce((overallStatus, status) => (status === 'SAVING' || (status === 'NOT_SAVED' && overallStatus !== 'SAVING')) ? status : overallStatus))
  }

  const setIcnode = (icnode) => {
    if (typeof icnode === 'object') {
      setCurrentIcnode(icnode)
    } else if (icnode === 'home') {
      setCurrentIcnode()
    } else {
      const node = questionnaireData.formData.find((_icnode) => _icnode.icnodeId === icnode)
      if (node) setCurrentIcnode(node)
    }
  }

  const setFormErrors = (icnodeId, errors) => {
    setFormsErrors({
      ...formsErrors,
      [icnodeId]: errors
    })
  }

  const onChanged = (response) => setPercent(response.percent || 0)

  useEffect(() => {
    const handleBeforeUnload = event => { event.returnValue = 'Are you sure?' }
    if (['NOT_SAVED', 'SAVING', 'ERROR'].indexOf(status) !== -1) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [status])

  useEffect(() => {
    if (!questionnaireData) return

    const { stats, formData } = questionnaireData

    const { allQuestionsPercentComplete = 0 } = stats
    if (!percent) setPercent(allQuestionsPercentComplete || 0) // only set percent at component load

    if (formData?.length) {
      const currentIndex = currentIcnode ? formData.findIndex((_icnode) => _icnode.icnodeId === currentIcnode.icnodeId) : -1
      if (currentIndex !== -1) {
        setPrevIcnode(formData[currentIndex - 1])
        setNextIcnode(formData[currentIndex + 1])
      } else {
        setPrevIcnode()
        setNextIcnode(formData.length ? formData[0] : undefined)
      }
    }
  }, [questionnaireData, currentIcnode])

  useEffect(() => {
    window.scrollTo({
      top: 90,
      left: 0,
      behavior: 'smooth'
    })
  }, [currentIcnode])

  useEffect(() => {
    if (questionnaireData) setIcnode(urlParamIcnodeId) // show the form if passed in url
  }, [questionnaireData, urlParamIcnodeId])

  if (error) {
    return (
      <>
        {error.graphQLErrors[0]?.message && <Alert message={error.graphQLErrors[0]?.message} type='warning' showIcon />}
        {!error.graphQLErrors[0] && <Alert message={error.message} type='warning' showIcon />}
      </>
    )
  }

  if (!questionnaireData) return <Loading />

  return (
    <>
      <QuestionnaireFeedback live={questionnaireData?.feedback?.live} reasons={questionnaireData?.feedback?.reasons} />

      <QuestionnaireHeader
        icquestionnaire={questionnaireData?.icquestionnaire}
        status={status}
        error={error}
        loading={loading}
        retry={() => setRetries(retries + 1)}
        percent={Number(percent)}
        hasPages={questionnaireData?.formData?.length > 1}
      />

      <Space direction='vertical' style={{ width: '100%' }}>
        <Card>
          {currentIcnode
            ? <QuestionnaireForm
                icquestionnaire={questionnaireData?.icquestionnaire}
                loading={loading}
                icnode={currentIcnode}
                setFormStatus={setFormStatus}
                setFormErrors={setFormErrors}
                retries={retries}
                onChanged={onChanged}
              />
            : <QuestionnaireHome
                loading={loading}
                icquestionnaire={questionnaireData?.icquestionnaire}
              />}
        </Card>
      </Space>

      <QuestionnaireFooter
        icquestionnaire={questionnaireData.icquestionnaire}
        prevIcnode={prevIcnode}
        nextIcnode={nextIcnode}
        loading={loading}
        formRef={formRef}
        status={status}
      />

      <Prompt
        when={['NOT_SAVED', 'SAVING', 'ERROR'].indexOf(status) !== -1}
        message={(location, action) => {
          const landingPageRoute = questionnaireData.icquestionnaire.landingPageRoute || 'ic/questionnaire/'
          if (!location.pathname.startsWith('/' + landingPageRoute)) return 'You have unsaved changes, are you sure you want to leave the questionnaire ?'
          return true
        }}
      />
    </>
  )
}

QuestionnaireLive.propTypes = {
  icquestionnaireId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object
}

export default QuestionnaireLive
