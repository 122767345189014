import React from 'react'
import PropTypes from 'prop-types'

const Container = (props) => {
  return (
    <div className='container'>
      <div className={`${props.margin ? 'marginlr' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  margin: PropTypes.bool
}

export default Container
