
import React from 'react'
import { RadialTreeGraph } from '@ant-design/charts'
import { useQuery } from '@apollo/client'

import Loading from '../../../../../components/Loading'

import { QUERY_NODE_TREE_TEMPLATE_GRAPH } from '../../../../../operations/queries/ic/templates'

const TemplateRadialTreeGraph = ({ ictemplateId }) => {
  const variables = { nodeTreeTemplateBuilderInput: { ictemplateId } }
  const { data } = useQuery(QUERY_NODE_TREE_TEMPLATE_GRAPH, { variables })

  if (!data) return <Loading />

  const { radialTreeGraph } = data.nodeTreeTemplateGraph

  const radialTreeGraphConfig = {
    behaviors: ['drag-canvas', 'zoom-canvas'],
    nodeCfg: {
      type: 'rect'
    },
    data: radialTreeGraph,
    onReady: (graph) => {
      graph.on('node:click', (evt) => {
        const { id } = evt.item._cfg
        const idObject = JSON.parse(id)

        // possibilities { templateId, icnodeChildId, iccategoryId, icquestionId, icanswerId } = idObject
        if (['local'].includes(process.env.REACT_APP_ENV)) console.info('TemplateRadialTreeGraph', JSON.stringify(idObject, null, 4))
      })
    }
  }

  return <RadialTreeGraph {...radialTreeGraphConfig} />
}

export default TemplateRadialTreeGraph
