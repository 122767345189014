import React from 'react'

import { Form, Select, Avatar, Space } from 'antd'

const { Option } = Select

const ReportModelField = ({ form, set }) => {
  const { field, display, data } = set

  const selectMultipleChanged = async list => {
    const clone = await form.getFieldsValue()
    clone[field] = list
    await form.setFieldsValue(clone)
  }

  return (
    <Select
      key={`select${field}`}
      mode='multiple'
      allowClear
      style={{ width: '100%' }}
      placeholder={`Multi-select ${display}`}
      onChange={selectMultipleChanged}
    >
      {
        data.map((record, index) => {
          const {
            id,
            name,
            imageURL,
            // hidden,
            // published,
            // country,
            // state,
            // city,
            countryEmoji
          } = record

          let src, icon

          if (imageURL) {
            src = imageURL
          }

          if (!src) {
            if (countryEmoji) icon = countryEmoji
          }
          return (
            <Option
              key={`checkbox${id}`}
              value={id}
            >
              <Space>
                <Avatar shape='square' size='small' src={src} icon={icon} />
                {name}
              </Space>
            </Option>
          )
        })
      }
    </Select>

  )
}

const ReportModels = ({ form, sets }) => {
  return sets.map((set, index) => {
    const { field, display } = set
    return (
      <Form.Item
        key={`formModels${field}${index}`}
        label={display.toUpperCase()}
        name={field}
      >
        <ReportModelField form={form} set={set} />
      </Form.Item>
    )
  })
}

export default ReportModels
