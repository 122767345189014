import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'

// contexts
// import AuthContext from '../../../../utils/authProvider'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_SPAWNER_LANDING } from '../../../../operations/queries/ic/spawners'

// ant
import { Typography, Space } from 'antd'

// ant design icons
import { LoadingOutlined } from '@ant-design/icons'

// logo
import Logo from '../../../../assets/images/logo.png'

export const SpawnerError = ({ error }) => {
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState('An unknown error has occured')

  useEffect(() => {
    if (!error) return null
    const { graphQLErrors } = error
    const { message, extensions } = graphQLErrors[0]
    const { code } = extensions

    if (code === 'BAD_USER_INPUT') setErrorMessage(message)
    if (code === 'UNAUTHENTICATED') {
      setErrorMessage(<div style={{ textAlign: 'center' }}>{message}<br /><Link to={'/signin?redirect=' + location?.pathname}>Sign-in</Link></div>) // david this is where we prompt for login
    }
  }, [error])

  return (
    <Space className='loading' direction='vertical'>
      <Typography.Title level={5}>{errorMessage}</Typography.Title>
    </Space>
  )
}

SpawnerError.propTypes = {
  error: PropTypes.object
}

export const SpawnerLoader = ({ message }) => (
  <Space className='loading' direction='vertical'>
    <Typography.Title level={5}>{message}</Typography.Title>
    <LoadingOutlined style={{ fontSize: '32px' }} />
  </Space>
)

SpawnerLoader.propTypes = {
  message: PropTypes.string
}

const Spawner = () => {
  const history = useHistory()
  const { rootRouteName, id } = useParams()
  const variables = {
    id,
    icquestionnairespawnerLandingInput: { rootRouteName }
  }
  const { data, loading, error } = useQuery(QUERY_SPAWNER_LANDING, { variables })

  useEffect(() => {
    if (!data) return null
    const { icquestionnairespawnerLanding } = data
    const { redirectURL } = icquestionnairespawnerLanding
    history.replace(`/${redirectURL}`) // use replace so they can not go BACK to generate a new survey
  }, [data])

  return (
    <>
      {loading && <SpawnerLoader message='Generating...' />}
      {error && <SpawnerError error={error} />}
      {data && <SpawnerLoader message='Redirecting...' />}
    </>
  )
}

const SpawnerLandingLink = () => {
  return (
    <>
      <div style={{ textAlign: 'center', margin: '12px 0' }}><img src={Logo} width='150px' height='auto' /></div>
      <div style={{ marginTop: '12px' }}>
        <div className='page-padding-leftRight page-padding-topBottom'>
          <div className='page-container'>
            <Spawner />
            {/*
              {isLoading
                ? <SpawnerLoader message='Loading...' />
                : <Spawner />}
            */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SpawnerLandingLink
