import React, { useState, useEffect } from 'react'

import ItemCardMeta from '../../../../../core/components/ItemCardMeta'

import { useQuery, useMutation } from '@apollo/client'

import PropTypes from 'prop-types'
import { Card, Button, Modal, Space, Row } from 'antd'
import SortableTree from 'react-sortable-tree'
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer'

// import TemplateFeedback from './TemplateFeedback'
import { PublishedIcon, EditNodechildIcon, DeleteNodeIcon, EmptyCategoryIcon, CopyNodeIcon, CategoryOrFormIcon, InputOrSelectIcon } from '../../../../../components/elements/icons'

import Loading from '../../../../../components/Loading'

import { QUERY_NODE_TREE_TEMPLATE_BUILDER } from '../../../../../operations/queries/ic/templates'
import { INSERT_CHILD_NODE, UPDATE_CHILD_NODE } from '../../../../../operations/mutations/ic/childNode'
import { COPY_ICNODE } from '../../../../../operations/mutations/ic/icnode'

import TemplateOrganizationGraph from './TemplateOrganizationGraph'
import TemplateRadialTreeGraph from './TemplateRadialTreeGraph'

const debug = false

const TemplateStructure = ({ data: templateData, refetchtemplate, readOnly }) => {
  if (!templateData) return <Loading />

  const { id } = templateData.ictemplate
  const variables = { nodeTreeTemplateBuilderInput: { ictemplateId: id }, fetchPolicy: 'cache-first' }

  if (debug) variables.fetchPolicy = 'no-cache'

  const { loading, error, data, refetch } = useQuery(QUERY_NODE_TREE_TEMPLATE_BUILDER, { variables })
  if (error) console.error(error)

  useEffect(() => {
    refetch()
    refetchtemplate()
  }, [data])

  if (loading) return <Loading />

  return (
    <Card
      size='small'
      extra={<TemplateGraphs ictemplateId={id} />}
    >
      <TemplateStructureView id={id} data={data} refetch={refetch} readOnly={readOnly} />
    </Card>
  )
}

TemplateStructure.propTypes = {
  data: PropTypes.object.isRequired,
  refetchtemplate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}

const TemplateStructureView = ({ id, data, refetch, readOnly }) => {
  if (!data?.nodeTreeTemplateBuilder) return null
  // const [feedback, setFeedback] = useState()
  const [treeData, setTreeData] = useState([data.nodeTreeTemplateBuilder])
  const [updateNodechild] = useMutation(UPDATE_CHILD_NODE)

  const [copyIcnode] = useMutation(COPY_ICNODE)
  const [insertNodechild] = useMutation(INSERT_CHILD_NODE)

  // this belongs to this SortableTree
  const canDrag = dragData => {
    const { parentNode } = dragData
    return Boolean(parentNode)
  }

  // this has to handle to this SortableTree and the rightSide categories and questions
  // use draggedIn boolean to indicate it came from right
  const canDrop = dropData => {
    const { prevPath, nextPath, node, nextParent } = dropData
    const { draggedIn } = node
    if (!nextParent) return false
    if (draggedIn) {
      if (nextParent.icnodeId && node.iccategory) return true
      if (nextParent.icnodeId && !nextParent.ictemplateId) return true
      // if (nextParent.icnode) return true
      return false
    } else {
      const currentFamily = prevPath[prevPath.length - 2]
      const newFamily = prevPath[nextPath.length - 2]
      return Boolean(currentFamily === newFamily)
    }
  }

  const modalError = async message => {
    setTreeData([data.nodeTreeTemplateBuilder])
    const modal = await (
      Modal.warning({
        className: 'modalDialogError',
        title: 'Template tree structure is locked',
        content: message
      })
    )

    return modal
  }

  const onMoveNode = async onMoveData => {
    if (!onMoveData) return false

    if (!onMoveData.nextParentNode) return false
    const { node, nextParentNode } = onMoveData

    const { draggedIn } = node

    if (draggedIn) {
      const { icnodeId, children } = nextParentNode
      const { iccategory, icquestion } = node

      const iccategoryId = iccategory?.id
      const icquestionId = icquestion?.id

      const order = children.findIndex(child => child.draggedIn)

      node.title = `Saving to position ${order + 1}`

      try {
        await insertNodechild({
          variables: {
            icnodeId,
            iccategoryId,
            icquestionId,
            order
          }
        })

        refetch()
      } catch (err) {
        await modalError(err.message)
      }
    } else {
      const { children } = nextParentNode
      const { icnodeChildId: id } = node
      const order = children.findIndex(child => child.icnodeChildId === id)

      try {
        await updateNodechild({
          variables: {
            id,
            icnodechildInputUpdate: {
              order
            }
          }
        })

        refetch()
      } catch (err) {
        await modalError(err.message)
      }
    }
  }

  const onCopyIcnode = async node => {
    const { icnodeId, isForm } = node
    if (!icnodeId) return
    const modal = await (
      Modal.confirm({
        className: 'modalDialogError',
        title: `Copy ${isForm ? 'Form' : 'Category'}?`,
        content: `This ${isForm ? 'Form' : 'Category'} and all it's descendants will also be copied.`,
        okText: 'Copy',
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            await copyIcnode({
              variables: {
                copyIcnodeInput: {
                  icnodeId
                }
              }
            })

            refetch()
          } catch (err) {
            await modalError(err.message)
          }
        }
      })
    )

    return modal
  }

  const onDeleteIcnodechild = async node => {
    const { icnodeChildId, title, icquestionId, isForm } = node

    const modal = await (
      Modal.confirm({
        className: 'modalDialogError',
        title: `Delete ${title}?`,
        content: `This ${icquestionId ? 'Question' : isForm ? 'Form' : 'Category'} and all it's descendants will also be deleted.`,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            await updateNodechild({
              variables: {
                id: icnodeChildId,
                icnodechildInputUpdate: {
                  hidden: true
                }
              }
            })

            await refetch()
          } catch (err) {
            await modalError(err.message)
          }
        }
      })
    )

    return modal
  }

  const generateNodeProps = rowInfo => {
    const { node } = rowInfo

    const icons = []
    const buttons = []

    icons.push(<EmptyCategoryIcon node={node} key={'EmptyCategoryIcon' + node.icnodeChildId} />)
    icons.push(<CategoryOrFormIcon node={node} key={'CategoryOrFormIconTree' + node.icnodeChildId} />)
    icons.push(<InputOrSelectIcon node={node} key={'InputOrSelectIcon' + node.icnodeChildId} />)
    icons.push(<PublishedIcon published={node.published} key={'PublishedIcon' + node.icnodeChildId} />)

    if (!readOnly) {
      buttons.push(
        <EditNodechildIcon ictemplateId={id} node={node} key={'EditNodechildIcon' + node.icnodeChildId} />
      )
    }

    if (!node.ictemplateId) {
      if (!readOnly) {
        buttons.push(
          <Button
            type='text'
            key={'DeleteNodeIcon' + node.icnodeChildId}
            onClick={() => onDeleteIcnodechild(node)}
          >
            <DeleteNodeIcon node={node} />
          </Button>
        )
        buttons.push(
          <Button
            type='text'
            key={'CopyNodeIcon' + node.icnodeChildId}
            onClick={() => onCopyIcnode(node)}
          >
            <CopyNodeIcon node={node} />
          </Button>
        )
      }
    }

    const title = <ItemCardMeta item={node} view='templatebuilder' />

    return { icons, buttons, node: { ...node, title } }
  }

  /*
    DEFAULTS
    FileExplorerTheme:
    rowHeight: 25
    scaffoldBlockPxWidth: 25
    slideRegionSize: 50
  */
  // console.log('FileExplorerTheme', FileExplorerTheme)

  const [rowHeight, scaffoldBlockPxWidth, slideRegionSize] = [36, 18, 40]

  return (
    <div style={{ height: 700, overflow: 'auto' }}>
      <SortableTree
        dndType='yourNodeType'
        theme={{ ...FileExplorerTheme, rowHeight, scaffoldBlockPxWidth, slideRegionSize }}
        treeData={treeData}
        onChange={newTreeData => setTreeData(newTreeData)}
        generateNodeProps={rowInfo => generateNodeProps(rowInfo)}
        canDrag={dragData => !readOnly && canDrag(dragData)}
        canDrop={dropData => !readOnly && canDrop(dropData)}
        onMoveNode={onMoveData => onMoveNode(onMoveData)}
      />
    </div>
  )
}

TemplateStructureView.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}

export default TemplateStructure

const TemplateGraphs = ({ ictemplateId }) => {
  const [organizationVisible, setOrganizationVisible] = useState(false)
  const [radialVisible, setRadialVisible] = useState(false)

  return (
    <>
      <Modal
        title={(
          <Row justify='space-between'>
            <>Organizational view of your template</>
            <Button type='link' onClick={() => setOrganizationVisible(false)}>Close</Button>
          </Row>
        )}
        centered
        closable={false}
        visible={organizationVisible}
        footer={null}
        width='100%'
      >
        <TemplateOrganizationGraph ictemplateId={ictemplateId} />
      </Modal>

      <Modal
        title={(
          <Row justify='space-between'>
            <>Radial tree view of your template</>
            <Button type='link' onClick={() => setRadialVisible(false)}>Close</Button>
          </Row>
        )}
        centered
        closable={false}
        visible={radialVisible}
        footer={null}
      >
        <TemplateRadialTreeGraph ictemplateId={ictemplateId} />
      </Modal>

      <Space size='small'>
        <Button type='link' onClick={() => setOrganizationVisible(true)}>Organized View</Button>
        <Button type='link' onClick={() => setRadialVisible(true)}>Radial View</Button>
      </Space>
    </>
  )
}
