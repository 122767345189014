import React from 'react'
import { useParams, withRouter } from 'react-router-dom'

import Page from '../../../../components/Page'
import QuestionForm from '../../../../components/sections/admin/ic/template/question/QuestionForm'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICQuestion = () => {
  const { id, templateId } = useParams()
  const title = id ? 'Edit question' : 'Add question'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    }
  ]

  templateId && breadcrumb.push(
    {
      path: `/admin/ic/template/${templateId}`,
      breadcrumbName: 'Template'
    }
  )

  breadcrumb.push(
    {
      path: '/admin/ic/templates/questions',
      breadcrumbName: 'Questions'
    }
  )
  breadcrumb.push(
    {
      path: '',
      breadcrumbName: title
    }
  )

  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-padding-leftRight page-padding-topBottom'>
          <QuestionForm />
        </div>
      </Page>
    </>
  )
}

export default withRouter(ICQuestion)
