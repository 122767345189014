import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { CaretRightOutlined, UnorderedListOutlined, ForkOutlined, FileTextOutlined, CodeOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheck, faExternalLinkAlt, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import { colorWarning, colorSuccess, colorPrimary, colorDanger, colorQuestion, colorCategory, colorDangerRealBad } from './'

export const PublishedIcon = ({ published }) => {
  const title = published ? 'Published' : 'Not published'
  const icon = published ? faCheck : faExclamationTriangle
  const color = published ? colorSuccess : colorWarning

  return (
    <Tooltip title={title} color={color}>
      <FontAwesomeIcon icon={icon} className='pointer' size='xs' color={color} style={{ marginRight: '8px' }} fixedWidth />
    </Tooltip>
  )
}

PublishedIcon.propTypes = {
  published: PropTypes.bool
}

export const CategoryOrFormIcon = ({ node }) => {
  if (!node.iccategoryId) return null

  const color = colorCategory
  const fontSize = '14px'
  const marginRight = '10px'
  const marginTop = '5px'

  if (node.isForm) {
    return (
      <Tooltip title={node.tooltip} color={color}>
        <FileTextOutlined className='pointer' style={{ color, fontSize, marginRight, marginTop }} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={node.tooltip} color={color}>
        <ForkOutlined className='pointer' style={{ color, fontSize, marginRight }} rotate='90' />
      </Tooltip>
    )
  }
}

CategoryOrFormIcon.propTypes = {
  node: PropTypes.object
}

export const InputOrSelectIcon = ({ icquestion, node, size }) => {
  if (!icquestion && !node?.icquestionId) return null

  const color = colorQuestion
  const colorMissingAnswers = colorDangerRealBad
  const fontSize = size || '14px'
  const marginRight = '10px'

  let icquestiontype, tooltip, icanswersCount

  if (icquestion) {
    icquestiontype = icquestion.icquestiontype
    tooltip = icquestion.tooltip
    icanswersCount = icquestion.icanswers.length
  }
  if (node) {
    icquestiontype = node.icquestiontype
    tooltip = node.tooltip
    icanswersCount = node.icanswersCount
  }

  if (icquestiontype === 'select') {
    return (
      <>

        <Tooltip title={tooltip} color={color}>
          <UnorderedListOutlined className='pointer' style={{ color, fontSize, marginRight }} />
        </Tooltip>
        {
          icanswersCount === 0 &&
            <Tooltip title='SELECT requires at least one answer' color={colorMissingAnswers}>
              <ExclamationCircleOutlined className='pointer' style={{ color: colorMissingAnswers, fontSize, marginRight }} />
            </Tooltip>
        }
      </>
    )
  }

  if (icquestiontype === 'input') {
    return (
      <Tooltip title={tooltip} color={color}>
        <CodeOutlined className='pointer' style={{ color, fontSize, marginRight }} />
      </Tooltip>
    )
  }
}

InputOrSelectIcon.propTypes = {
  icquestion: PropTypes.object,
  node: PropTypes.object,
  size: PropTypes.string
}

export const EmptyCategoryIcon = ({ node }) => {
  if (!node.iccategoryId) return null
  if (node?.children?.length > 0) return null

  const color = colorDanger
  const fontSize = '20px'
  const marginLeft = '-27px' // this matches open/close caret if closed

  return (
    <Tooltip title={'Category / Form can\'t be empty'} color={color}>
      <CaretRightOutlined className='pointer' style={{ color, fontSize, marginLeft }} />
    </Tooltip>
  )
}

EmptyCategoryIcon.propTypes = {
  node: PropTypes.object
}

export const EditNodechildIcon = ({ ictemplateId, iccategory, icquestion, node }) => {
  let title = 'Edit '
  let to = '/admin/ic/templates'

  let iccategoryId, icquestionId

  if (iccategory) iccategoryId = iccategory.id
  if (icquestion) icquestionId = icquestion.id

  if (node?.iccategoryId) iccategoryId = node.iccategoryId
  if (node?.icquestionId) icquestionId = node.icquestionId

  if (iccategoryId) {
    title += 'category'
    to += `/category/${iccategoryId}`
  }

  if (icquestionId) {
    title += 'question'
    to += `/question/${icquestionId}`
  }

  to += `/template/${ictemplateId}`

  if (!iccategoryId && !icquestionId) {
    title += 'template'
    to = `/admin/ic/template/${ictemplateId}`
  }

  title += ' properties'
  const icon = faExternalLinkAlt
  const color = colorPrimary

  return (
    <Tooltip title={title} color={color}>
      <Link className='pointer' to={to}>
        <FontAwesomeIcon icon={icon} className='pointer' size='xs' color={color} style={{ marginLeft: '6px' }} fixedWidth />
      </Link>
    </Tooltip>
  )
}

EditNodechildIcon.propTypes = {
  ictemplateId: PropTypes.string,
  iccategory: PropTypes.object,
  icquestion: PropTypes.object,
  node: PropTypes.object
}

export const DeleteNodeIcon = ({ node }) => {
  const { iccategoryId, icquestionId } = node

  // if (type === 'Ictemplate') return null

  let title = 'Delete '

  if (iccategoryId) title += 'category'
  if (icquestionId) title += 'question'

  title += ' from here'

  const icon = faTimes
  const color = colorDanger

  return (
    <Tooltip title={title} color={color} className='pointer'>
      <FontAwesomeIcon icon={icon} className='pointer' size='xs' color={color} style={{ marginLeft: '-8px' }} fixedWidth />
    </Tooltip>
  )
}

DeleteNodeIcon.propTypes = {
  node: PropTypes.object
}

export const CopyNodeIcon = ({ node }) => {
  const { icnodeId, isForm } = node
  if (!icnodeId) return null

  const title = `Copy ${isForm ? 'form' : 'category'} and all it's descendants`

  const icon = faCopy
  const color = colorSuccess

  return (
    <Tooltip title={title} color={color} className='pointer'>
      <FontAwesomeIcon icon={icon} className='pointer' size='xs' color={color} style={{ marginLeft: '-30px' }} fixedWidth />
    </Tooltip>
  )
}

CopyNodeIcon.propTypes = {
  node: PropTypes.object
}
