// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { memoryUser } from '../../../../../../core/auth/appMemory'
import ItemHeading from '../../../../../../core/components/ItemHeading'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_CATEGORY, QUERY_CATEGORIES } from '../../../../../../operations/queries/ic/categories'
import { INSERT_CATEGORY, UPDATE_CATEGORY } from '../../../../../../operations/mutations/ic/categories'

// contexts
// import { AuthContext } from '../../../../../../utils/authProvider'

// ant design
import { Card, Form, Input, Button, Switch, Alert } from 'antd'

// elements
import FormItemsSuperAdmin from '../../../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../../../../utils/form'
import FormContainer from '../../../../../../components/FormContainer'
import ModalDialog from '../../../../../../components/ModalDialog'

const FormComponent = ({ data }) => {
  const accountId = data?.iccategory?.accountId ? data.iccategory.accountId : memoryUser().currentAccountId
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateCategory, { loading: updateLoading }] = useMutation(UPDATE_CATEGORY)
  const [insertCategory, { loading: insertLoading }] = useMutation(INSERT_CATEGORY, {
    update (cache, { data: { insertIccategory } }) {
      const data = cache.readQuery({ query: QUERY_CATEGORIES })
      cache.writeQuery({
        query: QUERY_CATEGORIES,
        data: { iccategorys: !data ? [insertIccategory] : data.iccategorys.concat([insertIccategory]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/ic/templates/category/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateCategory({ variables: { id: id, iccategoryInput: { ...values, accountId } } }) : await insertCategory({ variables: { ...values, accountId } })
      const modalFunc = values.hidden ? ModalDialog.info : ModalDialog.success
      modalFunc({
        title: 'Success',
        content: `The category has been ${id ? (values.hidden ? 'deleted' : 'updated') : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/ic/templates/categories'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertIccategory?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  return (
    <>

      <Alert banner='true' message='About Forms:' description='If you assign this as a form, all childred (in a template) will make up this form. The name you provide below is either a link (category) or a form heading.' type='info' showIcon='true' style={{ marginBottom: '32px' }} />

      {data &&
        <Form
          form={form}
          id='categoryForm'
          name='categoryForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.iccategory}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >

          <Form.Item
            label='Name'
            name='name'
            hasFeedback
            validateStatus={itemErrors.name ? 'error' : ''}
            help={itemErrors.name}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label='Notes'
            name='note'
            hasFeedback
            validateStatus={itemErrors.note ? 'error' : ''}
            help={itemErrors.note}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Private Notes'
            name='privateNote'
            hasFeedback
            validateStatus={itemErrors.privateNote ? 'error' : ''}
            help={itemErrors.privateNote}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Image URL'
            name='imageURL'
            hasFeedback
            validateStatus={itemErrors.imageURL ? 'error' : ''}
            help={itemErrors.imageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Published'
            name='published'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <FormItemsSuperAdmin data={data.iccategory} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object
}

const CategoryForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_CATEGORY, { variables: { id }, skip: !id })
  const initialValues = { iccategory: { name: '' } }

  return (
    <>
      <ItemHeading loading={loading} item={data?.iccategory} refetch={refetch} />
      <Card title={data?.iccategory?.name} style={{ marginBottom: '24px' }}>
        <FormContainer loading={loading} size={4} form={<FormComponent data={{ ...initialValues, ...data }} />} />
      </Card>
    </>
  )
}

export default CategoryForm
