import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

const TemplateFeedback = ({ feedback }) => {
  if (!feedback) return null
  const { instructions, warnings, issues } = feedback

  const displayAlert = (index, type, label, message) => <Alert banner='true' key={`feedbackAlert${type}${index}`} message={`${label}: ${message}`} type={type} showIcon='true' style={{ width: '100%', marginBottom: '5px', fontSize: '0.8em' }} />

  return (
    <>
      {
        instructions.map((message, index) => displayAlert(index, 'info', 'info', message))
      }

      {
        warnings.map((message, index) => displayAlert(index, 'warning', 'warning', message))
      }

      {
        issues.map((message, index) => displayAlert(index, 'error', 'issue', message))
      }
    </>
  )
}

TemplateFeedback.propTypes = {
  feedback: PropTypes.object
}

export default TemplateFeedback
