import React from 'react'
import SimpleLink from '../components/SimpleLink'

import { Result } from 'antd'

const UserVerifiedWithNoRoles = () => (
  <Result
    status='success'
    title='Enjoy a free trial'
    subTitle='From the top-right menu, you can add an account anytime. Start using the Impact Calaulator right away.'
    extra={
      <SimpleLink
        to='/account/add'
        content='Add account'
        size='large'
        type='default'
      />
    }
  />
)

const HomeNoRoles = () => {
  return (
    <>
      <UserVerifiedWithNoRoles />
    </>
  )
}

export default HomeNoRoles
