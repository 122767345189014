// react
import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { memoryUser, reactiveIcquestionnairesPublished } from '../../../../../core/auth/appMemory'
import ItemHeading from '../../../../../core/components/ItemHeading'

import PropTypes from 'prop-types'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_QUESTIONNAIRE, QUERY_QUESTIONNAIRES } from '../../../../../operations/queries/ic/questionnaires'
import { INSERT_QUESTIONNAIRE, UPDATE_QUESTIONNAIRE } from '../../../../../operations/mutations/ic/questionnaires'

// ant design
import { Form, Input, Button, Switch, DatePicker, Alert, Space, Tooltip } from 'antd'

import FormItemSelectAccountGroup from '../../../../elements/formItems/FormItemSelectAccountGroup'
import FormItemSelectLocation from '../../../../elements/formItems/FormItemSelectLocation'
import FormItemSelectTemplate from '../../../../elements/formItems/FormItemSelectTemplate'
import FormItemsSuperAdmin from '../../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../../elements/formItems/FormItemError'
import TemplateStructure from '../template/TemplateStructure'
import TemplateFeedback from '../template/TemplateFeedback'
// other libraries
import moment from 'moment'
import formatErrors from '../../../../../utils/form'
import FormContainer from '../../../../FormContainer'
import ModalDialog from '../../../../ModalDialog'

export const QuestionnaireUserRoles = ({ userRoles }) => {
  if (!userRoles) return null
  const { allUsersDisplay, locationsAdminUsersDisplay } = userRoles

  const EmailList = ({ text }) => <div style={{ fontSize: '0.8em' }}>{text}</div>

  EmailList.propTypes = {
    text: PropTypes.string
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Alert message='Contributors' description={<EmailList text={allUsersDisplay} />} type='info' />
      <Alert message='Questionnaire Administrators' description={<EmailList text={locationsAdminUsersDisplay} />} type='info' />
    </Space>
  )
}

QuestionnaireUserRoles.propTypes = {
  userRoles: PropTypes.object
}

const FormComponent = ({ data, refetchtemplate }) => {
  const accountId = data?.icquestionnaire?.accountId ? data.icquestionnaire.accountId : memoryUser().currentAccountId
  // const accountId = memoryUser().currentAccountId
  const templateReadonly = !memoryUser().currentAccountUserPermissions?.iAmOwner || memoryUser().currentAccountUserPermissions.currentAccountId !== data.icquestionnaire?.ictemplate?.accountId
  const [reportingDate, setReportingDate] = useState(data.icquestionnaire?.reportingDate && moment(data.icquestionnaire.reportingDate))
  const [startDate, setStartDate] = useState(data.icquestionnaire?.startDate && moment(data.icquestionnaire.startDate))
  const [endDate, setEndDate] = useState(data.icquestionnaire?.endDate && moment(data.icquestionnaire.endDate))
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [live, setLive] = useState(data.icquestionnaire?.live)
  const [feedback, setFeedback] = useState(data.icquestionnaire?.feedback)
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateQuestionnaire, { loading: updateLoading }] = useMutation(UPDATE_QUESTIONNAIRE)
  const [insertQuestionnaire, { loading: insertLoading }] = useMutation(INSERT_QUESTIONNAIRE, {
    update (cache, { data: { insertIcquestionnaire } }) {
      const data = cache.readQuery({ query: QUERY_QUESTIONNAIRES })
      cache.writeQuery({
        query: QUERY_QUESTIONNAIRES,
        data: { icquestionnaires: !data ? [insertIcquestionnaire] : data.icquestionnaires.concat([insertIcquestionnaire]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/ic/questionnaire/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      let mutated
      if (id) {
        mutated = await updateQuestionnaire({ variables: { id: id, icquestionnaireInput: { ...values, accountId } } })
        setLive(mutated.data.updateIcquestionnaire.live)
        setFeedback(mutated.data.updateIcquestionnaire.feedback)
      } else {
        mutated = await insertQuestionnaire({ variables: { ...values, accountId } })
        setLive(mutated.data.insertIcquestionnaire.live)
        setFeedback(mutated.data.insertIcquestionnaire.feedback)
      }

      reactiveIcquestionnairesPublished(false)

      const modalFunc = values.hidden ? ModalDialog.info : ModalDialog.success

      modalFunc({
        title: 'Success',
        content: `The questionnaire has been ${id ? (values.hidden ? 'deleted' : 'updated') : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/ic/questionnaires'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertIcquestionnaire?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  const onChangeReportingDate = date => {
    form.setFieldsValue({ ...form.getFieldsValue(), reportingDate: date })
    setReportingDate(date)
  }

  const onChangeStartDate = date => {
    form.setFieldsValue({ ...form.getFieldsValue(), startDate: date })
    setStartDate(date)
  }

  const onChangeEndDate = date => {
    form.setFieldsValue({ ...form.getFieldsValue(), endDate: date })
    setEndDate(date)
  }

  return (

    <Space direction='vertical'>

      <Alert banner='true' message='Location(s)' description='Select locations(s) and those Location Administrators can review completion progress, change locked status and submit for finalization.' type='info' showIcon='true' style={{ marginBottom: '1px' }} />

      <Alert banner='true' message='Account Group(s)' description='Users within your account can be put into account groups. These groups contribute data and respond to this Questionniare in LIVE MODE.' type='info' showIcon='true' style={{ marginBottom: '32px' }} />

      {
        data &&

          <Form
            form={form}
            id='questionnaireForm'
            name='questionnaireForm'
            onFinish={onFinish}
            preserve={false}
            initialValues={data.icquestionnaire}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 17 }}
          >

            <Form.Item name='reportingDate' hidden>
              <Input />
            </Form.Item>

            <Form.Item
              label='Reporting date'
              validateStatus={itemErrors.reportingDate ? 'error' : ''}
              help={itemErrors.reportingDate}
            >
              <DatePicker
                style={{ width: '100%' }}
                picker='date'
                format='dddd, MMMM Do, yyyy'
                onChange={onChangeReportingDate}
                value={reportingDate}
              />
            </Form.Item>

            <Form.Item
              label='Name'
              name='name'
              hasFeedback
              validateStatus={itemErrors.name ? 'error' : ''}
              help={itemErrors.name}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label='Notes'
              name='note'
              hasFeedback
              validateStatus={itemErrors.note ? 'error' : ''}
              help={itemErrors.note}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>

            <Form.Item
              label='Private Notes'
              name='privateNote'
              hasFeedback
              validateStatus={itemErrors.privateNote ? 'error' : ''}
              help={itemErrors.privateNote}
            >
              <Input.TextArea
                rows={4}
              />
            </Form.Item>

            <Form.Item
              label='Image URL'
              name='imageURL'
              hasFeedback
              validateStatus={itemErrors.imageURL ? 'error' : ''}
              help={itemErrors.imageURL}
            >
              <Input placeholder='https://' />
            </Form.Item>

            <FormItemSelectTemplate
              label='Template'
              name='ictemplateId'
              hasFeedback
              validateStatus={itemErrors.ictemplateId ? 'error' : ''}
              help={itemErrors.ictemplateId}
            />

            <FormItemSelectLocation
              label='Location(s)'
              mode='multiple'
              name='locationIds'
              hasFeedback
              validateStatus={itemErrors.locationIds ? 'error' : ''}
              help={itemErrors.locationIds}
            />

            <FormItemSelectAccountGroup
              label='Account Group(s)'
              name='accountgroupIds'
              mode='multiple'
              hasFeedback
              validateStatus={itemErrors.accountgroupIds ? 'error' : ''}
              help={itemErrors.accountgroupIds}
            />

            <Form.Item name='startDate' hidden>
              <Input />
            </Form.Item>

            <Form.Item
              label='Start date'
            >
              <DatePicker
                style={{ width: '100%' }}
                picker='date'
                format='dddd, MMMM Do, yyyy'
                onChange={onChangeStartDate}
                value={startDate}
              />
            </Form.Item>

            <Form.Item name='endDate' hidden>
              <Input />
            </Form.Item>

            <Form.Item
              label='End date'
            >
              <DatePicker
                style={{ width: '100%' }}
                picker='date'
                format='dddd, MMMM Do, yyyy'
                onChange={onChangeEndDate}
                value={endDate}
              />
            </Form.Item>

            <Form.Item
              label='Locked'
              name='locked'
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label='Published'
              name='published'
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>

            <FormItemsSuperAdmin data={data.icquestionnaire} hasHidden />

            <FormItemError error={formError} />

            <Form.Item>
              <Button style={{ marginTop: '32px' }} type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
                {id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>

          </Form>
      }

      {/* <QuestionnaireUserRoles userRoles={userRoles} /> */}

      {
        id &&
          <Tooltip title='Open in new tab'>
            <Link to={`/ic/questionnaire/${id}`} target='_blank'>
              {
              live
                ? <Alert banner='true' message='Status' description='Questionnaire is LIVE' type='success' showIcon='false' />
                : <Alert banner='true' message='Status' description='Questionnaire is PAUSED' type='info' showIcon='false' />
              }
            </Link>
          </Tooltip>
      }

      <TemplateFeedback feedback={feedback} />

      {
        data.icquestionnaire?.ictemplateId &&

          <>
            {!templateReadonly && <Link to={`/admin/ic/template/${data.icquestionnaire.ictemplateId}`}>Edit this template:</Link>}
            <TemplateStructure data={data.icquestionnaire} refetchtemplate={refetchtemplate} readOnly={templateReadonly} />
          </>
      }

    </Space>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object,
  refetchtemplate: PropTypes.func
}

const QuestionnaireForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_QUESTIONNAIRE, { errorPolicy: 'all', variables: { id }, skip: !id })
  const initialValues = {
    icquestionnaire: {
      name: '',
      reportingDate: data?.icquestionnaire?.reportingDate,
      startDate: data?.icquestionnaire?.startDate,
      endDate: data?.icquestionnaire?.endDate
    }
  }

  const refetchtemplate = async () => refetch()

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <ItemHeading loading={loading} item={data?.icquestionnaire} refetch={refetch} />
      <FormContainer
        loading={loading}
        size={2}
        form={
          <FormComponent
            data={{ ...initialValues, ...data }}
            refetchtemplate={refetchtemplate}
          />
        }
      />

    </Space>
  )
}

export default QuestionnaireForm
