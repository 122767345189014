import React from 'react'

import { Form, Button, Space } from 'antd'

import CenterContent from '../components/CenterContent'
import ValidationError from '../components/ValidationError'

const FormWrapper = ({ children, heading, title, size = 'medium', form, preserve, initialValues, onFinish, loading, error, customerror, extra, fluid }) => {
  return (
    <CenterContent title={title} bordered fluid={fluid}>
      <Form
        layout='vertical'
        size={size}
        form={form}
        onFinish={onFinish}
        preserve={preserve}
        initialValues={initialValues}
      >
        <Space direction='vertical' style={{ width: '100%' }}>

          {
            heading &&
              <>{heading}</>
          }

          {children}

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={loading} block='true'>
              Submit
            </Button>
          </Form.Item>

          <ValidationError error={error} customerror={customerror} />

          {
            extra &&
              <Form.Item>
                {extra}
              </Form.Item>
          }
        </Space>

      </Form>
    </CenterContent>
  )
}

export default FormWrapper
