import { gql } from '@apollo/client'

const deiFields = `
  name
  reportable {
    dei
    percent
    percentEmployeed
    percentWages
  }
  values {
    percent
    percentEmployeed
    percentWages
  }
  display {
    percent
    percentEmployeed
    percentWages
  }
`
export const QUERY_LOCATION_FORM_DEIS = gql`
  query (
    $locationFormDeisInput: LocationFormDeisInput!
  ) {
    locationFormDeis (
      locationFormDeisInput: $locationFormDeisInput
    )
    {
      deiMappings {
        key
        arrayName
        display
      }
      ages {
        ageId
        ${deiFields}
      }
      ethnicityAndRaces {
        ethnicityAndRaceId
        ${deiFields}
      }
      sexes {
        sexId
        ${deiFields}
      }
    }
  }
`

export const QUERY_COUNTRIES = gql`
  query {
    countries {
      id
      name
    }
  }
`

export const QUERY_STATESOFCOUNTRY = gql`
  query (
    $countryId: Int
  ) {
    statesOfCountry (
      countryId: $countryId
    ) {
      id
      name
    }
  }
`

export const QUERY_CITIESOFSTATE = gql`
  query (
    $stateId: Int
  ) {
    citiesOfState (
      stateId: $stateId
    ) {
      id
      name
    }
  }
`

export const LOCATIONS_FIELDS = `
  id
  key
  accountId
  account {
    id
    name
    owner {
      id
      fullName
    }
  }
  address1
  address2
  cscCountryId
  cscStateId
  cscCityId
  city {
    id
    name
  }
  state {
    id
    name
  }
  country {
    id
    name
    emoji
  }
  hidden
  name
  postalzip
  published
  locationAdminUsers {
    id
    email
  }
  locationGeneralUsers {
    id
    email
  }
  icquestionnaires {
    id
    name
  }
  note
  imageURL
  cloudinaryImage
  isDeiReportable
`
export const QUERY_LOCATIONS = gql`
query(
  $filter: Filter
  $current: Int
  $pageSize: Int
  $sortField: String
  $sortDirection: Int
) {
   locations(
     filter: $filter
     current: $current
     pageSize: $pageSize
     sortField: $sortField
     sortDirection: $sortDirection
   )
  {
    ${LOCATIONS_FIELDS}
    deis {
      age {
        name
      }
      ethnicityAndRace {
        name
      }
      sex {
        name
      }
      percent
      percentEmployeed
      percentWages
    }

  }
}
`

export const LOCATION_FIELDS = `
  id
  accountId
  address1
  address2
  cscCountryId
  cscStateId
  cscCityId
  city {
    id
    name
  }
  state {
    id
    name
  }
  country {
    id
    name
    emoji
  }
  hidden
  name
  postalzip
  published
  account {
    id
    owner {
      id
      fullName
    }
  }
  locationAdminUsers {
    id
    email
  }
  locationGeneralUsers {
    id
    email
  }
  icquestionnaires {
    id
    name
  }
  note
  imageURL
  cloudinaryImage
  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "mmm dd yyyy HH:MM:ss")
  updatedAt (format: "mmm dd yyyy HH:MM:ss")
  isDeiReportable
`

export const QUERY_LOCATION = gql`
query Location($id: ID!) {
  location (id: $id) {
    ${LOCATION_FIELDS}
  }
}
`
