import React from 'react'
import { useParams } from 'react-router-dom'

import Page from '../../../components/Page'
import LocationForm from '../../../components/sections/admin/location/LocationForm'
import PageInstructions from '../../../documentation/PageInstructions'

// ant design
const Location = () => {
  const { id } = useParams()
  const title = id ? 'Edit location' : 'Add location'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/admin/locations',
      breadcrumbName: 'Locations'
    },
    {
      path: '',
      breadcrumbName: title
    }
  ]
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-container'>
          <div className='page-padding-leftRight page-padding-topBottom'>
            <LocationForm />
          </div>
        </div>
      </Page>
    </>
  )
}

export default Location
