import { setLocaleStorage } from './appStorage'
import { memoryAccessToken, memoryUser } from './appMemory'

const { REACT_APP_ENV } = process.env
const envs = ['local', 'production']

const processOutput = output => {
  const { code, message, operationName } = output
  let ignoreStack = false
  let hide = false
  let category

  if (message.includes('Your session has expired')) {
    setLocaleStorage('accessToken', null)
    memoryUser(false)
    memoryAccessToken(false)
    window.location.replace('/signin')
  }

  // console.log('operationName', operationName)
  // candidates: useEmailVerificationToken

  const senstiveOptions = [
    // 'queryRefreshMe',
    'signIn',
    'signUp',
    'signOut',
    'changeMyPassword'
  ]

  const legacyValidations = [
    'insertIcquestionnaire'
  ]

  const ignoreStackOperationNames = [
    ...legacyValidations,
    ...senstiveOptions,
    'submitNewPasswordWithPasswordResetToken',
    'insertInvite',
    'updateInvite',
    'updateMe',
    'createAccountForMe',
    'useEmailVerificationToken',
    'changeMyPassword',
    'transferToUsers',
    'itemUpload',
    'updateIcquestionnaire'
  ]

  if (ignoreStackOperationNames.includes(operationName)) {
    ignoreStack = true
    hide = true
    category = 'security authentication input'
    output.variables = { }
  }

  if (senstiveOptions.includes(operationName)) {
    output.variables = { }
  }

  // if (!ignoreStack && code === 'UNAUTHENTICATED' && path === 'me') {
  //   ignoreStack = true
  //   category = 'token check'
  // }

  if (!ignoreStack && code === 'INTERNAL_SERVER_ERROR' && message.includes('is required')) {
    ignoreStack = true
    hide = true
    category = 'form validation'
    console.log('validation: ', message)
  }

  if (code === 'UNAUTHENTICATED') {
    ignoreStack = true
  }

  return {
    ignoreStack,
    hide,
    category,
    ...output
  }
}

export const handleGraphQLErrors = args => {
  const { graphQLErrors, operation } = args

  if (!graphQLErrors) return

  const { operationName, variables } = operation

  const moreThanOneError = graphQLErrors.length > 1

  if (moreThanOneError) console.log('ALERT! moreThanOneError', graphQLErrors)

  const error = graphQLErrors[0]
  const { message, path: pathArray, extensions } = error
  const { code, exception } = extensions
  const { stacktrace } = exception

  const path = pathArray && pathArray[0]
  const moreThanOnePath = pathArray && pathArray.length > 1

  const output = {
    code,
    operationName,
    variables,
    moreThanOneError,
    message,
    path,
    moreThanOnePath
  }

  if (envs.includes(REACT_APP_ENV)) {
    const processedOutput = processOutput(output)

    if (!processedOutput.hide) {
      if (!processedOutput.ignoreStack) console.log('stacktrace', { stacktrace })

      console.log('GraphQL', JSON.stringify(processedOutput, null, 4))
    }
  }
}

export const handleNetworkError = args => {
  const { networkError, operation } = args
  if (!networkError) return

  const { operationName, variables } = operation

  const output = {
    operationName,
    variables,
    networkError
  }
  console.log('networkError', networkError)
  // console.log('stack', networkError.networkError.stack)
  if (envs.includes(REACT_APP_ENV)) {
    delete output.variables
    console.log('networkError', output)
  }
}
