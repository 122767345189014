import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Typography } from 'antd'

const { Paragraph } = Typography

const FormItemError = ({ error }) => {
  return (
    <>
      {error && <Paragraph type='danger'>{error}</Paragraph>}
    </>
  )
}

FormItemError.propTypes = {
  error: PropTypes.string
}

export default FormItemError
