import React from 'react'
import { useParams, withRouter, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../core/auth/appMemory'

import Page from '../../../../components/Page'
import CategoryForm from '../../../../components/sections/admin/ic/template/category/CategoryForm'
import PageInstructions from '../../../../documentation/PageInstructions'

const ICCategory = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Iccategory')
  const edit = permissions?.menuAccess?.edit.includes('Iccategory')

  if (!add || !edit) {
    useHistory().push('/')
    return null
  }

  const { id, templateId } = useParams()

  const title = id ? 'Edit category' : 'Add category'

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/',
      breadcrumbName: 'Impact calculator'
    }
  ]

  templateId && breadcrumb.push(
    {
      path: `/admin/ic/template/${templateId}`,
      breadcrumbName: 'Template'
    }
  )

  breadcrumb.push(
    {
      path: '/admin/ic/templates/categories',
      breadcrumbName: 'Categories'
    }
  )
  breadcrumb.push(
    {
      path: '',
      breadcrumbName: title
    }
  )

  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <PageInstructions />
        <div className='page-padding-leftRight page-padding-topBottom'>
          <CategoryForm />
        </div>
      </Page>
    </>
  )
}

export default withRouter(ICCategory)
