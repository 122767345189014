import { gql } from '@apollo/client'

const IC_MAX_LEVELS = parseInt(process.env.REACT_APP_IC_MAX_LEVELS)

const nodeTreeTemplateOrganizationGraphChildrenFields = `
  id
  value {
    text
    value
    icon
  }
`

const nodeTreeTemplateRadialTreeGraphChildrenFields = `
  id
  value
`

let nodeTreeTemplateOrganizationGraphChildren = ''
let nodeTreeTemplateRadialTreeGraphChildren = ''

let nodeTreeTemplateOrganizationGraphChildrenCounter = IC_MAX_LEVELS

while (nodeTreeTemplateOrganizationGraphChildrenCounter > 0) {
  if (nodeTreeTemplateOrganizationGraphChildrenCounter === IC_MAX_LEVELS) {
    nodeTreeTemplateOrganizationGraphChildren = `
      children {
        ${nodeTreeTemplateOrganizationGraphChildrenFields}
      }
    `
    nodeTreeTemplateRadialTreeGraphChildren = `
      children {
        ${nodeTreeTemplateRadialTreeGraphChildrenFields}
      }
    `
  } else {
    nodeTreeTemplateOrganizationGraphChildren = `
      children {
        ${nodeTreeTemplateOrganizationGraphChildrenFields}
        ${nodeTreeTemplateOrganizationGraphChildren}
      }
    `
    nodeTreeTemplateRadialTreeGraphChildren = `
      children {
        ${nodeTreeTemplateRadialTreeGraphChildrenFields}
        ${nodeTreeTemplateRadialTreeGraphChildren}
      }
    `
  }
  nodeTreeTemplateOrganizationGraphChildrenCounter -= 1
}

export const QUERY_NODE_TREE_TEMPLATE_GRAPH = gql`
  query nodeTreeTemplateGraph ( $nodeTreeTemplateBuilderInput: NodeTreeTemplateBuilderInput! ) {
    nodeTreeTemplateGraph (nodeTreeTemplateBuilderInput: $nodeTreeTemplateBuilderInput) {
      organizationGraph {
        id
        value {
          text
          value
          icon
        }
        ${nodeTreeTemplateOrganizationGraphChildren}
      }
      radialTreeGraph {
        id
        value
        ${nodeTreeTemplateRadialTreeGraphChildren}
      }
    }
  }
`
/*
  const nodeTreeFields = `
    name
    note
    imageURL
    cloudinaryImage
    tooltip
    title
    icnodeId
    ictemplateId,
    published,
    isForm
    expanded
    feedback {
      ictemplate {
        instructions
        warnings
        issues
        hasFeedback
      }
    }
  `

  const nodeTreeTemplateBuilderChildrenFields = `
    name
    note - REMOVED
    imageURL
    cloudinaryImage
    tooltip
    title
    icnodeId
    icnodeChildId
    published
    iccategoryId
    isForm
    icquestionId
    icquestiontype
    icanswersCount
    expanded
  `

  let nodeTreeTemplateBuilderChildren = ''

  let nodeTreeTemplateBuilderChildrenCounter = IC_MAX_LEVELS

  while (nodeTreeTemplateBuilderChildrenCounter > 0) {
    if (nodeTreeTemplateBuilderChildrenCounter === IC_MAX_LEVELS) {
      nodeTreeTemplateBuilderChildren = `
        children {
          ${nodeTreeTemplateBuilderChildrenFields}
        }
      `
    } else {
      nodeTreeTemplateBuilderChildren = `
        children {
          ${nodeTreeTemplateBuilderChildrenFields}
          ${nodeTreeTemplateBuilderChildren}
        }
      `
    }
    nodeTreeTemplateBuilderChildrenCounter -= 1
  }
*/

const nodeTreeOptimizedChildrenFields = `
  name
  imageURL
  cloudinaryImage
  tooltip
  title
  icnodeId
  icnodeChildId
  published
  iccategoryId
  isForm
  icquestionId
  icquestiontype
  icanswersCount
  expanded
`

let nodeTreeOptimizedChildren = ''

let nodeTreeOptimizedChildrenCounter = IC_MAX_LEVELS

while (nodeTreeOptimizedChildrenCounter > 0) {
  if (nodeTreeOptimizedChildrenCounter === IC_MAX_LEVELS) {
    nodeTreeOptimizedChildren = `
      children {
        ${nodeTreeOptimizedChildrenFields}
      }
    `
  } else {
    nodeTreeOptimizedChildren = `
      children {
        ${nodeTreeOptimizedChildrenFields}
        ${nodeTreeOptimizedChildren}
      }
    `
  }
  nodeTreeOptimizedChildrenCounter -= 1
}

const nodeTreeFieldsOptimized = `
  icnodeId
  published
  ictemplateId
  name
  title
  cloudinaryImage
  tooltip
  isForm
  expanded
`

export const QUERY_NODE_TREE_TEMPLATE_BUILDER = gql`
  query nodeTreeTemplateBuilder ( $nodeTreeTemplateBuilderInput: NodeTreeTemplateBuilderInput! ) {
    nodeTreeTemplateBuilder (nodeTreeTemplateBuilderInput: $nodeTreeTemplateBuilderInput) {
      ${nodeTreeFieldsOptimized}
      ${nodeTreeOptimizedChildren}
    }
  }
`

const commonFields = `
  id
  key
  name
  note
  privateNote
  imageURL
  cloudinaryImage
  accountId
  hidden
  published

  feedback {
    feedbackDataTime
    instructions
    warnings
    issues
    hasFeedback
    issuesCount
  }

  createdBy
  createdAtAgo
  updatedAtAgo
  createdAt (format: "ddd mmm dd yyyy h:MM:ss TT Z")
  updatedAt (format: "ddd mmm dd yyyy h:MM:ss TT Z")
`

const queryIcquestion = `
  icquestion {
    id
    name
    published
    icquestiontype
    icquestionscore
    randomizeselectableanswers
    canselectmanyanswers
    inputtype
    numbertype
    decimalplaces
    required
    icanswers {
      id
      name
      published
      icanswerscore
      icquestionId
    }
  }
`

const queryIccategory = `
  iccategory {
    id
    name
    published
  }
`

let counter = IC_MAX_LEVELS
export let icnodechilden = ''
while (counter > 0) {
  if (counter === IC_MAX_LEVELS) {
    icnodechilden = `
      icnodechilden {
        id
        icnodeId
        icnodechildtype
        ${queryIcquestion}
      }
    `
  } else {
    icnodechilden = `
      icnodechilden {
        id
        icnodeId
        icnodechildtype
        ${queryIcquestion}
        ${queryIccategory}

        iccategoryNode {
          id
          levelNumber #levelNumber ${counter}
          icnodetype #Iccategory

          ${icnodechilden}
        }

      }
    `
  }
  counter -= 1
}

export const queryTemplateFields = `
  icnode {
    id
    levelNumber #levelNumber 0
    icnodetype #Ictemplate
    ictemplate {
      id
      name
    }
    ${icnodechilden}
  }
`

export const TEMPLATES_FIELDS = `
  ${commonFields}
`
export const QUERY_TEMPLATES = gql`
  query(
    $filter: Filter
    $current: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: Int
  ) {
    ictemplates(
      filter: $filter
      current: $current
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      ${TEMPLATES_FIELDS}
    }
  }
`

export const TEMPLATE_FIELDS = `
  ${commonFields}
  icquestionnaires {
    id
    name
    reportingDate
    note
    imageURL
    cloudinaryImage
    live
    reasons
    feedback {
      instructions
      warnings
      issues
      hasFeedback
      issuesCount
    }
  }
`
export const QUERY_TEMPLATE = gql`
  query Template($id: ID!) {
    ictemplate (id: $id) {
      ${TEMPLATE_FIELDS}
    }
  }
`

export const QUERY_TEMPLATE_WITH_NODES = gql`
  query Template($id: ID!) {
    ictemplate (id: $id) {
      ${queryTemplateFields}
    }
  }
`
