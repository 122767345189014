import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'

import { useReactiveVar } from '@apollo/client'

import { memoryUser } from '../../../../core/auth/appMemory'

import Page from '../../../../components/Page'

import ImpactReport0002 from './ImpactReport0002'

// import ImpactReport0001 from './ImpactReport0001'
// import { Tabs } from 'antd'
// const { TabPane } = Tabs

const breadcrumb = [
  {
    path: '/',
    breadcrumbName: 'Home'
  },
  {
    path: '/',
    breadcrumbName: 'Impact calculator'
  },
  {
    path: '',
    breadcrumbName: 'Reporting Dashboard'
  }
]

const ImpactReportDashboard = () => {
  const user = useReactiveVar(memoryUser)
  const { currentAccountId, currentAccountUserPermissions } = user

  const { viewAccountAdministrator } = currentAccountUserPermissions

  if (!viewAccountAdministrator) {
    useHistory().push('/')
    return null
  }

  return (
    <Page title='Impact Report Dashboard' breadcrumb={breadcrumb}>

      <div className='page-padding-leftRight page-padding-topBottom'>
        {
          /*
          <Tabs defaultActiveKey='2'>
            <TabPane tab='Reports v1' key='1'>
              <ImpactReport0001 />
            </TabPane>
            <TabPane tab='Reports v2' key='2'>
              <ImpactReport0002 accountId={currentAccountId} />
            </TabPane>
          </Tabs>
          */
          currentAccountId && <ImpactReport0002 accountId={currentAccountId} />
        }
      </div>
    </Page>
  )
}

export default withRouter(ImpactReportDashboard)
