// import React, { useEffect, useState } from 'react'
// import { gql, useMutation } from '@apollo/client'
// import { Form, Input, Button, Alert, Row, Col, Space } from 'antd'
//
// import CenterContent from '../components/CenterContent'
// import ValidationError from '../components/ValidationError'
// import SimpleLink from '../components/SimpleLink'
//
// const CHANGE_PASSWORD_BY_TOKEN = gql`
//   mutation changePasswordByToken ($changePasswordByTokenInput: ChangePasswordByTokenInput!) {
//     changePasswordByToken (changePasswordByTokenInput: $changePasswordByTokenInput)
//   }
// `

// const Success = () => {
//   return (
//     <CenterContent>
//       <Space direction='vertical' size='large' style={{ width: '100%' }}>
//         <Alert message='Your password was reset' type='success' />
//
//         <SimpleLink
//           to='/signin'
//           content='Sign In'
//         />
//       </Space>
//     </CenterContent>
//   )
// }

const ResetPassword = ({ passwordToken }) => {
  return <>ResetPassword</>
  // const [form] = Form.useForm()
  //
  // const [resetPassword, setResetPassword] = useState()
  //
  // const [changePasswordByTokenMutation, { data, loading, error }] = useMutation(CHANGE_PASSWORD_BY_TOKEN)
  //
  // useEffect(() => {
  //   setResetPassword(false)
  //   if (!data?.changePasswordByToken) return
  //   const { changePasswordByToken } = data
  //   setResetPassword(changePasswordByToken)
  // }, [data])
  //
  // if (resetPassword) return <Success />
  // return (
  //   <CenterContent title='New password' bordered>
  //     <Form
  //       size='large'
  //       form={form}
  //       layout='vertical'
  //       onFinish={(values) => changePasswordByTokenMutation({
  //         variables: { changePasswordByTokenInput: { ...values, passwordToken } },
  //         errorPolicy: 'all'
  //       })}
  //     >
  //       <Form.Item
  //         name='password'
  //         label='New Password'
  //       >
  //         <Input.Password autoComplete='off' />
  //       </Form.Item>
  //       <Form.Item
  //         name='passwordConfirm'
  //         label='Password Confirm'
  //       >
  //         <Input.Password autoComplete='off' />
  //       </Form.Item>
  //
  //       <Form.Item>
  //         <Row justify='space-between'>
  //           <Col>
  //             <Button type='primary' htmlType='submit' loading={loading}>
  //               Submit
  //             </Button>
  //           </Col>
  //           <Col>
  //             <SimpleLink
  //               to='/signin'
  //               content='Sign In'
  //             />
  //           </Col>
  //         </Row>
  //       </Form.Item>
  //
  //       <ValidationError error={error} />
  //
  //     </Form>
  //   </CenterContent>
  // )
}

export default ResetPassword
