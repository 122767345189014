import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, InputNumber } from 'antd'

// components
import { TitleElement, NoteElement } from '../AvatarElement'

// librairies
import i18next from 'i18next'

const QuestionNumber = ({ icnodeChildId, question, onChange }) => {
  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={question.response}
        rules={[{ required: question.required, message: 'This field is required' }]}
      >
        <InputNumber
          formatter={value => i18next.t('intlNumber', { val: value })}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          precision={question.numbertype === 'real' ? question.decimalplaces : 0}
          min={question.numbertype === 'natural' ? '0' : Number.MIN_SAFE_INTEGER}
          onChange={onChange}
        />
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionNumber.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  onChange: PropTypes.func
}

export default QuestionNumber
