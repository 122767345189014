import React, { useState, useEffect } from 'react'
import { Provider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react'

import { useReactiveVar } from '@apollo/client'

import { memoryUser, refreshUser } from '../auth/appMemory'

const { REACT_APP_ENV, REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_VERSION } = process.env

const testMode = ['local'].includes(REACT_APP_ENV)

const RollbarProvider = ({ children }) => {
  const refresh = useReactiveVar(refreshUser)

  const [person, setPerson] = useState()

  useEffect(() => {
    if (refresh) return

    const refreshedPerson = { }
    if (!memoryUser()) return setPerson(refreshedPerson)

    const { id, emailObfuscated } = memoryUser()

    refreshedPerson.id = id
    refreshedPerson.emailObfuscated = emailObfuscated

    return setPerson(refreshedPerson)
  }, [refresh, memoryUser])

  const rollbarInstanceConfig = {
    environment: REACT_APP_ENV,
    server: {
      root: window.location.origin
    },
    code_version: REACT_APP_VERSION,
    person
  }

  const config = {
    accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: REACT_APP_ENV,
    ...rollbarInstanceConfig
  }

  // https://docs.rollbar.com/docs/react

  return (
    <Provider config={config}>
      {
        testMode
          ? <>{children}</>
          : (
            <ErrorBoundary
              level={LEVEL_WARN}
              errorMessage='Error in React render'
              extra={(error, info) => {
                console.log('RollbarProvider error', error)
                console.log('RollbarProvider info', info)
              }}
            >
              {children}
            </ErrorBoundary>
            )
      }
    </Provider>
  )
}

export default RollbarProvider
