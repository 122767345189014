import React, { useEffect } from 'react'

import { Button, Space, Dropdown, Menu, Spin, Typography } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faPortrait, faFilePdf } from '@fortawesome/free-solid-svg-icons'

const orientations = [
  {
    key: 'landscape',
    name: 'Landscape',
    icon: faImage
  },
  {
    key: 'portrait',
    name: 'Portrait',
    icon: faPortrait
  }
]

const LandscapePortrait = ({ orientation, setOrientation, loading, title, exportAllToPdf }) => {
  useEffect(() => {
    if (!orientation) return setOrientation(orientations[0])
  }, [orientation, setOrientation])

  if (loading || !orientation) return <Spin />

  return (

    <Space direction='vertical' align='end'>

      <Dropdown.Button
        trigger='click'
        disabled={loading}
        type='primary'
        icon={<CaretDownOutlined />}
        buttonsRender={(buttons) => [
          <Space align='center' key={0}>
            <Button onClick={() => exportAllToPdf(orientation)} type='primary' loading={loading}>
              <Space size='small' align='top'>
                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 22 }} />
                <div style={{ marginTop: 1 }}>{title}</div>
              </Space>
            </Button>
          </Space>,
          buttons[1]
        ]}
        overlay={
          <Menu>
            {
              orientations.map(orientation => <Menu.Item onClick={() => setOrientation(orientation)} key={orientation.key} icon={<FontAwesomeIcon icon={orientation.icon} />}>{`${orientation.name} orientation`}</Menu.Item>)
            }
          </Menu>
        }
      />
      <Typography.Text type='secondary'><FontAwesomeIcon icon={orientation.icon} /> {orientation.name} mode</Typography.Text>
    </Space>
  )
}

export default LandscapePortrait
