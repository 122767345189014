// react
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { memoryUser } from '../../../../core/auth/appMemory'
import ItemHeading from '../../../../core/components/ItemHeading'

// apollo & operations
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { QUERY_LOCATION, QUERY_LOCATIONS, QUERY_COUNTRIES, QUERY_STATESOFCOUNTRY, QUERY_CITIESOFSTATE, QUERY_LOCATION_FORM_DEIS } from '../../../../operations/queries/locations'
import { INSERT_LOCATION, UPDATE_LOCATION } from '../../../../operations/mutations/locations'

// ant design
import { Form, Input, Button, Switch, Select, Divider, Tabs, Space } from 'antd'
import { MailOutlined, AppstoreOutlined } from '@ant-design/icons'
// elements
import FormItemsSuperAdmin from '../../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../../utils/form'
import FormContainer from '../../../FormContainer'
import ModalDialog from '../../../ModalDialog'
import LocationDEIValuesForm from './LocationDEIValuesForm'

const { Option } = Select

const FormComponent = ({ data, countriesData }) => {
  if (!data || !countriesData) return null

  const accountId = data.location.accountId || memoryUser().currentAccountId
  const history = useHistory()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateLocation, { loading: updateLoading }] = useMutation(UPDATE_LOCATION)
  const [insertLocation, { loading: insertLoading }] = useMutation(INSERT_LOCATION, {
    update (cache, { data: { insertLocation } }) {
      const data = cache.readQuery({ query: QUERY_LOCATIONS })
      cache.writeQuery({
        query: QUERY_LOCATIONS,
        data: { locations: !data ? [insertLocation] : data.locations.concat([insertLocation]) }
      })
    }
  })

  const continueEditing = (_id) => _id && history.push(`/admin/location/${_id}`)

  const onFinish = async (values) => {
    try {
      clearErrors()
      const mutated = id ? await updateLocation({ variables: { id: id, locationInput: { ...values, accountId } } }) : await insertLocation({ variables: { ...values, accountId } })
      ModalDialog.success({
        title: 'Success',
        content: `The location has been ${id ? 'updated' : 'added'}.`,
        okText: 'Return to listing',
        onOk: () => history.push('/admin/locations'),
        cancelText: 'Continue editing',
        onCancel: () => continueEditing(mutated.data.insertLocation?.id)
      })
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = (errors) => errors.itemErrors ? setItemErrors(errors.itemErrors) : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  /*
    LOCATION city, state, country
    maybe turn this into a component if you want to copy
  */

  const [getStatesOfCountry, { data: statesOfCountryData }] = useLazyQuery(QUERY_STATESOFCOUNTRY)
  const [getCitiesOfState, { data: citiesOfStateData }] = useLazyQuery(QUERY_CITIESOFSTATE)

  const { location } = data

  // country
  const [countryHasNoData, setCountryHasNoData] = useState(true)
  const [countries] = useState(countriesData.countries)
  const [cscCountryId, setCscCountryId] = useState(location.cscCountryId)

  useEffect(async () => {
    if (cscCountryId !== location.cscCountryId || !location.cscCountryId) {
      form.setFieldsValue({
        cscStateId: null,
        cscCityId: null
      })
      setCscStateId()
      setCscCityId()
    }

    if (cscCountryId) getStatesOfCountry({ variables: { countryId: cscCountryId } })
  }, [cscCountryId])

  useEffect(() => {
    if (statesOfCountryData) {
      const { statesOfCountry } = statesOfCountryData
      setStates(statesOfCountry)
      statesOfCountry.length === 0 && cscCountryId ? setCountryHasNoData(true) : setCountryHasNoData(false)
    } else {
      setCountryHasNoData(true)
    }
  }, [statesOfCountryData])

  // state
  const [states, setStates] = useState([])
  const [cscStateId, setCscStateId] = useState(location.cscStateId)

  useEffect(async () => {
    if (cscStateId !== location.cscStateId) {
      form.setFieldsValue({
        cscCityId: null
      })
      setCscCityId()
    }
    if (cscStateId) getCitiesOfState({ variables: { stateId: cscStateId } })
    if (!cscStateId) setCities([])
  }, [cscStateId])

  useEffect(() => {
    if (citiesOfStateData) setCities(citiesOfStateData.citiesOfState)
  }, [citiesOfStateData])

  // city
  const [cities, setCities] = useState([])
  const [cscCityId, setCscCityId] = useState(location.cscCityId)

  return (
    <>
      {data &&
        <Form
          form={form}
          id='locationForm'
          name='locationForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.location}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >

          <Divider orientation='left'>Location</Divider>

          <Form.Item
            label='Name'
            name='name'
            hasFeedback
            validateStatus={itemErrors.name ? 'error' : ''}
            help={itemErrors.name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Notes'
            name='note'
            hasFeedback
            validateStatus={itemErrors.note ? 'error' : ''}
            help={itemErrors.note}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>

          <Form.Item
            label='Image URL'
            name='imageURL'
            hasFeedback
            validateStatus={itemErrors.imageURL ? 'error' : ''}
            help={itemErrors.imageURL}
          >
            <Input placeholder='https://' />
          </Form.Item>

          <Divider orientation='left'>Address</Divider>

          <Form.Item
            label='Country'
            name='cscCountryId'
            hasFeedback
            validateStatus={itemErrors.cscCountryId ? 'error' : ''}
            help={itemErrors.cscCountryId}
          >
            <Select
              defaultValue={cscCountryId}
              style={{ width: '100%' }}
              onChange={value => setCscCountryId(value)}
              allowClear='true'
              showSearch='true'
              optionFilterProp='children'
              filterOption={
                (input, option) => option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {
                countries.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item
            label='State / Province'
            name='cscStateId'
            hasFeedback
            validateStatus={itemErrors.cscStateMenuId ? 'error' : ''}
            help={itemErrors.cscStateId}
          >
            <Select
              disabled={countryHasNoData}
              value={cscStateId}
              placeholder={!cscCountryId ? 'First, select country' : 'Select state / province'}
              style={{ width: '100%' }}
              onChange={value => setCscStateId(value)}
              allowClear='true'
              showSearch='true'
              optionFilterProp='children'
              filterOption={
                (input, option) => option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {
                states.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item
            label='City'
            name='cscCityId'
            hasFeedback
            validateStatus={itemErrors.cscCityId ? 'error' : ''}
            help={itemErrors.cscCityId}
          >
            <Select
              disabled={countryHasNoData}
              value={cscCityId}
              placeholder={!cscStateId ? 'First, select state / province' : 'Select city'}
              style={{ width: '100%' }}
              allowClear='true'
              showSearch='true'
              optionFilterProp='children'
              filterOption={
                (input, option) => option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {
                cities.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item
            label='Address line 1'
            name='address1'
            hasFeedback
            validateStatus={itemErrors.address1 ? 'error' : ''}
            help={itemErrors.address1}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Address line 2'
            name='address2'
            hasFeedback
            validateStatus={itemErrors.address2 ? 'error' : ''}
            help={itemErrors.address2}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Zipcode'
            name='postalzip'
            hasFeedback
            validateStatus={itemErrors.postalzip ? 'error' : ''}
            help={itemErrors.postalzip}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Published'
            name='published'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <FormItemsSuperAdmin data={data.location} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={insertLoading || updateLoading}>
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>}
    </>
  )
}

const LocationForm = () => {
  const { id } = useParams()

  const { data, loading, refetch } = useQuery(QUERY_LOCATION, { variables: { id }, skip: !id })
  const { data: countriesData, loading: countriesLoading } = useQuery(QUERY_COUNTRIES)
  const initialValues = {
    location: { }
  }

  const deiData = useQuery(QUERY_LOCATION_FORM_DEIS, { variables: { locationFormDeisInput: { locationId: id } } })

  const { TabPane } = Tabs

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <ItemHeading loading={loading} item={data?.location} refetch={refetch} />
      <Tabs defaultActiveKey='1'>
        <TabPane
          tab={
            <span>
              <MailOutlined />
              Address
            </span>
          }
          key='1'
        >
          <FormContainer loading={loading || countriesLoading} size={2} form={<FormComponent data={{ ...initialValues, ...data }} countriesData={countriesData} />} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <AppstoreOutlined />
              DEI Settings
            </span>
          }
          key='2'
        >
          <LocationDEIValuesForm deiData={deiData} />
        </TabPane>
      </Tabs>
    </Space>
  )
}

export default LocationForm
