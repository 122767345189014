// react
import React, { useEffect, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { memoryUser } from '../auth/appMemory'

// ant design
import { Drawer, Layout, Tooltip } from 'antd'
import LeftSideLayout from './LeftSideLayout'
import Logo from '../components/Logo'

import {
  QUERY_NODE_TREE_QUESTIONNAIRE_LIVE
} from '../../operations/queries/ic/questionnaires'

const { Sider } = Layout

const width = 250

const DrawerAndSider = ({ setCollapsed, collapsed, Auth }) => {
  const user = useReactiveVar(memoryUser)

  const route = useRouteMatch({ path: '/ic/questionnaire/:id' })

  const location = useLocation()

  const [questionnaireLive, setQuestionnaireLive] = useState()

  const [queryQuestionnaireLive, { data }] = useLazyQuery(QUERY_NODE_TREE_QUESTIONNAIRE_LIVE, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  })

  const [icquestionnaireId, setIcquestionnaireId] = useState()

  useEffect(() => {
    if (route?.params?.id) return setIcquestionnaireId(route.params.id)
    return setQuestionnaireLive(null)
  }, [route])

  useEffect(() => {
    if (icquestionnaireId) {
      return queryQuestionnaireLive({
        variables: {
          nodeTreeQuestionnaireLiveInput: { icquestionnaireId }
        }
      })
    }
  }, [icquestionnaireId])

  useEffect(() => {
    if (data?.nodeTreeQuestionnaireLive) return setQuestionnaireLive(data.nodeTreeQuestionnaireLive)
  }, [data])

  if (!user?.currentAccountUserPermissions?.viewAccountAdministrator) return null
  if (!user?.currentAccountUserPermissions?.viewLocationsAdministrator) return null

  const locationRoutes = location.pathname.split('/')
  const ignoreRoutes = ['link', 'survey']
  if (locationRoutes[1] && ignoreRoutes.includes(locationRoutes[1])) return null

  return (
    <>
      {
        user &&
          <>

            <Sider
              theme='dark'
              breakpoint='lg'
              collapsedWidth='0'
              trigger={null}
              width={width}
              style={{
                marginTop: '2px',
                marginBottom: '2px'
              }}
            >
              <LeftSideLayout key='sider' mode='inline' questionnaireLive={questionnaireLive} />
            </Sider>

            <Drawer
              placement='left'
              onClick={(e) => {
                if (e.target.nodeName === 'A') {
                  setCollapsed(false)
                }
              }}
              onClose={() => setCollapsed(false)}
              closable={false}
              visible={collapsed}
              bodyStyle={{ padding: 0 }}
              width={width}
              className='sidebarDrawer'
            >
              <Layout.Sider
                theme='dark'
                trigger={null}
                width='100%'
                style={{ height: '100%' }}
              >
                <Tooltip title='Home'>
                  <div className='menu-logo'>
                    <Logo />
                  </div>
                </Tooltip>
                <LeftSideLayout route={route} key='drawer' mode='inline' className='sidebarMenu' questionnaireLive={questionnaireLive} />
              </Layout.Sider>
            </Drawer>

          </>
      }
    </>
  )
}

export default DrawerAndSider
