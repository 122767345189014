import React from 'react'
import { Alert, Space } from 'antd'
import SimpleLink from './SimpleLink'

const AlertMessage = ({ message, showIcon }) => <Alert showIcon={showIcon} message={message} type='error' closable />

const AlertError = ({ error, showIcon, customerror }) => {
  if (!error && !customerror) return null

  const { graphQLErrors = [] } = error || { }
  if (!customerror && !graphQLErrors[0] && !error?.message) return null

  const { message: gqlMessage, extensions = { } } = graphQLErrors[0] || { }
  const { askToSignUp } = extensions

  const message = customerror || gqlMessage || error.message

  if (askToSignUp) {
    return (
      <Space direction='vertical' style={{ width: '100%' }}>
        <AlertMessage message={message} showIcon={showIcon} />
        <SimpleLink
          to='/signup'
          content='Get started and Sign Up'
        />
      </Space>
    )
  }

  return <AlertMessage message={message} />
}

export default AlertError
