import React, { useEffect, useState } from 'react'

import { useReactiveVar, useLazyQuery } from '@apollo/client'

import { memoryUser, reactiveIcquestionnairesPublished } from '../auth/appMemory'

import TopHeader from './TopHeader'
import DrawerAndSider from './DrawerAndSider'
import LayoutFooter from '../../components/layout/main/Footer'

import { Layout } from 'antd'

import {
  QUERY_ICQUESTIONNAIRES_PUBLISHED
} from '../../operations/queries/ic/questionnaires'

const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)

  const user = useReactiveVar(memoryUser)
  const icquestionnairesPublished = useReactiveVar(reactiveIcquestionnairesPublished)

  const [queryIcquestionnairesPublished, { loading, data, error }] = useLazyQuery(QUERY_ICQUESTIONNAIRES_PUBLISHED, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (!memoryUser()?.permissions?.HAS_ROLES) return
    if (loading) return
    if (!user?.id) return reactiveIcquestionnairesPublished(false)
    if (icquestionnairesPublished) return

    return queryIcquestionnairesPublished()
  }, [user, icquestionnairesPublished, loading, memoryUser()])

  useEffect(() => {
    if (loading) return
    if (data?.icquestionnairesPublished) return reactiveIcquestionnairesPublished(data.icquestionnairesPublished)
  }, [data, loading])

  useEffect(() => {
    if (error) return reactiveIcquestionnairesPublished()
  }, [error])

  return (
    <Layout className='app-layout'>

      <TopHeader collapsed={collapsed} setCollapsed={setCollapsed} />

      <Layout className='content-layout'>

        <DrawerAndSider collapsed={collapsed} setCollapsed={setCollapsed} />

        <Layout>{children}</Layout>

      </Layout>

      <LayoutFooter />

    </Layout>
  )
}

export default AppLayout
