import React, { useCallback, useRef } from 'react'

import { toCanvas } from 'html-to-image'
import JsPDF from 'jspdf'

import ReportDataListing from '../../../../../components/reporting/ReportDataListing'
import ReportChart from './ReportChart'

import { DownloadOutlined } from '@ant-design/icons'
import { Card, Button, Space, Row, Col, Typography } from 'antd'

const ReportCard = ({ key, barChart, orientation }) => {
  if (!orientation) return null

  const { name, chartType, chartDescription, reportFilename } = barChart

  const reportRef = useRef(null)
  const titleRef = useRef(null)
  const chartRef = useRef(null)
  const dataRef = useRef(null)

  const format = 'PNG'
  const compression = 'NONE'
  const padding = 10
  const imagePadding = 3
  const fillColor = '#fff'

  const onButtonClick = useCallback(async buttonClicked => {
    // https://artskydj.github.io/jsPDF/docs/index.html
    const pdf = new JsPDF({ orientation: orientation.key })
    const { pageSize } = pdf.internal
    const pageSizeWidth = pageSize.getWidth()
    const pageSizeHeight = pageSize.getHeight()
    const pdfWidth = pageSizeWidth - (padding * 2)

    let y = padding

    const addCanvasToPdf = (canvas, alias) => {
      try {
        const imgData = canvas.toDataURL('image/png', 1.0)
        const imgProps = pdf.getImageProperties(imgData)
        const { width: imageWidth, height: imageHeight } = imgProps

        const ratio = pdfWidth / imageWidth

        const x = padding
        const w = pdfWidth
        const h = imageHeight * ratio

        // https://artskydj.github.io/jsPDF/docs/jsPDF.html#rect
        pdf.rect(x, y, w, h, 'F')

        // https://artskydj.github.io/jsPDF/docs/module-addImage.html
        pdf.addImage(imgData, format, x, y, w, h, alias, compression)

        y += h + imagePadding

        if (y > pageSizeHeight) {
          y = padding
          pdf.addPage()
        }
      } catch (err) {
        console.log('addCanvasToPdf', err)
      }
    }

    pdf.setFillColor(fillColor)

    const [title, chart, data] = await Promise.all([
      toCanvas(titleRef.current, { cacheBust: true }),
      toCanvas(chartRef.current, { cacheBust: true }),
      toCanvas(dataRef.current, { cacheBust: true })
    ])

    if (buttonClicked === 'report') {
      addCanvasToPdf(title, `title, ${key}`)
      addCanvasToPdf(chart, `chart, ${key}`)
      addCanvasToPdf(data, `data, ${key}`)
    } else if (buttonClicked === 'chart') {
      addCanvasToPdf(title, `title, ${key}`)
      addCanvasToPdf(chart, `chart, ${key}`)
    } else if (buttonClicked === 'data') {
      addCanvasToPdf(title, `title, ${key}`)
      addCanvasToPdf(data, `data, ${key}`)
    }

    await pdf.save(reportFilename)
  }, [reportRef])

  const HeaderTitle = () => {
    return (
      <div id='title' ref={titleRef}>
        <Row>
          {name}
        </Row>
        <Row>
          <Typography.Text type='secondary' style={{ fontSize: 12 }}>
            {chartDescription} - {chartType}
          </Typography.Text>
        </Row>
      </div>
    )
  }

  return (
    <div id='report' ref={reportRef}>
      <Card
        title={<HeaderTitle />}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <div id='chart' ref={chartRef}>
            <ReportChart barChart={barChart} />
          </div>
          <div id='data' ref={dataRef}>
            <ReportDataListing data={barChart.config.data} hideFooter='true' />
          </div>

          <Row justify='space-between'>
            <Col flex='auto'>
              <Space>
                <Button
                  key='chart'
                  type='default'
                  icon={<DownloadOutlined />}
                  onClick={() => onButtonClick('chart')}
                >
                  Chart
                </Button>

                <Button
                  key='data'
                  type='default'
                  icon={<DownloadOutlined />}
                  onClick={() => onButtonClick('data')}
                >
                  Data
                </Button>
              </Space>
            </Col>

            <Col flex='0'>

              <Button
                key='report'
                type='primary'
                icon={<DownloadOutlined />}
                onClick={() => onButtonClick('report')}
              >
                Download Both
              </Button>
            </Col>
          </Row>

        </Space>
      </Card>
    </div>
  )
}

export default ReportCard
