import React from 'react'
import moment from 'moment'

import { Collapse, Statistic, Space } from 'antd'

const { Panel } = Collapse

const ReportStats = ({ stats, minMaxDates }) => {
  const { maxDate, maxDateFromNow, minDate, minDateFromNow } = minMaxDates

  const { responses } = stats
  const header = `You have ${responses} responses that will be reported.`
  return (
    <Collapse
      bordered={false}
    >
      <Panel header={header} key='1' className='site-collapse-custom-panel'>
        <Space direction='vertical'>
          <Space wrap='true'>
            {
              Object.keys(stats).filter(key => key !== '__typename').map(key => (
                <Statistic
                  key={`statkey${key}`}
                  style={{ margin: 10 }}
                  title={key.toUpperCase()}
                  value={stats[key]}
                />
              ))
            }
          </Space>

          <Space wrap='true' align='top'>
            <Statistic
              key='response'
              style={{ margin: 10 }}
              title='TOTAL RESPONSES'
              value={responses}
              valueStyle={{ color: '#3f8600', fontSize: 32 }}
            />
            <Space direction='vertical'>
              <Statistic
                key='mindate'
                style={{ margin: 10 }}
                title={<><div>FIRST RESPONSE</div><div>{minDateFromNow}</div></>}
                value={minDate ? moment(minDate).format('LL') : 'n/a'}
              />
              <Statistic
                key='mindate'
                style={{ margin: 10 }}
                title={<><div>LAST RESPONSE</div><div>{maxDateFromNow}</div></>}
                value={maxDate ? moment(maxDate).format('LL') : 'n/a'}
              />
            </Space>
          </Space>
        </Space>
      </Panel>
    </Collapse>
  )
}

export default ReportStats
