import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, Checkbox, Space, Grid } from 'antd'

// components
import AvatarElement, { TitleElement, NoteElement } from '../AvatarElement'

const { useBreakpoint } = Grid

const QuestionSelectMultiple = ({ icnodeChildId, question }) => {
  const screens = useBreakpoint()
  const { response } = question

  const responses = response ? JSON.parse(response) : []

  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} note={question.note} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={question.response}
        rules={[{ required: question.required, message: 'This field is required' }]}
        className={question.answerIsClickTouch ? 'checkboxImage' : ''}
      >
        <Checkbox.Group>
          <Space direction={question.answerIsClickTouch && screens.md ? 'horizontal' : 'vertical'}>
            {question?.answers && question.answers.map(answer => (
              <Checkbox key={answer.icanswerId} value={answer.icanswerId} checked={() => Boolean(responses.includes(answer.icanswerId))}>
                <AvatarElement
                  title={answer.name}
                  note={!question.answerIsClickTouch ? answer.note : ''}
                  imageURL={answer.imageURL}
                  cloudinaryImage={answer.cloudinaryImage}
                  avatarSize='small'
                  titleStyle={question.answerIsClickTouch && screens.md ? { display: 'block' } : {}}
                />
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionSelectMultiple.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default QuestionSelectMultiple
