import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Input, Row, Col } from 'antd'

import FormWrapper from '../layout/FormWrapper'
import SimpleLink from '../components/SimpleLink'

import { memoryAccessToken } from '../auth/appMemory'
import { networkOnlyAllErrorsVariables } from '../consts'

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const SIGN_UP = gql`
  mutation signUp ($signUpInput: SignUpInput!) {
    signUp (signUpInput: $signUpInput) {
      accessToken
    }
  }
`

const extra = (
  <Row justify='end'>
    <Col>
      <SimpleLink
        to='/signin'
        content='Sign In'
        block='true'
        type='secondary'
      />
    </Col>
  </Row>
)

const SignUp = () => {
  const [form] = Form.useForm()

  const [signUpMutation, { data, loading, error }] = useMutation(SIGN_UP)

  useEffect(() => {
    if (loading) return
    if (data?.signUp?.accessToken) return memoryAccessToken(data.signUp.accessToken)
  }, [data])

  const onFinish = (values) => signUpMutation({
    variables: { signUpInput: { ...values, domainName } },
    ...networkOnlyAllErrorsVariables
  })

  return (
    <FormWrapper
      title='Sign in'
      size='large'
      form={form}
      onFinish={onFinish}
      loading={loading}
      error={error}
      extra={extra}
    >

      <Row wrap justify='space-between'>
        <Col flex={1} style={{ padding: 10 }}>
          <Form.Item
            name='email'
            label='Email'
          >
            <Input autoComplete='on' />
          </Form.Item>

          <Form.Item
            name='firstName'
            label='First name'
          >
            <Input autoComplete='on' />
          </Form.Item>

          <Form.Item
            name='lastName'
            label='Last name'
          >
            <Input autoComplete='on' />
          </Form.Item>

        </Col>
        <Col flex={1} style={{ padding: 10 }}>

          <Form.Item
            name='password'
            label='Password'
          >
            <Input.Password autoComplete='off' />
          </Form.Item>

          <Form.Item
            name='passwordConfirm'
            label='Password Confirm'
          >
            <Input.Password autoComplete='off' />
          </Form.Item>

          <Form.Item
            name='howDidYouHearAboutUsNote'
            label='How did you hear about us?'
          >
            <Input.TextArea
              placeholder='(optional)'
              rows={4}
            />
          </Form.Item>

        </Col>
      </Row>

    </FormWrapper>
  )
}

export default SignUp
