import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Input, Row, Col } from 'antd'
// import { setLocaleStorage } from '../auth/appStorage'

import FormWrapper from '../layout/FormWrapper'
import SimpleLink from '../components/SimpleLink'

import { memoryAccessToken } from '../auth/appMemory'
import { networkOnlyAllErrorsVariables } from '../consts'

const SIGN_IN = gql`
  mutation signIn ($signInInput: SignInInput!) {
    signIn (signInInput: $signInInput) {
      accessToken
    }
  }
`

const extra = (
  <Row justify='space-between'>
    <Col>
      <SimpleLink
        to='/forgotpassword'
        content='Forgot Password'
        type='secondary'
        block='true'
      />
    </Col>
    <Col>
      <SimpleLink
        to='/signup'
        content='Sign Up'
        block='true'
        type='secondary'
      />
    </Col>
  </Row>
)

const SignIn = () => {
  const [form] = Form.useForm()

  const [signInMutation, { data, loading, error }] = useMutation(SIGN_IN)

  useEffect(() => {
    if (loading) return
    if (data?.signIn?.accessToken) return memoryAccessToken(data.signIn.accessToken)
  }, [data, loading])

  return (
    <FormWrapper
      title='Sign in'
      size='large'
      form={form}
      onFinish={(values) => signInMutation({
        variables: { signInInput: values },
        ...networkOnlyAllErrorsVariables
      })}
      loading={loading}
      error={error}
      extra={extra}
    >

      <Form.Item
        name='email'
        label='Email'
      >
        <Input />
      </Form.Item>

      <Form.Item
        name='password'
        label='Password'
      >
        <Input.Password autoComplete='on' />
      </Form.Item>

    </FormWrapper>
  )
}

export default SignIn
