import React, { useEffect, useState } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { PageHeader, Table } from 'antd'
import { memoryUser } from '../auth/appMemory'

import AlertError from '../components/AlertError'
import TokenExpiryView from '../components/TokenExpiryView'

const TheTable = ({ loading, tableData }) => (
  <Table
    bordered
    loading={loading}
    {...tableData}
    pagination={false}
    size='small'
  />
)

export const QUERY_ME_VERIFICATION_HISTORY = gql`
  query queryMyVerifificationHistory {
    me {
      id
      emailIsVerified
      formattedEmailVerifiedDates {
        id
        email
        domainName
        verificationRequestDate
        verificationExpires
        verifiedDate
        verificationRequestDateAgo
        verificationExpiresAgo
        didExpire
        verifiedDateAgo
      }
    }
  }
`

const VerificationHistory = ({ sent }) => {
  useReactiveVar(memoryUser)
  const { data, loading, error, refetch } = useQuery(QUERY_ME_VERIFICATION_HISTORY, {
    // pollInterval: user ? 5000 : 0
  })

  // stopPolling()

  const [tableData, setTableData] = useState()

  useEffect(() => {
    if (sent) return refetch()
  }, [sent, refetch])

  const columns = [
    // {
    //   label: 'Requested from',
    //   key: 'domainName',
    //   render: (value, record) => (
    //     <>
    //       {record?.domainName}
    //     </>
    //   )
    //   // sorter: (a, b) => a.verificationRequestDateAgo.localeCompare(b.verificationRequestDateAgo)
    // },
    {
      label: 'Sent',
      key: 'Requested',
      render: (value, record) => (
        <>
          {record?.verificationRequestDateAgo}
        </>
      )
      // sorter: (a, b) => a.verificationRequestDateAgo.localeCompare(b.verificationRequestDateAgo)
    },
    {
      label: 'Sent to',
      key: 'email',
      render: (value, record) => (
        <>
          {record?.email}
        </>
      )
      // sorter: (a, b) => a.verificationRequestDateAgo.localeCompare(b.verificationRequestDateAgo)
    },
    {
      label: 'Expires',
      key: 'Expires',
      render: (value, record) => (
        <>
          {record?.verificationExpiresAgo}
        </>
      )
    },
    {
      label: 'Verification',
      key: 'verification',
      render: (value, record) => (
        <TokenExpiryView
          key={`verfiyexpiry_${record.key}`}
          expired={record?.didExpire}
          acceptedFromNow={record?.verifiedDateAgo}
          expiresFromNow={record?.verificationExpiresAgo}
        />
      )
    }
  ]

  useEffect(() => {
    if (!data?.me?.formattedEmailVerifiedDates) return
    const dataSource = []
    data.me.formattedEmailVerifiedDates.forEach(record => {
      const key = record.id.toString()
      dataSource.push({ ...record, key })
    })
    setTableData({ columns, dataSource })
  }, [data])

  return (
    <PageHeader
      style={{ padding: 0 }}
      title='Verification History'
    >
      <TheTable
        scroll={{ x: 700 }}
        loading={loading}
        tableData={tableData}
      />

      <AlertError error={error} />
    </PageHeader>
  )
}

export default VerificationHistory
