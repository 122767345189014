import React from 'react'

import { memoryUser } from './appMemory'

import CenterContent from '../components/CenterContent'

import { Alert } from 'antd'

const CheckCurrentAccount = () => {
  if (memoryUser().currentAccountId) return null

  return (
    <CenterContent>
      <Alert message='Please select the account you wish to manage' type='warning' />
    </CenterContent>
  )
}

export default CheckCurrentAccount
