// react
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { memoryUser } from '../../../../../../core/auth/appMemory'
import ItemCardMeta from '../../../../../../core/components/ItemCardMeta'

// ant design
import { Button, Tooltip } from 'antd'

// operations
import { QUERY_CATEGORIES } from '../../../../../../operations/queries/ic/categories'

// components
import Listing from '../../../../../Listing'

const CategoryList = () => {
  const { permissions } = memoryUser()

  const add = permissions?.menuAccess?.add.includes('Iccategory')
  const edit = permissions?.menuAccess?.edit.includes('Iccategory')
  const list = permissions?.menuAccess?.list.includes('Iccategory')

  if (!list) {
    useHistory().push('/')
    return null
  }

  const extra = (
    <>
      {add && <Tooltip title='Add new category'><Link to='./category'><Button type='primary'>Add Category</Button></Link></Tooltip>}
    </>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => {
        if (!edit) return <ItemCardMeta key={record.id} size='large' item={record} view='small' />
        return (
          <Link to={`/admin/ic/templates/category/${record.id}`}>
            <ItemCardMeta key={record.id} size='large' item={record} view='small' />
          </Link>
        )
      }
    },
    {
      title: 'Published',
      dataIndex: 'published',
      sorter: (a, b) => a > b,
      render: (value) => value ? 'TRUE' : 'FALSE'
    }
  ]

  return (
    <Listing
      criterias={{ accountId: memoryUser()?.currentAccountId }}
      query={QUERY_CATEGORIES}
      columns={columns}
      dataKey='iccategorys'
      defaultSort={{
        sortField: 'name',
        sortDirection: 1
      }}
      extra={extra}
    />
  )
}

export default CategoryList
