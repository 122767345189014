import { useEffect, useState } from 'react'
import { gql, useReactiveVar, useLazyQuery } from '@apollo/client'
import { memoryUser, refreshUser, memoryAccessToken } from './appMemory'
import { setLocaleStorage } from './appStorage'
import { ME_FIELDS, LOGO_DOT_RING_COLOR, APP_COLOR, networkOnlyAllErrorsVariables } from '../consts'

import { Row, Space, Typography, Image, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Text } = Typography

const debug = false

const QUERY_ME = gql`
  query queryRefreshMe {
    me {
      ${ME_FIELDS}
    }
  }
`

const key = 'refreshuser'
const duration = 3
const options = {
  key,
  duration
}

const TriggerWrapper = ({ children }) => {
  const refresh = useReactiveVar(refreshUser)
  // const storageAccessToken = returnLocaleStorage('accessToken')
  // const sessionAccessToken = memoryAccessToken()
  const sessionUser = memoryUser()

  const [currentRefresh, setCurrentRefresh] = useState(refresh)

  const [queryRefreshMe, { data, loading, error }] = useLazyQuery(QUERY_ME, networkOnlyAllErrorsVariables)

  useEffect(() => {
    if (refresh !== currentRefresh) {
      return setCurrentRefresh(refresh)
    }

    if (currentRefresh) {
      debug && message.loading({ content: 'Refreshing', ...options })
      return queryRefreshMe()
    }
  }, [refresh, currentRefresh])

  useEffect(() => {
    if (!currentRefresh) return
    if (loading) return

    if (data?.me?.id) {
      debug && message.success({ content: 'Refreshed', ...options, duration: 1 })
      memoryUser(data.me)
      return refreshUser(false)
    }
  }, [currentRefresh, data, loading])

  useEffect(() => {
    if (!currentRefresh) return
    if (error) {
      const content = error?.message || 'unknown error'
      setLocaleStorage('accessToken', null)
      message.error({ content, ...options, duration: 5 })
      memoryUser(false)
      memoryAccessToken(false)
      return refreshUser(false)
    }
  }, [currentRefresh, error])

  if (sessionUser) return children

  if (currentRefresh) return <ShowCheckingToken currentRefresh={currentRefresh} />

  return children
}

export default TriggerWrapper

const ShowCheckingToken = ({ currentRefresh }) => {
  return (
    <Row justify='center' align='middle' style={{ backgroundColor: 'black', width: '100%', height: '100vh' }}>
      <Space
        direction='vertical'
        size='large'
        align='center'
      >
        <Image
          src={LOGO_DOT_RING_COLOR}
          preview={false}
        />

        <Text style={{ color: APP_COLOR }}>
          <Space>
            <LoadingOutlined />
            {currentRefresh}
          </Space>
        </Text>

      </Space>
    </Row>

  )
}
