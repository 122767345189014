import { matchPath } from 'react-router'
import ReactGA from 'react-ga4'

import { returnLocaleStorage } from './appStorage'

const { REACT_APP_TITLE, REACT_APP_ENV } = process.env

const GA_MEASUREMENT_ID = 'G-8BRNHS56HF'

const initializeOptions = {
  testMode: ['local'].includes(REACT_APP_ENV)
}

export const sendGoogleAnalyticsPageview = ({ location, route }) => {
  const { pathname } = location
  const { exact } = route

  const match = matchPath(
    pathname,
    route,
    exact
  )

  if (!match) return

  ReactGA.initialize(GA_MEASUREMENT_ID, initializeOptions)

  const hitType = 'pageview'
  const page = location.pathname
  const title = `${REACT_APP_TITLE} · ${route.label}`

  document.title = title

  const options = {
    hitType,
    page,
    title,
    gaOptions: {
      userId: returnLocaleStorage('tid')
    }
  }

  ReactGA.send(options)
}
