import React from 'react'
import PropTypes from 'prop-types'
import { memoryUser } from '../../../core/auth/appMemory'
import ItemCardMeta from '../../../core/components/ItemCardMeta'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_ACCOUNT_GROUPS } from '../../../operations/queries/accountGroups'

// Contexts
// import AuthContext from '../../../utils/authProvider'

// ant design
import { Form, Select } from 'antd'

const FormItemSelectAccountGroup = ({ label, name, validateStatus, help, hasFeedback, mode, disabled, allowClear }) => {
  const { data, loading } = useQuery(QUERY_ACCOUNT_GROUPS, { variables: { filter: { accountId: memoryUser().currentAccountId } } })
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        disabled={disabled}
        mode={mode}
        loading={loading}
        optionFilterProp='children'
        allowClear={allowClear}
      >
        {data?.accountgroups && data.accountgroups.map(accountgroup => (
          <Select.Option key={accountgroup.id} value={accountgroup.id}>
            <ItemCardMeta item={accountgroup} view='small' size='small' />
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

FormItemSelectAccountGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.func,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string
}

export default FormItemSelectAccountGroup
