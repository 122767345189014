// react
import React from 'react'

import RoutedLiveQuestionnaireMenu from './RoutedLiveQuestionnaireMenu'
import LiveQuestionnairesMenu from './LiveQuestionnairesMenu'
import AccountAdminsistrationMenu from './AccountAdminsistrationMenu'

import { Grid } from 'antd'

const { useBreakpoint } = Grid

const LeftSideLayout = ({ mode, style, className, questionnaireLive }) => {
  const screens = useBreakpoint()

  return (
    <>
      <RoutedLiveQuestionnaireMenu mode='inline' className='questionnaireMenu' questionnaireLive={questionnaireLive} />

      {/* <Typography.Title level={5} className='title'>MENU</Typography.Title> */}

      {
        !screens.md &&
          <LiveQuestionnairesMenu parent='LeftSideLayout' className='sideMainmenu' theme='light' mode='inline' showBullets disabledTitleClick />
      }

      <AccountAdminsistrationMenu key='leftsidelayout' mode='inline' className='adminMenu' />
    </>
  )
}

export default LeftSideLayout
