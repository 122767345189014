import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ItemCardMeta from '../../../../../../core/components/ItemCardMeta'

// ant design
import { Table, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

// libraries
import { arrayMoveImmutable } from 'array-move'

// components
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import AnswerModalForm from './AnswerModalForm'

// apollo & operations
import { useMutation } from '@apollo/client'
import { UPDATE_ANSWER } from '../../../../../../operations/mutations/ic/answers'

// contexts
// import { AuthContext } from '../../../../../../utils/authProvider'

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)

const columns = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '100%',
    className: 'drag-visible',

    render: (value, record) => <ItemCardMeta key={record.id} size='large' item={record} view='small' />
  },
  {
    title: 'Published',
    dataIndex: 'published',
    sorter: (a, b) => a > b,
    render: (value) => value ? 'TRUE' : 'FALSE'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: 100,
    className: 'drag-visible'
  }
]

const SortableItem = sortableElement(props => <tr {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)

const AnswersList = ({ accountId, answers, questionId, onChange, refetch }) => {
  // const accountId = memoryUser().currentAccountId
  const [dataSource, setDataSource] = useState([])
  const [formVisible, setFormVisible] = useState(false)
  const [editAnswer, setEditAnswer] = useState({})
  const [deleting, setDeleting] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [updateAnswer] = useMutation(UPDATE_ANSWER)

  const openModal = (answer) => {
    setEditAnswer(answer)
    setFormVisible(true)
  }

  useEffect(() => {
    if (answers?.length) {
      const source = []
      for (const index in answers) {
        source[index] = { ...answers[index] }
        source[index].action = <a onClick={() => openModal(source[index])}>Edit</a>
      }
      setDataSource(source)
    }
  }, [answers])

  const deleteSelected = async () => {
    try {
      setDeleting(true)
      await Promise.all(selectedRows.map(async answer => {
        if (answer.id.startsWith('new_') === false) {
          await updateAnswer({ variables: { id: answer.id, icanswerInput: { ...answer, hidden: true, action: undefined, __typename: undefined, id: undefined } } })
        }
      }))
      setDeleting(false)
      removeRows(selectedRows.map(r => r.id))
    } catch (e) {
      setDeleting(false)
    }
  }

  const removeRows = (answerIds) => {
    const newSource = dataSource.filter(r => answerIds.indexOf(r.id) === -1)
    setDataSource(newSource)
    if (onChange) onChange(newSource)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const answer = dataSource[oldIndex]
      updateAnswer({ variables: { id: answer.id, icanswerInput: { ...answer, order: newIndex, action: undefined, __typename: undefined, id: undefined } } })
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el)
      setDataSource(newData)
    }
  }

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.id === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  DraggableBodyRow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
  }

  const addAnswer = () => {
    const newAnswer = {
      id: 'new_' + Date.now(),
      name: '-',
      order: dataSource.length,
      icquestionId: questionId,
      accountId
    }
    openModal(newAnswer)
    newAnswer.action = <a onClick={() => openModal(newAnswer)}>Edit</a>
    setDataSource([...dataSource, newAnswer])
  }

  return (
    <>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey='id'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
        rowSelection={{
          selectedRows,
          getCheckboxProps: record => {
            return {}
          },
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
          }
        }}
        footer={() =>
          <>
            <Button type='danger' onClick={deleteSelected} disabled={!selectedRows.length} loading={deleting}>
              Delete
            </Button>
            <Button type='primary' onClick={addAnswer} style={{ marginLeft: '12px' }}>
              Add answer
            </Button>
          </>}
      />
      {
        formVisible &&
          <AnswerModalForm
            refetch={refetch}
            visible={formVisible}
            setVisible={setFormVisible}
            answer={editAnswer}
            onChange={() => {
              if (onChange) onChange(dataSource)
            }}
          />
    }
    </>
  )
}

AnswersList.propTypes = {
  accountId: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  questionId: PropTypes.string.isRequired
}

export default AnswersList
