import React from 'react'
import PropTypes from 'prop-types'

// ant design
import { Form, DatePicker } from 'antd'

// components
import { TitleElement, NoteElement } from '../AvatarElement'

// other libraries
import moment from 'moment'

const QuestionDate = ({ icnodeChildId, question }) => {
  const { response } = question

  let initialValue = ''
  try {
    if (response) {
      const parsed = JSON.parse(response)
      if (parsed) initialValue = moment(parsed)
    }
  } catch (e) {
    // console.error('Could\'nt parse the json reponse.', e)
    initialValue = ''
  }

  return (
    <>
      <Form.Item
        label={<TitleElement cloudinaryImage={question.cloudinaryImage} imageURL={question.imageURL} title={question.name} style={{ fontWeight: '600' }} />}
        name={icnodeChildId}
        initialValue={initialValue}
        rules={[{ required: question.required, message: 'This field is required' }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          picker='date'
          format='dddd, MMMM Do, yyyy'
        />
      </Form.Item>
      <NoteElement note={question.note} />
    </>
  )
}

QuestionDate.propTypes = {
  icnodeChildId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired
}

export default QuestionDate
