import React from 'react'
import PropTypes from 'prop-types'
// import { memoryUser } from '../../../core/auth/appMemory'
import ItemCardMeta from '../../../core/components/ItemCardMeta'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_TEMPLATES } from '../../../operations/queries/ic/templates'

// ant design
import { Form, Select } from 'antd'

const FormItemSelectTemplate = ({ label, name, validateStatus, help, hasFeedback, mode, disabled, allowClear }) => {
  const { data, loading } = useQuery(QUERY_TEMPLATES, { variables: { } })
  // const { data, loading } = useQuery(QUERY_TEMPLATES, { variables: { filter: { accountId: memoryUser().currentAccountId } } })
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        disabled={disabled}
        mode={mode}
        loading={loading}
        optionFilterProp='children'
        allowClear={allowClear}
      >
        {data?.ictemplates && data.ictemplates.map(template => (
          <Select.Option key={template.id} value={template.id}>
            <ItemCardMeta item={template} view='small' size='small' />
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

FormItemSelectTemplate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.func,
  validateStatus: PropTypes.string,
  disabled: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string
}

export default FormItemSelectTemplate
