import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Input, Row, Col, Alert, Space } from 'antd'

import FormWrapper from '../layout/FormWrapper'
import CenterContent from '../components/CenterContent'
import SimpleLink from '../components/SimpleLink'

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const FORGOT_PASSWORD = gql`
  mutation sendEmailPasswordResetByEmail ($email: String, $passwordResetInput: PasswordResetInput!) {
    sendEmailPasswordResetByEmail (email: $email, passwordResetInput: $passwordResetInput)
  }
`

const extra = (
  <Row justify='end'>
    <Col>
      <SimpleLink
        to='/signin'
        content='Sign in'
        type='secondary'
        block='true'
      />
    </Col>
  </Row>
)

const heading = (
  <Alert style={{ textAlign: 'center' }} message='We can send you a password reset link to your Email. This link expires to help ensure your security.' type='warning' />
)

const ForgotPassword = () => {
  const [form] = Form.useForm()

  const [sent, setSent] = useState()

  const [forgotPasswordMutation, { data, loading, error }] = useMutation(FORGOT_PASSWORD)

  useEffect(() => {
    if (!data?.sendEmailPasswordResetByEmail) return
    setSent(true)
  }, [data])

  return (
    <>
      {
        sent &&
          <CenterContent title='Forgot password' bordered>
            <Space size='large' direction='vertical' style={{ width: '100%' }}>
              <Alert message='Email sent. Click the token we sent to your Email to reset your password.' type='success' />

              <Row justify='end'>
                <Col>
                  <SimpleLink
                    to='/'
                    content='Go to Home'
                    block='true'
                    type='secondary'
                  />
                </Col>
              </Row>
            </Space>
          </CenterContent>
      }
      {
        !sent &&
          <FormWrapper
            heading={heading}
            title='Forgot password'
            size='large'
            form={form}
            onFinish={(values) => forgotPasswordMutation({
              variables: { ...values, passwordResetInput: { domainName } },
              errorPolicy: 'all'
            })}
            loading={loading}
            error={error}
            extra={extra}
          >

            <Form.Item
              name='email'
              label='Email'
            >
              <Input autoComplete='on' />
            </Form.Item>

          </FormWrapper>

      }
    </>
  )
}

export default ForgotPassword
