import React from 'react'
import { Bar } from '@ant-design/charts'

import { Modal } from 'antd'

const ReportChart = ({ barChart }) => {
  if (!barChart) return null
  const [modal, contextHolder] = Modal.useModal()

  const config = {
    ...barChart.config,
    onReady: (plot) => {
      plot.on('element:click', (event) => {
        const suggestion = event.data.data.suggestion
        suggestion && openSuggestion(suggestion)
      })
    },
    autoFit: true,
    legend: {
      position: 'top'
    },
    marginRatio: 0.1,
    label: {
      style: {
        fontSize: 13
      },
      position: 'right'
    },
    barStyle: {
      cursor: 'pointer'
    }
  }

  const openSuggestion = content => {
    const title = 'Suggestion'
    modal.info({ title, content })
  }

  return (
    <>
      <Bar {...config} />
      {contextHolder}
    </>
  )
}

export default ReportChart
