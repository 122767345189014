import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { PageHeader, Table, Typography, List, Button, Skeleton, Row, Col, Modal, Space } from 'antd'

import AlertError from '../../components/AlertError'
import TokenExpiryView from '../../components/TokenExpiryView'
import ItemCardMeta from '../../components/ItemCardMeta'
import InvitationEdit from './InvitationEdit'
import TipImpactCalculatorAboutAdmins from '../tips/TipImpactCalculatorAboutAdmins'

import { USER_FIELDS, CORE_FIELDS, LOCATION_CORE_FIELDS } from '../../consts'

const { Text } = Typography

const INVITES = `
  account (id: $id) {
    invites {
      ${USER_FIELDS}
      key
      lastName
      fullName
      locationIds
      accountgroupIds
      userLevel

      invitationEmailFromNow,
      invitationExpiresFromNow,
      invitationExpired
      acceptedDateFromNow

      locations {
        ${LOCATION_CORE_FIELDS}
      }
      accountgroups {
        ${CORE_FIELDS}
      }

      acceptedUser {
        ${USER_FIELDS}
      }
    }
  }
`
export const QUERY_ACCOUNT_INVITATION_HISTORY = gql`
  query account ($id: ID!) {
    ${INVITES}
  }
`

const InvitationHistory = ({ setActiveKey, accountloading, account }) => {
  if (!account) return <Skeleton active avatar paragraph />

  const { id } = account

  const { data, loading, error, refetch } = useQuery(QUERY_ACCOUNT_INVITATION_HISTORY, {
    variables: { id },
    errorPolicy: 'all'
  })

  const [tableData, setTableData] = useState()

  const columns = [
    {
      label: 'Role',
      key: 'role',
      render: (value, record) => (
        <>
          {record.userLevel === 'accountAdmin' && <>Account Administrator</>}
          {(record.userLevel === 'locationAdmin' || record.canAdministerLocations) && <>Location Administrator</>}
          {record.userLevel === 'contributor' && <>Contributor</>}
        </>
      )
    },
    {
      label: 'Sent to',
      key: 'fullName',
      render: (value, record) => {
        const { acceptedUser } = record
        if (acceptedUser) return <ItemCardMeta key={`acceptedUser${acceptedUser.id}`} view='small' size='small' item={acceptedUser} />
        return <Text copyable>{record?.email}</Text>
      }
    },
    {
      label: 'Invitation',
      key: 'invitation',
      render: (value, record) => <InvitationExpiryView record={record} account={account} refetch={refetch} />
    }
  ]

  const expandable = {
    expandedRowRender: record => (
      <List itemLayout='horizontal'>

        <List.Item>
          <List.Item.Meta
            key={`invitelocations_${record.key}`}
            title={<Typography.Title level={5}>Locations</Typography.Title>}
            description={record?.locations?.map(i => <ItemCardMeta key={i.key} size='medium' item={i} />)}
          />
        </List.Item>

        <List.Item>
          <List.Item.Meta
            key={`inviteaccountgroups_${record.key}`}
            title={<Typography.Title level={5}>Groups</Typography.Title>}
            description={record?.accountgroups?.map(i => <ItemCardMeta key={i.key} size='medium' item={i} />)}
          />
        </List.Item>

      </List>
    )
  }

  useEffect(() => {
    if (!data?.account?.invites) return
    const dataSource = data.account.invites

    setTableData({ expandable, columns, dataSource })
  }, [data])

  const isLoading = Boolean(loading || accountloading)

  return (
    <PageHeader
      style={{ padding: 0 }}
      title='Invitation history'
    >
      {
        tableData?.dataSource.length === 0 &&
          <Space size='large' direction='vertical'>

            <Typography.Text italic>You have no history.</Typography.Text>

            <Typography.Text>
              You could <Typography.Link onClick={() => setActiveKey('inviteUser')}>invite users</Typography.Link> to help manage {account.name}.
            </Typography.Text>
            <Typography.Text> You add and remove users anytime in <Typography.Link onClick={() => setActiveKey('userManagement')}> User managment</Typography.Link>.
            </Typography.Text>

            <TipImpactCalculatorAboutAdmins />

          </Space>

      }
      {
        (isLoading || tableData?.dataSource?.length > 0) &&
          <TheTable loading={isLoading} tableData={tableData} />
      }

      <AlertError error={error} />
    </PageHeader>
  )
}

export default InvitationHistory

const InvitationChange = ({ record, account, refetch }) => {
  const [visible, setVisisble] = useState()

  return (
    <>
      <Col>
        <Button
          key={`inviteedit_${record.key}`}
          size='small'
          type='link'
          onClick={() => setVisisble(true)}
        >
          Change invitation
        </Button>
      </Col>

      <Modal
        header={null}
        width='90vmin'
        title='Change invitation'
        visible={visible}
        onCancel={() => setVisisble(false)}
        footer={null}
      >
        <InvitationEdit record={record} account={account} refetch={refetch} setVisisble={setVisisble} />
      </Modal>
    </>
  )
}

const InvitationExpiryView = ({ record, refetch, account }) => {
  return (
    <Row>
      <Col flex='1'>

        <TokenExpiryView
          key={`inviteexpiry_${record.key}`}
          expired={record?.invitationExpired}
          acceptedFromNow={record?.acceptedDateFromNow}
          expiresFromNow={record?.invitationExpiresFromNow}
        />

      </Col>
      {!record?.acceptedDateFromNow && <InvitationChange key={`invitechange_${record.key}`} record={record} account={account} refetch={refetch} />}
    </Row>
  )
}

const TheTable = ({ loading, tableData }) => (
  <Table
    scroll={{ x: 700 }}
    loading={loading}
    {...tableData}
  />
)
