import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Form, Input, message, Space, Card, Descriptions, Typography, Row } from 'antd'

import { PROFILE_FIELDS } from '../consts'
import { memoryUser, refreshUser } from '../auth/appMemory'

import VerifyUserEmailAddress from '../models/VerifyUserEmailAddress'
import FormWrapper from '../layout/FormWrapper'
import RouteErrorDisplay from '../components/RouteErrorDisplay'
import ItemHeading from '../components/ItemHeading'
import ItemCardMeta from '../components/ItemCardMeta'
import SimpleLink from '../components/SimpleLink'

import ContentWrapper from '../layout/ContentWrapper'

export const QUERY_PROFILE = gql`
  query queryProfile {
    me {
      ${PROFILE_FIELDS}
    }
  }
`

const UPDATE_ME = gql`
  mutation updateMe ($updateMeInput: UpdateMeInput!) {
    updateMe (updateMeInput: $updateMeInput) {
      id
    }
  }
`

const breadcrumb = [
  {
    path: '/',
    breadcrumbName: 'Home'
  },
  {
    path: '',
    breadcrumbName: 'Profile'
  }
]

const { REACT_APP_DOMAIN_NAME: domainName } = process.env

const Profile = () => {
  const [form] = Form.useForm()

  const { data, loading, error, refetch } = useQuery(QUERY_PROFILE, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  })

  const [updateMe, { data: updatemedata, loading: updatemeloading, error: updatemeerror }] = useMutation(UPDATE_ME)

  const [me, setMe] = useState()

  useEffect(() => {
    if (data?.me?.id) return setMe(data.me)
  }, [data, loading])

  useEffect(() => {
    if (updatemedata?.updateMe?.id) {
      message.success('Profile saved')
      refreshUser('Profile, profile saved')
      refetch()
    }
  }, [updatemedata])

  useEffect(() => {
    if (me) {
      refreshUser('Profile, mechanged')
    }
  }, [me, loading])

  if (error) return <RouteErrorDisplay error={error} />

  return (
    <ContentWrapper breadcrumb={breadcrumb}>
      <Space direction='vertical' style={{ width: '100%' }}>
        {
          !memoryUser().emailIsVerified &&
            <Card>
              <VerifyUserEmailAddress user={me} subTitle='The Impact Calulcator is one step away. Protect your account and verify your email address.' />
            </Card>
        }

        <ItemHeading loading={loading} item={me} refetch={refetch} />

        {
          me &&
            <>
              <Row justify='end'>
                <SimpleLink
                  to='/password'
                  content='Change my password'
                  type='link'
                />
              </Row>
              <ProfileForm
                data={data}
                form={form}
                updateMe={updateMe}
                updatemeloading={updatemeloading}
                updatemeerror={updatemeerror}
                me={me}
              />

              <CurrentAccountPermissions me={me} />

              {/*
                This was done in rush and is INACCURATE, deprecated by CurrentAccountPermissions
                <RolesView me={me} refetch={refetch} />

                const RolesView = ({ me, refetch }) => {
                  const { permissions } = me
                  const { bestRole } = permissions
                  const { displayableName, note } = bestRole

                  return (
                    <Card
                      title='Roles'
                    >
                      <Descriptions layout='horizontal' bordered>
                        <Descriptions.Item label='User Level' span={3}>{displayableName}</Descriptions.Item>
                        {
                          note &&
                            <Descriptions.Item label={`About: ${displayableName}`} span={3}>{note}</Descriptions.Item>
                        }
                      </Descriptions>
                    </Card>
                  )
                }
              */}

              <PermissionsView me={me} />
            </>
        }

      </Space>

    </ContentWrapper>
  )
}

export default Profile

const PermissionItem = ({ item }) => {
  return (
    <ItemCardMeta key={`permissionItem${item.id}`} item={item} view='small' size={36} bordered='true' />
  )
}

const PermissionsView = ({ me }) => {
  const { permissions } = me
  const { bestRole } = permissions
  const { rank } = bestRole
  if (!rank) return null

  return (
    <Card
      title='Permissions'
    >
      <Descriptions layout='horizontal' bordered>
        {
          rank <= 1 &&
            <Descriptions.Item label={`Accounts you administer [${me.userAdminAccounts.length}]`} span={3}>
              <Space wrap>
                {
                  me.userAdminAccounts.map(item => <PermissionItem key={`userAdminAccounts${item.id}`} item={item} />)
                }
              </Space>
            </Descriptions.Item>
        }

        {
          rank <= 2 &&
            <Descriptions.Item label={`Locations you administer [${me.userAdminLocations.length}]`} span={3}>
              <Space wrap>
                {
                  me.userAdminLocations.map(item => <PermissionItem key={`userAdminLocations${item.id}`} item={item} />)
                }
              </Space>
            </Descriptions.Item>
        }

        {
          rank <= 3 &&
            <>
              <Descriptions.Item label={`Locations you contribute to [${me.userGeneralLocations.length}]`} span={3}>
                <Space wrap>
                  {
                    me.userGeneralLocations.map(item => <PermissionItem key={`userGeneralLocations${item.id}`} item={item} />)
                  }
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label={`You belong in these groups [${me.accountgroups.length}]`} span={3}>
                <Space wrap>
                  {
                    me.accountgroups.map(item => <PermissionItem key={`accountgroups${item.id}`} item={item} />)
                  }
                </Space>
              </Descriptions.Item>

            </>
        }

      </Descriptions>

    </Card>
  )
}

const CurrentAccountPermissions = ({ me }) => {
  if (!me?.currentAccountUserPermissions?.currentAccount) return null

  const { currentAccountUserPermissions } = me
  const { currentAccount, currentAccountUsers, viewAccountAdministrator, viewLocationsAdministrator } = currentAccountUserPermissions

  // fix later to return higest role level for account
  let role = 'Contributor'
  if (viewLocationsAdministrator) role = 'Location administrator'
  if (viewAccountAdministrator) role = 'Account administrator'

  return (
    <Card
      title='Current Account'
    >
      <Descriptions layout='horizontal' bordered>

        <Descriptions.Item label='Account' span={3}>
          <ItemCardMeta item={currentAccount} size={192} bordered='true' />
        </Descriptions.Item>

        <Descriptions.Item label='Your role for this account' span={3}>
          <Typography.Text style={{ fontSize: 36 }}>{role}</Typography.Text>
        </Descriptions.Item>

        {
          viewAccountAdministrator &&
            <Descriptions.Item label={`Curent users [${currentAccountUsers.length}]`} span={3}>
              <Space wrap>
                {
                  currentAccountUsers.map(item => <PermissionItem key={`currentUser${item.id}`} item={item} />)
                }
              </Space>
            </Descriptions.Item>
        }

      </Descriptions>

    </Card>
  )
}

const ProfileForm = ({ form, updateMe, updatemeloading, updatemeerror, me }) => {
  return (
    <FormWrapper
      size='medium'
      form={form}
      preserve={false}
      initialValues={me}
      onFinish={(values) => updateMe({
        variables: { updateMeInput: { ...values, domainName } },
        errorPolicy: 'all'
      })}
      loading={updatemeloading}
      error={updatemeerror}
      fluid='true'
    >

      <Form.Item
        name='email'
        label='Email'
      >
        <Input autoComplete='on' />
      </Form.Item>

      <Form.Item
        name='firstName'
        label='First name'
      >
        <Input autoComplete='on' />
      </Form.Item>

      <Form.Item
        name='lastName'
        label='Last name'
      >
        <Input autoComplete='on' />
      </Form.Item>

      <Form.Item
        name='imageURL'
        label='imageURL'
      >
        <Input />
      </Form.Item>

      <Form.Item
        name='note'
        label='Notes'
      >
        <Input.TextArea
          placeholder='(optional)'
          rows={4}
        />
      </Form.Item>

      <Form.Item
        name='howDidYouHearAboutUsNote'
        label='How did you hear about us?'
      >
        <Input.TextArea
          placeholder='(optional)'
          rows={4}
        />
      </Form.Item>

    </FormWrapper>
  )
}
